import MDTypography from "../MDTypography";

export function SidenavTitle({ textColor, title }) {
  return (
    <MDTypography
      color={textColor}
      display="block"
      variant="caption"
      fontWeight="bold"
      textTransform="uppercase"
      pl={3}
      mt={2}
      mb={1}
      ml={1}
    >
      {title}
    </MDTypography>
  );
}
