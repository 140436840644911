/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";

// Material Dashboard 2 PRO React imageExample components
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import FooterSimple from "../../../components/Footer/FooterSimple";

// WelcomeLeave page components
// Images
import Card from "@mui/material/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRobot } from "@fortawesome/pro-duotone-svg-icons";
import { useParams } from "react-router-dom";
import backgroundImage from "../../../assets/images/bg-profile.jpeg";
import Error404Modal from "../../../components/Dialog/Error404Modal";
import { useSelector } from "react-redux";
import { WhitelistAddForm } from "./WhitelistAddForm";
import WhitelistTable from "./WhitelistTable";

function Whitelist() {
  const { botId } = useParams();

  const { loggedIn } = useSelector((state) => state.auth);

  return (
    <DashboardLayout>
      <DashboardNavbar isAbsolute={loggedIn === false} />
      {loggedIn === false ? <Error404Modal /> : <></>}
      <MDBox mb={2} />
      <MDBox position="relative" mb={5}>
        <MDBox
          display="flex"
          alignItems="center"
          position="relative"
          minHeight="15rem"
          borderRadius="xl"
          sx={{
            backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.info.main, 0.6),
                rgba(gradients.info.state, 0.6)
              )}, url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            overflow: "hidden",
          }}
        />
        <Card
          sx={{
            position: "relative",
            mt: -8,
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <MDTypography color="dark">
                <FontAwesomeIcon icon={faRobot} size="3x" color="info" />
              </MDTypography>
            </Grid>
            <Grid item>
              <MDBox height="100%" mt={0.5} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium">
                  Whitelist
                </MDTypography>
                <MDTypography variant="button" color="text" fontWeight="regular">
                  Erlaube dem Bot neuen Servern beizutreten
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        </Card>
      </MDBox>
      <MDBox mt={5} mb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Card sx={{ height: "100%" }}>
              <MDBox pt={2} px={2}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  Whitelisted Guilds
                </MDTypography>
              </MDBox>
              <MDBox p={2}>
                <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                  <WhitelistTable botId={botId} />
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <WhitelistAddForm botId={botId} />
          </Grid>
        </Grid>
      </MDBox>
      <FooterSimple />
    </DashboardLayout>
  );
}

export default Whitelist;
