// @mui material components

// Material Dashboard 2 PRO React components
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";

// Material Dashboard 2 PRO React imageExample components
import Card from "@mui/material/Card";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faImage,
    faInfoCircle,
    faMemory,
    faRotate,
    faStar,
    faTable,
    faTag,
    faTrash,
    faUserHeadset,
} from "@fortawesome/pro-solid-svg-icons";
import {NavLink} from "react-router-dom";
import DefaultItem from "../../../examples/Items/DefaultItem";
import MDButton from "../../../components/MDButton";
import {useGetBotDetailsQuery} from "../../../store/admin/admin-api";

export function AdminBotPanelCard({
  bot,
  deleteBotEvent,
  restartBotEvent,
  redeemSupportPinEvent,
  deleteBotIsLoading,
  restartBotIsLoading,
  redeemSupportPinIsLoading,
}) {
  const { data: propertyData } = useGetBotDetailsQuery({
    botId: bot.id,
    type: "property",
  });

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={2} px={2} lineHeight={1}>
        <MDBox display="flex" justifyContent="center">
          <MDBox mr={2}>
            <MDTypography variant="h6" fontWeight="medium">
              {bot.name?.toString()}
            </MDTypography>
            <MDTypography variant="button" color="text" fontWeight="regular">
              {bot.botId?.toString()}
            </MDTypography>
          </MDBox>
          <MDBox ml="auto" mr={0} display="flex" gap={1} flexWrap="wrap">
            <NavLink to={`${bot.id}`}>
              <MDButton
                color="info"
                iconOnly
                title="Zeigt dir weitere Informationen an, außerdem kannst du hier den Bot bearbeiten."
              >
                <FontAwesomeIcon icon={faInfoCircle} />
              </MDButton>
            </NavLink>
            <NavLink to={`/admin/entity?botId=${bot.botId}`}>
              <MDButton color="info" iconOnly title="Zeigt dir die Entitys an.">
                <FontAwesomeIcon icon={faTable} />
              </MDButton>
            </NavLink>
            <MDButton
              color="info"
              iconOnly
              onClick={() => redeemSupportPinEvent(bot.botId)}
              title="Erstellt einen Support Pin für den Bot."
            >
              <FontAwesomeIcon icon={faUserHeadset} />
            </MDButton>
            <MDButton
              color="warning"
              iconOnly
              disabled={restartBotIsLoading}
              onClick={() => restartBotEvent(bot.botId)}
              title="Startet den Bot neu."
            >
              <FontAwesomeIcon icon={faRotate} />
            </MDButton>
            <MDButton
              color="error"
              disabled={deleteBotIsLoading}
              iconOnly
              title="Löscht den Bot."
              onClick={() => deleteBotEvent(bot.botId, bot.name, propertyData.botType)}
            >
              <FontAwesomeIcon icon={faTrash} />
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox p={2}>
        <DefaultItem color="dark" icon={faStar} title="Priorität" description={bot.priority} />
        <MDBox mt={2.5}>
          <DefaultItem
            color="dark"
            icon={faTag}
            title="Traefik Label"
            description={bot.traefikLabel ? "Aktiviert" : "Deaktiviert"}
          />
        </MDBox>
        <MDBox mt={2.5}>
          <DefaultItem
            color="dark"
            icon={faMemory}
            title="Max Ram"
            description={`${((bot?.maxRam || 0) / 1000 / 1000).toLocaleString("de-DE")} MB`}
          />
        </MDBox>
        {bot?.customImage && (
          <MDBox mt={2.5}>
            <DefaultItem
              color="dark"
              icon={faImage}
              title="Docker Image"
              description={bot.customImage || "?"}
            />
          </MDBox>
        )}
      </MDBox>
    </Card>
  );
}
