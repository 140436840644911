import Card from "@mui/material/Card";
import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";
import MDButton from "../../../../../components/MDButton";
import Grid from "@mui/material/Grid";
import { useForm } from "react-hook-form";
import { FormCategoryAutocomplete, FormInput } from "../../../../../components/Form/FormInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import apiMessages from "../../../../../apiMessages";
import { useCreateModuleDataMutation } from "../../../../../store/modules/module-api";
import { useGetCategoriesQuery, useGetVoiceChannelsQuery } from "../../../../../store/guild/guild-api";
import Logger from "../../../../../Logger";
import { AutoCompleteChannelIcon } from "../../../../../components/Form/AutoCompleteIcon";

export function PrivateTalkAddForm({ botId, guildId, fullHeight }) {
  const [update, { isLoading }] = useCreateModuleDataMutation();

  const {
    data: categoriesData = [],

    isFetching: categoriesIsFetching,
    refetch: categoriesRefetch,
  } = useGetCategoriesQuery({
    botId,
    guildId,
  });
  const {
    data: voiceChannelData = [],
    refetch: voiceChannelsRefetch,
    isFetching: voiceChannelsIsFetching,
  } = useGetVoiceChannelsQuery({
    botId,
    guildId,
  });

  const formHandler = useForm({
    defaultValues: {
      channelId: "",
      channelName: "",
      userLimit: "",
    },
  });
  const { control, handleSubmit } = formHandler;

  const onSubmit = async (e) => {
    update({
      botId,
      guildId,
      module: "private_talk",
      data: {
        ...e,
      },
    }).then(({ data, error }) => {
      Logger.info("SubmitEvent", { module: "SubmitForm" }, data, error);
      apiMessages.showMessage({
        type: "PrivateTalk",
        title: data ? "added" : error?.data?.error || error,
      });
    });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        height: fullHeight ? "100%" : "auto",
      }}
    >
      <Card sx={{ height: "100%" }}>
        <MDBox pt={2} px={2}>
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            Add PrivateTalk
          </MDTypography>
        </MDBox>
        <MDBox p={2}>
          <Grid item xs={12}>
            <MDBox pt={2} px={2}>
              <FormCategoryAutocomplete
                name="channelId"
                label="Channel"
                categories={categoriesData}
                options={[...voiceChannelData].sort((x, y) => x.category - y.category)}
                groupByFunction="default"
                labelIcon={(option) => <AutoCompleteChannelIcon option={option} />}
                additionalParameters={["type"]}
                formHandler={formHandler}
                refetch={() => {
                  voiceChannelsRefetch();
                  categoriesRefetch();
                }}
                refetching={voiceChannelsIsFetching || categoriesIsFetching}
                rules={{
                  required: "Du musst einen Channel angeben.",
                }}
              />
            </MDBox>
            <MDBox pt={2} px={2}>
              <FormInput
                formHandler={formHandler}
                name="channelName"
                label="Channel Name"
                rules={{
                  required: "Wie soll der erstellte Channel heißen?",
                }}
              />
            </MDBox>
            <MDBox pt={2} px={2}>
              <FormInput
                formHandler={formHandler}
                name="userLimit"
                label="Nutzerlimit"
                type="number"
                rules={{
                  required: "Wie soll der erstellte Channel heißen?",
                  min: {
                    value: 0,
                    message: "Das Nutzerlimit muss mindestens 0 sein.",
                  },
                  max: {
                    value: 99,
                    message: "Das Nutzerlimit darf maximal 99 sein.",
                  },
                }}
              />
            </MDBox>
          </Grid>

          <Grid item xs={12}>
            <MDBox pt={2} px={2}>
              <MDButton
                type="submit"
                variant="contained"
                sx={{ width: "100%" }}
                disabled={isLoading}
                color="primary"
              >
                Hinzufügen
                {isLoading && (
                  <FontAwesomeIcon icon={faSpinnerThird} size="lg" spin style={{ marginLeft: "5px" }} />
                )}
              </MDButton>
            </MDBox>
          </Grid>
        </MDBox>
      </Card>
    </form>
  );
}
