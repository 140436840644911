import { useWatch } from "react-hook-form";
import { FormAutocomplete, FormInput } from "../../../../components/Form/FormInput";
import MDBox from "../../../../components/MDBox";

export function StatusForm({ formHandler }) {
  const activity = useWatch({ name: "status.activity", control: formHandler.control });

  return (
    <>
      <MDBox display="flex" flexDirection="column" gap={1}>
        <FormAutocomplete
          formHandler={formHandler}
          name="status.activity"
          label="Activity"
          options={[
            {
              value: "DEFAULT",
              label: "DEFAULT",
            },
            {
              value: "WATCHING",
              label: "WATCHING",
            },
            {
              value: "STREAMING",
              label: "STREAMING",
            },
            {
              value: "LISTENING",
              label: "LISTENING",
            },
            {
              value: "CUSTOM_STATUS",
              label: "CUSTOM_STATUS",
            },
            {
              value: "COMPETING",
              label: "COMPETING",
            },
          ]}
        />
        <FormInput formHandler={formHandler} name="status.statusText" label="Status Text" />
        {activity === "STREAMING" && (
          <FormInput formHandler={formHandler} name="status.streamUrl" label="Stream Url" />
        )}
      </MDBox>
    </>
  );
}
