import Card from "@mui/material/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/pro-solid-svg-icons";
import { Collapse } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import ImgsViewer from "react-images-viewer";
import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";
import MDButton from "../../../../../components/MDButton";
import { useUnleashFlag } from "../../../../../store/flags/flags-actions";

/**
 * This function takes a string and formats it based on certain patterns.
 * It recognizes the following patterns:
 * - Text enclosed in underscores (_) is underlined.
 * - Text enclosed in asterisks (*) is made bold.
 * - Text enclosed in plus signs (+) is italicized.
 * - Text enclosed in backticks (`) is formatted as code.
 * - Text enclosed in tildes (~) is strikethrough.
 *
 * Any text not matching these patterns is returned as is.
 *
 * @param {string} text - The text to be formatted.
 * @returns {ReactElement} A React fragment containing the formatted text.
 */
function formatText(text) {
  const parts = text.split(/(_.*?_|\*.*?\*|\+.*?\+|`.*?`|~.*?~)/g).map((part, index) => {
    if (/_.*?_/.test(part)) {
      // Für Unterstrichen mit _
      return <u key={index}>{part.substring(1, part.length - 2)}</u>;
    } else if (/\*.*?\*/.test(part)) {
      // Für Fett mit *
      return <strong key={index}>{part.substring(1, part.length - 2)}</strong>;
    } else if (/\+.*?\+/.test(part)) {
      // Für Kursiv mit +
      return <em key={index}>{part.substring(1, part.length - 2)}</em>;
    } else if (/`.*?`/.test(part)) {
      // Für Code
      return <code key={index}>{part.substring(1, part.length - 2)}</code>;
    } else if (/~.*?~/.test(part)) {
      // Für Durchgestrichen
      return <del key={index}>{part.substring(1, part.length - 2)}</del>;
    } else {
      return part; // Unveränderte Teile
    }
  });
  return <>{parts}</>;
}

function HowItWorksHelper({ items = [] }) {
  const [opened, setOpened] = useState(false);
  const [showImages, setShowImages] = useState(false);
  const [imageState, setImageState] = useState(0);

  const moduleHelp = useUnleashFlag("showModuleHelpMenu");

  useEffect(() => {
    const moduleHelp = localStorage.getItem("moduleHelp");
    if (moduleHelp) {
      const moduleHelpParsed = JSON.parse(moduleHelp);
      if (moduleHelpParsed.autoClear) {
        setOpened(true);
      } else {
        setOpened(false);
      }
    }
  }, []);

  const handleOpened = () => {
    setOpened(!opened);
    const moduleHelp = localStorage.getItem("moduleHelp");
    if (moduleHelp) {
      const moduleHelpParsed = JSON.parse(moduleHelp);
      moduleHelpParsed.autoClear = !opened;
      localStorage.setItem("moduleHelp", JSON.stringify(moduleHelpParsed));
    } else {
      localStorage.setItem("moduleHelp", JSON.stringify({ autoClear: !opened }));
    }
  };

  const cols = (length) => {
    if (length > 4) {
      return 3;
    }
    if (length > 2) {
      return 2;
    }
    return 1;
  };

  if (!moduleHelp) return <></>;

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox p={2}>
        <MDBox sx={{ width: "100%", display: "flex" }}>
          <MDButton size="small" onClick={handleOpened} variant="gradient" color="primary">
            Hilfe {opened ? "schließen" : "öffnen"}
          </MDButton>
          <MDButton
            size="small"
            onClick={handleOpened}
            variant="gradient"
            color="primary"
            iconOnly
            sx={{ marginLeft: "auto" }}
          >
            <FontAwesomeIcon
              icon={faAngleUp}
              style={{
                transform: opened ? "rotate(0)" : "rotate(-180deg)",
                transition: "transform .5s",
              }}
            />
          </MDButton>
        </MDBox>
        <Collapse in={opened} unmountOnExit>
          <MDTypography variant="body2" component="div" sx={{ ml: 0, mt: 2 }}>
            <MDBox
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xs: "1fr",
                  md: "1fr 1fr",
                  xl: "repeat(3, 1fr)",
                },

                gridGap: "20px",
                color: "inherit",
              }}
            >
              {items?.map((item, index) => {
                switch (item.type) {
                  case "text":
                    return (
                      <li key={item?.title + index}>
                        <b>{item.title}</b>
                        <br />
                        {formatText(item.description)}
                      </li>
                    );
                  case "image":
                    return (
                      <li key={item?.title + index}>
                        <b>{item.title}</b>
                        <br />
                        <ImgsViewer
                          imgs={item.images.map((img) => ({ src: img.src, caption: img.alt }))}
                          currImg={imageState}
                          isOpen={showImages}
                          onClickPrev={() => {
                            setImageState(imageState - 1);
                          }}
                          onClickNext={() => {
                            setImageState(imageState + 1);
                          }}
                          onClose={() => {
                            setShowImages(false);
                          }}
                        />
                        <MDBox
                          sx={{
                            display: "grid",
                            gridTemplateColumns: {
                              xs: "1fr",
                              md: `repeat(${item.images.length > 1 ? 2 : 1}, 1fr)`,
                              xl: `repeat(${item.cols || item.cols || cols(item.images.length)}, 1fr)`,
                            },

                            gridGap: "5px",
                            color: "inherit",
                          }}
                        >
                          {item.images.map((img, index) => (
                            <MDBox
                              component="img"
                              key={img.alt + index}
                              src={img.src}
                              alt={img.alt}
                              onClick={() => {
                                setImageState(index);
                                setShowImages(true);
                              }}
                              sx={({ palette }) => ({
                                width: "100%",
                                maxHeight: "150px",
                                height: "100%",
                                borderRadius: "10px",
                                objectPosition: "0% 100%",
                                cursor: "pointer",
                                objectFit: "cover",
                                "&:hover": {
                                  boxShadow: `0 0 0 2px ${palette?.primary?.main}`,
                                },
                              })}
                            />
                          ))}
                        </MDBox>
                      </li>
                    );
                  case "table":
                    return (
                      <li key={item?.title + index}>
                        <b>{item?.title}</b>
                        <br />
                        <MDBox
                          component="table"
                          sx={{
                            width: "100%",
                            borderCollapse: "separate",
                            "& th": {
                              padding: "8px",
                              borderBottom: "3px double rgba(125, 125, 125, 1)",
                              "&:not(:last-child)": {
                                borderRight: "1px solid rgba(125, 125, 125, 1)",
                              },
                            },
                            "& td": {
                              padding: "8px",
                              "&:not(:last-child)": {
                                borderRight: "1px dashed rgba(125, 125, 125, 1)",
                              },
                              "&:first-child": {
                                textAlign: "center",
                              },
                            },
                            "& tr:not(:last-child) td": {
                              borderBottom: "1px dashed rgba(125, 125, 125, 1)",
                            },
                            "& tr:nth-of-type(2n)": {
                              backgroundColor: "rgba(129,129,129,0.1)",
                            },

                            border: "3px double rgba(125, 125, 125, 1)",
                            borderRadius: "7.5px",
                            borderSpacing: "0",
                            color: "inherit",
                          }}
                        >
                          <thead>
                            <tr>
                              <th>Parameter</th>
                              <th>Erklärung</th>
                            </tr>
                          </thead>
                          <tbody>
                            {item?.params?.map((param, index) => (
                              <tr key={param?.name + index}>
                                <td>{formatText(param.name)}</td>
                                <td>{formatText(param.description)}</td>
                              </tr>
                            ))}
                          </tbody>
                        </MDBox>
                      </li>
                    );
                  default:
                    return <> </>;
                }
              })}
            </MDBox>
          </MDTypography>
        </Collapse>
      </MDBox>
    </Card>
  );
}

export const HowItWorks = React.memo(HowItWorksHelper);
