/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "../../../../../components/MDBox";

// Material Dashboard 2 PRO React imageExample components
import DashboardLayout from "../../../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../../../examples/Navbars/DashboardNavbar";
import FooterSimple from "../../../../../components/Footer/FooterSimple";

// WelcomeLeave page components
// Images
import {useEffect, useState} from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCog, faList} from "@fortawesome/pro-solid-svg-icons";
import {useParams} from "react-router-dom";
import breakpoints from "../../../../../assets/theme/base/breakpoints";
import ModuleHeader from "../components/ModuleHeader";
import LogsSettingsTabs from "./LogsSettingsTabs";
import LogsOverviewTab from "./LogsOverviewTab";
import Error404Modal from "../../../../../components/Dialog/Error404Modal";
import {faFileLines} from "@fortawesome/pro-duotone-svg-icons";

function LogSystem() {
  const { botId, serverId } = useParams();

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(1);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /**
         The event listener that's calling the handleTabsOrientation function when resizing the window.
         */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Error404Modal />
      <MDBox mb={2} />
      <ModuleHeader
        name="Log System"
        type="LOG_SYSTEM"
        description="Speichere alle Logs in einem Log System"
        botId={botId}
        icon={faFileLines}
        serverId={serverId}
      />
      <MDBox mt={5} mb={3}>
        <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
          <Tab label="Logs" disabled icon={<FontAwesomeIcon icon={faList} size="sm" sx={{ mt: -0.25 }} />} />
          <Tab label="Settings" icon={<FontAwesomeIcon icon={faCog} size="sm" sx={{ mt: -0.25 }} />} />
        </Tabs>

        <Grid container spacing={1} sx={{ marginTop: "10px" }}>
          {tabValue !== 0 ? (
            <LogsSettingsTabs botId={botId} serverId={serverId} />
          ) : (
            <LogsOverviewTab botId={botId} serverId={serverId} />
          )}
        </Grid>
      </MDBox>
      <FooterSimple />
    </DashboardLayout>
  );
}

export default LogSystem;
