import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { useCreateModuleDataMutation } from "../../../../store/modules/module-api";
import { useGetCategoriesQuery, useGetTextChannelsQuery } from "../../../../store/guild/guild-api";
import Logger from "../../../../Logger";
import apiMessages from "../../../../apiMessages";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import { FormCategoryAutocomplete, FormInput } from "../../../../components/Form/FormInput";
import MDButton from "../../../../components/MDButton";
import { hexToDecimal } from "../Tools";
import { AutoCompleteChannelIcon } from "../../../../components/Form/AutoCompleteIcon";
import { dateIsBeforeNow } from "../../../../Utils";

export function SendEmbedForm({ botId, guildId, fullHeight, json }) {
  const [update, { isLoading }] = useCreateModuleDataMutation();

  const {
    data: textChannelData = [],
    isFetching: textChannelIsFetching,
    refetch: textChannelRefetch,
  } = useGetTextChannelsQuery({
    botId,
    guildId,
  });
  const {
    data: categoriesData = [],
    isFetching: categoriesIsFetching,
    refetch: categoriesRefetch,
  } = useGetCategoriesQuery({
    botId,
    guildId,
  });

  const formHandler = useForm({
    defaultValues: {
      channelId: "",
      sendAt: "",
    },
  });
  const { control, handleSubmit } = formHandler;

  const onSubmit = async (e) => {
    const sendJson = JSON.parse(JSON.stringify(json));

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < json.embeds.length; i++) {
      console.log("Color", sendJson.embeds[i].color);
      // eslint-disable-next-line no-restricted-globals
      if (sendJson.embeds[i].color && isNaN(sendJson.embeds[i].color)) {
        console.log("Color Before", sendJson.embeds[i].color);
        sendJson.embeds[i].color = hexToDecimal(sendJson.embeds[i]?.color?.replace("#", ""));
        console.log("Color After", sendJson.embeds[i].color);
      }
      if (sendJson.embeds[i].timestamp) {
        const isoString = new Date(sendJson.embeds[i].timestamp).toISOString();
        console.log("Before", sendJson.embeds[i].timestamp, isoString);
        sendJson.embeds[i].timestamp = isoString; /* .substring(0, isoString.length - 1); */
        console.log("After", sendJson.embeds[i].timestamp, isoString);
      } else {
        sendJson.embeds[i].timestamp = null;
      }
    }

    update({
      botId,
      guildId,
      module: "message",
      isModule: false,
      data: {
        ...e,
        message: sendJson,
        sendAt: e.sendAt ? new Date(e.sendAt).getTime() : "",
      },
    }).then(({ data, error }) => {
      Logger.info("SubmitEvent", { module: "SubmitForm" }, data, error);
      apiMessages.showMessage({
        type: "SendEmbed",
        title: data ? "sent" : error?.data?.error || error,
      });
    });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        height: fullHeight ? "100%" : "auto",
      }}
    >
      <Card sx={{ height: "100%" }}>
        <MDBox pt={2} px={2}>
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            Nachricht senden
          </MDTypography>
        </MDBox>
        <MDBox p={2}>
          <Grid item xs={12}>
            <MDBox pt={2} px={2}>
              <FormCategoryAutocomplete
                name="channelId"
                label="Channel"
                categories={categoriesData}
                options={[...textChannelData].sort((x, y) => x.category - y.category)}
                groupByFunction="default"
                labelIcon={(option) => <AutoCompleteChannelIcon option={option} />}
                additionalParameters={["type"]}
                formHandler={formHandler}
                refetch={() => {
                  textChannelRefetch();
                  categoriesRefetch();
                }}
                refetching={textChannelIsFetching || categoriesIsFetching}
                rules={{
                  required: "Du musst einen Channel angeben.",
                }}
              />
            </MDBox>
            <MDBox pt={2} px={2}>
              <FormInput
                name="sendAt"
                type="datetime-local"
                InputLabelProps={{ shrink: true }}
                rules={{
                  validate: (value) => dateIsBeforeNow(value) || "Das Datum muss in der Zukunft liegen.",
                }}
                label="Wann soll die Nachricht gesendet werden?"
                formHandler={formHandler}
              />
            </MDBox>
          </Grid>

          <Grid item xs={12}>
            <MDBox pt={2} px={2}>
              <MDButton
                type="submit"
                variant="contained"
                sx={{ width: "100%" }}
                disabled={isLoading}
                color="primary"
              >
                Nachricht versenden
                {isLoading && (
                  <FontAwesomeIcon icon={faSpinnerThird} size="lg" spin style={{ marginLeft: "5px" }} />
                )}
              </MDButton>
            </MDBox>
          </Grid>
        </MDBox>
      </Card>
    </form>
  );
}
