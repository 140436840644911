/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useEffect } from "react";

// @mui material components
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// @mui icons
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Custom styles for the Configurator
import ConfiguratorRoot from "examples/Configurator/ConfiguratorRoot";

// Material Dashboard 2 PRO React context
import { setDarkMode } from "context";

import Autocomplete from "@mui/material/Autocomplete";
import { devModeEnabled } from "../../config";
import FormField from "../../layouts/old/pages/account/components/FormField";
import MDAlert from "../../components/MDAlert";
import { useDispatch, useSelector } from "react-redux";
import { uiActions } from "../../store/ui/ui-slice";
import { cookieActions } from "../../store/cookie/cookie-slice";
import { useUnleashFlag } from "../../store/flags/flags-actions";
import { flagsAction } from "../../store/flags/flags-slice";

// TODO anpassen
function Configurator() {
  const cookies = useSelector((state) => state.cookie || {});

  const showExperimentToggle = useUnleashFlag("experimentSwitch", true);

  const dispatch = useDispatch();

  const { expertMode, miniSidenav, openConfigurator, fixedNavbar, transparentSidenav, darkMode } =
    useSelector((state) => state.ui);

  useEffect(() => {
    setTimeout(() => {
      let settings = localStorage.getItem("settings");
      if (settings) {
        settings = JSON.parse(settings);
        if (settings.miniSidenav !== null) dispatch(uiActions.setMiniSidenav(settings.miniSidenav));
        if (settings.fixedNavbar !== null) dispatch(uiActions.setFixedNavbar(settings.fixedNavbar));
        if (typeof settings.darkMode === "boolean") dispatch(uiActions.setDarkMode(settings.darkMode));
        else if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches)
          dispatch(uiActions.setDarkMode(true));
      } else if (window.matchMedia && window.matchMedia("(prefers-color-scheme: light)").matches)
        dispatch(uiActions.setDarkMode(false));
    }, 1);
  }, [setDarkMode, dispatch]);

  const saveToLocalStorage = (type, value) => {
    let settings = localStorage.getItem("settings");
    if (settings) {
      settings = JSON.parse(settings);
      localStorage.setItem(
        "settings",
        JSON.stringify({
          ...settings,
          [type]: value,
        })
      );
    }

    if (!settings)
      localStorage.setItem(
        "settings",
        JSON.stringify({
          [type]: value,
        })
      );
  };

  const handleCloseConfigurator = () => dispatch(uiActions.setOpenConfigurator(false));
  const handleMiniSidenav = () => {
    saveToLocalStorage("miniSidenav", !miniSidenav);
    dispatch(uiActions.setMiniSidenav(!miniSidenav));
  };
  const handleFixedNavbar = () => {
    saveToLocalStorage("fixedNavbar", !fixedNavbar);
    dispatch(uiActions.setFixedNavbar(!fixedNavbar));
  };
  const handleDarkMode = () => {
    saveToLocalStorage("darkMode", !darkMode);
    dispatch(uiActions.setDarkMode(!darkMode));
  };
  const handleExpertMode = () => {
    saveToLocalStorage("expertMode", !expertMode);
    dispatch(uiActions.setExpertMode(!expertMode));
  };

  return (
    <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="baseline" pt={4} pb={0.5} px={3}>
        <MDBox>
          <MDTypography variant="h5">Interface Konfigurator</MDTypography>
          <MDTypography
            variant="body2"
            color="text"
            sx={{
              whiteSpace: "pre-wrap",
            }}
          >
            Hier kannst du die Oberfläche anpassen.
          </MDTypography>
        </MDBox>

        <Icon
          sx={({ typography: { size }, palette: { dark, white } }) => ({
            fontSize: `${size.lg} !important`,
            color: darkMode ? white.main : dark.main,
            stroke: "currentColor",
            strokeWidth: "2px",
            cursor: "pointer",
            transform: "translateY(5px)",
          })}
          onClick={handleCloseConfigurator}
        >
          close
        </Icon>
      </MDBox>

      <Divider />

      <MDBox pt={0.5} pb={3} px={3}>
        {!cookies.preferences && (
          <MDAlert
            severity="info"
            sx={{
              whiteSpace: "pre-wrap",
              mb: 2,
            }}
          >
            <MDTypography variant="body2" fontWeight="medium">
              Damit du hier etwas ändern kannst, musst du in den Cookie-Einstellungen &quot;Präferenzen&quot;
              akzeptieren.
            </MDTypography>
          </MDAlert>
        )}
        <MDTypography variant="body2" fontWeight="regular">
          Hier kannst du die Oberfläche anpassen.
        </MDTypography>
        <MDBox display="flex" justifyContent="space-between" alignItems="center" mt={3} lineHeight={1}>
          <MDTypography variant="h6">Navbar Fixed</MDTypography>

          <Switch
            checked={fixedNavbar || false}
            disabled={!cookies.preferences}
            onChange={handleFixedNavbar}
          />
        </MDBox>
        <Divider />
        <MDBox display="flex" justifyContent="space-between" alignItems="center" lineHeight={1}>
          <MDTypography variant="h6">Sidenav Mini</MDTypography>

          <Switch
            checked={miniSidenav || false}
            disabled={!cookies.preferences}
            onChange={handleMiniSidenav}
          />
        </MDBox>
        <Divider />
        <MDBox display="flex" justifyContent="space-between" alignItems="center" lineHeight={1}>
          <MDTypography variant="h6">Light / Dark</MDTypography>
          <Switch checked={darkMode || false} disabled={!cookies.preferences} onChange={handleDarkMode} />
        </MDBox>
        <Divider />
        <MDBox display="flex" justifyContent="space-between" alignItems="center" lineHeight={1}>
          <MDTypography variant="h6">Expert Mode</MDTypography>
          <Switch checked={expertMode || false} disabled={!cookies.preferences} onChange={handleExpertMode} />
        </MDBox>
        <Divider />
        <MDBox display="flex" justifyContent="space-between" alignItems="center" lineHeight={1}>
          <Autocomplete
            defaultValue={{ label: "Deutsch", value: "german", disabled: false }}
            // onChange={(e, newData) => setNewChannel(newData)}
            getOptionLabel={(option) => option.label}
            disabled={!cookies.preferences}
            disableClearable
            isOptionEqualToValue={(option, value) => option.value === value.value}
            getOptionDisabled={(option) => option.disabled || false}
            // disabled={!loaded}
            options={[
              { label: "Deutsch", value: "german", disabled: false },
              { label: "English", value: "english", disabled: false },
              { label: "Français", value: "french", disabled: true },
              { label: "Español", value: "spanish", disabled: true },
              { label: "Português", value: "portuguese", disabled: true },
              { label: "中文", value: "chinese", disabled: true },
              { label: "日本語", value: "japanese", disabled: true },
              { label: "한국어", value: "korean", disabled: true },
              { label: "Русский", value: "russian", disabled: true },
              { label: "Українська", value: "ukrainian", disabled: true },
              { label: "Български", value: "bulgarian", disabled: true },
              { label: "Српски", value: "serbian", disabled: true },
            ]}
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <FormField {...params} label="Sprache" variant="standard" sx={{ width: "100%" }} />
            )}
          />
        </MDBox>
        <Divider />
        <MDBox mt={3} mb={2}>
          <MDBox mb={2}>
            <MDButton
              component={Link}
              href="https://sv-studios.net"
              target="_blank"
              rel="noreferrer"
              color="info"
              variant="contained"
              fullWidth
            >
              SV-Studios
            </MDButton>
          </MDBox>
          {devModeEnabled() && (
            <>
              <MDBox my={1}>
                <MDButton
                  component={Link}
                  href="https://www.creative-tim.com/learning-lab/react/quick-start/material-dashboard/"
                  target="_blank"
                  rel="noreferrer"
                  color="primary"
                  variant="contained"
                  fullWidth
                >
                  documentation
                </MDButton>
              </MDBox>
              <MDBox my={1}>
                <MDButton
                  component={Link}
                  href="https://fontawesome.com/search"
                  target="_blank"
                  rel="noreferrer"
                  color="primary"
                  variant="contained"
                  fullWidth
                >
                  FontAwesome
                </MDButton>
              </MDBox>
            </>
          )}
          <MDBox my={2}>
            <MDButton
              variant="contained"
              color="info"
              fullWidth
              onClick={() => {
                dispatch(cookieActions.setShowDialog(true));
              }}
            >
              Cookies anpassen
            </MDButton>
          </MDBox>
          {showExperimentToggle && (
            <MDBox my={2}>
              <MDButton
                variant="outlined"
                color="info"
                fullWidth
                onClick={() => {
                  dispatch(flagsAction.setShowDialog(true));
                }}
              >
                Experimente
              </MDButton>
            </MDBox>
          )}
          <MDBox my={2}>
            <MDButton
              variant="outlined"
              color="error"
              fullWidth
              component={Link}
              onClick={() => {
                window.localStorage.clear();
              }}
            >
              Cache Löschen
            </MDButton>
          </MDBox>
        </MDBox>
        <MDBox mt={2} textAlign="center">
          {/*
              <MDBox mb={0.5}>
            <MDTypography variant="h6">Thank you for sharing!</MDTypography>
          </MDBox>
                <MDBox display="flex" justifyContent="center">
            <MDBox mr={1.5}>
              <MDButton
                component={Link}
                href="//twitter.com/intent/tweet?text=Check%20Material%20Dashboard%202%20PRO%20React%20made%20by%20%40CreativeTim%20%23webdesign%20%23dashboard%20%23react%20%mui&url=https%3A%2F%2Fwww.creative-tim.com%2Fproduct%2Fmaterial-dashboard-pro-react"
                target="_blank"
                rel="noreferrer"
                color="dark"
              >
                <TwitterIcon />
                &nbsp; Tweet
              </MDButton>
            </MDBox>
            <MDButton
              component={Link}
              href="https://www.facebook.com/sharer/sharer.php?u=https://www.creative-tim.com/product/material-dashboard-pro-react"
              target="_blank"
              rel="noreferrer"
              color="dark"
            >
              <FacebookIcon />
              &nbsp; Share
            </MDButton>
          </MDBox>
             */}
        </MDBox>
      </MDBox>
    </ConfiguratorRoot>
  );
}

export default Configurator;
