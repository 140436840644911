import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/pro-solid-svg-icons";
import { FormInput } from "../../../../components/Form/FormInput";
import MDBox from "../../../../components/MDBox";
import { useCreateBotDetailsMutation } from "../../../../store/admin/admin-api";
import apiMessages from "../../../../apiMessages";
import MDButton from "../../../../components/MDButton";

export function AddEnvironmentForm({ botId }) {
  const formHandler = useForm({
    defaultValues: {
      key: "",
      value: "",
    },
  });
  const [addEnvironment, { isLoading: addEnvironmentIsLoading }] = useCreateBotDetailsMutation();

  const { handleSubmit, setValue } = formHandler;

  const onSubmit = async (e) => {
    addEnvironment({
      botId,
      type: "environment",
      data: {
        environment: e.key + "=" + e.value,
      },
    }).then(({ data, error }) => {
      apiMessages.showMessage({
        type: "AdminBot",
        title: data ? "addedEnvironment" : error?.data?.error || error,
      });
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <MDBox display="flex" flexDirection="row" gap={0.5}>
        <MDBox flex={1}>
          <FormInput
            name="key"
            label="Environment Key"
            formHandler={formHandler}
            fullWidth
            hideStatusIcon
            rules={{
              required: "Key wird benötigt",
              validate: (value) => {
                return (
                  [/^[A-Za-z0-9_.-]*$/].every((pattern) => pattern.test(value)) ||
                  "Darf nur Buchstaben, Zahlen und die Zeichen _.- enthalten"
                );
              },
            }}
          />
        </MDBox>
        <MDBox flex={1}>
          <FormInput
            name="value"
            label="Environment Value"
            formHandler={formHandler}
            fullWidth
            hideStatusIcon
            rules={{
              required: "Value wird benötigt",
              validate: (value) => {
                return (
                  [/^[A-Za-z0-9_.-]*$/].every((pattern) => pattern.test(value)) ||
                  "Darf nur Buchstaben, Zahlen und die Zeichen _.- enthalten"
                );
              },
            }}
          />
        </MDBox>
        <MDBox flex={0}>
          <MDButton color="info" type="submit" iconOnly isLoading={addEnvironmentIsLoading}>
            <FontAwesomeIcon icon={faPlusCircle} />
          </MDButton>
        </MDBox>
      </MDBox>
    </form>
  );
}
