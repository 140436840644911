import { useEffect, useMemo, useState } from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRectangleXmark } from "@fortawesome/pro-solid-svg-icons";
import { Grid } from "@mui/material";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import MDBox from "../../../../../../../components/MDBox";
import MDTypography from "../../../../../../../components/MDTypography";
import { hexToDecimal } from "../../../../Tools";
import MDButton from "../../../../../../../components/MDButton";
import Builder from "../../../../../../../components/EmbedBuilder/Builder";
import Preview from "../../../../../../../components/EmbedBuilder/Preview";
import apiMessages from "../../../../../../../apiMessages";
import {
  useGetCategoriesQuery,
  useGetRolesQuery,
  useGetTextChannelsQuery,
} from "../../../../../../../store/guild/guild-api";
import {
  useGetModuleDataQuery,
  useUpdateModuleDataMutation,
} from "../../../../../../../store/modules/module-api";
import { useForm } from "react-hook-form";
import { CategoryForm } from "./CategoryForm";
import Logger from "../../../../../../../Logger";

export function TicketSystemModal({ botId, serverId, editTicketCategory, onClose }) {
  const {
    data: rolesData = [],
    isLoading: rolesIsLoading,
    refetch: rolesRefetch,
    isRefetching: rolesIsRefetching,
  } = useGetRolesQuery({
    botId,
    guildId: serverId,
  });

  const {
    data: textChannelsData = [],
    isLoading: textChannelsIsLoading,
    refetch: textChannelsRefetch,
    isRefetching: textChannelsIsRefetching,
  } = useGetTextChannelsQuery({
    botId,
    guildId: serverId,
  });

  const {
    data: categoriesData = [],
    isLoading: categoriesIsLoading,
    refetch: categoriesRefetch,
    isRefetching: categoriesIsRefetching,
  } = useGetCategoriesQuery({
    botId,
    guildId: serverId,
  });

  const {
    data: ticketPanelData = [],
    isLoading: ticketPanelIsLoading,
    refetch: ticketPanelRefetch,
    isRefetching: ticketPanelIsRefetching,
  } = useGetModuleDataQuery({
    botId,
    guildId: serverId,
    module: "ticket/panel",
  });

  const formHandler = useForm({
    defaultValues: {
      name: "",
      supportRoles: [],
      category: "",
      ticketName: "",
      archive: "",
      customLogsChannel: "",
      instantDelete: false,
      autoTranscript: false,
      sendDM: false,
      pinBotMessage: false,
      mentionUserOnCreation: false,
      allowReopening: false,
      mentionSupportRoles: false,
      publicTicket: false,
      description: "",
      emoji: "",
    },
  });

  const { control, handleSubmit, setValue, getValues } = formHandler;

  useEffect(() => {
    if (!ticketPanelData) return;
    const currentTicketCategory = ticketPanelData.find((category) => category.id === editTicketCategory);
    if (!currentTicketCategory) return;

    setValue("name", currentTicketCategory.name);
    setValue("supportRoles", currentTicketCategory.supportRoles);
    setValue("category", currentTicketCategory.category);
    setValue("ticketName", currentTicketCategory.ticketName);
    setValue("archive", currentTicketCategory.archive);
    setValue("customLogsChannel", currentTicketCategory.customLogsChannel);
    setValue("instantDelete", currentTicketCategory.instantDelete);
    setValue("autoTranscript", currentTicketCategory.autoTranscript);
    setValue("sendDM", currentTicketCategory.sendDM);
    setValue("pinBotMessage", currentTicketCategory.pinBotMessage);
    setValue("mentionUserOnCreation", currentTicketCategory.mentionUserOnCreation);
    setValue("allowReopening", currentTicketCategory.allowReopening);
    setValue("mentionSupportRoles", currentTicketCategory.mentionSupportRoles);
    setValue("publicTicket", currentTicketCategory.publicTicket);
    setValue("description", currentTicketCategory.description || "");
    currentTicketCategory.emoji && setValue("emoji", currentTicketCategory.emoji || "");

    try {
      const localJson = JSON.parse(currentTicketCategory?.welcomeJson);
      for (let i = 0; i < localJson.embeds.length; i++) {
        if (localJson.embeds[i].color && !Number.isNaN(localJson.embeds[i].color)) {
          localJson.embeds[i].color = `#${Number(localJson.embeds[i]?.color).toString(16)}`;
        }
        /* TODO Hier schauen wie man das mit dem Timestamp macht
        if (localJson.embeds[i].timestamp) {
          const isoString = new Date(localJson.embeds[i].timestamp).toISOString();
          localJson.embeds[i].timestamp = isoString;
        } else {
          localJson.embeds[i].timestamp = null;
        }
        */
      }
      setJson(localJson);
    } catch (e) {
      Logger.warning("Error while parsing JSON", {}, e);
    }
  }, [editTicketCategory, ticketPanelData, setValue]);

  const [update, { isLoading }] = useUpdateModuleDataMutation();

  const [builderOpened, setBuilderOpened] = useState(false);

  const [json, setJson] = useState({
    content: "",
    embeds: [],
  });

  const close = () => onClose(false);

  const onSubmit = async (e) => {
    const sendJson = JSON.parse(JSON.stringify(json));

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < json.embeds.length; i++) {
      console.log("Color", sendJson.embeds[i].color);
      // eslint-disable-next-line no-restricted-globals
      if (sendJson.embeds[i].color && isNaN(sendJson.embeds[i].color)) {
        console.log("Color Before", sendJson.embeds[i].color);
        sendJson.embeds[i].color = hexToDecimal(sendJson.embeds[i]?.color?.replace("#", ""));
        console.log("Color After", sendJson.embeds[i].color);
      }
      if (sendJson.embeds[i].timestamp) {
        const isoString = new Date(sendJson.embeds[i].timestamp).toISOString();
        console.log("Before", sendJson.embeds[i].timestamp, isoString);
        sendJson.embeds[i].timestamp = isoString; /* .substring(0, isoString.length - 1); */
        console.log("After", sendJson.embeds[i].timestamp, isoString);
      } else {
        sendJson.embeds[i].timestamp = null;
      }
    }

    update({
      botId,
      guildId: serverId,
      module: `ticket/panel/${editTicketCategory}`,
      data: {
        ...e,
        welcomeJson: {
          content: sendJson.content || "",
          embeds: sendJson.embeds || [],
          attachments: [],
        },
      },
    }).then(({ data, error }) => {
      Logger.info("SubmitEvent", { module: "SubmitForm" }, data, error);
      apiMessages.showMessage({
        type: "TicketPanel",
        title: data ? "updated" : error?.data?.error || error,
      });
    });
  };

  return (
    <>
      <Dialog fullScreen open={builderOpened} onClose={() => setBuilderOpened(false)}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton edge="start" onClick={() => setBuilderOpened(false)} aria-label="close">
              <MDTypography>
                <FontAwesomeIcon icon={faRectangleXmark} />
              </MDTypography>
            </IconButton>
            <MDTypography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Eröffnungsnachricht (wird automatisch gespeichert)
            </MDTypography>
          </Toolbar>
        </AppBar>
        <Grid container spacing={1} sx={{ p: 2 }}>
          <Grid item xs={12} lg={6}>
            {useMemo(
              () => (
                <Builder setJson={setJson} json={json} />
              ),
              [setJson, json]
            )}
          </Grid>
          <Grid item xs={12} lg={6}>
            {useMemo(
              () => (
                <Preview json={json} bot={botId} />
              ),
              [json, botId]
            )}
          </Grid>
        </Grid>
      </Dialog>
      <Dialog maxWidth="lg" open={editTicketCategory !== null} onClose={() => close()}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton edge="start" onClick={() => close()} aria-label="close">
              <MDTypography>
                <FontAwesomeIcon icon={faRectangleXmark} />
              </MDTypography>
            </IconButton>
            <MDTypography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Ticket Category bearbeiten
            </MDTypography>
          </Toolbar>
        </AppBar>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <CategoryForm
                guildId={serverId}
                botId={botId}
                formHandler={formHandler}
                setBuilderOpened={setBuilderOpened}
              />
              <MDBox pt={1} pb={2} px={2}>
                <MDButton variant="contained" type="submit" color="primary" fullWidth disabled={isLoading}>
                  Neue Kategorie aktualisieren
                  {isLoading && (
                    <FontAwesomeIcon
                      icon={faSpinnerThird}
                      size="lg"
                      spin
                      style={{
                        marginLeft: "5px",
                      }}
                    />
                  )}
                </MDButton>
              </MDBox>
            </form>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}
