/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDAvatar from "../../../components/MDAvatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Grid from "@mui/material/Grid";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { NavLink } from "react-router-dom";
import MDButton from "../../../components/MDButton";
import MDBadge from "../../../components/MDBadge";
import { useGetBotQuery } from "../../../store/bot/bot-api";
import { Skeleton } from "@mui/material";
import MDAlert from "../../../components/MDAlert";

function BotListCard({ botId, supportBot, supportExpires }) {
  const { data: botData = {}, isLoading: botIsLoading } = useGetBotQuery({ botId });

  if (botIsLoading) return <Skeleton variant="rounded" width="100%" height={250} animation="wave" />;
  if (!botData) return <MDAlert severity="error">Bot nicht gefunden</MDAlert>;
  return (
    <Card>
      <MDBox p={2}>
        <MDBox display="flex" alignItems="center">
          <MDAvatar
            src={botData?.avatarUrl}
            alt={botData?.name}
            size="xl"
            variant="rounded"
            bgColor="dark"
            sx={{ p: 1, mt: -6, borderRadius: ({ borders: { borderRadius } }) => borderRadius.xl }}
          />
          <MDBox ml={2} mt={-2} lineHeight={0}>
            <MDTypography variant="h6" textTransform="capitalize" fontWeight="medium" sx={{ mt: 1 }}>
              {botData?.name}
            </MDTypography>
          </MDBox>
          {supportBot && (
            <MDBadge badgeContent="Support Pin Bot" variant="gradient" container sx={{ mx: 1 }} />
          )}
          {supportExpires && (
            <MDBadge
              badgeContent={`Gültig bis: ${new Date(supportExpires).toLocaleTimeString()}`}
              variant="gradient"
              container
            />
          )}
          {botData?.maintenance === true && (
            <MDBadge badgeContent="Wartung" color="error" container sx={{ ml: "auto" }} />
          )}
        </MDBox>
        <Divider />
        <MDBox my={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <MDTypography variant="h6" fontWeight="regular">
                <FontAwesomeIcon size="1x" icon={faChevronRight} style={{ marginRight: "10px" }} /> Owner:{" "}
                {botData?.owner?.name}
              </MDTypography>
            </Grid>
            <Grid item xs={12} md={6}>
              <MDTypography variant="h6" fontWeight="regular">
                <FontAwesomeIcon size="1x" icon={faChevronRight} style={{ marginRight: "10px" }} /> Version:{" "}
                {botData.version}
              </MDTypography>
            </Grid>
            <Grid item xs={12} md={6}>
              <MDTypography variant="h6" fontWeight="regular">
                <FontAwesomeIcon size="1x" icon={faChevronRight} style={{ marginRight: "10px" }} /> Guilds:{" "}
                {botData.guilds?.length}/{botData.maxWhitelist}
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox display="flex" justifyContent="space-between" alignItems="center">
          {botData.maintenance ? (
            <MDButton variant="outlined" color="error" fullWidth disabled>
              Wartung
            </MDButton>
          ) : (
            <MDButton variant="contained" color="info" component={NavLink} to={`/bots/${botId}`} fullWidth>
              Bot anzeigen
            </MDButton>
          )}
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default BotListCard;
