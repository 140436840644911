/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components

// Material Dashboard 2 PRO React components

// Material Dashboard 2 PRO React imageExample components

// WelcomeLeave page components
// Images
import { useMemo } from "react";
import { Skeleton } from "@mui/material";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import MDButton from "../../../../../components/MDButton";
import apiErrorMessages from "../../../../../apiMessages";
import ReloadTable from "../../../../../components/Table/ReloadTable";
import { useGetTextChannelsQuery } from "../../../../../store/guild/guild-api";
import { useSelector } from "react-redux";
import { useDeleteModuleDataMutation } from "../../../../../store/modules/module-api";
import { Duration } from "luxon";

const module = "autoclear";

function AutoClear({ botId, guildId }) {
  const { data: channelsData = [], isLoading: channelsIsLoading } = useGetTextChannelsQuery({
    botId,
    guildId: guildId,
  });
  const [deleteAutoClear, { isLoading: deleteAutoClearIsLoading }] = useDeleteModuleDataMutation({});

  const expertMode = useSelector((state) => state.ui.expertMode);

  const deleteDataEvent = (id) => {
    deleteAutoClear({ botId, guildId, module, id }).then(({ data, error }) => {
      apiErrorMessages.showMessage({
        type: "AutoClear",
        title: data ? "deleted" : error?.data?.error || error,
      });
    });
  };

  const tableColumns = useMemo(
    () =>
      [
        expertMode
          ? {
              Header: "Channel ID",
              accessor: "channelId",
            }
          : null,
        {
          Header: "Channel Name",
          accessor: "channelName",
        },
        {
          Header: "Zeit",
          accessor: "time",
        },
        {
          Header: "Actions",
          accessor: "actions",
          width: "7%",
        },
      ].filter((x) => x),
    [expertMode]
  );

  const tableRows = useMemo(
    () => (autoClear) => ({
      channelId: autoClear.channelId?.toString(),
      channelName: channelsData?.filter((r) => r.id?.toString() === autoClear.channelId?.toString())[0]
        ?.name || <i>Name nicht gefunden</i>,
      time: Duration.fromISO(autoClear.duration, {}).toHuman({ unitDisplay: "long" }),
      actions: (
        <MDButton
          variant="contained"
          iconOnly
          disabled={deleteAutoClearIsLoading}
          color="primary"
          onClick={() => deleteDataEvent(autoClear.id)}
        >
          {deleteAutoClearIsLoading ? (
            <FontAwesomeIcon icon={faSpinnerThird} size="lg" spin />
          ) : (
            <FontAwesomeIcon icon={faTrash} size="lg" />
          )}
        </MDButton>
      ),
    }),
    [channelsData, deleteAutoClearIsLoading]
  );

  return (
    <ReloadTable
      path={{
        botId,
        guildId,
        module,
      }}
      loaded
      rowsLoad={() => ({
        channelId: <Skeleton width={100} animation="wave" />,
        channelName: <Skeleton width={100} animation="wave" />,
        time: <Skeleton width={100} animation="wave" />,
        actions: <Skeleton width={100} animation="wave" />,
      })}
      columns={tableColumns}
      rows={tableRows}
      canSearch
      showTotalEntries
    />
  );
}

export default AutoClear;
