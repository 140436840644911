import {
  faAbacus,
  faFaceSwear,
  faFileLines,
  faFloppyDisk,
  faHandWave,
  faListCheck,
  faMedal,
  faMemo,
  faMessageSmile,
  faRulerCombined,
  faTicket,
  faToiletPaper,
  faVacuum,
  faUserHeadset,
} from "@fortawesome/pro-solid-svg-icons";

const messages = {
  AUTO_ROLE: {
    text: "Auto Roles",
    link: "auto-role",
    icon: faToiletPaper,
  },
  ROLE_SAVER: {
    text: "Role Saver",
    link: "role-saver",
    icon: faFloppyDisk,
  },
  SUGGESTION_SYSTEM: {
    text: "Suggestion System",
    link: "suggestion",
    icon: faMemo,
  },
  COUNTER_SYSTEM: {
    text: "Counter System",
    link: "counter-system",
    icon: faAbacus,
  },
  JOIN_LEAVE_MESSAGES: {
    text: "Join/Leave Messages",
    link: "welcome-leave",
    icon: faHandWave,
  },
  CHANNEL_LOG: {
    text: "Channel Log",
    link: "channel-log",
    icon: faListCheck,
  },
  AUTO_CLEAR: {
    text: "Auto Clear",
    link: "auto-clear",
    icon: faVacuum,
  },
  LOG_SYSTEM: {
    text: "Log System",
    link: "logs",
    icon: faFileLines,
  },
  ROLE_REACTION: {
    text: "Role Reaction",
    link: "role-reaction",
    icon: faRulerCombined,
  },
  AUTO_REACTION: {
    text: "Auto Reaction",
    link: "auto-reaction",
    icon: faMessageSmile,
  },
  TICKET_SYSTEM: {
    text: "Ticket System",
    link: "ticket-system",
    icon: faTicket,
  },
  PRIVATE_TALK: {
    text: "Private Talk",
    new: true,
    link: "private-talk",
    icon: faUserHeadset,
  },
  WORD_BLACKLIST: {
    text: "Word Blacklist",
    link: "word-blacklist",
    icon: faFaceSwear,
  },
  XP_SYSTEM: {
    text: "XP System",
    beta: true,
    new: true,
    link: "xp-system",
    icon: faMedal,
  },
};

export default {
  ...messages,
};
