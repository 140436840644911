/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components

// Material Dashboard 2 PRO React components

// Material Dashboard 2 PRO React imageExample components

// WelcomeLeave page components
// Images
import { useMemo, useState } from "react";
import { Skeleton } from "@mui/material";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEdit, faGlobe, faTimes, faTrash } from "@fortawesome/pro-solid-svg-icons";
import MDButton from "../../../../../components/MDButton";
import apiErrorMessages from "../../../../../apiMessages";
import ReloadTable from "../../../../../components/Table/ReloadTable";
import { useGetTextChannelsQuery } from "../../../../../store/guild/guild-api";
import { useSelector } from "react-redux";
import { useDeleteModuleDataMutation } from "../../../../../store/modules/module-api";
import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";
import MDBadge from "../../../../../components/MDBadge";
import { NavLink } from "react-router-dom";
import { getServerUrl } from "../../../../../config";
import { VoteSystemModal } from "./VoteSystemModal";
import { DateTime } from "luxon";

const module = "vote";

export function VoteSystemTable({ botId, guildId }) {
  const { data: textChannelData = [], isLoading: textChannelIsLoading } = useGetTextChannelsQuery({
    botId,
    guildId,
  });

  const [deleteModuleData, { isLoading: deleteModuleDataIsLoading }] = useDeleteModuleDataMutation({});

  const expertMode = useSelector((state) => state.ui.expertMode);

  const [editVote, setEditVote] = useState(null);

  const deleteDataEvent = (id) => {
    deleteModuleData({ botId, guildId, module, id, isModule: false }).then(({ data, error }) => {
      apiErrorMessages.showMessage({
        type: "VoteSystem",
        title: data ? "deleted" : error?.data?.error || error,
      });
    });
  };

  const tableColumns = useMemo(
    () =>
      [
        expertMode
          ? {
              Header: "Channel ID",
              accessor: "channelId",
            }
          : null,
        {
          Header: "Channel Name",
          accessor: "channelName",
        },
        {
          Header: "Frage",
          accessor: "question",
        },
        { Header: "Antworten", accessor: "answers" },
        {
          Header: "Multi",
          accessor: "isMultiVote",
        },
        {
          Header: "Geheim",
          accessor: "isAnonymous",
        },
        { Header: "Ablaufdatum", accessor: "expiresAt" },

        {
          Header: "Actions",
          accessor: "actions",
          disableSort: true,
          width: "0%",
        },
      ].filter((x) => x),
    [expertMode]
  );

  const badgeColor = (ownVotes, totalVotes) => {
    console.log(ownVotes, totalVotes);
    if (ownVotes === 0) return "secondary";
    if (ownVotes >= totalVotes / 2) return "success";
    if (ownVotes >= totalVotes / 4) return "warning";
    return "error";
  };

  const tableRows = useMemo(
    () => (row) => {
      const totalVotes = row.answers?.reduce((acc, cur) => acc + cur?.votes?.length, 0);

      return {
        channelId: row.channelId,
        channelName: textChannelData?.find((r) => r.id === row.channelId)?.name || <i>Name nicht gefunden</i>,
        isMultiVote: row.isMultiVote ? (
          <FontAwesomeIcon icon={faCheck} />
        ) : (
          <FontAwesomeIcon icon={faTimes} />
        ),
        isAnonymous: row.isAnonymous ? (
          <FontAwesomeIcon icon={faCheck} />
        ) : (
          <FontAwesomeIcon icon={faTimes} />
        ),
        expiresAt: row.expiresAt
          ? DateTime.fromFormat(row.expiresAt, "yyyy-MM-dd HH:mm:ss", { zone: "utc" })
              .toLocal()
              .toLocaleString(DateTime.DATETIME_SHORT)
          : "Nicht gesetzt",
        question: row.question,
        answers: (
          <MDBox display="flex" flexDirection="column" gap={1} component="ul">
            {row.answers?.map((a, key) => (
              <MDBox component="li" key={key} display="flex">
                <MDBadge
                  container
                  badgeContent={String(a.votes?.length)}
                  color={badgeColor(a.votes?.length, totalVotes)}
                  sx={{ mr: 1 }}
                  size="xs"
                />
                <MDTypography>{a.text}</MDTypography>
              </MDBox>
            ))}
          </MDBox>
        ),
        actions: (
          <MDBox display="flex" gap={1}>
            <NavLink
              to={`${getServerUrl()}/public/bot/${botId}/guild/${guildId}/module/vote/${row.id}`}
              target="_blank"
            >
              <MDButton
                variant="contained"
                iconOnly
                disabled={deleteModuleDataIsLoading}
                color="info"
                title="Öffentliche Vote Ansicht"
                onClick={() => deleteDataEvent(row.id)}
              >
                <FontAwesomeIcon icon={faGlobe} size="lg" />
              </MDButton>
            </NavLink>
            <MDButton variant="contained" iconOnly color="warning" onClick={() => setEditVote(row.id)}>
              <FontAwesomeIcon icon={faEdit} />
            </MDButton>
            <MDButton
              variant="contained"
              iconOnly
              disabled={deleteModuleDataIsLoading}
              color="primary"
              title="Abstimmung löschen"
              onClick={() => deleteDataEvent(row.id)}
            >
              {deleteModuleDataIsLoading ? (
                <FontAwesomeIcon icon={faSpinnerThird} size="lg" spin />
              ) : (
                <FontAwesomeIcon icon={faTrash} size="lg" />
              )}
            </MDButton>
          </MDBox>
        ),
      };
    },
    [textChannelData, deleteModuleDataIsLoading]
  );

  return (
    <>
      <VoteSystemModal
        botId={botId}
        guildId={guildId}
        editEntityId={editVote}
        onClose={() => setEditVote(null)}
      />
      <MDBox
        sx={{
          overflow: "auto",
        }}
      >
        <MDBox
          sx={{
            minWidth: "800px",
          }}
        >
          <ReloadTable
            path={{
              botId,
              guildId,
              module,
              isModule: false,
            }}
            loaded={!textChannelIsLoading}
            rowsLoad={() => ({
              roleId: <Skeleton width={100} animation="wave" />,
              roleName: <Skeleton width={100} animation="wave" />,
              actions: <Skeleton width={100} animation="wave" />,
            })}
            columns={tableColumns}
            rows={tableRows}
            canSearch
            showTotalEntries
          />
        </MDBox>
      </MDBox>
    </>
  );
}
