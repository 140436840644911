import Card from "@mui/material/Card";
import MDTypography from "../../../components/MDTypography";
import { useEffect, useState } from "react";
import MDBox from "../../../components/MDBox";
import MDProgress from "../../../components/MDProgress";

const numberProps = {
  variant: "h1",
  sx: {
    fontSize: {
      xs: "1.5rem",
      sm: "2.5rem",
      md: "3.5rem",
    },
  },
  fontFamily: "Monospace",
};

const unitProps = {
  variant: "h4",
  sx: {
    fontSize: {
      xs: "0.7rem",
      sm: "0.9rem",
      md: "1rem",
    },
  },
};

export function Countdown({ startTime, endTime, ended }) {
  /**
   * Calculates the percentage of elapsed time between a given start time and end time with respect to current time.
   *
   * @param {number|Date} startTime - The start time of the interval.
   * @param {number|Date} endTime - The end time of the interval.
   * @return {number} The percentage of elapsed time between start time and end time.
   */
  function getElapsedTimePercent(startTime, endTime) {
    if (!startTime || !endTime) return 100;
    // Convert start and end times to Date objects if they are not
    const start = new Date(startTime).getTime();
    const end = new Date(endTime).getTime();
    const now = new Date().getTime();

    if (start >= end) {
      return 100;
    }

    // Calculate total duration and elapsed time
    const totalTime = end - start;
    const elapsedTime = now - start;

    // Calculate percentage
    var percent = (elapsedTime / totalTime) * 100;
    percent = Math.round(Math.max(0, Math.min(percent, 100)));

    return percent;
  }
  /**
   * Calculates the time left between the current date and the provided end time.
   *
   * @returns {Object} An object containing the calculated time left, formatted as days, hours, minutes, seconds, and milliseconds.
   */
  const calculateTimeLeft = () => {
    const difference = new Date(endTime) - new Date();
    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((difference / (1000 * 60)) % 60);
      const seconds = Math.floor((difference / 1000) % 60);
      const milliseconds = difference % 1000;
      return {
        days: days.toString().padStart(2, "0"),
        hours: hours.toString().padStart(2, "0"),
        minutes: minutes.toString().padStart(2, "0"),
        seconds: seconds.toString().padStart(2, "0"),
        milliseconds: milliseconds.toString().padStart(3, "0"),
      };
    } else {
      return {
        days: "00",
        hours: "00",
        minutes: "00",
        seconds: "00",
        milliseconds: "000",
      };
    }
  };

  const [timeLeft, setTimeLeft] = useState("00:00:00:00:000");

  useEffect(() => {
    if (ended) return;
    const timer = setInterval(() => {
      const left = calculateTimeLeft();
      if (
        left.days === "00" &&
        left.hours === "00" &&
        left.minutes === "00" &&
        left.seconds === "00" &&
        left.milliseconds === "000"
      )
        clearInterval(timer);
      setTimeLeft(left);
    }, 15); // Millisekunden aktualisieren jede Millisekunde

    return () => clearInterval(timer);
  }, [endTime, ended]);

  const progress = getElapsedTimePercent(startTime, endTime);

  const getBarColor = () => {
    if (progress < 60) {
      return "success";
    } else if (progress < 90) {
      return "warning";
    } else if (progress >= 100) {
      return "info";
    } else {
      return "primary";
    }
  };

  console.log(progress, startTime, endTime);

  return (
    <>
      <Card sx={{ p: 2, textAlign: "center" }}>
        <MDTypography
          variant="h2"
          sx={{
            fontSize: {
              xs: "1.5rem",
              sm: "1.5rem",
              md: "2.5rem",
              lg: "2.5rem",
            },
          }}
        >
          {ended ? "Gewinnspiel beendet" : "Gewinnspiel endet in"}
        </MDTypography>

        {!ended && (
          <>
            <MDBox display="flex" justifyContent="center" alignItems="center" mb={2}>
              <table
                style={{
                  width: "min-content",
                }}
              >
                <tbody>
                  <tr>
                    <td>
                      <MDTypography {...numberProps}>{timeLeft.days}</MDTypography>
                    </td>
                    <td>
                      <MDTypography {...numberProps}>:</MDTypography>
                    </td>
                    <td>
                      <MDTypography {...numberProps}>{timeLeft.hours}</MDTypography>
                    </td>
                    <td>
                      <MDTypography {...numberProps}>:</MDTypography>
                    </td>
                    <td>
                      <MDTypography {...numberProps}>{timeLeft.minutes}</MDTypography>
                    </td>
                    <td>
                      <MDTypography {...numberProps}>:</MDTypography>
                    </td>
                    <td>
                      <MDTypography {...numberProps}>{timeLeft.seconds}</MDTypography>
                    </td>
                    <td>
                      <MDTypography {...numberProps}>:</MDTypography>
                    </td>
                    <td>
                      <MDTypography {...numberProps}>{timeLeft.milliseconds}</MDTypography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <MDTypography {...unitProps}>Tage</MDTypography>
                    </td>
                    <td></td>
                    <td>
                      <MDTypography {...unitProps}>Stunden</MDTypography>
                    </td>
                    <td></td>

                    <td>
                      <MDTypography {...unitProps}>Minuten</MDTypography>
                    </td>
                    <td></td>
                    <td>
                      <MDTypography {...unitProps}>Sekunden</MDTypography>
                    </td>
                    <td></td>
                    <td>
                      <MDTypography {...unitProps}>Millisekunden</MDTypography>
                    </td>
                  </tr>
                </tbody>
              </table>
            </MDBox>
          </>
        )}
      </Card>

      <Card sx={{ p: 2, mt: 0.5 }}>
        <MDProgress value={progress} variant="gradient" color={getBarColor()} />
      </Card>
    </>
  );
}
