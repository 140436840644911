/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";
import DashboardLayout from "../../../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../../../examples/Navbars/DashboardNavbar";
import FooterSimple from "../../../../../components/Footer/FooterSimple";
import {useMemo, useState} from "react";
import Card from "@mui/material/Card";
import {faMedal} from "@fortawesome/pro-duotone-svg-icons";
import {useNavigate, useParams} from "react-router-dom";
import MDButton from "../../../../../components/MDButton";
import ModuleHeader from "../components/ModuleHeader";
import Error404Modal from "../../../../../components/Dialog/Error404Modal";
import {ImageSelector} from "../../../../../components/Modal/ImageSelector";
import {useGetModuleDataQuery} from "../../../../../store/modules/module-api";
import MDAlert from "../../../../../components/MDAlert";
import {getServerUrl} from "../../../../../config";

const xpTemplate = {
  imageType: "XP",
  data: {
    width: 900,
    height: 280,
    backgroundColor: "#FFFFFF",
    elements: [
      {
        url: "https://cdn.discordapp.com/attachments/805545336302075915/1092527529245540363/mainBackground.png",
        x: 0,
        y: 0,
        width: null,
        height: null,
        borderRadius: 0,
        type: "IMAGE",
      },
      {
        x: 20,
        y: 20,
        color: "#808080",
        width: 860,
        height: 240,
        borderRadius: 10,
        transparency: 0.7,
        type: "RECTANGLE",
      },
      {
        url: "%user_avatar%",
        x: 50,
        y: 30,
        width: 128,
        height: 128,
        borderRadius: 360,
        type: "IMAGE",
      },
      {
        x: 200,
        y: 100,
        color: "#ffffff",
        font: "Poppins",
        fontSize: 70,
        type: "TEXT",
      },
      {
        text: "XP: %member_xp% / %member_max_xp%",
        x: 200,
        y: 150,
        color: "#ffffff",
        font: "Poppins",
        fontSize: 30,
        type: "TEXT",
      },
      {
        text: "Level: %member_level%",
        x: 500,
        y: 150,
        color: "#ffffff",
        font: "Poppins",
        fontSize: 30,
        type: "TEXT",
      },
      {
        x: 35,
        y: 170,
        width: 830,
        height: 70,
        progress: -1,
        barBorderRadius: 70,
        barColor: "#c0c0c0",
        barOffsetX: 7,
        barOffsetY: 7,
        backgroundBorderRadius: 70,
        backgroundColor: "#000000",
        borderColor: null,
        type: "LOADER",
      },
    ],
  },
};

function XpSystem() {
  const { botId, serverId } = useParams();

  const [modals, setModals] = useState({
    xp: false,
  });

  const { data: xpData, isLoading: xpIsLoading } = useGetModuleDataQuery({
    botId,
    guildId: serverId,
    module: "xp",
  });

  const [templates, setTemplates] = useState([]);

  const xpTemplates = useMemo(
    () => templates.content?.filter((template) => template.imageType === "XP"),
    [templates]
  );

  const navigate = useNavigate();

  return (
    <DashboardLayout>
      {modals.xp && (
        <ImageSelector
          open
          onClose={() => setModals((prev) => ({ ...prev, xp: false }))}
          type="XP"
          title="Rank Image"
          templates={xpTemplates}
          replacer={[
            {
              value: "User#0000",
            },
            {
              name: "member_xp",
              value: "0",
            },
            {
              name: "member_max_xp",
              value: "100",
            },
            {
              name: "member_level",
              value: "0",
            },
            {
              name: "user_avatar",
              value:
                "https://cdn.discordapp.com/attachments/805545336302075915/1092527529245540363/mainBackground.png",
            },
          ]}
          onSubmit={() => {}}
        />
      )}
      <DashboardNavbar />
      <Error404Modal />
      <MDBox mb={2} />
      <ModuleHeader
        name="XP System"
        type="XP_SYSTEM"
        description="Hier kannst du dir aktuelle Informationen über das XP System anzeigen lassen."
        botId={botId}
        serverId={serverId}
        icon={faMedal}
      />
      <MDBox mt={5} mb={3}>
        <MDBox mb={2}>
          <MDAlert
            color="info"
            dismissible
            sx={{
              width: "100%",
            }}
          >
            <MDTypography variant="body2">
              Link zum Öffentlichen Leaderboard:{" "}
              <MDBox
                component="a"
                href={`${getServerUrl()}/public/bot/${botId}/guild/${serverId}/module/xp`}
                target={"_blank"}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/public/bot/${botId}/guild/${serverId}/module/xp`);
                }}
                sx={{
                  color: "#ffffff",
                  whiteSpace: "pre-wrap",
                  overflowWrap: "anywhere",
                }}
              >
                {getServerUrl()}/public/bot/{botId}/guild/{serverId}/module/xp
              </MDBox>
            </MDTypography>
          </MDAlert>
        </MDBox>
        <Card sx={{ height: "100%" }}>
          <MDBox pt={2} px={2}>
            <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
              XP Leaderboard
            </MDTypography>
          </MDBox>
          <MDBox p={2}>
            <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
              {xpData?.map((xp, index) => (
                <MDBox key={index}>
                  <MDBox component="li" display="flex" flexDirection="row" alignItems="center" p={0} m={0}>
                    <MDBox
                      component="img"
                      src={xp?.user?.avatar || "https://cdn.discordapp.com/embed/avatars/0.png"}
                      sx={{ width: "50px", height: "50px", borderRadius: "50%" }}
                    />
                    <MDBox ml={2}>
                      <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                        {xp?.user?.name || xp?.user?.id || "Name nicht bekannt"}
                      </MDTypography>
                    </MDBox>
                    <MDBox ml="auto">
                      <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                        XP: {xp?.xp?.toString()}
                      </MDTypography>
                      <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                        Level: {xp?.level?.toString()}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                  <MDBox py={1} px={2}>
                    <hr
                      style={{
                        borderColor: "#353535",
                      }}
                    />
                  </MDBox>
                </MDBox>
              ))}
            </MDBox>
          </MDBox>
          <MDBox p={2} color="red">
            <MDButton
              variant="gradient"
              fullWidth
              color="info"
              onClick={() => {
                setModals((prev) => ({
                  ...prev,
                  xp: true,
                }));
              }}
            >
              Ranking Image bearbeiten
            </MDButton>
          </MDBox>
        </Card>
      </MDBox>
      <FooterSimple />
    </DashboardLayout>
  );
}

export default XpSystem;
