import { useCallback, useEffect, useMemo, useState } from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRectangleXmark } from "@fortawesome/pro-solid-svg-icons";
import { Grid } from "@mui/material";
import Builder from "../../../../../components/EmbedBuilder/Builder";
import Preview from "../../../../../components/EmbedBuilder/Preview";
import MDTypography from "../../../../../components/MDTypography";

export function StickyMessagesModal({
  botId,
  serverId,
  message = {
    content: "",
    embeds: [],
  },
  open = false,
  onClose = () => {},
  updateMessage = (x) => {},
}) {
  const [json, setJson] = useState(message);

  const close = useCallback(() => {
    console.log("close", json);
    updateMessage(json);
    onClose();
  }, [json]);

  return (
    <>
      <Dialog fullScreen open={open} onClose={close}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton edge="start" onClick={close} aria-label="close">
              <MDTypography>
                <FontAwesomeIcon icon={faRectangleXmark} />
              </MDTypography>
            </IconButton>
            <MDTypography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Sticky Message bearbeiten (wird alle 5 Sekunden automatisch gespeichert)
            </MDTypography>
          </Toolbar>
        </AppBar>
        <Grid container spacing={1} sx={{ p: 2 }}>
          <Grid item xs={12} lg={6}>
            {useMemo(
              () => (
                <Builder setJson={setJson} json={json} />
              ),
              [setJson, json]
            )}
          </Grid>
          <Grid item xs={12} lg={6}>
            {useMemo(
              () => (
                <Preview json={json} />
              ),
              [json, botId]
            )}
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}
