/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { Fragment, useEffect, useMemo } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";

// Material Dashboard 2 PRO React imageExample components
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import FooterSimple from "../../../components/Footer/FooterSimple";

import Card from "@mui/material/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRobot } from "@fortawesome/pro-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "@mui/material";
import { useSnackbar } from "notistack";
import backgroundImage from "../../../assets/images/bg-profile.jpeg";
import BotListCard from "./BotListCard";
import config from "../../../config";
import MDAlert from "../../../components/MDAlert";
import Error404Modal from "../../../components/Dialog/Error404Modal";
import MDButton from "../../../components/MDButton";
import { useGetBotsQuery } from "../../../store/bot/bot-api";
import { useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";

function BotList() {
  const { data: botsData, isLoading: botsIsLoading } = useGetBotsQuery();
  const supportPinData = useSelector((state) => state.cookie.supportPinData);

  const botsList = useMemo(
    () =>
      (Array.isArray(botsData) &&
        botsData?.map(({ botId }) => (
          <Grid item xs={12} md={6} key={botId}>
            <MDBox mb={1.5} mt={1.5}>
              <BotListCard botId={botId} />
            </MDBox>
          </Grid>
        ))) ||
      [],
    [botsData]
  );

  const supportBotCard = useMemo(
    () =>
      supportPinData && (
        <Grid item xs={12} md={6} key="supportBot">
          <MDBox mb={1.5} mt={1.5}>
            <BotListCard
              botId={supportPinData?.botId}
              supportBot
              supportExpires={supportPinData?.expiresAt}
            />
          </MDBox>
        </Grid>
      ),
    [supportPinData]
  );

  const placeholder = (...size) =>
    [...size].map((key) => (
      <Fragment key={key}>
        <Grid item xs={12} md={6} lg={4}>
          <Skeleton variant="rounded" width="100%" height="200px" animation="wave" />
        </Grid>
      </Fragment>
    ));
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Error404Modal />
      <MDBox mb={2} />
      <MDBox position="relative" mb={5}>
        <MDBox
          display="flex"
          alignItems="center"
          position="relative"
          minHeight="15rem"
          borderRadius="xl"
          sx={{
            backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.info.main, 0.6),
                rgba(gradients.info.state, 0.6)
              )}, url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            overflow: "hidden",
          }}
        />

        <Card
          sx={{
            position: "relative",
            mt: -5,
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <MDTypography variant="h5" fontWeight="medium">
                <FontAwesomeIcon icon={faRobot} size="2x" />
              </MDTypography>
            </Grid>
            <Grid item>
              <MDBox height="100%" mt={0.5} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium" sx={{ width: "100%" }}>
                  Bot Übersicht
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        </Card>
      </MDBox>

      {config.local.enabled && (
        <MDAlert color="info">Achtung der lokale Modus ist noch aktiviert, keine Bots...</MDAlert>
      )}

      <MDBox pb={3}>
        <Grid container alignItems="center">
          <Grid item xs={12} md={7}>
            <MDBox mb={1}>
              <MDTypography variant="h5">Private Bot</MDTypography>
            </MDBox>
            <MDBox mb={2}>
              <MDTypography variant="body2" color="text">
                Private Bots, basieren auf dem Public Bot, können aber individuell angepasst werden, und haben
                eine bessere Performance.
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={5}>
          <Grid container spacing={3}>
            {botsIsLoading ? placeholder(1, 2, 3) : [...botsList, supportBotCard]}
          </Grid>
        </MDBox>
      </MDBox>
      <MDBox pb={3}>
        <Grid container alignItems="center">
          <Grid item xs={12} md={7}>
            <MDBox mb={1}>
              <MDTypography variant="h5">Public Bot</MDTypography>
            </MDBox>
            <MDBox mb={2}>
              <MDTypography variant="body2" color="text">
                Dieser Bot ist für alle Benutzer zugänglich. Er benötigt keine Mindestanforderungen,
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={5}>
          <Grid container spacing={3}>
            {botsIsLoading
              ? placeholder(1)
              : [].map((bot, index) => (
                  <Fragment key={index}>
                    <Grid item xs={12} md={6} lg={4}>
                      <MDBox mb={1.5} mt={1.5}>
                        <BotListCard image={bot.avatarUrl} title={bot.name} maintenance={bot.maintenance} />
                      </MDBox>
                    </Grid>
                  </Fragment>
                ))}
          </Grid>
          <Tooltip title="Der Public Bot ist noch in Entwicklung">
            <span>
              <MDButton color="info" disabled>
                Bot einladen
              </MDButton>
            </span>
          </Tooltip>
        </MDBox>
      </MDBox>
      <MDBox pb={3}>
        <Grid container alignItems="center">
          <Grid item xs={12} md={7}>
            <MDBox mb={1}>
              <MDTypography variant="h5">Enterprise Bot</MDTypography>
            </MDBox>
            <MDBox mb={2}>
              <MDTypography variant="body2" color="text">
                Enterprise Bots, werden speziell für dich entwickelt, und können individuell angepasst. Sie
                können beliebig erweitert werden.
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={5}>
          <Grid container spacing={3}>
            {botsIsLoading
              ? placeholder(1, 2, 3, 4)
              : [].map((bot, index) => (
                  <Fragment key={index}>
                    <Grid item xs={12} md={6} lg={4}>
                      <MDBox mb={1.5} mt={1.5}>
                        <BotListCard image={bot.avatarUrl} title={bot.name} members={[]} />
                      </MDBox>
                    </Grid>
                  </Fragment>
                ))}
          </Grid>
          <Tooltip title="Enterprise Bots sind aktuell noch nicht verfügbar">
            <span>
              <MDButton color="info" disabled>
                Enterprise Bot anfragen
              </MDButton>
            </span>
          </Tooltip>
        </MDBox>
      </MDBox>
      <FooterSimple />
    </DashboardLayout>
  );
}

export default BotList;
