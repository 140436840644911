/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "../../../../../components/MDBox";

// Material Dashboard 2 PRO React imageExample components
import DashboardLayout from "../../../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../../../examples/Navbars/DashboardNavbar";
import FooterSimple from "../../../../../components/Footer/FooterSimple";

import {useEffect, useState} from "react";
import {faTags} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import {faTicket} from "@fortawesome/pro-duotone-svg-icons";
import {useParams, useSearchParams} from "react-router-dom";
import breakpoints from "../../../../../assets/theme/base/breakpoints";
import TicketSystemMessages from "./list/TicketSystemMessages";
import ModuleHeader from "../components/ModuleHeader";
import TicketSystemCategories from "../components/TicketSystem/TicketSystemCategories";
import {setSearchParam} from "../../../../../Utils";
import Error404Modal from "../../../../../components/Dialog/Error404Modal";
import {TicketSystemManage} from "./manage/TicketSystemManage";

function Overview() {
  const { botId, serverId } = useParams();

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");

  const [searchParams, setSearchParams] = useSearchParams();

  const tabParam = searchParams.get("tab") || "manage";

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /**
         The event listener that's calling the handleTabsOrientation function when resizing the window.
         */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Error404Modal />
      <MDBox mb={2} />
      <ModuleHeader
        name="TicketSystem"
        type="TICKET_SYSTEM"
        description="Erstelle und Verwalte Tickets von der Community."
        serverId={serverId}
        botId={botId}
        icon={faTicket}
      />
      <MDBox mt={5} mb={3}>
        <Tabs
          orientation={tabsOrientation}
          value={tabParam || "categories"}
          onChange={(event, newValue) =>
            setSearchParam(searchParams, setSearchParams, {
              tab: newValue,
            })
          }
        >
          {/*   <Tab
            label="Kategorien"
            value="categories"
            icon={<FontAwesomeIcon icon={faTags} size="sm" sx={{ mt: -0.25 }} />}
            disabled
          /> */}
          <Tab
            label="Verwalten"
            value="manage"
            icon={<FontAwesomeIcon icon={faTags} size="sm" sx={{ mt: -0.25 }} />}
          />
          <Tab
            label="Ticket"
            value="tickets"
            icon={<FontAwesomeIcon icon={faTicket} size="sm" sx={{ mt: -0.25 }} />}
          />
        </Tabs>
        <Grid container spacing={3} sx={{ marginTop: "20px" }}>
          {tabParam === "categories" ? (
            <>
              <TicketSystemCategories botId={botId} serverId={serverId} />
            </>
          ) : null}
          {tabParam === "manage" ? <TicketSystemManage botId={botId} serverId={serverId} /> : null}
          {tabParam === "tickets" ? <TicketSystemMessages botId={botId} serverId={serverId} /> : null}
        </Grid>
      </MDBox>
      <FooterSimple />
    </DashboardLayout>
  );
}

export default Overview;
