const HelpContent = [
  {
    title: "Was ist AutoClear?",
    type: "text",
    description: `Wenn die AutoClear-Funktion aktiviert ist, werden Nachrichten in den Discord-Kanälen
                automatisch gelöscht oder entfernt, um Platz für neue Nachrichten zu schaffen. Diese
                Funktion kann nützlich sein, um die Übersichtlichkeit und Sauberkeit von Kanälen zu
                verbessern, insbesondere in stark frequentierten oder zeitkritischen Umgebungen.`,
  },
  {
    title: "Wozu brauche Ich das?",
    type: "text",

    description: `Es gibt verschiedene Anwendungsfälle, in denen die AutoClear-Funktion
                nützlich sein kann. Zum Beispiel kann sie helfen, Spam-Nachrichten zu verhindern,
                indem alte Nachrichten regelmäßig entfernt werden. Sie kann auch in Situationen
                nützlich sein, in denen eine begrenzte Menge an Speicherplatz zur Verfügung steht
                oder in denen vertrauliche Informationen schnell gelöscht werden müssen, um die
                Sicherheit zu gewährleisten.`,
  },

  {
    title: "Was muss angegeben werden?",
    type: "table",

    params: [
      {
        name: "Channel",
        description: `Du musst einen Channel auswählen in welchem die Nachricht automatisch
                        gelöscht werden sollen,`,
      },
      {
        name: "Zeit",
        description: `Du musst eine Zeit angeben, nach welcher die Nachrichten gelöscht werden sollen.`,
      },
    ],
  },
  {
    title: "Bilder",
    type: "image",
    cols: 2,
    images: [
      {
        src: "https://zyonic-public-file-access.s3.eu-central-1.amazonaws.com/images/panel/modules/module_autoclear.gif",
        alt: "AutoClear in Discord",
      },
    ],
  },
  {
    type: "text",

    title: "Besonderheiten",
    description: `Wenn du eine Nachricht in einem AutoClear-Channel schreibst, und diese Nachricht
                _angepinnt_ wird, wird diese Nachricht _nicht gelöscht_.`,
  },
];

export { HelpContent as AutoClearHelp };
