import { NavLink, useLocation } from "react-router-dom";
import { SidenavTitle } from "./SidenavUtils";
import { useGetBotsQuery } from "../../store/bot/bot-api";
import { BotRoute } from "./BotRoute";
import SidenavItem from "../../examples/Sidenav/SidenavItem";
import MDBox from "../MDBox";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/pro-solid-svg-icons";
import Divider from "@mui/material/Divider";

export function BotsRoute({ search }) {
  const { data: botsData } = useGetBotsQuery();
  const location = useLocation();
  const { pathname } = location;
  const showModuleIcons = useSelector((state) => state.ui?.showModuleIcons);
  const supportPinData = useSelector((state) => state.cookie.supportPinData);

  const searchIncludes = (key) => key.toLowerCase().includes(search.toLowerCase());

  return (
    <>
      {supportPinData && (
        <>
          <SidenavTitle
            title={`Support Bot bis ${new Date(supportPinData?.expiresAt).toLocaleTimeString()}`}
            textColor={"white"}
          />
          {supportPinData && (
            <BotRoute botId={supportPinData?.botId} supportBot key={"supportBot"} search="" />
          )}
          <Divider />
        </>
      )}
      <SidenavTitle title={"Bots"} textColor={"white"} />
      {searchIncludes("Bot Liste") && (
        <MDBox px={2}>
          <NavLink to={`/bots`} sx={{ textDecoration: "none" }}>
            <SidenavItem
              name="Bot Liste"
              navKey={`bots`}
              active={`/bots` === pathname}
              nested
              icon={showModuleIcons && <FontAwesomeIcon icon={faList} />}
            />
          </NavLink>
        </MDBox>
      )}
      {Array.isArray(botsData) &&
        botsData?.map(({ botId }) => <BotRoute botId={botId} key={botId} search={search} />)}
    </>
  );
}
