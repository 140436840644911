import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useGetPublicModuleDataQuery } from "../../store/global/global-api";
import PageLayout from "../../examples/LayoutContainers/PageLayout";
import MDTypography from "../../components/MDTypography";
import MDAvatar from "../../components/MDAvatar";
import MDAlert from "../../components/MDAlert";
import DefaultNavbar from "../../examples/Navbars/DefaultNavbar";
import MDBox from "../../components/MDBox";
import image from "../../assets/images/bg-sign-in-cover.jpeg";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import { Emoji, EmojiStyle } from "emoji-picker-react";
import Footer from "../old/authentication/components/Footer";
import { Countdown } from "./components/Countdown";
import MDButton from "../../components/MDButton";
import { Skeleton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";

export function PublicGiveaway() {
  const { botId, guildId, id: giveawayId } = useParams();
  const navigate = useNavigate();
  const expertMode = useSelector((state) => state.ui.expertMode);
  const darkMode = useSelector((state) => state.ui.darkMode);

  const {
    data: giveawayData,
    isError: giveawayIsError,
    error: giveawayError,
    isLoading: giveawayIsLoading,
    isSuccess: giveawayIsSuccess,
  } = useGetPublicModuleDataQuery(
    {
      botId: botId,
      guildId: guildId,
      module: "giveaway",
      id: giveawayId,
    },
    {
      pollingInterval: 1000 * 30,
    }
  );

  const randomEmoji = () => {
    const emojis = ["1f389", "1f973", "1f38a"];
    return emojis[Math.floor(Math.random() * emojis.length)];
  };

  //http://localhost:3000/public/bot/715718893641138286/guild/635871759060434955/module/giveaway/6553bb7d621c0a1a85c3eca8

  return (
    <>
      <PageLayout>
        <DefaultNavbar
          routes={[]}
          page="Giveaway"
          action={{
            type: "internal",
            route: "/bots",
            label: "Zum Bot Panel",
          }}
        />
        <MDBox
          position="absolute"
          width="100%"
          minHeight="100vh"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              image &&
              `${linearGradient(
                rgba(gradients.dark.main, 0.6),
                rgba(gradients.dark.state, 0.6)
              )}, url(${image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            zIndex: -1,
          }}
        />
        <MDBox px={1} pt={12} width="100%" height="100vh" mx="auto" maxWidth="100%">
          <Container
            maxWidth="md"
            sx={{
              height: "100%",
            }}
          >
            {giveawayIsSuccess && (
              <MDBox mb={2}>
                <Countdown
                  endTime={giveawayData?.endTime}
                  startTime={giveawayData?.startTime}
                  ended={giveawayData.ended}
                />
              </MDBox>
            )}

            <MDBox
              justifyContent="space-between"
              display="flex"
              width="100%"
              height="100%"
              flexDirection="column"
              gap={1}
            >
              {giveawayIsLoading && (
                <Card
                  sx={({
                    palette: { transparent: transparentColor, white, background },
                    functions: { rgba },
                  }) => ({
                    backgroundColor: rgba(darkMode ? background.sidenav : white.main, 0.8),
                    backdropFilter: `blur(10px)`,
                  })}
                >
                  <Skeleton
                    variant="rounded"
                    width="100%"
                    height="600px"
                    animation="wave"
                    sx={{
                      maxHeight: "90vh",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <MDTypography>
                      <FontAwesomeIcon
                        icon={faSpinnerThird}
                        size="lg"
                        spin
                        style={{ visibility: "visible" }}
                      />
                    </MDTypography>
                  </Skeleton>
                </Card>
              )}
              {giveawayIsError && (
                <MDBox display="flex" flexDirection="column" gap={2}>
                  <MDAlert color="error">Das Gewinnspiel existiert nicht, oder wurde gelöscht.</MDAlert>
                  <NavLink to={`discord://discord.com/channels/${guildId}`} style={{ marginLeft: "auto" }}>
                    <MDButton
                      onClick={() => {
                        window.close();
                      }}
                      variant="contained"
                      color="secondary"
                    >
                      Zurück zum Discord
                    </MDButton>
                  </NavLink>
                </MDBox>
              )}
              {giveawayIsSuccess && (
                <MDBox display="flex" gap={2} flexDirection={{ xs: "column", lg: "row" }}>
                  <Card sx={{ p: 4 }}>
                    <MDBox display="flex" alignItems="center" flexDirection="column" gap={2}>
                      <Emoji unified="1f389" emojiStyle={EmojiStyle.TWITTER} size={64} />
                      <MDBox textAlign="center">
                        <MDTypography variant="h5">Gewinnspiel</MDTypography>
                        {expertMode && (
                          <NavLink
                            to={`discord://discord.com/channels/${guildId}/${giveawayData.channelId}/${giveawayData.messageId}`}
                          >
                            <MDTypography
                              variant="body2"
                              sx={{
                                "&:hover": {
                                  textDecoration: "underline",
                                },
                              }}
                            >
                              {giveawayData.id}
                            </MDTypography>
                          </NavLink>
                        )}
                      </MDBox>
                      <MDBox textAlign="center">
                        <MDTypography variant="h6">Preis</MDTypography>
                        <MDTypography variant="body2">{giveawayData.prize}</MDTypography>
                      </MDBox>
                      <MDBox textAlign="center">
                        <MDTypography variant="h6">Gewinneranzahl</MDTypography>
                        <MDTypography variant="body2">{giveawayData.winnerNumber}</MDTypography>
                      </MDBox>

                      <MDTypography variant="body2">
                        Bis: {new Date(giveawayData.endTime).toLocaleString()}
                      </MDTypography>

                      <MDTypography variant="body2">
                        Hosted by:
                        <MDAvatar
                          src={giveawayData.host?.avatarUrl}
                          alt={giveawayData.host?.name}
                          sx={{
                            width: 24,
                            height: 24,
                            mx: 1,
                            display: "inline-flex",
                            verticalAlign: "middle",
                          }}
                        />
                        {giveawayData.host.name}
                      </MDTypography>
                    </MDBox>
                  </Card>
                  <MDBox display="flex" flexDirection="column" gap={2} flexGrow={1}>
                    {giveawayData.ended && giveawayData.winners.length > 0 && (
                      <Card sx={{ p: 2 }}>
                        <MDTypography variant="subtitle2">
                          Das Gewinnspiel wurde beendet. Hier kannst du{" "}
                          {giveawayData.winners.length === 1 ? `den` : `die ${giveawayData.winners.length}`}{" "}
                          Gewinner sehen.
                        </MDTypography>
                      </Card>
                    )}

                    <Grid container spacing={2}>
                      {giveawayData.winners.map(({ name, avatarUrl, id }, index, array) => (
                        <Grid
                          item
                          key={id}
                          lg={array.length > 4 ? 4 : 12}
                          md={array.length > 5 ? 6 : 12}
                          xs={12}
                        >
                          <Card
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "row",
                              gap: 2,
                              p: 2,
                            }}
                          >
                            <MDAvatar
                              src={avatarUrl}
                              alt={name}
                              sx={{ width: 50, height: 50, display: "inline-flex", verticalAlign: "middle" }}
                            />
                            <MDBox
                              display="flex"
                              gap={1}
                              justifyContent="space-between"
                              flexGrow={1}
                              pr={2}
                              alignItems="center"
                            >
                              <div>
                                <MDTypography variant="h6">{name}</MDTypography>
                                {expertMode && <MDTypography variant="body2">{id}</MDTypography>}
                              </div>
                              <Emoji unified={randomEmoji()} emojiStyle={EmojiStyle.TWITTER} size={32} />
                            </MDBox>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>

                    <Card sx={{ p: 2 }}>
                      {
                        // TODO add button to join giveaway bzw einen link zur message im discord
                      }
                      {giveawayData.ended ? (
                        <MDTypography variant="subtitle2">
                          Es hatten sich {giveawayData.entries.length} Leute für das Gewinnspiel angemeldet.
                          Die ersten 50 werden hier angezeigt.
                        </MDTypography>
                      ) : (
                        <>
                          <MDTypography
                            variant="subtitle2"
                            sx={{
                              alignItems: "center",
                            }}
                          >
                            Wenn du am Gewinnspiel teilnehmen möchtest, klicke auf das{" "}
                            <Emoji unified="1f389" emojiStyle={EmojiStyle.TWITTER} size={16} />
                            -Emoji im Discord Server.
                          </MDTypography>
                          <MDTypography variant="subtitle2">
                            Es haben sich bereits {giveawayData.entries.length} Leute für das Gewinnspiel
                            angemeldet. Die ersten 50 werden hier angezeigt.
                          </MDTypography>
                        </>
                      )}
                    </Card>
                    <Grid container spacing={2}>
                      {giveawayData.entries
                        .filter((entry, index) => index < 50)
                        .map(({ name, avatarUrl, id }) => (
                          <Grid item xl={4} md={6} xs={12}>
                            <Card
                              key={id}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "row",
                                gap: 2,
                                p: 2,
                              }}
                            >
                              <MDAvatar
                                src={avatarUrl}
                                alt={name}
                                sx={{
                                  width: 50,
                                  height: 50,
                                  display: "inline-flex",
                                  verticalAlign: "middle",
                                }}
                              />
                              <MDBox>
                                <MDTypography variant="h6">{name}</MDTypography>
                                {expertMode && <MDTypography variant="body2">{id}</MDTypography>}
                              </MDBox>
                            </Card>
                          </Grid>
                        ))}
                    </Grid>
                  </MDBox>
                </MDBox>
              )}
              <Footer light absolute={false} />
            </MDBox>
          </Container>
        </MDBox>
      </PageLayout>
    </>
  );
}
