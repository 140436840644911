import Card from "@mui/material/Card";
import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";
import MDButton from "../../../../../components/MDButton";
import Grid from "@mui/material/Grid";
import { useForm } from "react-hook-form";
import { FormCategoryAutocomplete } from "../../../../../components/Form/FormInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import apiMessages from "../../../../../apiMessages";
import {
  useGetCategoriesQuery,
  useGetGuildQuery,
  useGetTextChannelsQuery,
  useUpdateGuildMutation,
} from "../../../../../store/guild/guild-api";
import Logger from "../../../../../Logger";
import { useEffect } from "react";
import { AutoCompleteChannelIcon } from "../../../../../components/Form/AutoCompleteIcon";

export function SuggestionUpdateForm({ botId, guildId, fullHeight }) {
  const [update, { isLoading }] = useUpdateGuildMutation();

  const {
    data: guildData = [],
    isFetching: guildIsFetching,
    refetch: guildRefetch,
  } = useGetGuildQuery({
    botId,
    guildId,
  });

  const {
    data: textChannelData = [],
    isFetching: textChannelIsFetching,

    refetch: textChannelRefetch,
  } = useGetTextChannelsQuery({
    botId,
    guildId,
  });
  const {
    data: categoriesData = [],

    isFetching: categoriesIsFetching,
    refetch: categoriesRefetch,
  } = useGetCategoriesQuery({
    botId,
    guildId,
  });

  const formHandler = useForm({
    defaultValues: {
      suggestChannel: "",
    },
  });
  const { control, setValue, handleSubmit } = formHandler;

  const onSubmit = async (e) => {
    update({
      botId,
      guildId,
      data: {
        ...guildData,
        ...e,
      },
    }).then(({ data, error }) => {
      Logger.info("SubmitEvent", { module: "SubmitForm" }, data, error);
      apiMessages.showMessage({
        type: "Suggestion",
        title: data ? "updated" : error?.data?.error || error,
      });
    });
  };

  useEffect(() => {
    if (guildData?.suggestChannel) {
      setValue("suggestChannel", guildData.suggestChannel);
    }
  }, [guildData, setValue]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        height: fullHeight ? "100%" : "auto",
      }}
    >
      <Card sx={{ height: "100%" }}>
        <MDBox pt={2} px={2}>
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            Einstellungen
          </MDTypography>
        </MDBox>
        <MDBox p={2}>
          <Grid item xs={12}>
            <MDBox pt={2} px={2}>
              <FormCategoryAutocomplete
                name="suggestChannel"
                label="Suggestion Channel"
                categories={categoriesData}
                options={[...textChannelData].sort((x, y) => x.category - y.category)}
                groupByFunction="default"
                labelIcon={(option) => <AutoCompleteChannelIcon option={option} />}
                additionalParameters={["type"]}
                formHandler={formHandler}
                refetch={() => {
                  textChannelRefetch();
                  categoriesRefetch();
                  guildRefetch();
                }}
                refetching={textChannelIsFetching || categoriesIsFetching || guildIsFetching}
              />
            </MDBox>
          </Grid>

          <Grid item xs={12}>
            <MDBox pt={2} px={2}>
              <MDButton
                type="submit"
                variant="contained"
                sx={{ width: "100%" }}
                disabled={isLoading}
                color="primary"
              >
                Speichern
                {isLoading && (
                  <FontAwesomeIcon icon={faSpinnerThird} size="lg" spin style={{ marginLeft: "5px" }} />
                )}
              </MDButton>
            </MDBox>
          </Grid>
        </MDBox>
      </Card>
    </form>
  );
}
