// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import { Skeleton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/pro-solid-svg-icons";
import { UserChart } from "./chart/UserChart";
import { useGetStatisticsQuery } from "../../../../store/guild/guild-api";
import MDAlert from "../../../../components/MDAlert";

function ServerOverviewChart({ count = 0, height = "6.25rem", loading = false, botId, guildId }) {
  const { data: statisticData } = useGetStatisticsQuery({
    botId: botId,
    guildId: guildId,
    size: 30,
  });

  const labels = statisticData?.content?.map((item) => item.createdAt);
  const data = statisticData?.content?.map((item) => item.memberCount);

  console.log("Statistics Data", statisticData);

  return (
    <Card>
      <MDBox display="flex" alignItems="center" pt={2} px={2}>
        <MDBox
          width="3rem"
          height="3rem"
          display="grid"
          justifyContent="center"
          alignItems="center"
          borderRadius="md"
          shadow="md"
          color="white"
          bgColor="info"
          variant="gradient"
        >
          <FontAwesomeIcon icon={faUsers} />
        </MDBox>
        <MDBox ml={2} lineHeight={1}>
          <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
            Users
          </MDTypography>
          {/* eslint-disable-next-line no-nested-ternary */}
          {count ? (
            loading ? (
              <>
                <Skeleton variant="text" animation="wave">
                  <MDTypography variant="h5" display="block" fontWeight="regular" color="text">
                    ***********
                  </MDTypography>
                </Skeleton>
              </>
            ) : (
              <>
                <MDTypography variant="button" display="block" fontWeight="regular" color="text">
                  {count}
                </MDTypography>
              </>
            )
          ) : null}
        </MDBox>
      </MDBox>
      {statisticData?.content?.length <= 3 && (
        <MDBox p={2} pb={0}>
          <MDAlert color="dark">
            <MDTypography variant="h6">
              Wir sammeln noch Daten um dir hier einen schönen Graphen zu zeigen. Schaue in den nächsten Tagen
              nochmal vorbei.
            </MDTypography>
          </MDAlert>
        </MDBox>
      )}
      {statisticData?.content?.length > 3 &&
        (loading ? (
          <MDBox mt={2} p={2}>
            <Skeleton
              variant="rectangular"
              width="100%"
              height={height}
              style={{ borderRadius: "0.375rem" }}
              animation="wave"
            />
          </MDBox>
        ) : (
          <MDBox mt={2}>
            <UserChart
              height="20rem"
              chart={{
                labels: labels,
                datasets: [
                  {
                    lineTension: 0.1,
                    label: "User",
                    color: "info",
                    data: data,
                  },
                ],
              }}
            />
          </MDBox>
        ))}
    </Card>
  );
}

// Typechecking props for the ProgressLineChart
ServerOverviewChart.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  progress: PropTypes.number.isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chart: PropTypes.objectOf(PropTypes.array).isRequired,
  loading: PropTypes.bool,
};

export default ServerOverviewChart;
