import { useSelector } from "react-redux";
import { useState } from "react";
import * as Sentry from "@sentry/react";
import Card from "@mui/material/Card";
import MDTypography from "../MDTypography";
import { Collapse } from "@mui/material";
import MDBox from "../MDBox";
import MDButton from "../MDButton";
import Tooltip from "@mui/material/Tooltip";

/**
 *
 * @param error {Sentry.Error}
 * @param eventId {string}
 * @param componentStack {string}
 * @param resetError {function}
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
export function FallbackComponent({ eventId, error, componentStack, resetError, ...props }) {
  const user = useSelector((state) => state.auth.user);
  const [checked, setChecked] = useState(false);

  const analyticsCookies = useSelector((state) => state.cookie.analytics);

  // TODO: wenn man kekse deaktiviert hat, ist trotzdem der Report Dialog da

  return (
    <Card
      sx={{
        p: 2,
      }}
    >
      <MDTypography variant="h6" fontWeight="bold">
        An error has occurred
      </MDTypography>
      <MDTypography variant="h6" fontWeight="bold">
        {error.toString()}
      </MDTypography>

      <Collapse in={checked}>
        <MDTypography
          variant="caption"
          fontWeight="bold"
          sx={{
            overflowX: "auto",
            whiteSpace: "pre-wrap",
          }}
        >
          <code>{componentStack}</code>
        </MDTypography>
      </Collapse>
      <MDBox
        display="flex"
        gap={2}
        {...props}
        sx={{
          mt: 2,
          width: "100%",
        }}
      >
        <MDButton variant="outlined" color="primary" fullWidth onClick={() => setChecked(!checked)}>
          Stack Trace
        </MDButton>
        <MDButton variant="gradient" color="primary" fullWidth onClick={() => resetError()}>
          Erneut versuchen
        </MDButton>
        {analyticsCookies ? (
          <MDButton
            variant="gradient"
            color="primary"
            fullWidth
            onClick={() =>
              Sentry.showReportDialog({
                eventId,
                user: {
                  name: user.username + " - " + user.id,
                },
              })
            }
          >
            Fehler melden
          </MDButton>
        ) : (
          <Tooltip title="Du musst die Analytics Cookies akzeptieren, um Fehler melden zu können">
            <span
              style={{
                width: "100%",
              }}
            >
              <MDButton variant="gradient" color="primary" fullWidth disabled>
                Fehler melden
              </MDButton>
            </span>
          </Tooltip>
        )}
      </MDBox>
    </Card>
  );
}
