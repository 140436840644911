import { createApi, retry } from "@reduxjs/toolkit/query/react";
import {
  defaultTransformErrorResponse,
  defaultTransformResponse,
  devRoles,
  devUser,
  dynamicBaseQuery,
} from "../redux-utils.js";
import { devModeEnabled, getServerUrl } from "../../config";
import { inlineTryCatch } from "../../Utils";

/**
 * Retry the base query with a maximum of 3 retries.
 */
const retryQuery = retry(dynamicBaseQuery, { maxRetries: 3 });

/**
 * The authApi object is created using Redux Toolkit's createApi method.
 * It includes endpoints for getting account details, account roles, and login status.
 */
export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: retryQuery,
  tagTypes: ["auth", "apikey"],
  endpoints: (builder) => ({
    /**
     * Endpoint for getting account details.
     * In development mode, it returns a mock user.
     * In production, it fetches the user details from the server.
     */
    getAccount: builder.query({
      queryFn: async (arg, { signal }) => {
        if (devModeEnabled()) {
          return {
            data: {
              ...devUser,
            },
          };
        }
        try {
          const data = await fetch(`${getServerUrl()}/api/cloud/self`, {
            signal,
            credentials: "include",
            mode: "cors",
            redirect: "manual",
          });
          const text = await data.text();

          return { data: defaultTransformResponse(text) };
        } catch (error) {
          return { error: inlineTryCatch(() => defaultTransformErrorResponse(error), {}) };
        }
      },
    }),
    /**
     * Endpoint for getting account roles.
     * In development mode, it returns mock roles.
     * In production, it fetches the roles from the server.
     */
    getAccountRoles: builder.query({
      queryFn: async (arg, { signal }) => {
        if (devModeEnabled()) {
          return {
            data: {
              roles: [...devRoles],
            },
          };
        }
        try {
          const data = await fetch(`${getServerUrl()}/api/cloud/roles`, {
            signal,
            credentials: "include",
            mode: "cors",
            redirect: "manual",
          });
          const text = await data.text();
          return { data: defaultTransformResponse(text) };
        } catch (error) {
          return { error: inlineTryCatch(() => defaultTransformErrorResponse(error), {}) };
        }
      },
    }),
    /**
     * Endpoint for getting login status.
     * The URL depends on whether the app is in development mode or not.
     */
    getLoginStatus: builder.query({
      extraOptions: { maxRetries: 1 },
      query: (arg) => ({
        url: devModeEnabled() ? "api/logged-in" : "api/cloud/logged-in",
        credentials: "include",
        mode: "cors",
        redirect: "manual",
      }),
      transformResponse: defaultTransformResponse,
      transformErrorResponse: defaultTransformErrorResponse,
    }),
  }),
});
export const { useGetAccountQuery, useGetLoginStatusQuery, useGetAccountRolesQuery } = authApi;
