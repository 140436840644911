import { createApi } from "@reduxjs/toolkit/query/react";
import { defaultTransformErrorResponse, defaultTransformResponse, dynamicBaseQuery } from "../redux-utils.js";
import config, { devModeEnabled, getServerUrl } from "../../config";
import { inlineTryCatch } from "../../Utils";

export const botApi = createApi({
  reducerPath: "botApi",
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    // ---- Bot ----
    getBot: builder.query({
      query: ({ botId }) => ({
        url: `api/${botId}`,
      }),
      providesTags: (result, error, botId) => [{ type: "Bot", id: botId }],
      transformResponse: defaultTransformResponse,
      transformErrorResponse: defaultTransformErrorResponse,
    }),
    updateBot: builder.mutation({
      query: ({ botId, data }) => ({
        url: `api/${botId}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, { botId }) => [{ type: "Bot", id: botId }],
      transformResponse: defaultTransformResponse,
      transformErrorResponse: defaultTransformErrorResponse,
    }),

    getBots: builder.query({
      queryFn: async (arg, { signal }) => {
        if (devModeEnabled()) {
          return {
            data: [
              {
                botId: config.local.bot,
              },
            ],
          };
        }
        try {
          const data = await fetch(`${getServerUrl()}/api/cloud/bot`, {
            signal,
            credentials: "include",
            mode: "cors",
            redirect: "manual",
          });
          const text = await data.text();
          return { data: defaultTransformResponse(text) };
        } catch (error) {
          return { error: inlineTryCatch(() => defaultTransformErrorResponse(error), {}) };
        }
      },
      providesTags: (result, error) => [{ type: "Bot", id: "LIST" }],
    }),
    // ---- WhiteList ----
    getWhitelist: builder.query({
      query: ({ botId }) => ({
        url: `api/${botId}/whitelist`,
      }),
      transformResponse: defaultTransformResponse,
      transformErrorResponse: defaultTransformErrorResponse,
      providesTags: (result, error, botId) => [
        {
          type: "Whitelist",
          id: botId,
        },
        {
          type: "Whitelist",
          id: "LIST",
        },
      ],
    }),
    updateWhitelist: builder.mutation({
      query: ({ botId, data }) => ({
        url: `api/${botId}/whitelist`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result, error, { botId }) => [
        { type: "Whitelist", id: botId },
        { type: "Whitelist", id: "LIST" },
      ],
      transformResponse: defaultTransformResponse,
      transformErrorResponse: defaultTransformErrorResponse,
    }),
    deleteWhitelist: builder.mutation({
      query: ({ botId, id, data }) => ({
        url: `api/${botId}/whitelist/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, { botId }) => [
        { type: "Whitelist", id: botId },
        { type: "Whitelist", id: "LIST" },
      ],
      transformResponse: defaultTransformResponse,
      transformErrorResponse: defaultTransformErrorResponse,
    }),
    // Support Pin
    createSupportPin: builder.mutation({
      query: ({ botId }) => ({
        url: `api/cloud/support-pin/${botId}`,
        method: "POST",
      }),
      invalidatesTags: (result, error, { botId }) => [{ type: "SupportPin", id: botId }],
      transformResponse: defaultTransformResponse,
      transformErrorResponse: defaultTransformErrorResponse,
    }),
    redeemSupportPin: builder.mutation({
      query: ({ data }) => ({
        url: `api/cloud/support-pin/redeem-pin`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result, error, { botId }) => [{ type: "SupportPin", id: botId }],
      transformResponse: defaultTransformResponse,
      transformErrorResponse: defaultTransformErrorResponse,
    }),
  }),
});
export const {
  useGetBotQuery,
  useGetBotsQuery,
  useUpdateBotMutation,
  useGetWhitelistQuery,
  useUpdateWhitelistMutation,
  useDeleteWhitelistMutation,
} = botApi;
