import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useGetPublicModuleDataQuery } from "../../store/global/global-api";
import PageLayout from "../../examples/LayoutContainers/PageLayout";
import MDTypography from "../../components/MDTypography";
import DefaultNavbar from "../../examples/Navbars/DefaultNavbar";
import MDBox from "../../components/MDBox";
import image from "../../assets/images/bg-sign-in-cover.jpeg";
import Grid from "@mui/material/Grid";
import Footer from "../old/authentication/components/Footer";
import Card from "@mui/material/Card";
import MDAvatar from "../../components/MDAvatar";
import Container from "@mui/material/Container";
import MDAlert from "../../components/MDAlert";
import MDButton from "../../components/MDButton";
import { Skeleton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { useSelector } from "react-redux";

const numberHexColor = {
  1: "#FFD700",
  2: "#C0C0C0",
  3: "#CD7F32",
};

const Podium = ({ user, height, number, darkMode }) => (
  <Grid
    item
    xs={number === "1" ? 12 : 6}
    lg={4}
    xl={3}
    order={{
      xs: number === "1" ? 0 : number === "2" ? 1 : 2,
      lg: number === "1" ? 1 : number === "2" ? 0 : 2,
    }}
  >
    <Card
      sx={({ palette: { white, card }, functions: { rgba } }) => ({
        backgroundColor: rgba(darkMode ? card.main : white.main, 0.75),
        backdropFilter: `blur(10px)`,
        p: 2,
        height: {
          xs: "200px",
          lg: height,
        },
        display: "flex",
        justifyContent: "space-between",
        gap: 1,
        border: ({ palette }) => `medium double ${numberHexColor[number]}`,
        mt: 3,
      })}
    >
      <MDBox
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <MDAvatar
          src={user?.user?.avatar}
          size="xl"
          sx={({ palette: { transparent, white, card }, functions: { rgba } }) => ({
            backgroundColor: rgba(darkMode ? card.main : white.main, 1),
            border: `2px solid ${numberHexColor[number]}`,
            backdropFilter: `blur(10px)`,
            mt: -5,
            mb: 2,
          })}
        />
        <MDTypography variant="h5">{number}. Platz</MDTypography>
        <MDTypography variant="h6">{user?.user?.name}</MDTypography>
      </MDBox>
      <MDBox display="flex" justifyContent="space-between" width="100%">
        <MDTypography variant="body2">Level: {user?.level}</MDTypography>
        <MDTypography variant="body2">XP: {user?.xp}</MDTypography>
      </MDBox>
    </Card>
  </Grid>
);

export function PublicXP() {
  const { botId, guildId } = useParams();
  const navigate = useNavigate();
  const darkMode = useSelector((state) => state.ui.darkMode);
  const {
    data: xpData,
    isError: xpIsError,
    error: xpError,
    isLoading: xpIsLoading,
    isSuccess: xpIsSuccess,
  } = useGetPublicModuleDataQuery(
    {
      botId: botId,
      guildId: guildId,
      module: "xp",
    },
    {
      pollingInterval: 1000 * 60,
    }
  );

  const [firstUser, secondUser, thirdUser] = xpData || [];

  return (
    <>
      <PageLayout>
        <DefaultNavbar
          routes={[]}
          page="XP Leaderboard"
          action={{
            type: "internal",
            route: "/bots",
            label: "Zum Bot Panel",
          }}
        />
        <MDBox
          position="absolute"
          width="100%"
          minHeight="100vh"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              image &&
              `${linearGradient(
                rgba(gradients.dark.main, 0.6),
                rgba(gradients.dark.state, 0.6)
              )}, url(${image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            zIndex: -1,
          }}
        />
        <MDBox px={1} pt={12} width="100%" height="100vh" mx="auto" maxWidth="100%">
          <Container
            maxWidth="md"
            sx={{
              height: "100%",
            }}
          >
            <MDBox
              justifyContent="space-between"
              display="flex"
              width="100%"
              height="100%"
              flexDirection="column"
              gap={1}
            >
              <MDBox display="flex" gap={2} flexDirection="column" width="100%">
                {xpIsLoading && (
                  <Card
                    sx={({
                      palette: { transparent: transparentColor, white, background },
                      functions: { rgba },
                    }) => ({
                      backgroundColor: rgba(darkMode ? background.sidenav : white.main, 0.8),
                      backdropFilter: `blur(10px)`,
                    })}
                  >
                    <Skeleton
                      variant="rounded"
                      width="100%"
                      height="600px"
                      animation="wave"
                      sx={{
                        maxHeight: "90vh",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <MDTypography>
                        <FontAwesomeIcon
                          icon={faSpinnerThird}
                          size="lg"
                          spin
                          style={{ visibility: "visible" }}
                        />
                      </MDTypography>
                    </Skeleton>
                  </Card>
                )}
                {xpIsError && (
                  <MDBox display="flex" flexDirection="column" gap={2}>
                    <MDAlert color="error">Es gibt noch kein XP Leaderboard für diesen Server.</MDAlert>
                    <NavLink to={`discord://discord.com/channels/${guildId}`} style={{ marginLeft: "auto" }}>
                      <MDButton
                        onClick={() => {
                          window.close();
                        }}
                        variant="contained"
                        color="secondary"
                      >
                        Zurück zum Discord
                      </MDButton>
                    </NavLink>
                  </MDBox>
                )}
                <MDBox>
                  <Grid
                    container
                    spacing={1}
                    sx={{
                      alignItems: "end",
                      justifyContent: "center",
                    }}
                  >
                    {secondUser && <Podium user={secondUser} height="200px" number="2" darkMode={darkMode} />}
                    {firstUser && <Podium user={firstUser} height="220px" number="1" darkMode={darkMode} />}
                    {thirdUser && <Podium user={thirdUser} height="180px" number="3" darkMode={darkMode} />}
                  </Grid>
                </MDBox>

                {xpIsSuccess &&
                  xpData
                    ?.filter((_, index) => index > 2)
                    .map((item, index) => {
                      return (
                        <Card
                          sx={({ palette: { white, card }, functions: { rgba } }) => ({
                            p: 2,
                            backgroundColor: rgba(darkMode ? card.main : white.main, 0.75),
                            backdropFilter: `blur(10px)`,
                          })}
                        >
                          <MDBox
                            key={index}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <MDBox display="flex" alignItems="center">
                              <MDTypography variant="body2" key={index}>
                                {index + 4}.
                              </MDTypography>
                              <MDAvatar
                                src={item.user?.avatar || "https://cdn.discordapp.com/embed/avatars/0.png"}
                                sx={{
                                  mx: 1,
                                }}
                              />
                              <MDTypography variant="body2" key={index}>
                                {item.user?.name}
                              </MDTypography>
                            </MDBox>
                            <MDBox>
                              <MDBox display="flex" alignItems="end" flexDirection="column">
                                <MDTypography variant="body2" key={index}>
                                  Level: {item.level}
                                </MDTypography>
                                <MDTypography variant="body2" key={index}>
                                  Xp: {item.xp}
                                </MDTypography>
                              </MDBox>
                            </MDBox>
                          </MDBox>
                        </Card>
                      );
                    })}

                <Card
                  sx={({ palette: { white, card }, functions: { rgba } }) => ({
                    p: 2,
                    backgroundColor: rgba(darkMode ? card.main : white.main, 0.75),
                    backdropFilter: `blur(10px)`,
                  })}
                >
                  <MDTypography variant="body2">
                    Schreibe <code>!rank</code> in den Chat um deinen aktuelles Level und deine XP zu sehen.
                    Du bekommst bei jeder Nachricht 1 - 20 XP.
                  </MDTypography>
                </Card>
              </MDBox>
              <Footer light absolute={false} />
            </MDBox>
          </Container>
        </MDBox>
      </PageLayout>
    </>
  );
}
