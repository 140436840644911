/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { forwardRef } from "react";
import PropTypes from "prop-types";
import MDTypographyRoot from "components/MDTypography/MDTypographyRoot";
import { useSelector } from "react-redux";

const MDTypography = forwardRef(
  (
    {
      color,
      fontWeight,
      textTransform,
      verticalAlign,
      variant,
      horizontalAlign,
      textGradient,
      opacity,
      children,
      gutterBottom,
      ...rest
    },
    ref
  ) => {
    const darkMode = useSelector((state) => state.ui.darkMode);

    return (
      <MDTypographyRoot
        {...rest}
        ref={ref}
        variant={variant}
        gutterBottom={gutterBottom}
        align={horizontalAlign}
        ownerState={{
          color,
          textTransform,
          verticalAlign,
          fontWeight,
          opacity,
          textGradient,
          darkMode,
          horizontalAlign,
          gutterBottom,
          variant,
        }}
      >
        {children}
      </MDTypographyRoot>
    );
  }
);

// Setting default values for the props of MDTypography
MDTypography.defaultProps = {
  color: "dark",
  fontWeight: false,
  textTransform: "none",
  verticalAlign: "unset",
  horizontalAlign: "inherit",
  textGradient: false,
  opacity: 1,
  variant: "inherit",
  gutterBottom: false,
};

// Typechecking props for the MDTypography
MDTypography.propTypes = {
  color: PropTypes.oneOf([
    "inherit",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
    "text",
    "white",
  ]),
  fontWeight: PropTypes.oneOf([false, "light", "regular", "medium", "bold"]),
  textTransform: PropTypes.oneOf(["none", "capitalize", "uppercase", "lowercase"]),
  verticalAlign: PropTypes.oneOf([
    "unset",
    "baseline",
    "sub",
    "super",
    "text-top",
    "text-bottom",
    "middle",
    "top",
    "bottom",
  ]),
  horizontalAlign: PropTypes.oneOf(["inherit", "left", "right", "center", "justify"]),
  variant: PropTypes.oneOf([
    "inherit",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "subtitle1",
    "subtitle2",
    "body1",
    "body2",
    "caption",
    "button",
    "overline",
  ]),
  textGradient: PropTypes.bool,
  children: PropTypes.node.isRequired,
  opacity: PropTypes.number,
  gutterBottom: PropTypes.bool,
};

export default MDTypography;
