import { useEffect, useMemo, useState } from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandPointer, faPenToSquare, faRectangleXmark } from "@fortawesome/pro-solid-svg-icons";
import { Alert, Grid } from "@mui/material";
import Menu from "@mui/material/Menu";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";
import MDButton from "../MDButton";
import NotificationItem from "../../examples/Items/NotificationItem";
import ImageEditorModal from "./ImageEditorModal";
import { PreviewCanvas } from "./components/PreviewCanvas";
import MDAlert from "../MDAlert";
import { replace } from "./components/FabricUtils";
import { useCreateModuleDataMutation, useGetModuleDataQuery } from "../../store/modules/module-api";
import { useSelector } from "react-redux";
import { ErrorBoundary } from "../ErrorBoundary/ErrorBoundary";

export function ImageSelector({
  type = "",
  title = "",
  onClose = (x) => {},
  onSubmit = () => {},
  open = false,
  replacer = [],
}) {
  const { botId, serverId } = useParams();

  const [channel, setChannel] = useState(null);

  const [update, setUpdate] = useState(false);

  const close = () => onClose(false);

  useEffect(() => {
    setUpdate((prev) => !prev);
  }, [channel]);

  const expertMode = useSelector((state) => state.ui.expertMode);
  const { enqueueSnackbar } = useSnackbar();

  const [loaded, setLoaded] = useState(true);
  const [openMenu, setOpenMenu] = useState(false);

  const [openEditor, setOpenEditor] = useState(false);
  const [canvasData, setCanvasData] = useState({});
  const [noImage, setNoImage] = useState(false);
  const [availableFonts, setAvailableFonts] = useState([]);

  const { data: fonts = [], isLoading: fontsIsLoading } = useGetModuleDataQuery({
    botId,
    guildId: serverId,
    module: "image/fonts",
  });

  const { data: imageData = [], isLoading: imageIsLoading } = useGetModuleDataQuery({
    botId,
    guildId: serverId,
    module: `image/`,
  });

  const { data: imageTemplateData = [], isLoading: imageTemplateIsLoading } = useGetModuleDataQuery({
    botId,
    guildId: serverId,
    module: `image/template/`,
  });

  const templates =
    useMemo(
      () =>
        imageTemplateData.content?.filter(
          (template) => template.imageType.toLowerCase() === type.toLowerCase()
        ),
      [imageTemplateData, type]
    ) || [];

  useEffect(() => {
    if (!imageData) return setNoImage(true);
    const { content: images } = imageData;
    const image = images?.find((x) => x.type?.toLowerCase() === type.toLowerCase());
    console.log("image", image, images);
    if (!image) return setNoImage(true);
    setCanvasData(image);
    setNoImage(false);
  }, [imageData, type]);

  const [create, { isLoading: createIsLoading }] = useCreateModuleDataMutation();

  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const submit = (submitData) => {
    console.log("submitData", submitData);
    create({
      botId,
      serverId,
      module: "image/",
      data: {
        type,
        data: submitData,
      },
    });
  };

  console.log("canvasData", canvasData);

  return (
    <>
      <ErrorBoundary
        fallbackRender={({ error, resetErrorBoundary }) => (
          <>
            <MDAlert variant="outlined" color="error" dismissible>
              Es ist ein Fehler innerhalb des Image Builders aufgetreten. Bitte versuche es erneut.
            </MDAlert>
          </>
        )}
      >
        <Dialog maxWidth="xl" fullWidth open={open} onClose={() => close()}>
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <MDTypography sx={{ flex: 1 }} variant="h6" component="div">
                Bild auswählen {title && `(${title})`}
              </MDTypography>
              <IconButton edge="end" onClick={() => close()} aria-label="close">
                <MDTypography>
                  <FontAwesomeIcon icon={faRectangleXmark} />
                </MDTypography>
              </IconButton>
            </Toolbar>
            <MDBox px={2}>
              <MDAlert color="secondary">
                <small>Diese Funktion ist noch in der Entwicklung und kann Fehler enthalten</small>
              </MDAlert>
            </MDBox>
          </AppBar>
          <Menu
            anchorEl={openMenu}
            anchorReference={null}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            variant="selectedMenu"
            open={Boolean(openMenu)}
            onClose={() => setOpenMenu(false)}
            sx={{ m: 0.5 }}
          >
            <NotificationItem
              icon={<FontAwesomeIcon icon={faHandPointer} />}
              title="Aktuelles Bild durch Template ersetzen"
              onClick={() => {
                setOpenMenu(false);
                setCanvasData(selectedTemplate);
                submit(selectedTemplate?.data);
              }}
            />
            <NotificationItem
              icon={<FontAwesomeIcon icon={faPenToSquare} />}
              title="Auswählen und Bearbeiten"
              onClick={() => {
                setOpenMenu(false);
                setOpenEditor(true);
                setCanvasData(selectedTemplate);
              }}
            />
          </Menu>
          <MDBox
            sx={{
              mx: 2,
              mb: 2,
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <MDTypography variant="button">
                  Du hast die Auswahl zwischen verschiedenen Templates, kannst aber auch eigene Vorlagen
                  zusammenstellen
                </MDTypography>
              </Grid>
              <Grid item xs={12}>
                <MDTypography variant="h6">Aktuelles Bild</MDTypography>
                {noImage && (
                  <MDAlert color="info" sx={{ mt: 2 }}>
                    <MDTypography variant="body2" fontWeight="regular" color="dark">
                      Es sieht so aus, als hättest du noch kein Bild für diese Funktion erstellt. Wir
                      verwenden deshalb unser Standardbild.
                    </MDTypography>
                    {
                      // TODO wenn kein Bild vorhanden ist wird der canvas nicht gelöst und überdeckt alles
                    }
                  </MDAlert>
                )}
                {canvasData && Object.keys(canvasData).length !== 0 && (
                  <MDBox
                    sx={{
                      height: "200px",
                      width: "fit-content",
                      backgroundColor: "rgba(0,0,0,0.1)",
                      position: "relative",
                    }}
                  >
                    <p
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        zIndex: 0,
                        margin: "auto",
                      }}
                    >
                      Bild wird geladen...
                    </p>
                    <ErrorBoundary>
                      <PreviewCanvas canvasData={replace(canvasData, replacer)} title="current" />
                    </ErrorBoundary>
                  </MDBox>
                )}
              </Grid>
              <Grid item xs={12}>
                <MDTypography variant="h6">Templates</MDTypography>
                <Grid container spacing={2}>
                  {templates?.map((template, index) => (
                    <Grid item xs={12} sm={6} md={4} key={template.id}>
                      <MDBox
                        onClick={(event) => {
                          setOpenMenu(event.currentTarget);
                          setSelectedTemplate(template);
                        }}
                        sx={{
                          height: "auto",
                          cursor: "pointer",
                          width: "100%",
                          backgroundColor: "rgba(0,0,0,0.1)",
                        }}
                      >
                        <ErrorBoundary>
                          <PreviewCanvas canvasData={template} title="templates" replacer={replacer} />
                        </ErrorBoundary>
                        <MDTypography
                          variant="body2"
                          fontWeight="regular"
                          color="dark"
                          horizontalAlign="center"
                        >
                          Template {index + 1} {expertMode && `(ID: ${template.id})`}
                        </MDTypography>
                      </MDBox>
                    </Grid>
                  ))}
                  {templates.length === 0 && (
                    <Grid item xs={12}>
                      <MDTypography variant="body2" fontWeight="regular" color="dark">
                        Es sieht so aus, als hätten wir noch keine Templates für diese Funktion
                      </MDTypography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <MDTypography variant="h6" gutterBottom>
                  Anpassen
                </MDTypography>

                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <MDButton
                      variant="gradient"
                      color="primary"
                      fullWidth
                      onClick={() => {
                        setOpenEditor(true);
                      }}
                    >
                      Aktuelles Bild bearbeiten
                    </MDButton>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MDButton
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onClick={() => {
                        setOpenEditor(true);
                        setCanvasData({
                          imageType: "?",
                          data: {
                            width: 900,
                            height: 280,
                            backgroundColor: "#282828",
                            elements: [],
                          },
                        });
                      }}
                    >
                      Neues Bild erstellen
                    </MDButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </MDBox>
        </Dialog>
      </ErrorBoundary>
      <ErrorBoundary
        fallback={
          <>
            <Alert variant="outlined">Fehler</Alert>
          </>
        }
      >
        {openEditor && (
          <ImageEditorModal
            replacer={replacer}
            open={openEditor}
            canvasData={canvasData}
            onClose={setOpenEditor}
            onSubmit={submit}
            availableFonts={availableFonts}
          />
        )}
      </ErrorBoundary>
    </>
  );
}
