import Card from "@mui/material/Card";
import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";
import MDButton from "../../../../../components/MDButton";
import Grid from "@mui/material/Grid";
import { useForm } from "react-hook-form";
import { FormCategoryAutocomplete, FormInput } from "../../../../../components/Form/FormInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import apiMessages from "../../../../../apiMessages";
import {
  useGetCategoriesQuery,
  useGetGuildQuery,
  useGetVoiceChannelsQuery,
  useUpdateGuildMutation,
} from "../../../../../store/guild/guild-api";
import Logger from "../../../../../Logger";
import { useEffect } from "react";
import { AutoCompleteChannelIcon } from "../../../../../components/Form/AutoCompleteIcon";

export function CounterUpdateForm({ botId, guildId, fullHeight }) {
  const [update, { isLoading }] = useUpdateGuildMutation();

  const {
    data: guildData = {},
    isLoading: guildIsLoading,
    refetch: guildRefetch,
    isRefetching: guildIsRefetching,
  } = useGetGuildQuery({
    botId,
    guildId,
  });

  const {
    data: categoriesData = [],

    isFetching: categoriesIsFetching,
    refetch: categoriesRefetch,
  } = useGetCategoriesQuery({
    botId,
    guildId,
  });
  const {
    data: voiceChannelData = [],
    refetch: voiceChannelsRefetch,
    isFetching: voiceChannelsIsFetching,
  } = useGetVoiceChannelsQuery({
    botId,
    guildId,
  });

  const formHandler = useForm({
    defaultValues: {
      userCount: 0,
      userCountName: "",
      botCount: 0,
      botCountName: "",
      totalCount: 0,
      totalCountName: "",
    },
  });
  const { control, handleSubmit, setValue, getValues } = formHandler;

  useEffect(() => {
    if (!guildData) return;
    setValue("userCount", guildData?.userCount);
    setValue("userCountName", guildData?.userCountName);
    setValue("botCount", guildData?.botCount);
    setValue("botCountName", guildData?.botCountName);
    setValue("totalCount", guildData?.totalCount);
    setValue("totalCountName", guildData?.totalCountName);
  }, [guildData]);

  const onSubmit = async (e) => {
    update({
      botId,
      guildId,
      data: {
        ...guildData,
        ...e,
      },
    }).then(({ data, error }) => {
      Logger.info("SubmitEvent", { module: "SubmitForm" }, data, error);
      apiMessages.showMessage({
        type: "CounterSystem",
        title: data ? "updated" : error?.data?.error || error,
      });
    });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        height: fullHeight ? "100%" : "auto",
      }}
    >
      <Card sx={{ height: "100%" }}>
        <MDBox pt={2} px={2}>
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            Update Counter
          </MDTypography>
        </MDBox>
        <MDBox p={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <MDBox pt={2} px={2}>
                <FormCategoryAutocomplete
                  name="userCount"
                  label="User Count"
                  categories={categoriesData}
                  options={[...voiceChannelData].sort((x, y) => x.category - y.category)}
                  groupByFunction="default"
                  labelIcon={(option) => <AutoCompleteChannelIcon option={option} />}
                  additionalParameters={["type"]}
                  formHandler={formHandler}
                  refetch={() => {
                    voiceChannelsRefetch();
                    categoriesRefetch();
                  }}
                  refetching={voiceChannelsIsFetching || categoriesIsFetching}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} lg={6}>
              <MDBox pt={2} px={2}>
                <FormInput
                  formHandler={formHandler}
                  name="userCountName"
                  label="User Count Name"
                  rules={{
                    required: "Du musst einen Namen angeben",
                  }}
                  variables={[
                    {
                      label: "%count% einfügen",
                      description: "Fügt die Anzahl der User ein",
                      replacer: "%count%",
                    },
                  ]}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} lg={6}>
              <MDBox pt={2} px={2}>
                <FormCategoryAutocomplete
                  name="botCount"
                  label="Bot Count"
                  categories={categoriesData}
                  options={[...voiceChannelData].sort((x, y) => x.category - y.category)}
                  groupByFunction="default"
                  labelIcon={(option) => <AutoCompleteChannelIcon option={option} />}
                  additionalParameters={["type"]}
                  formHandler={formHandler}
                  refetch={() => {
                    voiceChannelsRefetch();
                    categoriesRefetch();
                  }}
                  refetching={voiceChannelsIsFetching || categoriesIsFetching}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} lg={6}>
              <MDBox pt={2} px={2}>
                <FormInput
                  formHandler={formHandler}
                  name="botCountName"
                  label="Bot Count Name"
                  rules={{
                    required: "Du musst einen Namen angeben",
                  }}
                  variables={[
                    {
                      label: "%count% einfügen",
                      description: "Fügt die Anzahl der Bots ein",
                      replacer: "%count%",
                    },
                  ]}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} lg={6}>
              <MDBox pt={2} px={2}>
                <FormCategoryAutocomplete
                  name="totalCount"
                  label="Total Member Count"
                  categories={categoriesData}
                  options={[...voiceChannelData].sort((x, y) => x.category - y.category)}
                  groupByFunction="default"
                  labelIcon={(option) => <AutoCompleteChannelIcon option={option} />}
                  additionalParameters={["type"]}
                  formHandler={formHandler}
                  refetch={() => {
                    voiceChannelsRefetch();
                    categoriesRefetch();
                  }}
                  refetching={voiceChannelsIsFetching || categoriesIsFetching}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} lg={6}>
              <MDBox pt={2} px={2}>
                <FormInput
                  formHandler={formHandler}
                  name="totalCountName"
                  label="Total Member Count Name"
                  rules={{
                    required: "Du musst einen Namen angeben",
                  }}
                  variables={[
                    {
                      label: "%count% einfügen",
                      description: "Fügt die Anzahl von allen Membern (User+Bot) ein",
                      replacer: "%count%",
                    },
                  ]}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <MDBox pt={2} px={2}>
                <MDButton
                  type="submit"
                  variant="contained"
                  sx={{ width: "100%" }}
                  disabled={isLoading}
                  color="primary"
                >
                  Speichern
                  {isLoading && (
                    <FontAwesomeIcon icon={faSpinnerThird} size="lg" spin style={{ marginLeft: "5px" }} />
                  )}
                </MDButton>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    </form>
  );
}
