import Card from "@mui/material/Card";
import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";
import MDButton from "../../../../../components/MDButton";
import Grid from "@mui/material/Grid";
import { useForm, useWatch } from "react-hook-form";
import { FormCategoryAutocomplete, FormInput } from "../../../../../components/Form/FormInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import apiMessages from "../../../../../apiMessages";
import { useCreateModuleDataMutation } from "../../../../../store/modules/module-api";
import {
  useGetCategoriesQuery,
  useGetEmojisQuery,
  useGetTextChannelsQuery,
} from "../../../../../store/guild/guild-api";
import Logger from "../../../../../Logger";
import { useMemo, useState } from "react";
import EmojiPicker, { Emoji, EmojiStyle, SkinTonePickerLocation, Theme } from "emoji-picker-react";
import { InputAdornment } from "@mui/material";
import { faSync } from "@fortawesome/pro-solid-svg-icons";
import { getUnifiedEmoji, isUnifiedEmoji } from "../../../../../Utils";
import { AutoCompleteChannelIcon } from "../../../../../components/Form/AutoCompleteIcon";
import { useSelector } from "react-redux";

export function AutoReactionAddForm({ botId, guildId, fullHeight }) {
  const darkMode = useSelector((state) => state.ui.darkMode);
  const [update, { isLoading }] = useCreateModuleDataMutation();
  const {
    data: textChannelData = [],
    isFetching: textChannelIsFetching,
    refetch: textChannelRefetch,
  } = useGetTextChannelsQuery({ botId, guildId });
  const {
    data: categoriesData = [],
    isFetching: categoriesIsFetching,
    refetch: categoriesRefetch,
  } = useGetCategoriesQuery({ botId, guildId });
  const {
    data: emojisData = [],
    isFetching: emojisIsFetching,
    refetch: emojisRefetch,
  } = useGetEmojisQuery({ botId, guildId });

  const formHandler = useForm({
    defaultValues: {
      emote: "",
      channelId: null,
    },
  });

  const { control, handleSubmit, setValue } = formHandler;

  const onSubmit = async (e) => {
    update({
      botId,
      guildId,
      module: "autoreaction",
      data: {
        ...e,
      },
    }).then(({ data, error }) => {
      Logger.info("SubmitEvent", { module: "SubmitForm" }, data, error);
      apiMessages.showMessage({
        type: "AutoReaction",
        title: data ? "added" : error?.data?.error || error,
      });
    });
  };

  const customEmojiPicker = useMemo(
    () => (
      <EmojiPicker
        onEmojiClick={(emojisData) => {
          console.log(emojisData);
          setValue("emote", emojisData.emoji);
        }}
        autoFocusSearch={false}
        theme={darkMode ? Theme.DARK : Theme.LIGHT}
        skinTonePickerLocation={SkinTonePickerLocation.PREVIEW}
        width="100%"
        lazyLoadEmojis
        previewConfig={{
          defaultCaption: "Wähle ein Emoji",
          defaultEmoji: "1f41f",
        }}
        searchPlaceHolder="Suchen"
        emojiStyle={EmojiStyle.TWITTER}
        customEmojis={[...emojisData]?.map((emoji) => ({
          id: emoji.id,
          names: [emoji.name],
          imgUrl: emoji.imageUrl,
        }))}
      />
    ),

    [emojisData, setValue]
  );

  const [openEmojiPicker, setOpenEmojiPicker] = useState(false);
  const emote = useWatch({
    control,
    name: "emote",
  });

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        height: fullHeight ? "100%" : "auto",
      }}
    >
      <Card sx={{ height: "100%" }}>
        <MDBox pt={2} px={2}>
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            Add AutoReaction
          </MDTypography>
        </MDBox>
        <MDBox p={2}>
          <Grid item xs={12}>
            <MDBox pt={2} px={2}>
              <FormCategoryAutocomplete
                name="channelId"
                label="Channel"
                categories={categoriesData}
                options={[...textChannelData].sort((x, y) => x.category - y.category)}
                labelIcon={(option) => <AutoCompleteChannelIcon option={option} />}
                additionalParameters={["type"]}
                groupByFunction="default"
                formHandler={formHandler}
                refetch={() => {
                  textChannelRefetch();
                  categoriesRefetch();
                }}
                refetching={textChannelIsFetching || categoriesIsFetching}
                rules={{
                  required: "Du musst einen Channel angeben.",
                }}
              />
            </MDBox>
            <MDBox pt={2} px={2}>
              <FormInput
                formHandler={formHandler}
                name="emote"
                label="Emoji"
                rules={{
                  required: "Du musst einen Emoji angeben.",
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ paddingLeft: 0, marginRight: 0 }}>
                      {isUnifiedEmoji(getUnifiedEmoji(emote)) ? (
                        <Emoji unified={getUnifiedEmoji(emote)} emojiStyle={EmojiStyle.TWITTER} size={22} />
                      ) : (
                        <img
                          alt=""
                          src={emojisData?.find((r) => r.id === emote)?.imageUrl}
                          style={{ height: "22px" }}
                        />
                      )}
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end" sx={{ paddingLeft: 0, paddingRight: 0, marginRight: 0 }}>
                      <MDButton
                        variant="gradient"
                        sx={{ mr: 1 }}
                        onClick={emojisRefetch}
                        color="primary"
                        iconOnly
                        size="small"
                      >
                        <FontAwesomeIcon icon={faSync} spin={emojisIsFetching} />
                      </MDButton>
                      <MDButton
                        variant="contained"
                        onClick={() => setOpenEmojiPicker(!openEmojiPicker)}
                        color="primary"
                        size="small"
                      >
                        {openEmojiPicker ? "Schließen" : "Öffnen"}
                      </MDButton>
                    </InputAdornment>
                  ),
                }}
              />
              {openEmojiPicker && (
                <>
                  <MDBox pt={2} px={2} />
                  {customEmojiPicker}
                </>
              )}
            </MDBox>
          </Grid>

          <Grid item xs={12}>
            <MDBox pt={2} px={2}>
              <MDButton
                type="submit"
                variant="contained"
                sx={{ width: "100%" }}
                disabled={isLoading}
                color="primary"
              >
                Hinzufügen
                {isLoading && (
                  <FontAwesomeIcon icon={faSpinnerThird} size="lg" spin style={{ marginLeft: "5px" }} />
                )}
              </MDButton>
            </MDBox>
          </Grid>
        </MDBox>
      </Card>
    </form>
  );
}
