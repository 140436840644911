import Card from "@mui/material/Card";
import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";
import MDButton from "../../../../../components/MDButton";
import Grid from "@mui/material/Grid";
import { useForm, useWatch } from "react-hook-form";
import {
  FormAutocomplete,
  FormCategoryAutocomplete,
  FormInput,
  FormSimpleAutocomplete,
} from "../../../../../components/Form/FormInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import apiMessages from "../../../../../apiMessages";
import { useCreateModuleDataMutation } from "../../../../../store/modules/module-api";
import {
  useGetCategoriesQuery,
  useGetEmojisQuery,
  useGetRolesQuery,
  useGetTextChannelsQuery,
} from "../../../../../store/guild/guild-api";
import Logger from "../../../../../Logger";
import EmojiPicker, { Emoji, EmojiStyle, SkinTonePickerLocation, Theme } from "emoji-picker-react";
import { InputAdornment } from "@mui/material";
import { useMemo, useState } from "react";
import { faSync } from "@fortawesome/pro-solid-svg-icons";
import LastMessageModal from "../../../../../components/Modal/LastMessageModal";
import { getUnifiedEmoji, isUnifiedEmoji } from "../../../../../Utils";
import {
  AutoCompleteChannelIcon,
  AutoCompleteRoleIcon,
} from "../../../../../components/Form/AutoCompleteIcon";
import { useSelector } from "react-redux";

export function RoleReactionAddForm({ botId, guildId, fullHeight }) {
  const darkMode = useSelector((state) => state.ui.darkMode);
  const [update, { isLoading }] = useCreateModuleDataMutation();

  const {
    data: categoriesData = [],

    isFetching: categoriesIsFetching,
    refetch: categoriesRefetch,
  } = useGetCategoriesQuery({
    botId,
    guildId,
  });
  const {
    data: textChannelData = [],
    refetch: textChannelsRefetch,
    isFetching: textChannelsIsFetching,
  } = useGetTextChannelsQuery({
    botId,
    guildId,
  });

  const {
    data: emojiData = [],
    refetch: emojiRefetch,
    isFetching: emojiIsFetching,
  } = useGetEmojisQuery({
    botId,
    guildId,
  });

  const {
    data: roleData = [],
    refetch: roleRefetch,
    isFetching: roleIsFetching,
  } = useGetRolesQuery({
    botId,
    guildId,
  });

  const formHandler = useForm({
    defaultValues: {
      messageId: "",
      channelId: "",
      emote: "",
      roleId: "",
      mode: "add",
    },
  });
  const { control, handleSubmit, setValue } = formHandler;

  const emote = useWatch({
    control,
    name: "emote",
  });

  const [openEmojiPicker, setOpenEmojiPicker] = useState(false);

  const onSubmit = async (e) => {
    update({
      botId,
      guildId,
      module: "rolereaction",
      data: {
        ...e,
      },
    }).then(({ data, error }) => {
      Logger.info("SubmitEvent", { module: "SubmitForm" }, data, error);
      apiMessages.showMessage({
        type: "RoleReaction",
        title: data ? "added" : error?.data?.error || error,
      });
    });
  };

  const customEmojiPicker = useMemo(
    () => (
      <EmojiPicker
        onEmojiClick={(emojiData) => {
          setValue("emote", emojiData.emoji);
        }}
        autoFocusSearch={false}
        theme={darkMode ? Theme.DARK : Theme.LIGHT}
        skinTonePickerLocation={SkinTonePickerLocation.PREVIEW}
        width="100%"
        lazyLoadEmojis
        previewConfig={{
          defaultCaption: "Wähle ein Emoji",
          defaultEmoji: "1f41f",
        }}
        searchPlaceHolder="Suchen"
        emojiStyle={EmojiStyle.TWITTER}
        customEmojis={[...emojiData]?.map((emoji) => ({
          id: emoji.id,
          names: [emoji.name],
          imgUrl: emoji.imageUrl,
        }))}
      />
    ),

    [emojiData, setValue]
  );

  const [showLastMessageModal, setShowLastMessageModal] = useState(false);

  const channel = useWatch({
    control,
    name: "channelId",
  });

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        height: fullHeight ? "100%" : "auto",
      }}
    >
      <LastMessageModal
        serverId={guildId}
        botId={botId}
        open={showLastMessageModal}
        defaultChannel={channel}
        onClose={(state, data) => {
          setShowLastMessageModal(state);
          if (data) {
            setValue("messageId", data?.message || "");
            setValue("channelId", data?.channel || "");
          }
        }}
      />
      <Card sx={{ height: "100%" }}>
        <MDBox pt={2} px={2}>
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            Add Role Reaction
          </MDTypography>
        </MDBox>
        <MDBox p={2}>
          <Grid item xs={12}>
            <MDBox pt={2} px={2}>
              <FormCategoryAutocomplete
                name="channelId"
                label="Channel"
                categories={categoriesData}
                options={[...textChannelData].sort((x, y) => x.category - y.category)}
                groupByFunction="default"
                labelIcon={(option) => <AutoCompleteChannelIcon option={option} />}
                additionalParameters={["type"]}
                formHandler={formHandler}
                refetch={() => {
                  textChannelsRefetch();
                  categoriesRefetch();
                }}
                refetching={textChannelsIsFetching || categoriesIsFetching}
                rules={{
                  required: "Du musst einen Channel angeben.",
                }}
              />
            </MDBox>
            <MDBox pt={2} px={2}>
              <FormInput
                formHandler={formHandler}
                name="messageId"
                label="Message"
                rules={{
                  required: "Du musst eine Message angeben.",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={{ paddingRight: 0, marginLeft: 0 }}>
                      <MDButton
                        aria-label="Add"
                        edge="end"
                        color="primary"
                        variant="contained"
                        size="small"
                        onClick={() => {
                          setShowLastMessageModal(true);
                        }}
                      >
                        Auswählen
                      </MDButton>
                    </InputAdornment>
                  ),
                }}
              />
            </MDBox>
            <MDBox pt={2} px={2}>
              <FormInput
                formHandler={formHandler}
                name="emote"
                label="Emoji"
                rules={{
                  required: "Du musst einen Emoji angeben.",
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ paddingLeft: 0, marginRight: 0 }}>
                      {isUnifiedEmoji(getUnifiedEmoji(emote)) ? (
                        <Emoji unified={getUnifiedEmoji(emote)} emojiStyle={EmojiStyle.TWITTER} size={22} />
                      ) : (
                        <img
                          alt=""
                          src={emojiData?.find((r) => r.id === emote)?.imageUrl}
                          style={{ height: "22px" }}
                        />
                      )}
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end" sx={{ paddingLeft: 0, paddingRight: 0, marginRight: 0 }}>
                      <MDButton
                        variant="gradient"
                        sx={{ mr: 1 }}
                        onClick={emojiRefetch}
                        color="primary"
                        iconOnly
                        size="small"
                      >
                        <FontAwesomeIcon icon={faSync} spin={emojiIsFetching} />
                      </MDButton>
                      <MDButton
                        variant="contained"
                        onClick={() => setOpenEmojiPicker(!openEmojiPicker)}
                        color="primary"
                        size="small"
                      >
                        {openEmojiPicker ? "Schließen" : "Öffnen"}
                      </MDButton>
                    </InputAdornment>
                  ),
                }}
              />
              {openEmojiPicker && (
                <>
                  <MDBox pt={2} px={2} />
                  {customEmojiPicker}
                </>
              )}
            </MDBox>

            <MDBox pt={2} px={2}>
              <FormSimpleAutocomplete
                name="roleId"
                label="Rolle"
                options={[...roleData].sort((x, y) => x.category - y.category)}
                formHandler={formHandler}
                additionalParameters={["colorHex"]}
                labelIcon={(option) => <AutoCompleteRoleIcon option={option} />}
                refetch={() => {
                  roleRefetch();
                }}
                refetching={roleIsFetching}
                rules={{
                  required: "Du musst eine Rolle angeben.",
                }}
              />
            </MDBox>
            <MDBox pt={2} px={2}>
              <FormAutocomplete
                name="mode"
                label="Rolle hinzufügen oder entfernen"
                options={[
                  { value: "add", label: "Hinzufügen" },
                  { value: "remove", label: "Entfernen" },
                ]}
                formHandler={formHandler}
                autoCompleteProps={{
                  disableClearable: true,
                }}
                rules={{
                  required: "Du musst einen Modus angeben.",
                }}
              />
            </MDBox>
          </Grid>

          <Grid item xs={12}>
            <MDBox pt={2} px={2}>
              <MDButton
                type="submit"
                variant="contained"
                sx={{ width: "100%" }}
                disabled={isLoading}
                color="primary"
              >
                Hinzufügen
                {isLoading && (
                  <FontAwesomeIcon icon={faSpinnerThird} size="lg" spin style={{ marginLeft: "5px" }} />
                )}
              </MDButton>
            </MDBox>
          </Grid>
        </MDBox>
      </Card>
    </form>
  );
}
