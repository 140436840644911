import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import { faSignIn } from "@fortawesome/pro-solid-svg-icons";
import { useSnackbar } from "notistack";
import CoverLayout from "../old/authentication/components/CoverLayout";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
import backgroundImage from "../../assets/images/custom/webinterface.png";
import config from "../../config";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import { LoginState } from "../../Utils";

function Login() {
  const [acceptUsage, setAcceptUsage] = useState(false);

  const handleSetRememberMe = () => setAcceptUsage(!acceptUsage);
  const { enqueueSnackbar } = useSnackbar();
  const status = useSelector((state) => state.auth.status);
  const navigate = useNavigate();

  useEffect(() => {
    if (status === LoginState.authenticated) {
      enqueueSnackbar("Sie sind bereits angemeldet!", {
        variant: "info",
        preventDuplicate: true,
      });
      navigate("/bots");
    }
  }, [status]);

  return (
    <CoverLayout image={backgroundImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Anmelden
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Nach der Anmeldung ist das ganze Dashboard verfügbar
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={acceptUsage} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{
                  cursor: "pointer",
                  userSelect: "none",
                  ml: 1,
                }}
              >
                Ich stimmte den Nutzungsbedingungen zu.{" "}
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={() => {
                  if (!acceptUsage) {
                    enqueueSnackbar("Bitte stimmen Sie den Nutzungsbedingungen zu!", {
                      preventDuplicate: true,
                      variant: "error",
                    });
                  } else {
                    Cookies.set("acceptUsage", "true", { expires: 365 });
                    window.location.href = `${
                      config.local.enabled ? config.local.backend.url : config.backend.url
                    }/api/${config.local.enabled ? `` : `cloud/`}login`;
                  }
                }}
              >
                <FontAwesomeIcon icon={faDiscord} size="lg" style={{ marginRight: "10px" }} /> Mit Discord
                anmelden
              </MDButton>
            </MDBox>
            <MDBox my={1}>
              <MDButton variant="gradient" color="info" fullWidth disabled>
                <FontAwesomeIcon icon={faSignIn} size="lg" style={{ marginRight: "10px" }} /> Mit Zyonic
                anmelden
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Bei Problemen wende dich an den Support{" "}
                <MDTypography
                  component={Link}
                  to="/"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  support@sv-studios.net
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Login;
