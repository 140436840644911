import { useMemo } from "react";
import Card from "@mui/material/Card";
import MDTypography from "../../../../components/MDTypography";
import MDBox from "../../../../components/MDBox";
import MDInput from "../../../../components/MDInput";
import { InputAdornment } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import apiErrorMessages from "../../../../apiMessages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import { useDeleteBotDetailsMutation, useGetBotDetailsQuery } from "../../../../store/admin/admin-api";
import { AddEnvironmentForm } from "./AddEnvironmentForm";

export function UpdateEnvironment({ botId }) {
  const { data: environmentData = {}, isLoading: environmentIsLoading } = useGetBotDetailsQuery({
    botId,
    type: "environment",
  });

  const pageData = useMemo(
    () => ({
      number: environmentData.number || 0,
      numberOfElements: environmentData.numberOfElements || 0,
      size: environmentData.size || 1000,
      totalElements: environmentData.totalElements || 0,
      totalPages: environmentData.totalPages || 0,
      first: environmentData.first,
      last: environmentData.last,
      empty: environmentData.empty,
    }),
    [environmentData]
  );

  const [deleteBotDetails, { isLoading: deleteBotDetailsIsLoading }] = useDeleteBotDetailsMutation();

  const deleteBotDetailsEvent = (id) => {
    deleteBotDetails({
      botId,
      type: "environment",
      id,
    }).then(({ data, error }) => {
      apiErrorMessages.showMessage({
        type: "AdminBot",
        title: data ? "deletedEnvironment" : error?.data?.error || error,
      });
    });
  };

  return (
    <Card sx={{ p: 2, height: "100%" }}>
      <MDTypography variant="h5" gutterBottom horizontalAlign="center" fontWeight="medium">
        Environment
      </MDTypography>
      {environmentData.content?.map((env, index) => (
        <MDBox mb={2}>
          <MDInput
            type="text"
            value={env?.environment}
            readOnly
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" color="error" onClick={() => deleteBotDetailsEvent(env.id)}>
                    <FontAwesomeIcon icon={faTimesCircle} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </MDBox>
      ))}
      <MDBox mb={2}>
        <AddEnvironmentForm botId={botId} />
      </MDBox>
    </Card>
  );
}
