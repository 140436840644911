import { useState } from "react";
import { Checkbox, FormControlLabel, Skeleton } from "@mui/material";
import MDBox from "../../../../../../components/MDBox";
import parseMessage from "../../../../../../components/EmbedBuilder/DiscordParser";
import { useGetModuleDataQuery } from "../../../../../../store/modules/module-api";
import MDButton from "../../../../../../components/MDButton";
import { DiscordMessages } from "@skyra/discord-components-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import MDAlert from "../../../../../../components/MDAlert";
import MDTypography from "../../../../../../components/MDTypography";

function TicketSystemMessage({ ticket, botId, serverId }) {
  if (!ticket)
    return (
      <MDBox px={2}>
        <MDAlert color="info">
          <MDTypography variant="body2">Du musst ein Ticket auswählen.</MDTypography>
        </MDAlert>
      </MDBox>
    );

  const [autoRefresh, setAutoRefresh] = useState(false);

  const {
    data: ticketChatData,
    isLoading: ticketChatLoading,
    isError: ticketChatError,
    isFetching: ticketChatFetching,
    refetch: ticketChatRefetch,
  } = useGetModuleDataQuery(
    {
      botId: botId,
      guildId: serverId,
      module: `ticket/${ticket.id}/chat`,
    },
    {
      pollingInterval: autoRefresh ? 5000 : 0,
    }
  );

  return (
    <>
      <MDBox px={2} pb={2} display="flex" justifyContent="space-between" alignItems="center">
        <FormControlLabel
          p={0}
          color="primary"
          control={
            <Checkbox
              color="primary"
              onChange={(e) => {
                setAutoRefresh(e.target.checked);
              }}
            />
          }
          label="Auto-Refresh"
        />
        <MDButton onClick={ticketChatRefetch} color="primary" size="small" disabled={ticketChatFetching}>
          Chat aktualisieren
          {ticketChatFetching && <FontAwesomeIcon icon={faSpinnerThird} spin style={{ marginLeft: "5px" }} />}
        </MDButton>
      </MDBox>

      <MDBox px={2} pb={2}>
        {ticketChatData?.length > 0 && (
          <DiscordMessages style={{ borderRadius: "10px" }} noBackground>
            {ticketChatData?.map((row) => parseMessage(row)).reverse()}
          </DiscordMessages>
        )}
        {ticketChatData?.length === 0 && (
          <MDBox display="flex" justifyContent="center" alignItems="center" height="100%">
            <MDAlert color="info">Es sieht so aus, als ob es hier noch keine Nachrichten gibt.</MDAlert>
          </MDBox>
        )}
        {ticketChatLoading && (
          <DiscordMessages style={{ borderRadius: "10px" }} noBackground>
            <MDBox display="flex" gap={1} p={2} alignItems="center" height="100%" flexDirection="column">
              <Skeleton width="100%" height="100px" animation="wave" variant="rounded" />
              <Skeleton width="100%" height="100px" animation="wave" variant="rounded" />{" "}
              <Skeleton width="100%" height="100px" animation="wave" variant="rounded" />{" "}
              <Skeleton width="100%" height="100px" animation="wave" variant="rounded" />{" "}
              <Skeleton width="100%" height="100px" animation="wave" variant="rounded" />
            </MDBox>
          </DiscordMessages>
        )}
      </MDBox>
    </>
  );
}

export default TicketSystemMessage;
