import { useParams } from "react-router-dom";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Error404Modal from "../../components/Dialog/Error404Modal";
import MDBox from "../../components/MDBox";
import backgroundImage from "../../assets/images/bg-sign-in-cover.jpeg";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDTypography from "../../components/MDTypography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle, faRobot } from "@fortawesome/pro-solid-svg-icons";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";

export function BotError({}) {
  const { botId } = useParams();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Error404Modal />
      <MDBox mb={2} />
      <MDBox position="relative" mb={5}>
        <MDBox
          display="flex"
          alignItems="center"
          position="relative"
          minHeight="15rem"
          borderRadius="xl"
          sx={{
            backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.error.main, 0.6),
                rgba(gradients.error.state, 0.6)
              )}, url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            overflow: "hidden",
          }}
        />

        <Card
          sx={{
            position: "relative",
            mt: -5,
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <MDTypography variant="h5" fontWeight="medium">
                <FontAwesomeIcon icon={faExclamationCircle} size="2x" />
              </MDTypography>
            </Grid>
            <Grid item>
              <MDBox height="100%" mt={0.5} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium" sx={{ width: "100%" }}>
                  Bot Fehler
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        </Card>
      </MDBox>
      <MDBox pb={3}>
        <Card sx={{ p: 2 }}>
          <MDTypography variant="h6" fontWeight="medium">
            Bot mit der ID <i>{botId}</i> konnte nicht gefunden werden. Das kann folgende Gründe haben:
          </MDTypography>
          <MDBox component="ul" display="flex" flexDirection="column" p={0} my={2}>
            <MDTypography variant="body2" fontWeight="regular">
              Der Bot existiert nicht.
            </MDTypography>
            <MDTypography variant="body2" fontWeight="regular">
              Der Bot ist noch nicht gestartet.
            </MDTypography>
            <MDTypography variant="body2" fontWeight="regular">
              Es ist ein anderes internes Problem aufgetreten.
            </MDTypography>
          </MDBox>
          <MDTypography variant="body2" fontWeight="regular">
            Normalerweise sollte der Bot innerhalb von 5 Minuten neustarten. Falls nicht, kontaktiere bitte
            den Support.
          </MDTypography>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
