import Card from "@mui/material/Card";
import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";
import MDButton from "../../../../../components/MDButton";
import Grid from "@mui/material/Grid";
import { Controller, useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import apiMessages from "../../../../../apiMessages";
import { useGetModuleDataQuery, useUpdateModuleDataMutation } from "../../../../../store/modules/module-api";
import { useGetRolesQuery } from "../../../../../store/guild/guild-api";
import Logger from "../../../../../Logger";
import Autocomplete from "@mui/material/Autocomplete";
import { faRotateForward } from "@fortawesome/pro-solid-svg-icons";
import FormField from "../../../../old/pages/account/components/FormField";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { AutoCompleteRoleIcon } from "../../../../../components/Form/AutoCompleteIcon";

export function RoleSaverUpdateForm({ botId, guildId, fullHeight }) {
  const [update, { isLoading }] = useUpdateModuleDataMutation();

  const expertMode = useSelector((state) => state.ui?.expertMode);

  const {
    data: rolesSaverData = [],
    isFetching: rolesSaverIsFetching,
    refetch: rolesSaverRefetch,
  } = useGetModuleDataQuery({ guildId, botId, module: "rolesaver" });

  const {
    data: roleData = [],
    isFetching: roleIsFetching,
    refetch: roleRefetch,
  } = useGetRolesQuery({ guildId, botId });

  const formHandler = useForm({
    defaultValues: {
      rolesToSave: [],
    },
  });
  const { control, setValue, handleSubmit } = formHandler;

  const onSubmit = async (e) => {
    console.log(e);
    update({
      botId,
      guildId,
      module: "rolesaver",
      data: {
        ...e,
      },
    }).then(({ data, error }) => {
      Logger.info("SubmitEvent", { module: "SubmitForm" }, data, error);
      apiMessages.showMessage({
        type: "RoleSaver",
        title: data ? "updated" : error?.data?.error || error,
      });
    });
  };

  useEffect(() => {
    setValue("rolesToSave", rolesSaverData?.roleToSave || []);
  }, [rolesSaverData]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        height: fullHeight ? "100%" : "auto",
      }}
    >
      <Card sx={{ height: "100%" }}>
        <MDBox pt={2} px={2}>
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            Aktuelle Rollen zum Speichern
          </MDTypography>
        </MDBox>
        <MDBox p={2}>
          <Grid item xs={12}>
            <MDBox pt={2} px={2}>
              <Controller
                control={formHandler.control}
                name="rolesToSave"
                render={({ field: { onChange, value } }) => (
                  <>
                    <Autocomplete
                      disableCloseOnSelect
                      multiple
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      options={useMemo(
                        () =>
                          roleData?.map((role) => ({
                            name: role.name,
                            id: role.id,
                            colorHex: role.colorHex,
                          })),
                        [roleData]
                      )}
                      value={useMemo(
                        () =>
                          roleData
                            ?.map((role) => ({
                              name: role.name,
                              id: role.id,
                              colorHex: role.colorHex,
                            }))
                            .filter((role) => value?.some((r) => r.toString() === role?.id?.toString())) ||
                          null,
                        [roleData, value]
                      )}
                      onChange={(e, newData) => onChange((newData && newData?.map((r) => r.id)) || null)}
                      getOptionLabel={(option) => `${option.name}${expertMode ? ` - ${option.id}` : ""}`}
                      renderOption={(optionProps, option) => (
                        <li {...optionProps} key={option.id}>
                          <AutoCompleteRoleIcon option={option} />
                          {`${option.name}${expertMode ? ` - ${option.id}` : ""}`}
                        </li>
                      )}
                      noOptionsText={
                        <>
                          <MDButton
                            variant="outlined"
                            color="primary"
                            fullWidth
                            size="medium"
                            disabled={roleIsFetching}
                            onClick={roleRefetch}
                          >
                            {roleIsFetching ? (
                              <FontAwesomeIcon icon={faSpinnerThird} size="lg" spin fade />
                            ) : (
                              <>
                                <FontAwesomeIcon
                                  size="1x"
                                  style={{ marginRight: "10px" }}
                                  icon={faRotateForward}
                                />
                                Klicke hier zum erneuten Laden
                              </>
                            )}
                          </MDButton>
                        </>
                      }
                      renderInput={(params) => (
                        <FormField
                          {...params}
                          label="Rollen"
                          hideStatusIcon
                          variant="outlined"
                          name="rolesToSave"
                          error={Boolean(formHandler.formState.errors["rolesToSave"])}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                    <MDTypography variant="caption" color="error" p={0} m={0}>
                      {formHandler.formState.errors["rolesToSave"]?.message || ""}
                    </MDTypography>
                  </>
                )}
              />
            </MDBox>
          </Grid>

          <Grid item xs={12}>
            <MDBox pt={2} px={2}>
              <MDButton
                type="submit"
                variant="contained"
                sx={{ width: "100%" }}
                disabled={isLoading}
                color="primary"
              >
                Hinzufügen
                {isLoading && (
                  <FontAwesomeIcon icon={faSpinnerThird} size="lg" spin style={{ marginLeft: "5px" }} />
                )}
              </MDButton>
            </MDBox>
          </Grid>
        </MDBox>
      </Card>
    </form>
  );
}
