import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React imageExample components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FooterSimple from "../../components/Footer/FooterSimple";

import Card from "@mui/material/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRobot } from "@fortawesome/pro-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "@mui/material";
import backgroundImage from "../../assets/images/bg-profile.jpeg";
import { getAdmin } from "./Tools";
import Error404Modal from "../../components/Dialog/Error404Modal";
import DataTable from "../../examples/Tables/DataTable";
import { useSelector } from "react-redux";
import { LoginState } from "../../Utils";

function AdminPinPanel() {
  const status = useSelector((state) => state.auth.status);
  const navigate = useNavigate();

  const [pinList, setPinList] = useState([]);

  const [loaded, setLoaded] = useState(false);

  useEffect(async () => {
    if (status === LoginState.unauthenticated) {
      navigate("/account/login");
      return;
    }

    if (status === LoginState.authenticated) {
      try {
        //TODO update to redux rtk query
        getAdmin({ type: "cloud/bot/pin" })
          .then((data) => {
            setPinList(data?.content || []);
            setLoaded(true);
          })
          .catch((err) => {
            setLoaded(true);
            console.log(err);
          });
      } catch (e) {
        console.warn(e);
      }
      setLoaded(true);
    }
  }, [status]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Error404Modal />
      <MDBox mb={2} />
      <MDBox position="relative" mb={5}>
        <MDBox
          display="flex"
          alignItems="center"
          position="relative"
          minHeight="15rem"
          borderRadius="xl"
          sx={{
            backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.info.main, 0.6),
                rgba(gradients.info.state, 0.6)
              )}, url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            overflow: "hidden",
          }}
        />

        <Card
          sx={{
            position: "relative",
            mt: -5,
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <MDTypography variant="h5" fontWeight="medium">
                <FontAwesomeIcon icon={faRobot} size="2x" />
              </MDTypography>
            </Grid>
            <Grid item>
              <MDBox height="100%" mt={0.5} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium" sx={{ width: "100%" }}>
                  Admin Pin Panel
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        </Card>
      </MDBox>

      {loaded ? (
        <>
          <MDBox pb={3}>
            <MDBox mt={5}>
              <DataTable
                table={{
                  columns: [
                    { Header: "ID", accessor: "id" },
                    { Header: "Bot", accessor: "bot.name" },
                    { Header: "Expire At", accessor: "expireAt" },
                    { Header: "Expired", accessor: "expired" },
                    { Header: "Bot ID", accessor: "botId" },
                    { Header: "Admin Token", accessor: "adminToken" },
                    { Header: "User Token ", accessor: "userToken" },
                  ],
                  rows: pinList?.map((item, i, array) => ({
                    id: item?.id,
                    expireAt: item?.expireAt,
                    bot: item?.bot,
                    expired: item?.expired,
                    botId: item?.bot?.botId,
                    adminToken: item?.adminToken,
                    userToken: item?.userToken,
                  })),
                }}
                canSearch
              />
            </MDBox>
          </MDBox>
        </>
      ) : (
        <MDBox p={4}>
          <Grid container spacing={3}>
            {[1, 2, 3, 4, 5, 6].map(() => (
              <Grid item xs={12} md={6} lg={4}>
                <Skeleton variant="rectangular" height={300} />
              </Grid>
            ))}
          </Grid>
        </MDBox>
      )}

      <FooterSimple />
    </DashboardLayout>
  );
}

export default AdminPinPanel;
