import { useForm, useWatch } from "react-hook-form";
import { useGetBotDetailsQuery, useUpdateBotDetailsMutation } from "../../../../store/admin/admin-api";
import Card from "@mui/material/Card";
import MDTypography from "../../../../components/MDTypography";
import MDBox from "../../../../components/MDBox";
import { FormAutocomplete, FormInput } from "../../../../components/Form/FormInput";
import { useEffect } from "react";
import apiMessages from "../../../../apiMessages";
import MDButton from "../../../../components/MDButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";

export function UpdateStatusForm({ botId }) {
  const formHandler = useForm({
    defaultValues: {
      activity: "DEFAULT",
      statusText: "string",
      streamUrl: "string",
    },
  });

  const { handleSubmit, setValue } = formHandler;

  const { data: statusData } = useGetBotDetailsQuery({
    botId,
    type: "custom_status",
  });

  const [updateStatus, { isLoading: updateStatusIsLoading }] = useUpdateBotDetailsMutation();

  useEffect(() => {
    if (!statusData) return;
    setValue("activity", statusData.activity || "");
    setValue("statusText", statusData.statusText || "");
    setValue("streamUrl", statusData.streamUrl || "");
  }, [statusData]);

  const onSubmit = async (e) => {
    updateStatus({
      botId,
      type: "custom_status",
      data: {
        ...e,
      },
    }).then(({ data, error }) => {
      apiMessages.showMessage({
        type: "AdminPanel",
        title: data ? "updated" : error?.data?.error || error,
      });
    });
  };

  const activity = useWatch({ name: "activity", control: formHandler.control });

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        height: "100%",
      }}
    >
      <Card sx={{ p: 2, height: "100%" }}>
        <MDTypography variant="h5" gutterBottom horizontalAlign="center" fontWeight="medium">
          Custom Status
        </MDTypography>
        <MDBox mb={2}>
          <FormAutocomplete
            formHandler={formHandler}
            name="activity"
            label="Activity"
            options={[
              {
                value: "DEFAULT",
                label: "DEFAULT",
              },
              {
                value: "WATCHING",
                label: "WATCHING",
              },
              {
                value: "STREAMING",
                label: "STREAMING",
              },
              {
                value: "LISTENING",
                label: "LISTENING",
              },
              {
                value: "CUSTOM_STATUS",
                label: "CUSTOM_STATUS",
              },
              {
                value: "COMPETING",
                label: "COMPETING",
              },
            ]}
          />
        </MDBox>
        <MDBox mb={2}>
          <FormInput formHandler={formHandler} name="statusText" label="Status Text" />
        </MDBox>
        {activity === "STREAMING" && (
          <MDBox>
            <FormInput formHandler={formHandler} name="streamUrl" label="Stream Url" />
          </MDBox>
        )}
        <MDButton variant="gradient" color="info" fullWidth type="submit" sx={{ mt: "auto" }}>
          Status aktualisieren
          {updateStatusIsLoading && (
            <FontAwesomeIcon icon={faSpinnerThird} size="lg" spin style={{ marginLeft: "5px" }} />
          )}
        </MDButton>
      </Card>
    </form>
  );
}
