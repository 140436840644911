import Card from "@mui/material/Card";
import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";
import MDButton from "../../../../../components/MDButton";
import Grid from "@mui/material/Grid";
import { useForm } from "react-hook-form";
import { FormCheckbox, FormInput } from "../../../../../components/Form/FormInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import apiMessages from "../../../../../apiMessages";
import { useCreateModuleDataMutation } from "../../../../../store/modules/module-api";
import Logger from "../../../../../Logger";

export function WordBlacklistAddForm({ botId, guildId, fullHeight }) {
  const [update, { isLoading }] = useCreateModuleDataMutation();

  const formHandler = useForm({
    defaultValues: {
      word: "",
      regex: false,
    },
  });
  const { control, handleSubmit } = formHandler;

  const onSubmit = async (e) => {
    update({
      botId,
      guildId,
      module: "word-blacklist",
      data: {
        ...e,
      },
    }).then(({ data, error }) => {
      Logger.info("SubmitEvent", { module: "SubmitForm" }, data, error);
      apiMessages.showMessage({
        type: "WordBlacklist",
        title: data ? "added" : error?.data?.error || error,
      });
    });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        height: fullHeight ? "100%" : "auto",
      }}
    >
      <Card sx={{ height: "100%" }}>
        <MDBox pt={2} px={2}>
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            Zeichenfolge hinzufügen
          </MDTypography>
        </MDBox>
        <MDBox p={2}>
          <Grid item xs={12}>
            <MDBox pt={2} px={2}>
              <FormInput
                name="word"
                label="Zeichenfolge"
                formHandler={formHandler}
                rules={{
                  required: "Du musst eine Zeichenfolge angeben",
                }}
              />
            </MDBox>
            <MDBox pt={2} px={2}>
              <FormCheckbox name="regex" label="Ist die Zeichenfolge eine Regex?" formHandler={formHandler} />
            </MDBox>
          </Grid>

          <Grid item xs={12}>
            <MDBox pt={2} px={2}>
              <MDButton
                type="submit"
                variant="contained"
                sx={{ width: "100%" }}
                disabled={isLoading}
                color="primary"
              >
                Hinzufügen
                {isLoading && (
                  <FontAwesomeIcon icon={faSpinnerThird} size="lg" spin style={{ marginLeft: "5px" }} />
                )}
              </MDButton>
            </MDBox>
          </Grid>
        </MDBox>
      </Card>
    </form>
  );
}
