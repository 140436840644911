import Logger from "./Logger.js";
import { enqueueSnackbar } from "notistack";

const messages = {
  null: {
    color: "error",
    icon: "error",
    title: "Fehler",
    content: "Es ist ein Fehler aufgetreten. Bitte versuche es erneut oder wende dich an den Support.",
  },
  nullSuccess: {
    color: "warning",
    icon: "warning",
    title: "Erfolg",
    content: "Die Aktion wurde erfolgreich ausgeführt. Jedoch wurde keine Nachricht gefunden",
  },
  linkDiscord: {
    color: "warning",
    icon: "warning",
    title: "Achtung",
    content: "Deine Session ist abgelaufen, lade die Seite neu",
  },
  "Suggestion channel not set": {
    color: "error",
    icon: "error",
    title: "Fehler",
    content:
      "Es wurde kein Suggestion Channel gesetzt. Bitte setze einen Suggestion Channel in den Einstellungen.",
  },
  "Embed sended": {
    color: "success",
    icon: "success",
    title: "Gesichert",
    content: "Die Nachricht wurde erfolgreich versendet.",
  },

  "Suggestion channel updated": {
    color: "success",
    icon: "success",
    title: "Gesichert",
    content: "Die Einstellungen für den Suggestion Channel wurden gespeichert.",
  },
  "Suggestion send": {
    color: "success",
    icon: "success",
    title: "Gesichert",
    content: "Ihre Idee wurde gesendet.",
  },
  "Autorole added": {
    color: "success",
    icon: "success",
    title: "Gesichert",
    content: "Die AutoRole wurde hinzugefügt.",
  },
  "AutoReaction added": {
    color: "success",
    icon: "success",
    title: "Gesichert",
    content: "Die AutoReaction wurde hinzugefügt.",
  },
  "validation error": {
    color: "error",
    icon: "error",
    title: "Fehler",
    content: "Nicht alle angegebenen Werte sind korrekt.",
  },
  "Entity auto clear already exists": {
    color: "error",
    icon: "error",
    title: "Fehler",
    content: "Es existiert bereits ein gleicher Auto-Clear Eintrag.",
  },
  "Autoclear added": {
    color: "success",
    icon: "success",
    title: "Gesichert",
    content: "Die Autoclear wurde hinzugefügt.",
  },
  "Entity Auto Role already exists": {
    color: "error",
    icon: "error",
    title: "Fehler",
    content: "Es existiert bereits ein gleicher Auto-Role Eintrag.",
  },
  "channellog added": {
    color: "success",
    icon: "success",
    title: "Gesichert",
    content: "Der Channellog wurde hinzugefügt.",
  },
  "Entity channel log already exists": {
    color: "error",
    icon: "error",
    title: "Fehler",
    content: "Es existiert bereits ein gleicher Auto-Role Eintrag.",
  },
  "ticket system panel added": {
    color: "success",
    icon: "success",
    title: "Gesichert",
    content: "Die Ticket System Kategorie wurde hinzugefügt.",
  },
  "rolereaction added": {
    color: "success",
    icon: "success",
    title: "Gesichert",
    content: "Die Role Reaction wurde hinzugefügt.",
  },
  "Entity Role Reaction already exists": {
    color: "error",
    icon: "error",
    title: "Fehler",
    content: "Es existiert bereits ein gleicher Role Reaction Eintrag.",
  },
  "rolesaver updated": {
    color: "success",
    icon: "success",
    title: "Gesichert",
    content: "Die Rollen Saver Einstellungen wurden gespeichert.",
  },
  "welcomeleave updated": {
    color: "success",
    icon: "success",
    title: "Gesichert",
    content: "Die Willkommens/Verlassens Einstellungen wurden gespeichert.",
  },
  "counter updated": {
    color: "success",
    icon: "success",
    title: "Gesichert",
    content: "Die Counter Einstellungen wurden gespeichert.",
  },
  "giveaway created": {
    color: "success",
    icon: "success",
    title: "Gesichert",
    content: "Der Giveaway wurde erstellt.",
  },
  "serveroverview updated": {
    color: "success",
    icon: "success",
    title: "Gesichert",
    content: "Die Serverübersicht Einstellungen wurden gespeichert.",
  },
  "You have reached the maximum amount of whitelists.": {
    color: "error",
    icon: "error",
    title: "Fehler",
    content: "Du hast die maximale Anzahl an Servern erreicht.",
  },
  "ticket reaction added": {
    color: "success",
    icon: "success",
    title: "Gesichert",
    content: "Die Ticket Reaction wurde hinzugefügt.",
  },
  "module activated": {
    color: "success",
    icon: "success",
    title: "Gesichert",
    content: "Das Modul wurde aktiviert.",
  },
  "module deactivated": {
    color: "success",
    icon: "success",
    title: "Gesichert",
    content: "Das Modul wurde deaktiviert.",
  },
  "bot updated": {
    color: "success",
    icon: "success",
    title: "Gesichert",
    content: "Der Bot wurde aktualisiert.",
  },
};
const globalMessages = {
  success: {
    refreshed: "Die Tabelle wurde aktualisiert.",
  },
  warning: {},
  error: {
    "Bot not found": "Der Bot wurde nicht gefunden.",
    "Guild not found": "Der Server wurde nicht gefunden.",
    "Internal Server Error": "Ein interner Server Fehler ist aufgetreten.",
    "TypeError: Failed to fetch": "Es konnte keine Verbindung zum Server hergestellt werden.",
    "Not Found": "Ein angefragter Inhalt wurde nicht gefunden.",
    "Bad Gateway": "Der Endpunkt ist nicht erreichbar.",
  },
};
const botMessages = {
  BotOverview: {
    success: {
      pinCreated: "Support-Pin wurde erstellt, bitte teile ihn mit unseren Supportern.",
    },
    warning: {},
    error: {},
  },
  Whitelist: {
    success: {
      added: "Es wurde ein Server zur Whitelist hinzugefügt.",
      deleted: "Es wurde ein Server aus der Whitelist entfernt.",
    },
    warning: {},
    error: {
      "You have reached the maximum amount of whitelists.":
        "Du hast die maximale Anzahl an Servern erreicht.",
      "Whitelist already exists!": "Es existiert bereits ein gleicher Server in der Whitelist.",
      "Entity Whitelist not exists": "Dieser Server existiert nicht in der Whitelist.",
    },
  },
  SupportPin: {
    success: {
      redeemed: "Der Support-Pin wurde erfolgreich eingelöst.",
    },
    warning: {},
    error: {
      "Support pin not found": "Der Support-Pin wurde nicht gefunden.",
      "The support pin is invalid or has already been redeemed by another supporter.":
        "Der Support-Pin ist ungültig oder wurde bereits von einem anderen Supporter eingelöst.",
    },
  },
  AdminPanel: {
    success: {
      updated: "Die Einstellungen wurden gespeichert und übernommen.",
      deleted: "Ein Bot wurde gelöscht.",
      restart: "Der Bot wird neugestartet.",
      supportPinSuccess: "Der Support-Pin wurde erfolgreich eingelöst.",
    },
    warning: {},
    error: {
      "Entity Bot not found": "Der Bot wurde nicht gefunden.",
    },
  },
  AdminBot: {
    success: {
      created: "Der Bot wurde mit alles Einstellungen erstellt.",
      deletedEnvironment: "Das Environment wurde gelöscht.",
      addedEnvironment: "Das Environment wurde hinzugefügt.",
    },
    warning: {},
    error: {},
  },
  AdminToken: {
    success: {
      deleted: "Der Token wurde gelöscht.",
      added: "Der Token wurde hinzugefügt.",
    },
    warning: {},
    error: {
      "token is invalid": "Der Token ist ungültig oder existiert bereits.",
    },
  },
  AdminEntities: {
    success: {},
    warning: {},
    error: {
      "Entity not found": "Die Entität wurde nicht gefunden.",
    },
  },
};

const guildMessages = {
  ServerOverview: {
    success: {
      updated: "Die Server Einstellungen wurden gespeichert und übernommen.",
    },
    warning: {},
    error: {
      "The prefix must be between 1 and 10 characters.": "Es wurde kein Prefix angegeben.",
      noLanguage: "Es wurde keine Sprache angegeben.",
      "The nickname must max 32 characters.": "Der Nickname darf nicht länger als 32 Zeichen sein.",
    },
  },
};
const moduleMessages = {
  AutoClear: {
    success: {
      added: "Ein Auto-Clear Eintrag wurde hinzugefügt.",
      deleted: "Ein Auto-Clear Eintrag wurde gelöscht.",
    },
    warning: {},
    error: {
      "Please provide a time greater than 0": "Bitte gebe eine Zeit größer als 0 an.",
      timeGreaterThanZero: "Die Zeit muss größer als 0 sein.",
      channelNotSelected: "Es wurde kein Channel angegeben.",
      "Please provide a time less than 2 weeks": "Die Zeit darf nicht länger als 2 Wochen sein.",
      "Entity auto clear already exists": "Es existiert bereits ein gleicher Auto-Clear Eintrag.",
      "Entity AutoClear already exists": "Es existiert bereits ein gleicher Auto-Clear Eintrag.",
    },
  },
  AutoReaction: {
    success: {
      added: "Eine Auto-Reaction wurde hinzugefügt.",
      deleted: "Eine Auto-Reaction wurde gelöscht.",
    },
    warning: {},
    error: {
      "Please provide a emote": "Es wurde kein Emote angegeben.",
      "Please provide a channelId": "Es wurde kein Channel angegeben.",
    },
  },
  AutoRole: {
    success: {
      added: "Ein Auto-Role Eintrag wurde hinzugefügt.",
      deleted: "Ein Auto-Role Eintrag wurde gelöscht.",
    },

    warning: {},
    error: {
      "Please provide a roleId": "Es wurde kein Rolle angegeben.",
      "Entity Auto Role already exists": "Es existiert bereits ein gleicher Auto-Role Eintrag.",
      "Entity AutoRole already exists": "Es existiert bereits ein gleicher Auto-Role Eintrag.",
    },
  },
  ChannelLog: {
    success: {
      added: "Ein Channel Log Eintrag wurde hinzugefügt.",
      deleted: "Ein Channel Log Eintrag wurde gelöscht.",
    },
    warning: {},
    error: {
      "Please provide a channelId": "Es wurde kein Channel angegeben.",
      "Please provide a logChannelId": "Es wurde kein Log Channel angegeben.",
      "Entity channel log already exists": "Es existiert bereits ein gleicher Channel Log Eintrag.",
      "Entity ChannelLog already exists": "Es existiert bereits ein gleicher Channel Log Eintrag.",
    },
  },
  LogSystem: {
    success: {
      defaultUpdated: "Die Standard Logs wurden aktualisiert.",
      customUpdated: "Die Custom Logs wurden aktualisiert.",
      typeStatusUpdated: "Der Typ Status wurde aktualisiert.",
    },
    warning: {},
    error: {},
  },
  LogsSettingsTabs: {
    success: {
      updated: "Die Logs Einstellungen wurden gespeichert und übernommen.",
      activated: "Das Logs Modul wurde aktiviert.",
      deactivated: "Das Logs Modul wurde deaktiviert.",
      channelUpdated: "Der Custom Log Channel wurde aktualisiert.",
    },
    warning: {},
    error: {},
  },
  RoleReaction: {
    success: {
      added: "Eine Role Reaction wurde hinzugefügt.",
      deleted: "Eine Role Reaction wurde gelöscht.",
    },
    warning: {},
    error: {
      "Please provide a emote": "Es wurde kein Emote angegeben.",
      "Please provide a channelId": "Es wurde kein Channel angegeben.",
      "Please provide a roleId": "Es wurde kein Rolle angegeben.",
      "Please provide a messageId": "Es wurde kein Message angegeben.",
      "Entity RoleReaction already exists": "Es existiert bereits eine gleiche Role Reaction.",
    },
  },
  RoleSaver: {
    success: {
      updated: "Ein Role Saver Eintrag wurde aktualisiert.",
    },
    warning: {},
    error: {},
  },
  BotOverview: {
    success: {
      updated: "Der Bot wurde aktualisiert.",
    },
    warning: {},
    error: {
      "Name may not be blank": "Der Name darf nicht leer sein.",
      "The bot name must be between 3 and 32 characters long.":
        "Der Name muss zwischen 3 und 32 Zeichen lang sein.",
    },
  },
  SendEmbed: {
    success: {
      sent: "Die Nachricht wurde versendet.",
      updated: "Die Nachricht wurde aktualisiert.",
    },
    warning: {},
    error: {
      "Logs channel is not allowed": "Der Logs Channel ist nicht erlaubt.",
      "The channelId cannot be null": "Es wurde kein Channel angegeben.",
      "Please provide a date in the future": "Bitte gebe ein Datum in der Zukunft an.",
    },
  },
  Suggestion: {
    success: {
      sent: "Der Vorschlag wurde versendet.",
      deleted: "Eine Vorschlag wurde gelöscht.",
      updated: "Die Einstellungen für den Suggestion Channel wurden gespeichert.",
      accepted: "Der Vorschlag wurde angenommen.",
      denied: "Der Vorschlag wurde abgelehnt.",
      neutralized: "Der Vorschlag wurde neutralisiert.",
    },
    warning: {},
    error: {
      "Suggestion channel not set": "Der Suggestion Channel ist nicht gesetzt.",
      deleted: "Eine Vorschlag wurde gelöscht.",
      "Message must be between 3 and 2048 characters":
        "Die Nachricht muss zwischen 3 und 2048 Zeichen lang sein.",
    },
  },
  TicketAction: {
    success: {
      added: "Eine Ticket System Action wurde hinzugefügt.",
      deleted: "Eine Ticket System Action wurde gelöscht.",
    },
    warning: {},
    error: {
      "Message not found": "Die Nachricht wurde nicht gefunden.",
      "Reaction already exists": "Es existiert bereits eine gleiche Reaction.",
      "The ticketPanel is required": "Es wurde kein Ticket Panel angegeben.",
      "The ticket panel name is required": "Es wurde kein Emoji angegeben.",
      "The message is required": "Es wurde keine Nachricht angegeben.",
      "The channel is required": "Es wurde kein Channel angegeben.",
      "Message is not from bot":
        "Die Nachricht ist nicht vom Bot. Du kannst nur Nachrichten vom Bot verwenden.",
      "Entity TicketAction already exists": "Es existiert bereits eine Action, an dieser Nachricht.",
    },
  },
  TicketPanel: {
    success: {
      added: "Ein Ticket System Panel wurde hinzugefügt.",
      updated: "Ein Ticket System Panel wurde aktualisiert.",
      deleted: "Ein Ticket System Panel wurde gelöscht.",
    },
    warning: {},
    error: {},
  },

  WordBlacklist: {
    success: {
      added: "Ein Wort/Regex wurde zur Blacklist hinzugefügt.",
      deleted: "Ein Wort/Regex wurde aus der Blacklist entfernt.",
    },
    warning: {},
    error: {
      "Entity BlacklistedWord already exists": "Es existiert bereits ein gleiches Wort.",
      "Word must not be empty": "Das Wort darf nicht leer sein.",
    },
  },
  PrivateTalk: {
    success: {
      added: "Ein Privater Talk wurde hinzugefügt.",
      deleted: "Ein Privater Talk wurde gelöscht.",
    },
    warning: {},
    error: {
      "Entity PrivateTalk already exists": "Es existiert bereits ein Privater Talk.",
      "Name must not be empty": "Der Name darf nicht leer sein.",
      "The name is required": "Ein Name ist erforderlich.",
      "The channel is required": "Ein Channel ist erforderlich.",
      "The role is required": "Eine Rolle ist erforderlich.",
    },
  },
  AdminBotUpdate: {
    success: {},
    warning: {},
    error: {},
  },
  AdminBotCreate: {
    success: {},
    warning: {},
    error: {
      "Entity Bot already exists": "Es existiert bereits ein Bot mit diesem Namen.",
      "No bot available": "Es ist kein Bot verfügbar.",
    },
  },
  CounterSystem: {
    success: {
      updated: "Die Counter Einstellungen wurden gespeichert.",
    },
    warning: {},
    error: {},
  },
  GreetingSystem: {
    success: {
      updated: "Die Greeding Einstellungen wurden gespeichert.",
    },
    warning: {},
    error: {},
  },
  ImageSelector: {
    success: {
      added: "Bild wurde hinzugefügt.",
      deleted: "Bild wurde gelöscht.",
    },
    warning: {
      "Entity Image already exists": "Es existiert schon ein Bild. Ersetze das Bild mit dem neuen.",
    },
    error: {},
  },
  ModuleHeader: {
    success: {
      activated: "Das Modul wurde aktiviert.",
      deactivated: "Das Modul wurde deaktiviert.",
    },
    warning: {},
    error: {},
  },
  VoteSystem: {
    success: {
      added: "Ein neuer Vote wurde erstellt.",
      updated: "Der Vote wurde aktualisiert.",
      deleted: "Der Vote wurde gelöscht.",
    },
    warning: {},
    error: {},
  },
  GameRole: {
    success: {
      added: "Eine Game Role Action wurde hinzugefügt.",
      deleted: "Eine Game Role Action wurde gelöscht.",
    },
    warning: {},
    error: {},
  },
  Utils: {
    success: {},
    warning: {},
    error: {
      "url param not valid": "Der Url Parameter ist nicht gültig.",
    },
  },
  StickyMessages: {
    success: {
      added: "Eine Sticky Message wurde hinzugefügt.",
      deleted: "Eine Sticky Message wurde gelöscht.",
    },
    warning: {},
    error: {
      "Entity StickyMessage already exists": "Es existiert bereits eine Sticky Message.",
    },
  },
};

const getMessage = (type, title) =>
  messages[type]?.success[title] ||
  globalMessages.success[title] ||
  botMessages[type]?.success[title] ||
  guildMessages[type]?.success[title] ||
  moduleMessages[type]?.success[title];

const getErrorMessage = (type, title) =>
  messages[type]?.error[title] ||
  messages[type]?.warning[title] ||
  globalMessages.error[title] ||
  botMessages[type]?.error[title] ||
  guildMessages[type]?.error[title] ||
  moduleMessages[type]?.error[title] ||
  moduleMessages[type]?.warning[title];

const getWarningMessage = (type, title) =>
  messages[type]?.warning[title] || moduleMessages[type]?.warning[title];

export default {
  ...messages,
  /**
   * Send a message to the user.
   * @param type {React.ReactNode}
   * @param title {String}
   * @param preventDuplicate {boolean}
   */
  showMessage: ({ type, title, preventDuplicate = true }) => {
    Logger.info(
      "showMessage",
      {
        module: "apiMessages",
      },
      type,
      title
    );

    if (title && title.error) title = title.error;

    let variant = "error";
    if (getMessage(type, title)) variant = "success";
    else if (getWarningMessage(type, title)) variant = "warning";

    enqueueSnackbar(getMessage(type, title) || getErrorMessage(type, title) || messages.null.content, {
      preventDuplicate,
      variant: variant,
    });
  },
  getErrorMessage,
  getMessage,
  getErrorFromResponse: (type, error) => {
    const parsedError = error?.data?.error || error?.error;

    const message = getErrorMessage(type, parsedError);
    if (message) return message;
    return messages.null.content;
  },
};
