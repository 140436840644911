// @mui material components

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React imageExample components
import Card from "@mui/material/Card";
import {useNavigate} from "react-router-dom";
import MDButton from "../../components/MDButton";
import {useForm} from "react-hook-form";
import {FormInput} from "../../components/Form/FormInput";
import apiMessages from "../../apiMessages";
import {useRedeemSupportPinMutation} from "../../store/global/global-api";
import {cookieActions} from "../../store/cookie/cookie-slice";
import {useDispatch} from "react-redux";
import Cookies from "js-cookie";

export function SupportPinCreateForm() {
  const navigate = useNavigate();

  const [redeemSupportPin, {}] = useRedeemSupportPinMutation();
  const dispatch = useDispatch();

  const formHandler = useForm({
    defaultValues: {
      pin: "",
    },
  });
  const { watch, control, handleSubmit, setValue, register, getValues } = formHandler;

  const onSubmit = async (e) => {
    redeemSupportPin({
      data: {
        ...e,
      },
    }).then(({ data, error }) => {
      apiMessages.showMessage({
        type: "SupportPin",
        title: data ? "redeemed" : error?.data?.error || error,
      });
      if (error) return;
      const supportPinCookie = Cookies.get("support_pin");
      dispatch(cookieActions.setSupportPin(supportPinCookie));
      navigate("/bots");
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <MDBox pt={2} px={2}>
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            Support Modus
          </MDTypography>
        </MDBox>
        <MDBox p={2}>
          <FormInput
            label="Enter Pin"
            name="pin"
            formHandler={formHandler}
            autoComplete="off"
            type="number"
            rules={{
              required: "Bitte gib einen Pin ein",
              minLength: {
                value: 6,
                message: "Der Pin muss mindestens 6 Zeichen lang sein",
              },
              maxLength: {
                value: 6,
                message: "Der Pin darf maximal 6 Zeichen lang sein",
              },
              pattern: {
                value: /^[0-9]*$/,
                message: "Der Pin darf nur aus Zahlen bestehen",
              },
            }}
          />
          <MDButton variant="contained" color="primary" fullWidth sx={{ mt: 2 }} type="submit">
            Support Mode beitreten
          </MDButton>
        </MDBox>
      </Card>
    </form>
  );
}
