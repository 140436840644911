import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { Grid, Skeleton } from "@mui/material";
import MDButton from "../../../../../../components/MDButton";

function TicketSystemCategoryCardSkeleton() {
  return (
    <Card>
      <MDBox p={2}>
        <MDBox display="flex" alignItems="center">
          <Skeleton variant="circular" width={60} height={60} animation="wave" />
          <MDBox ml={2} lineHeight={0}>
            <MDTypography variant="h6" textTransform="capitalize" fontWeight="medium">
              <Skeleton variant="text" width={200} animation="wave" />
            </MDTypography>
            <MDBox my={1} lineHeight={1}>
              <MDTypography variant="button" fontWeight="light" color="text">
                <Skeleton variant="text" width={200} animation="wave" />
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
        <Divider />
        <MDBox display="flex" justifyContent="space-between" alignItems="center">
          <MDBox display="flex" flexDirection="column" lineHeight={0}>
            <MDTypography variant="button" fontWeight="medium">
              <Skeleton variant="text" width={50} animation="wave" />
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="secondary">
              Tickets
            </MDTypography>
          </MDBox>
          <MDBox display="flex" flexDirection="column" lineHeight={0} sx={{ textAlign: "center" }}>
            <MDTypography variant="button" fontWeight="medium">
              <Skeleton variant="text" width={50} animation="wave" />
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="secondary">
              Geschlossen
            </MDTypography>
          </MDBox>
          <MDBox display="flex" flexDirection="column" lineHeight={0} sx={{ textAlign: "end" }}>
            <MDTypography variant="button" fontWeight="medium">
              <Skeleton variant="text" width={50} animation="wave" />
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="secondary">
              Offen
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox lineHeight={0} mt={2}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <MDButton
                color="secondary"
                size="small"
                sx={{ width: "100%" }}
                disabled
                variant="contained"
              >
                Edit
                <FontAwesomeIcon
                  icon={faArrowRight}
                  size="sm"
                  style={{ marginTop: -0.5, marginLeft: 5 }}
                />
              </MDButton>
            </Grid>
            <Grid item xs={12} md={6}>
              <MDButton
                color="primary"
                size="small"
                variant="contained"
                disabled
                sx={{ width: "100%" }}
              >
                Tickets
                <FontAwesomeIcon
                  icon={faArrowRight}
                  size="sm"
                  style={{ marginTop: -0.5, marginLeft: 5 }}
                />
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default TicketSystemCategoryCardSkeleton;
