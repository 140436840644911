/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Link from "@mui/material/Link";

// Material Dashboard 2 PRO React components
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";

// Material Dashboard 2 PRO React base styles
import typography from "../../assets/theme/base/typography";
import config from "../../config";
import MDBadge from "../MDBadge";
import MDButton from "../MDButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCookieBite } from "@fortawesome/pro-solid-svg-icons";
import { cookieActions } from "../../store/cookie/cookie-slice";
import { useDispatch } from "react-redux";

function FooterSimple({
  links = [
    { href: "https://zyonicsoftware.com/impressum", name: "Impressum" },
    { href: "https://zyonicsoftware.com/datenschutz", name: "Datenschutz" },
    { href: "https://sv-studios.net/contact", name: "Kontakt" },
    { href: "https://dsc.gg/sv", name: "Discord" },
  ],
}) {
  const { size } = typography;
  const dispatch = useDispatch();

  const renderLinks = () =>
    links.map((link) => (
      <MDBox key={link.name} component="li" px={2} lineHeight={1}>
        <Link href={link.href} target="_blank">
          <MDTypography variant="button" fontWeight="regular" color="text">
            {link.name}
          </MDTypography>
        </Link>
      </MDBox>
    ));

  const handleCookieClick = () => {
    dispatch(cookieActions.setShowDialog(true));
  };

  return (
    <MDBox
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      px={1.5}
      mt={2}
    >
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="text"
        fontSize={size.sm}
      >
        <MDBadge
          badgeContent={config.version}
          container
          variant="gradient"
          color="dark"
          size="xs"
          sx={{ mr: 1 }}
        />
        &copy; 2020 - {new Date().getFullYear()} SV-Studios | Hasewinkel und Rempe GbR{" "}
      </MDBox>
      <MDBox
        component="ul"
        sx={({ breakpoints }) => ({
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          listStyle: "none",
          mt: 3,
          mb: 0,
          p: 0,

          [breakpoints.up("lg")]: {
            mt: 0,
          },
        })}
      >
        <MDButton variant="text" color="text" onClick={handleCookieClick} iconOnly>
          <FontAwesomeIcon icon={faCookieBite} size="lg" />
        </MDButton>
        {renderLinks()}
      </MDBox>
    </MDBox>
  );
}

// Typechecking props for the Footer
FooterSimple.propTypes = {
  links: PropTypes.arrayOf(PropTypes.object),
};

export default FooterSimple;
