import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useForm, useWatch } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import {
  useGetModuleTableDataQuery,
  useUpdateModuleDataMutation,
} from "../../../../store/modules/module-api";
import { useGetCategoriesQuery, useGetTextChannelsQuery } from "../../../../store/guild/guild-api";
import Logger from "../../../../Logger";
import apiMessages from "../../../../apiMessages";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import { FormCategoryAutocomplete, FormInput } from "../../../../components/Form/FormInput";
import MDButton from "../../../../components/MDButton";
import { hexToDecimal } from "../Tools";
import { AutoCompleteChannelIcon } from "../../../../components/Form/AutoCompleteIcon";
import LastMessageModal from "../../../../components/Modal/LastMessageModal";
import { useState } from "react";
import { InputAdornment } from "@mui/material";
import { dateIsBeforeNow } from "../../../../Utils";
import _ from "lodash";

function toLocalISOString(date) {
  const offset = date.getTimezoneOffset() * 60000; // Offset in Millisekunden
  const localISOTime = new Date(date.getTime() - offset).toISOString();
  return localISOTime.slice(0, 16); // Schneidet den String, um nur Datum und Uhrzeit bis zur Minute zu behalten
}

export function SendEmbedUpdateForm({ botId, guildId, fullHeight, json, setJson }) {
  const [update, { isLoading }] = useUpdateModuleDataMutation();

  const [showLastMessageModal, setShowLastMessageModal] = useState(false);

  const {
    data: textChannelData = [],
    isFetching: textChannelIsFetching,
    refetch: textChannelRefetch,
  } = useGetTextChannelsQuery({
    botId,
    guildId,
  });
  const {
    data: categoriesData = [],
    isFetching: categoriesIsFetching,
    refetch: categoriesRefetch,
  } = useGetCategoriesQuery({
    botId,
    guildId,
  });

  const formHandler = useForm({
    defaultValues: {
      channelId: "",
      sendAt: "",
      messageId: "",
    },
  });
  const { control, handleSubmit, setValue, watch } = formHandler;

  const messageId = watch("messageId");
  const channelId = watch("channelId");

  const {
    data: lastMessageData = [],
    isFetching: lastMessageIsFetching,
    refetch: lastMessageRefetch,
  } = useGetModuleTableDataQuery(
    {
      botId,
      guildId,
      module: `channel/${channelId}/messages`,
      size: 30,
      isModule: false,
    },
    {
      skip: !channelId,
    }
  );

  const onSubmit = async (e) => {
    const sendJson = JSON.parse(JSON.stringify(json));

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < json.embeds.length; i++) {
      console.log("Color", sendJson.embeds[i].color);
      // eslint-disable-next-line no-restricted-globals
      if (sendJson.embeds[i].color && isNaN(sendJson.embeds[i].color)) {
        console.log("Color Before", sendJson.embeds[i].color);
        sendJson.embeds[i].color = hexToDecimal(sendJson.embeds[i]?.color?.replace("#", ""));
        console.log("Color After", sendJson.embeds[i].color);
      }
      if (sendJson.embeds[i].timestamp) {
        const isoString = new Date(sendJson.embeds[i].timestamp).toISOString();
        console.log("Before", sendJson.embeds[i].timestamp, isoString);
        sendJson.embeds[i].timestamp = isoString; /* .substring(0, isoString.length - 1); */
        console.log("After", sendJson.embeds[i].timestamp, isoString);
      } else {
        sendJson.embeds[i].timestamp = null;
      }
    }

    update({
      botId,
      guildId,
      module: "message",
      id: e.messageId,
      isModule: false,
      data: {
        ...e,
        message: sendJson,
        sendAt: e.sendAt ? new Date(e.sendAt).getTime() : "",
        channelId: e.channelId,
      },
    }).then(({ data, error }) => {
      Logger.info("SubmitEvent", { module: "SubmitForm" }, data, error);
      apiMessages.showMessage({
        type: "SendEmbed",
        title: data ? "updated" : error?.data?.error || error,
      });
    });
  };

  const channel = useWatch({
    control,
    name: "channelId",
  });

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        height: fullHeight ? "100%" : "auto",
      }}
    >
      <LastMessageModal
        serverId={guildId}
        botId={botId}
        open={showLastMessageModal}
        allowedMessages={["selfBot"]}
        defaultChannel={channel}
        onClose={(state, data) => {
          setShowLastMessageModal(state);
          if (data) {
            setValue("messageId", data?.message || "");
            setValue("channelId", data?.channel || "");
          }
        }}
      />
      <Card sx={{ height: "100%" }}>
        <MDBox pt={2} px={2}>
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            Nachricht editieren
          </MDTypography>
        </MDBox>
        <MDBox p={2}>
          <Grid item xs={12}>
            <MDBox pt={2} px={2}>
              <FormCategoryAutocomplete
                name="channelId"
                label="Channel"
                categories={categoriesData}
                options={[...textChannelData].sort((x, y) => x.category - y.category)}
                groupByFunction="default"
                labelIcon={(option) => <AutoCompleteChannelIcon option={option} />}
                additionalParameters={["type"]}
                formHandler={formHandler}
                refetch={() => {
                  textChannelRefetch();
                  categoriesRefetch();
                }}
                refetching={textChannelIsFetching || categoriesIsFetching}
                rules={{
                  required: "Du musst einen Channel angeben.",
                }}
              />
            </MDBox>
            <MDBox pt={2} px={2}>
              <FormInput
                formHandler={formHandler}
                name="messageId"
                label="Message"
                rules={{
                  required: "Du musst eine Message angeben.",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={{ paddingRight: 0, marginLeft: 0 }}>
                      <MDButton
                        aria-label="Add"
                        edge="end"
                        color="primary"
                        variant="contained"
                        size="small"
                        onClick={() => {
                          setShowLastMessageModal(true);
                        }}
                      >
                        Auswählen
                      </MDButton>
                    </InputAdornment>
                  ),
                }}
              />
            </MDBox>
            <MDBox pb={2} px={2}>
              <MDButton
                type="button"
                variant="contained"
                sx={{ width: "100%" }}
                disabled={
                  isLoading || lastMessageIsFetching || lastMessageData.content?.length === 0 || !messageId
                }
                color="secondary"
                size="small"
                onClick={() => {
                  const message = lastMessageData.content.find((x) => x.id === messageId);
                  console.log("Message", message);
                  if (!message) return;

                  //TODO schauen manchmal gibs felder mit dem wert "undefined" und das sollte nicht sein

                  const localJson = JSON.parse(JSON.stringify(message.content));

                  const defaultEmbed = {
                    title: "",
                    description: "",
                    url: "",
                    author: { name: "", url: "", icon_url: "" },
                    footer: { text: "", icon_url: "" },
                    fields: [{ title: "", desc: "", inline: false }],
                    image: { url: "" },
                    thumbnail: { url: "" },
                    color: "#000000", // Standardfarbe als Hex setzen, falls keine Farbe vorhanden ist.
                    timestamp: null, // Standardwert für timestamp
                    type: "rich",
                  };

                  localJson.embeds = localJson.embeds.map((embed) => {
                    // Konvertiert die Farbnummer in einen Hex-Code, wenn sie existiert und keine NaN ist.
                    if (embed.color && !Number.isNaN(embed.color)) {
                      embed.color = `#${Number(embed.color).toString(16).padStart(6, "0")}`; // Fügt führende Nullen hinzu, falls nötig.
                    }

                    // Konvertiert den Timestamp in eine ISO-Zeichenkette, wenn vorhanden.
                    if (embed.timestamp) {
                      embed.timestamp = toLocalISOString(new Date(embed.timestamp));
                    }

                    return _.defaultsDeep({}, embed, defaultEmbed);
                  });

                  setJson({
                    content: localJson.content,
                    embeds: localJson.embeds,
                  });
                }}
              >
                Lade Ausgewählte Nachricht
              </MDButton>
            </MDBox>

            <MDBox pt={2} px={2}>
              <FormInput
                name="sendAt"
                type="datetime-local"
                InputLabelProps={{ shrink: true }}
                rules={{
                  validate: (value) => dateIsBeforeNow(value) || "Das Datum muss in der Zukunft liegen.",
                }}
                label="Wann soll die Nachricht gesendet werden?"
                formHandler={formHandler}
              />
            </MDBox>
          </Grid>

          <Grid item xs={12}>
            <MDBox pt={2} px={2}>
              <MDButton
                type="submit"
                variant="contained"
                sx={{ width: "100%" }}
                disabled={isLoading}
                color="primary"
              >
                Nachricht editieren
                {isLoading && (
                  <FontAwesomeIcon icon={faSpinnerThird} size="lg" spin style={{ marginLeft: "5px" }} />
                )}
              </MDButton>
            </MDBox>
          </Grid>
        </MDBox>
      </Card>
    </form>
  );
}
