import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import { useEffect, useState } from "react";
import { faArrowUpRightFromSquare, faHourglass, faList } from "@fortawesome/pro-solid-svg-icons";
import { Link } from "react-router-dom";
import MDBox from "../../../../components/MDBox";
import LoadingItem from "../../components/LoadingItem";
import MDBadge from "../../../../components/MDBadge";
import breakpoints from "../../../../assets/theme/base/breakpoints";
import MDButton from "../../../../components/MDButton";
import LogsOverviewTab from "../modules/LogSystem/LogsOverviewTab";
import moduleMessages from "../../../../moduleMessages";
import { useGetModulesQuery } from "../../../../store/modules/module-api";
import { useUnleashFlag } from "../../../../store/flags/flags-actions";

// eslint-disable-next-line no-unused-vars
function ServerOverviewTab({ botId, loaded, serverId }) {
  const { data: moduleData = [], isLoading: moduleIsLoading } = useGetModulesQuery({
    botId,
    guildId: serverId,
  });

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  const newModuleOverview = useUnleashFlag("newModuleOverview");

  const textAndLink = moduleMessages;

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /**
         The event listener that's calling the handleTabsOrientation function when resizing the window.
         */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  return (
    <>
      <Grid item xs={12}>
        <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
          <Tab
            label="Module"
            icon={
              <Icon fontSize="small" sx={{ mt: -0.25 }}>
                home
              </Icon>
            }
          />
          <Tab
            label="Logs"
            disabled
            icon={
              <Icon fontSize="small" sx={{ mt: -0.25 }}>
                email
              </Icon>
            }
          />
          {/*
             <Tab
            label="Erweiterte Einstellungen"
            icon={
              <Icon fontSize="small" sx={{ mt: -0.25 }}>
                settings
              </Icon>
            }
          />
             */}
        </Tabs>
      </Grid>
      {tabValue === 0 ? (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {!moduleIsLoading &&
              moduleData
                .filter((module) => textAndLink[module.moduleType])
                .sort((a, b) => a.moduleType - b.moduleType)
                .map((module, key) => (
                  <Grid item xs={12} md={6} lg={4} xl={3} key={key}>
                    <Card>
                      <MDBox p={2}>
                        <LoadingItem
                          icon={
                            textAndLink[module.moduleType]
                              ? newModuleOverview
                                ? textAndLink[module.moduleType].icon || faArrowUpRightFromSquare
                                : faArrowUpRightFromSquare
                              : faHourglass
                          }
                          color={
                            textAndLink[module.moduleType]
                              ? newModuleOverview
                                ? module.disabled
                                  ? "error"
                                  : "success"
                                : "info"
                              : "warning"
                          }
                          title={textAndLink[module.moduleType]?.text || module.moduleType}
                          new={textAndLink[module.moduleType]?.new}
                          description={
                            textAndLink[module.moduleType] &&
                            (!module.disabled ? (
                              <MDBadge badgeContent="Aktiviert" size="xs" color="success" container />
                            ) : (
                              <MDBadge badgeContent="Deaktiviert" size="xs" color="error" container />
                            ))
                          }
                          loading={!loaded}
                          isLink
                          enabled={!!textAndLink[module.moduleType]}
                          href={`./modules/${textAndLink[module.moduleType]?.link}` || "./"}
                        />
                      </MDBox>
                    </Card>
                  </Grid>
                ))}
            <Grid item xs={12} md={6} lg={4} xl={3}>
              <Card>
                <MDBox p={2}>
                  <LoadingItem
                    icon={faList}
                    color="primary"
                    description={
                      <MDButton
                        fullWidth
                        color="primary"
                        size="medium"
                        component={Link}
                        to={`/bots/${botId}/servers/${serverId}/modules`}
                      >
                        Alle Module
                      </MDButton>
                    }
                    loading={!loaded}
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      ) : null}

      {tabValue === 1 ? (
        <Grid item xs={12} mt={2}>
          <LogsOverviewTab serverId={serverId} botId={botId} />
        </Grid>
      ) : null}
    </>
  );
}

export default ServerOverviewTab;
