import { useParams } from "react-router-dom";
import { useMemo } from "react";
import AutoClear from "./AutoClear/AutoClear";
import ModuleNotFound from "./ModuleNotFound";
import ModuleOverview from "./ModuleOverview";
import AutoReaction from "./AutoReaction/AutoReaction";
import ChannelLog from "./ChannelLog/ChannelLog";
import AutoRole from "./AutoRole/AutoRole";
import LogSystem from "./LogSystem/LogSystem";
import RoleReaction from "./RoleReaction/RoleReaction";
import RoleSaver from "./RoleSaver/RoleSaver";
import Suggestion from "./Suggestion/Suggestion";
import TicketSystem from "./TicketSystem/TicketSystem";
import WelcomeLeave from "./GreedingSystem/WelcomeLeave";
import Counter from "./Counter/Counter";
import WordBlacklist from "./WordBlacklist/WordBlacklist";
import PrivateTalk from "./PrivateTalk/PrivateTalk";
import VoteSystem from "./VoteSystem/VoteSystem";
import Giveaway from "./Giveway/Giveaway";
import XpSystem from "./XpSystem/XpSystem";
import StickyMessages from "./StickyMessages/StickyMessages";

function ModuleManager() {
  const { botId, serverId, module } = useParams();

  return useMemo(() => {
    if (!module) return <ModuleOverview botId={botId} serverId={serverId} />;

    switch (module) {
      case "auto-clear":
        return <AutoClear />;
      case "auto-reaction":
        return <AutoReaction />;
      case "auto-role":
        return <AutoRole />;
      case "channel-log":
        return <ChannelLog />;
      case "logs":
        return <LogSystem />;
      case "role-reaction":
        return <RoleReaction />;
      case "role-saver":
        return <RoleSaver />;
      case "sticky-messages":
        return <StickyMessages />;
      case "suggestion":
        return <Suggestion />;
      case "ticket-system":
        return <TicketSystem />;
      case "greeting-system":
        return <WelcomeLeave />;
      case "counter-system":
        return <Counter />;
      case "word-blacklist":
        return <WordBlacklist bot={botId} server={serverId} />;
      case "private-talk":
        return <PrivateTalk bot={botId} server={serverId} />;
      case "vote":
        return <VoteSystem bot={botId} server={serverId} />;
      case "giveaway":
        return <Giveaway bot={botId} server={serverId} />;
      case "xp-system":
        return <XpSystem bot={botId} server={serverId} />;
      default:
        return <ModuleNotFound />;
    }
  }, [botId, serverId, module]);
}

export default ModuleManager;
