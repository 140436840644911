import Card from "@mui/material/Card";
import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";
import MDButton from "../../../../../components/MDButton";
import Grid from "@mui/material/Grid";
import { useForm, useWatch } from "react-hook-form";
import { FormCategoryAutocomplete, FormInput } from "../../../../../components/Form/FormInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import apiMessages from "../../../../../apiMessages";
import { useCreateModuleDataMutation } from "../../../../../store/modules/module-api";
import { useGetCategoriesQuery, useGetTextChannelsQuery } from "../../../../../store/guild/guild-api";
import Logger from "../../../../../Logger";
import React, { useMemo, useState } from "react";
import { dateIsBeforeNow, durationString } from "../../../../../Utils";
import { AutoCompleteChannelIcon } from "../../../../../components/Form/AutoCompleteIcon";
import { StickyMessagesModal } from "./StickyMessagesModal";
import { useGetBotQuery } from "../../../../../store/bot/bot-api";
import Preview from "../../../../../components/EmbedBuilder/Preview";

export function StickyMessagesAddForm({ botId, guildId, fullHeight }) {
  const [update, { isLoading }] = useCreateModuleDataMutation();
  const { data: botData = {} } = useGetBotQuery({
    botId,
  });

  const {
    data: categoriesData = [],

    isFetching: categoriesIsFetching,
    refetch: categoriesRefetch,
  } = useGetCategoriesQuery({
    botId,
    guildId,
  });
  const {
    data: textChannelData = [],
    refetch: textChannelsRefetch,
    isFetching: textChannelsIsFetching,
  } = useGetTextChannelsQuery({
    botId,
    guildId,
  });

  const formHandler = useForm({
    defaultValues: {
      channelId: "",
      messageJson: {
        content: "Ich bin eine StickyMessage",
        embeds: [],
      },
      duration: "00:10:00",
      startTime: null,
    },
  });
  const { control, handleSubmit, setValue } = formHandler;

  const [openEmojiPicker, setOpenEmojiPicker] = useState(false);

  const onSubmit = async (e) => {
    update({
      botId,
      guildId,
      module: "stickymessage",
      data: {
        ...e,
        duration: durationString(e.duration),
        startTime: e.startTime ? new Date(e.startTime).getTime() : "",
      },
    }).then(({ data, error }) => {
      Logger.info("SubmitEvent", { module: "SubmitForm" }, data, error);
      apiMessages.showMessage({
        type: "StickyMessages",
        title: data ? "added" : error?.data?.error || error,
      });
    });
  };

  const [showMessageBuilder, setShowMessageBuilder] = useState(false);

  const messageJson = useWatch({
    control,
    name: "messageJson",
  });

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        height: fullHeight ? "100%" : "auto",
      }}
    >
      <StickyMessagesModal
        updateMessage={(json) => setValue("messageJson", json)}
        message={messageJson}
        open={showMessageBuilder}
        onClose={() => setShowMessageBuilder(false)}
      />
      <Card sx={{ height: "100%" }}>
        <MDBox pt={2} px={2}>
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            Add StickyMessage
          </MDTypography>
        </MDBox>
        <MDBox p={2}>
          <Grid item xs={12}>
            <MDBox pt={2} px={2}>
              <FormCategoryAutocomplete
                name="channelId"
                label="Channel"
                categories={categoriesData}
                options={[...textChannelData].sort((x, y) => x.category - y.category)}
                groupByFunction="default"
                labelIcon={(option) => <AutoCompleteChannelIcon option={option} />}
                additionalParameters={["type"]}
                formHandler={formHandler}
                refetch={() => {
                  textChannelsRefetch();
                  categoriesRefetch();
                }}
                refetching={textChannelsIsFetching || categoriesIsFetching}
                rules={{
                  required: "Du musst einen Channel angeben.",
                }}
              />
            </MDBox>
            <MDBox px={2}>
              <MDBox mb={2} class="hide-profile-skyra">
                {useMemo(
                  () => (
                    <Preview json={messageJson} bot={botData} />
                  ),
                  [messageJson, botData]
                )}
              </MDBox>

              <MDButton
                onClick={() => setShowMessageBuilder(true)}
                variant="outlined"
                color="primary"
                fullWidth
              >
                Nachricht anpassen
              </MDButton>
            </MDBox>
            <MDBox pt={2} px={2}>
              <FormInput
                name="duration"
                type="time"
                label="Nach wie viel Zeit soll die Nachricht erneut gesendet werden?"
                formHandler={formHandler}
                hideStatusIcon
                inputProps={{ step: 1, min: "00:00:01", max: "23:59:59" }}
                rules={{
                  required: "Du musst eine Zeit angeben.",
                }}
              />
            </MDBox>
            <MDBox pt={2} px={2}>
              <FormInput
                name="startTime"
                type="datetime-local"
                InputLabelProps={{ shrink: true }}
                rules={{
                  validate: (value) => dateIsBeforeNow(value) || "Das Datum muss in der Zukunft liegen.",
                }}
                label="Wann soll die Nachricht gesendet werden?"
                formHandler={formHandler}
              />
            </MDBox>
          </Grid>

          <Grid item xs={12}>
            <MDBox pt={2} px={2}>
              <MDButton
                type="submit"
                variant="contained"
                sx={{ width: "100%" }}
                disabled={isLoading}
                color="primary"
              >
                Hinzufügen
                {isLoading && (
                  <FontAwesomeIcon icon={faSpinnerThird} size="lg" spin style={{ marginLeft: "5px" }} />
                )}
              </MDButton>
            </MDBox>
          </Grid>
        </MDBox>
      </Card>
    </form>
  );
}
