/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components

// Material Dashboard 2 PRO React components

// Material Dashboard 2 PRO React imageExample components

// WelcomeLeave page components
// Images
import { useMemo } from "react";
import { Skeleton } from "@mui/material";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import MDButton from "../../../../../components/MDButton";
import apiErrorMessages from "../../../../../apiMessages";
import ReloadTable from "../../../../../components/Table/ReloadTable";
import {
  useGetEmojisQuery,
  useGetRolesQuery,
  useGetTextChannelsQuery,
} from "../../../../../store/guild/guild-api";
import { useSelector } from "react-redux";
import { useDeleteModuleDataMutation } from "../../../../../store/modules/module-api";
import { Emoji, EmojiStyle } from "emoji-picker-react";
import { getUnifiedEmoji, isUnifiedEmoji } from "../../../../../Utils";

const module = "rolereaction";

export function RoleReactionTable({ botId, guildId }) {
  const { data: textChannelData = [], isLoading: textChannelIsLoading } = useGetTextChannelsQuery({
    botId,
    guildId: guildId,
  });
  const { data: roleData = [], isLoading: roleIsLoading } = useGetRolesQuery({
    botId,
    guildId: guildId,
  });
  const { data: emojiData = [], isLoading: emojiIsLoading } = useGetEmojisQuery({
    botId,
    guildId: guildId,
  });

  const [deleteModuleData, { isLoading: deleteModuleDataIsLoading }] = useDeleteModuleDataMutation({});

  const expertMode = useSelector((state) => state.ui.expertMode);

  const deleteDataEvent = (id) => {
    deleteModuleData({ botId, guildId, module, id }).then(({ data, error }) => {
      apiErrorMessages.showMessage({
        type: "PrivateTalk",
        title: data ? "deleted" : error?.data?.error || error,
      });
    });
  };

  const tableColumns = useMemo(
    () =>
      [
        expertMode
          ? {
              Header: "ChannelId",
              accessor: "channelId",
            }
          : null,
        {
          Header: "ChannelName",
          accessor: "channelName",
        },
        {
          Header: "Message",
          accessor: "messageId",
        },
        {
          Header: "Emoji",
          accessor: "emote",
        },
        expertMode
          ? {
              Header: "Emoji Id",
              accessor: "emoteId",
            }
          : null,

        expertMode
          ? {
              Header: "Rollen Id",
              accessor: "roleId",
            }
          : null,
        {
          Header: "Rolle",
          accessor: "roleName",
        },
        {
          Header: "Art",
          accessor: "addOrRemove",
        },
        {
          Header: "Actions",
          accessor: "actions",
          disableSort: true,
          width: "0%",
        },
      ].filter((x) => x),
    [expertMode]
  );

  const tableRows = useMemo(
    () => (row) => ({
      channelId: row.channelId,
      channelName: textChannelData?.find((r) => r.id === row.channelId)?.name || <i>Name nicht gefunden</i>,
      roleId: row.roleId,
      roleName: roleData?.find((r) => r.id === row.roleId)?.name || <i>Name nicht gefunden</i>,
      emote: isUnifiedEmoji(getUnifiedEmoji(row.emote)) ? (
        <Emoji unified={getUnifiedEmoji(row.emote)} emojiStyle={EmojiStyle.TWITTER} size={22} />
      ) : (
        <img
          alt={emojiData?.find((r) => r.id === row.emote)?.name || <i>Name nicht gefunden</i>}
          loading="lazy"
          width="auto"
          height="20"
          style={{
            marginRight: "5px",
          }}
          src={`https://cdn.discordapp.com/emojis/${row.emote}.webp?size=96&quality=lossless`}
        />
      ),
      emoteId: row.emote,
      emoteName: emojiData?.find((r) => r.id === row.emote)?.name || <i>Name nicht gefunden</i>,
      messageId: row.messageId,
      addOrRemove: !row.reversed ? "Rolle hinzufügen" : "Rolle entfernen",
      actions: (
        <MDButton
          variant="contained"
          iconOnly
          disabled={deleteModuleDataIsLoading}
          color="primary"
          onClick={() => deleteDataEvent(row.id)}
        >
          {deleteModuleDataIsLoading ? (
            <FontAwesomeIcon icon={faSpinnerThird} size="lg" spin />
          ) : (
            <FontAwesomeIcon icon={faTrash} size="lg" />
          )}
        </MDButton>
      ),
    }),
    [textChannelData, roleData, emojiData, deleteModuleDataIsLoading]
  );

  return (
    <ReloadTable
      path={{
        botId,
        guildId,
        module,
      }}
      loaded={!textChannelIsLoading && !roleIsLoading && !emojiIsLoading}
      rowsLoad={() => ({
        channelId: <Skeleton width={100} animation="wave" />,
        channelName: <Skeleton width={100} animation="wave" />,
        roleId: <Skeleton width={100} animation="wave" />,
        roleName: <Skeleton width={100} animation="wave" />,
        emote: <Skeleton width={100} animation="wave" />,
        emoteId: <Skeleton width={100} animation="wave" />,
        emoteName: <Skeleton width={100} animation="wave" />,
        messageId: <Skeleton width={100} animation="wave" />,
        addOrRemove: <Skeleton width={100} animation="wave" />,
        actions: <Skeleton width={100} animation="wave" />,
      })}
      columns={tableColumns}
      rows={tableRows}
      canSearch
      showTotalEntries
    />
  );
}
