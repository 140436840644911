import { FormAutocomplete, FormInput, FormSwitch } from "../../../../components/Form/FormInput";
import MDBox from "../../../../components/MDBox";

export function PropertyForm({ formHandler }) {
  return (
    <>
      <MDBox display="flex" flexDirection="column" gap={1}>
        <FormAutocomplete
          formHandler={formHandler}
          name="properties.botType"
          label="Bot Type"
          options={[
            {
              value: "PUBLIC_BOT",
              label: "Public Bot",
            },
            {
              value: "PRIVATE_BOT",
              label: "Private Bot",
            },
            {
              value: "CUSTOM_BOT",
              label: "Custom Bot",
            },
          ]}
        />

        <FormSwitch formHandler={formHandler} name="properties.beta" label="Beta" />
        <FormSwitch formHandler={formHandler} name="properties.premium" label="Premium" />
        <FormSwitch formHandler={formHandler} name="properties.partner" label="Partner" />
        <FormSwitch formHandler={formHandler} name="properties.adsDisabled" label="Ads Disabled" />
        <FormInput
          name="properties.shards"
          label={"Shards"}
          type="number"
          rules={{
            min: {
              value: 1,
              message: "Der Bot muss mindestens auf einem Shard laufen.",
            },
          }}
          formHandler={formHandler}
        />
        <FormInput name="properties.ownerId" label={"Owner Id"} formHandler={formHandler} />
        <FormAutocomplete
          formHandler={formHandler}
          name="properties.onlineStatus"
          label="Online Status"
          options={[
            {
              value: "ONLINE",
              label: "Online",
            },
            {
              value: "OFFLINE",
              label: "Offline",
            },
            {
              value: "IDLE",
              label: "Idle",
            },
            {
              value: "DO_NOT_DISTURB",
              label: "Do not disturb",
            },
            {
              value: "INVISIBLE",
              label: "Invisible",
            },
          ]}
        />
      </MDBox>
    </>
  );
}
