import { Grid } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { faPaperPlaneTop } from "@fortawesome/pro-duotone-svg-icons";
import { useParams } from "react-router-dom";
import Preview from "../../../../components/EmbedBuilder/Preview";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import MDBox from "../../../../components/MDBox";
import MDAlert from "../../../../components/MDAlert";
import ModuleHeader from "../modules/components/ModuleHeader";
import FooterSimple from "../../../../components/Footer/FooterSimple";
import DashboardLayout from "../../../../examples/LayoutContainers/DashboardLayout";
import Builder from "../../../../components/EmbedBuilder/Builder";
import MDTypography from "../../../../components/MDTypography";
import Error404Modal from "../../../../components/Dialog/Error404Modal";
import { SendEmbedForm } from "./SendEmbedForm";
import { useGetBotQuery } from "../../../../store/bot/bot-api";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import Tabs from "@mui/material/Tabs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { SendEmbedUpdateForm } from "./SendEmbedUpdateForm";

function SendEmbed() {
  const { botId, serverId } = useParams();

  const { data: botData = {} } = useGetBotQuery({
    botId,
  });

  const [json, setJson] = useState(
    sessionStorage.getItem("embedBuilder")
      ? JSON.parse(sessionStorage.getItem("embedBuilder"))
      : {
          content: "",
          embeds: [],
        }
  );

  useEffect(() => {
    // setJson({});
    const saveInterval = setTimeout(() => {
      sessionStorage.setItem("embedBuilder", JSON.stringify(json));
    }, 1000 * 5);
    return () => {
      clearTimeout(saveInterval);
    };
  }, [json]);

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState("new");

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <Error404Modal />
        <MDBox mb={2} />
        <ModuleHeader
          name="Send Embed"
          type="SEND_EMBED"
          description="Sende ein Embed in einen Textkanal."
          botId={botId}
          serverId={serverId}
          disableSwitch
          icon={faPaperPlaneTop}
        />
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={6}>
              {useMemo(
                () => (
                  <Builder setJson={setJson} json={json} />
                ),
                [setJson, json]
              )}
            </Grid>
            <Grid item xs={12} lg={6}>
              <Tabs
                sx={{ mb: 1 }}
                orientation={tabsOrientation}
                value={tabValue}
                onChange={(e, newValue) => setTabValue(newValue)}
              >
                <Tab label="Erstellen" value="new" icon={<FontAwesomeIcon icon={faPlus} />} />
                <Tab label="Bearbeiten" value="edit" icon={<FontAwesomeIcon icon={faPenToSquare} />} />
              </Tabs>
              {tabValue === "new" && <SendEmbedForm guildId={serverId} botId={botId} json={json} />}
              {tabValue === "edit" && (
                <SendEmbedUpdateForm guildId={serverId} botId={botId} json={json} setJson={setJson} />
              )}
              {useMemo(
                () => (
                  <Preview json={json} bot={botData} />
                ),
                [json, botData]
              )}
              {false && (
                <MDAlert color="info" dismissible>
                  <MDTypography variant="subtitle2" color="white">
                    Die Vorschau aktualisiert sich alle 5 Sekunden, sobald nicht mehr geschrieben wird.
                  </MDTypography>
                </MDAlert>
              )}
            </Grid>
          </Grid>
        </MDBox>

        <FooterSimple />
      </DashboardLayout>
    </>
  );
}

export default SendEmbed;
