/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import MDBox from "../../../../../components/MDBox";
import DashboardLayout from "../../../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../../../examples/Navbars/DashboardNavbar";
import FooterSimple from "../../../../../components/Footer/FooterSimple";
import {useState} from "react";
import {faHandWave} from "@fortawesome/pro-duotone-svg-icons";
import {useParams} from "react-router-dom";
import ModuleHeader from "../components/ModuleHeader";
import Error404Modal from "../../../../../components/Dialog/Error404Modal";
import {ImageSelector} from "../../../../../components/Modal/ImageSelector";
import {GreetingUpdateForm} from "./GreetingUpdateForm";
import MDAlert from "../../../../../components/MDAlert";
import MDTypography from "../../../../../components/MDTypography";

function WelcomeLeave() {
  const { botId, serverId } = useParams();

  const [modals, setModals] = useState({
    welcome: false,
    leave: false,
  });

  return (
    <DashboardLayout>
      {modals.welcome && (
        <ImageSelector
          open
          onClose={() => setModals((prev) => ({ ...prev, welcome: false }))}
          type="JOIN"
          title="Willkommensnachricht"
          onSubmit={() => {}}
        />
      )}
      {modals.leave && (
        <ImageSelector
          open
          type="LEAVE"
          title="Verabschiedungsnachricht"
          onClose={() => setModals((prev) => ({ ...prev, leave: false }))}
          onSubmit={() => {}}
        />
      )}

      <DashboardNavbar />
      <Error404Modal />
      <MDBox mb={2} />
      <ModuleHeader
        name="Willkommens- und Verlassensystem"
        type="JOIN_LEAVE_MESSAGES"
        description="Begrüße und verabschiede Nutzer mit einer spezifischen Nachricht."
        serverId={serverId}
        botId={botId}
        icon={faHandWave}
      />

      <MDBox mt={5} mb={3}>
        <MDBox mb={1}>
          <MDAlert color="warning">
            <MDTypography variant="body2">
              <strong>Info:</strong> Dieses Modul wird derzeit überarbeitet und ist ggf. nicht verfügbar.
            </MDTypography>
          </MDAlert>
        </MDBox>
        <GreetingUpdateForm guildId={serverId} botId={botId} setModals={setModals} />
      </MDBox>
      <FooterSimple />
    </DashboardLayout>
  );
}

export default WelcomeLeave;
