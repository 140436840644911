import MDBox from "../../MDBox";
import Card from "@mui/material/Card";
import MDTypography from "../../MDTypography";
import MDButton from "../../MDButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faChevronDown, faChevronUp, faClone, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { Collapse, Grid } from "@mui/material";
import React, { useState } from "react";
import { FormInput } from "../../Form/FormInput";
import { useFieldArray, UseFieldArrayReturn } from "react-hook-form";
import { Field } from "./Field";

const cardPadding = 1.5;

/**
 * Renders an embed card with various fields and options.
 * @param {Object} params - The parameters object.
 * @param {Object} params.embed - The embed object.
 * @param {number} params.embedIndex - The index of the embed in the embedArray.
 * @param {boolean} params.viewOnly - Flag to indicate if the form is in viewOnly mode.
 * @param {Object} params.formHandler - The form handler object.
 * @param {UseFieldArrayReturn} params.embedArray - The embedArray object.
 * @returns {JSX.Element} - The rendered embed card.
 */
export function Embed({ embed, embedIndex, viewOnly, formHandler, embedArray }) {
  const [collapse, setCollapse] = useState({
    author: {
      collapsed: true,
    },
    footer: {
      collapsed: true,
    },
    fields: {
      collapsed: true,
    },
  });

  const fieldArray = useFieldArray({
    control: formHandler.control,
    name: `embeds[${embedIndex}].fields`,
  });
  const { fields, append: addField } = fieldArray;

  return (
    <Card
      sx={(theme) => ({
        p: cardPadding,
      })}
      key={"embed" + embedIndex}
    >
      <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
        <MDButton
          size="small"
          onClick={() =>
            setCollapse((prevState) => {
              const newState = { ...prevState };
              newState.collapsed = !newState?.collapsed;
              return newState;
            })
          }
          variant="gradient"
          color="info"
          iconOnly
          sx={{ float: "left", mr: 2 }}
        >
          <FontAwesomeIcon
            icon={faAngleRight}
            style={{
              transform: collapse?.collapsed ? "rotate(0)" : "rotate(90deg)",
              transition: "transform .5s",
            }}
          />
        </MDButton>
        {embedIndex + 1}. Embed
        <MDBox sx={{ float: "right" }} display="flex" flexDirection="row" gap={1}>
          <MDButton
            color="secondary"
            size="small"
            disabled={viewOnly || embedIndex === 0}
            iconOnly
            onClick={() => embedArray.swap(embedIndex, embedIndex - 1)}
          >
            <FontAwesomeIcon icon={faChevronUp} />
          </MDButton>
          <MDButton
            color="secondary"
            size="small"
            disabled={viewOnly || embedIndex === embedArray.fields.length - 1}
            iconOnly
            onClick={() => embedArray.swap(embedIndex, embedIndex + 1)}
          >
            <FontAwesomeIcon icon={faChevronDown} />
          </MDButton>
          <MDButton
            color="secondary"
            size="small"
            disabled={viewOnly || embedArray.fields.length >= 10}
            iconOnly
            onClick={() => embedArray.insert(embedIndex, embed)}
          >
            <FontAwesomeIcon icon={faClone} />
          </MDButton>
          <MDButton
            color="error"
            size="small"
            disabled={viewOnly}
            iconOnly
            onClick={() => embedArray.remove(embedIndex)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </MDButton>
        </MDBox>
      </MDTypography>
      <Collapse in={!collapse?.collapsed} unmountOnExit>
        <Grid container spacing={1} pt={2}>
          <Grid
            item
            xs={12}
            xl={6}
            order={{
              xl: 0,
            }}
          >
            <Card
              sx={(theme) => ({
                backgroundColor: theme.palette?.card?.dark,
                p: cardPadding,
                display: "flex",
                flexDirection: "column",
                gap: 1.5,
              })}
            >
              <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                Author
                <MDButton
                  size="small"
                  onClick={() =>
                    setCollapse((prevState) => {
                      const newState = { ...prevState };
                      if (!newState.author) newState.author = {};
                      newState.author.collapsed = !newState?.author?.collapsed;
                      return newState;
                    })
                  }
                  variant="gradient"
                  color="info"
                  iconOnly
                  sx={{ float: "left", mr: 2 }}
                >
                  <FontAwesomeIcon
                    icon={faAngleRight}
                    style={{
                      transform: collapse?.author?.collapsed ? "rotate(0)" : "rotate(90deg)",
                      transition: "transform .5s",
                    }}
                  />
                </MDButton>
              </MDTypography>
              <Collapse in={!collapse?.author?.collapsed} unmountOnExit>
                <MDBox display="flex" flexDirection="column" gap={1} mt={1}>
                  <FormInput
                    name={`embeds[${embedIndex}].author.name`}
                    formHandler={formHandler}
                    label="Author"
                    rules={{
                      maxLength: {
                        value: 256,
                        message: "Der Name darf maximal 256 Zeichen lang sein.",
                      },
                    }}
                    disabled={viewOnly}
                    size="small"
                  />

                  <FormInput
                    label="Url"
                    formHandler={formHandler}
                    name={`embeds[${embedIndex}].author.url`}
                    size="small"
                    disabled={viewOnly}
                  />

                  <FormInput
                    formHandler={formHandler}
                    name={`embeds[${embedIndex}].author.icon_url`}
                    label="Icon-Url"
                    size="small"
                    disabled={viewOnly}
                  />
                </MDBox>
              </Collapse>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            xl={6}
            order={{
              xl: 1,
              xs: 2,
            }}
          >
            <Card
              sx={(theme) => ({
                backgroundColor: theme.palette?.card?.dark,
                p: cardPadding,
                display: "flex",
                flexDirection: "column",
                gap: 1.5,
              })}
            >
              <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                Footer
                <MDButton
                  size="small"
                  onClick={() =>
                    setCollapse((prevState) => {
                      const newState = { ...prevState };
                      if (!newState.footer) newState.footer = {};
                      newState.footer.collapsed = !newState?.footer?.collapsed;
                      return newState;
                    })
                  }
                  variant="gradient"
                  color="info"
                  iconOnly
                  sx={{ float: "left", mr: 2 }}
                >
                  <FontAwesomeIcon
                    icon={faAngleRight}
                    style={{
                      transform: collapse?.footer?.collapsed ? "rotate(0)" : "rotate(90deg)",
                      transition: "transform .5s",
                    }}
                  />
                </MDButton>
              </MDTypography>
              <Collapse in={!collapse?.footer?.collapsed} unmountOnExit>
                <MDBox display="flex" flexDirection="column" gap={1} mt={1}>
                  <FormInput
                    formHandler={formHandler}
                    name={`embeds[${embedIndex}].footer.text`}
                    label="Footer"
                    rules={{
                      maxLength: {
                        value: 2048,
                        message: "Der Text darf maximal 2048 Zeichen lang sein.",
                      },
                    }}
                    size="small"
                    disabled={viewOnly}
                  />

                  <FormInput
                    formHandler={formHandler}
                    name={`embeds[${embedIndex}].footer.icon_url`}
                    label="Icon Url"
                    size="small"
                    disabled={viewOnly}
                  />

                  <FormInput
                    formHandler={formHandler}
                    name={`embeds[${embedIndex}].timestamp`}
                    label="Timestamp"
                    type="datetime-local"
                    size="small"
                    disabled={viewOnly}
                    InputLabelProps={{ shrink: true }}
                  />
                </MDBox>
              </Collapse>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            order={{
              xl: 2,
            }}
          >
            <Card
              sx={(theme) => ({
                backgroundColor: theme.palette?.card?.dark,
                p: cardPadding,
                display: "flex",
                flexDirection: "column",
                gap: 1.5,
              })}
            >
              <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                Body
                <MDButton
                  size="small"
                  onClick={() =>
                    setCollapse((prevState) => {
                      const newState = { ...prevState };
                      if (!newState.body) newState.body = {};
                      newState.body.collapsed = !newState?.body?.collapsed;
                      return newState;
                    })
                  }
                  variant="gradient"
                  color="info"
                  iconOnly
                  sx={{ float: "left", mr: 2 }}
                >
                  <FontAwesomeIcon
                    icon={faAngleRight}
                    style={{
                      transform: collapse?.body?.collapsed ? "rotate(0)" : "rotate(90deg)",
                      transition: "transform .5s",
                    }}
                  />
                </MDButton>
              </MDTypography>
              <Collapse in={!collapse?.body?.collapsed} unmountOnExit>
                <MDBox display="flex" flexDirection="column" gap={1} mt={1}>
                  <FormInput
                    formHandler={formHandler}
                    name={`embeds[${embedIndex}].title`}
                    label="Titel"
                    disabled={viewOnly}
                    size="small"
                    maxLength={256}
                    rules={{
                      maxLength: {
                        value: 256,
                        message: "Der Titel darf maximal 256 Zeichen lang sein.",
                      },
                    }}
                  />
                  <FormInput
                    formHandler={formHandler}
                    name={`embeds[${embedIndex}].description`}
                    label="Description"
                    size="small"
                    disabled={viewOnly}
                    sx={{ width: "100%" }}
                    minRows={4}
                    multiline
                    rules={{
                      maxLength: {
                        value: 4096,
                        message: "Die Beschreibung darf maximal 4096 Zeichen lang sein.",
                      },
                    }}
                  />
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <MDBox>
                        <FormInput
                          formHandler={formHandler}
                          name={`embeds[${embedIndex}].color`}
                          label="Color"
                          size="small"
                          disabled={viewOnly}
                          type="color"
                          sx={{ width: "100%" }}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <MDBox>
                        <FormInput
                          formHandler={formHandler}
                          name={`embeds[${embedIndex}].url`}
                          label="Url"
                          type="url"
                          disabled={viewOnly}
                          size="small"
                        />
                      </MDBox>
                    </Grid>
                  </Grid>
                  <FormInput
                    formHandler={formHandler}
                    name={`embeds[${embedIndex}].image.url`}
                    label="Image"
                    type="url"
                    size="small"
                    disabled={viewOnly}
                  />
                  <FormInput
                    formHandler={formHandler}
                    name={`embeds[${embedIndex}].thumbnail.url`}
                    label="Thumbnail"
                    type="url"
                    disabled={viewOnly}
                    size="small"
                  />
                </MDBox>
              </Collapse>
            </Card>
          </Grid>

          <Grid
            item
            xs={12}
            order={{
              xl: 3,
            }}
          >
            <Card
              sx={(theme) => ({
                backgroundColor: theme.palette?.card?.dark,
                display: "flex",
                flexDirection: "column",
                gap: 1.5,
                p: cardPadding,
              })}
            >
              <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                Fields
                <MDButton
                  size="small"
                  onClick={() =>
                    setCollapse((prevState) => {
                      const newState = { ...prevState };
                      if (!newState.fields) newState.fields = {};
                      newState.fields.collapsed = !newState?.fields?.collapsed;
                      return newState;
                    })
                  }
                  variant="gradient"
                  color="info"
                  iconOnly
                  sx={{ float: "left", mr: 2 }}
                >
                  <FontAwesomeIcon
                    icon={faAngleRight}
                    style={{
                      transform: collapse?.fields?.collapsed ? "rotate(0)" : "rotate(90deg)",
                      transition: "transform .5s",
                    }}
                  />
                </MDButton>
              </MDTypography>
              <Collapse in={!collapse?.fields?.collapsed} unmountOnExit>
                <MDBox display="flex" flexDirection="column" gap={1}>
                  <Grid container spacing={1}>
                    {/* eslint-disable-next-line no-unused-vars */}
                    {fields?.map((field, fieldIndex) => (
                      <Field
                        key={"fields" + fieldIndex}
                        formHandler={formHandler}
                        field={field}
                        fieldIndex={fieldIndex}
                        embed={embed}
                        embedIndex={embedIndex}
                        fieldArray={fieldArray}
                        viewOnly={viewOnly}
                      />
                    ))}
                  </Grid>
                  <MDButton
                    sx={{ width: "100%" }}
                    color="info"
                    variant="gradient"
                    disabled={fields?.length >= 25 || viewOnly}
                    onClick={() => {
                      addField({
                        name: "",
                        value: "",
                        inline: true,
                      });
                    }}
                  >
                    Field hinzufügen
                  </MDButton>
                </MDBox>
              </Collapse>
            </Card>
          </Grid>
        </Grid>
      </Collapse>
    </Card>
  );
}
