import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion, faHexagonCheck, faHexagonXmark } from "@fortawesome/pro-solid-svg-icons";
import MDBox from "../../../../../components/MDBox";
import backgroundImage from "../../../../../assets/images/custom/webinterface.png";
import MDTypography from "../../../../../components/MDTypography";
import breakpoints from "../../../../../assets/theme/base/breakpoints";
import apiMessages from "../../../../../apiMessages";
import { useGetModulesQuery, useUpdateModuleMutation } from "../../../../../store/modules/module-api";

function ModuleHeader({
  botId,
  serverId,
  name = "ModuleHeader",
  description = "",
  type = null,
  icon = faCircleQuestion,
  disableSwitch = false,
}) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");

  const { data: modulesData = [] } = useGetModulesQuery({ botId, guildId: serverId });
  const [updateModule, { isLoading: updateModuleIsLoading }] = useUpdateModuleMutation();

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const moduleIsDisabled = modulesData.find((module) => module.moduleType === type)?.disabled;

  const handleModuleUpdate = (event, newValue) => {
    updateModule({
      botId,
      guildId: serverId,
      module: type,
      data: { disabled: newValue === 1 },
    }).then(({ data, error }) => {
      apiMessages.showMessage({
        type: "ModuleHeader",
        title: data ? (moduleIsDisabled ? "activated" : "deactivated") : error?.data?.error || error,
      });
    });
  };

  return (
    <>
      <MDBox position="relative" mb={5}>
        <MDBox
          display="flex"
          alignItems="center"
          position="relative"
          // minHeight="18.75rem"
          minHeight="10rem"
          borderRadius="xl"
          sx={{
            backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.info.main, 0.6),
                rgba(gradients.info.state, 0.6)
              )}, url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            overflow: "hidden",
          }}
        />
        <Card
          sx={{
            position: "relative",
            mt: -6,
            mx: {
              xs: 1,
              sm: 3,
            },
            py: 2,
            px: 2,
          }}
        >
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <MDTypography variant="h5" fontWeight="medium" color="dark">
                <FontAwesomeIcon icon={icon} size="3x" color="primary" />
              </MDTypography>
            </Grid>
            <Grid item>
              <MDBox height="100%" mt={0.5} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium">
                  {name}
                </MDTypography>
                <MDTypography variant="button" color="text" fontWeight="regular">
                  {description}
                </MDTypography>
              </MDBox>
            </Grid>
            {!disableSwitch && (
              <>
                <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}>
                  <AppBar position="static">
                    <Tabs
                      orientation={tabsOrientation}
                      value={moduleIsDisabled ? 1 : 0}
                      onChange={handleModuleUpdate}
                    >
                      <Tab
                        label="Aktiviert"
                        disabled={updateModuleIsLoading}
                        icon={
                          <FontAwesomeIcon
                            icon={faHexagonCheck}
                            fade={updateModuleIsLoading && moduleIsDisabled}
                          />
                        }
                      />
                      <Tab
                        label="Deaktiviert"
                        disabled={updateModuleIsLoading}
                        icon={
                          <FontAwesomeIcon
                            icon={faHexagonXmark}
                            fade={updateModuleIsLoading && !moduleIsDisabled}
                          />
                        }
                      />
                    </Tabs>
                  </AppBar>
                </Grid>
              </>
            )}
          </Grid>
        </Card>
      </MDBox>
    </>
  );
}

ModuleHeader.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.objectOf(PropTypes.any),
  botId: PropTypes.string.isRequired,
  serverId: PropTypes.string.isRequired,
  disableSwitch: PropTypes.bool,
};
export default ModuleHeader;
