/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components

// Material Dashboard 2 PRO React components

// Material Dashboard 2 PRO React imageExample components

// WelcomeLeave page components
// Images
import {useMemo} from "react";
import {Skeleton} from "@mui/material";
import {faSpinnerThird} from "@fortawesome/pro-duotone-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/pro-solid-svg-icons";
import MDButton from "../../../../../components/MDButton";
import apiErrorMessages from "../../../../../apiMessages";
import ReloadTable from "../../../../../components/Table/ReloadTable";
import {useGetRolesQuery} from "../../../../../store/guild/guild-api";
import {useSelector} from "react-redux";
import {useDeleteModuleDataMutation} from "../../../../../store/modules/module-api";

const plugin = "game";

export function GameRoleTable({ botId, guildId }) {
  const { data: roleData = [], isLoading: rolesIsLoading } = useGetRolesQuery({ botId, guildId });
  const [deleteModuleData, { isLoading: deleteModuleDataIsLoading }] = useDeleteModuleDataMutation({});

  const expertMode = useSelector((state) => state.ui.expertMode);

  const deleteDataEvent = (id) => {
    deleteModuleData({ botId, guildId, plugin, isModule: false, isPlugin: true, id }).then(
      ({ data, error }) => {
        apiErrorMessages.showMessage({
          type: "GameRole",
          title: data ? "deleted" : error?.data?.error || error,
        });
      }
    );
  };

  const tableColumns = useMemo(
    () =>
      [
        expertMode
          ? {
              Header: "Role Id",
              accessor: "roleId",
              width: "7%",
            }
          : null,
        {
          Header: "Role Name",
          accessor: "roleName",
        },
        {
          Header: "Name",
          accessor: "name",
        },
        {
          Header: "Actions",
          accessor: "actions",
          disableSort: true,
          width: "0%",
        },
      ].filter((x) => x),
    [expertMode]
  );

  const tableRows = useMemo(
    () => (row) => ({
      roleId: row.roleId,
      roleName: roleData?.find((r) => r.id === row.roleId)?.name || <i>Name nicht gefunden</i>,
      name: row.name,
      actions: (
        <MDButton
          variant="contained"
          iconOnly
          disabled={deleteModuleDataIsLoading}
          color="primary"
          onClick={() => deleteDataEvent(row.id)}
        >
          {deleteModuleDataIsLoading ? (
            <FontAwesomeIcon icon={faSpinnerThird} size="lg" spin />
          ) : (
            <FontAwesomeIcon icon={faTrash} size="lg" />
          )}
        </MDButton>
      ),
    }),
    [roleData, deleteModuleDataIsLoading]
  );

  return (
    <ReloadTable
      path={{
        botId,
        guildId,
        plugin,
        isModule: false,
        isPlugin: true,
      }}
      loaded={!rolesIsLoading}
      rowsLoad={() => ({
        roleId: <Skeleton width={100} animation="wave" />,
        roleName: <Skeleton width={100} animation="wave" />,
        actions: <Skeleton width={100} animation="wave" />,
      })}
      columns={tableColumns}
      rows={tableRows}
      canSearch
      showTotalEntries
    />
  );
}
