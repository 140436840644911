import { useMemo, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-table components
import { useAsyncDebounce, useGlobalFilter, usePagination, useSortBy, useTable } from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";

// Material Dashboard 2 PRO React imageExample components
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";

function SimpleTable({
  entriesPerPage = { defaultValue: 10, entries: [2, 5, 10, 15, 20, 25] },
  canSearch = false,
  showTotalEntries = false,
  pagination = { variant: "gradient", color: "info" },
  isSorted = true,
  noEndBorder = false,
  initialState = { pageIndex: 0 },
  setPageData = () => {},
  pageData = {},
  pageSizes = [5, 10, 15, 20, 25],
  defaultPageSizes = [5, 10, 15, 20, 25],
  loaded = true,
  columns: defaultColumns,
  rows: defaultRows,
  refreshButton = <></>,
  headerPadding = 0,
}) {
  const tablePageSizes = defaultPageSizes || [1, 2, 5, 10, 15, 20, 25];

  const columns = useMemo(() => defaultColumns || [], [defaultColumns]);
  const data = useMemo(() => defaultRows || [], [defaultRows]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 20 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    rows,
    setGlobalFilter,
    state: { globalFilter },
  } = tableInstance;

  const [search, setSearch] = useState(globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (column.disableSort) return false;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asc";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  return (
    <TableContainer sx={{ boxShadow: "none" }}>
      {(entriesPerPage || canSearch) && (
        <MDBox display="flex" justifyContent="space-between" alignItems="center" py={3} px={headerPadding}>
          {entriesPerPage && (
            <MDBox display="flex" alignItems="center">
              {refreshButton}
              <Autocomplete
                disableClearable
                disabled={!loaded}
                value={pageData.size || 10}
                options={tablePageSizes}
                onChange={(event, newValue) => {
                  setPageData({
                    ...pageData,
                    size: Number(newValue),
                  });
                }}
                getOptionLabel={(option) => option.toString()}
                size="small"
                sx={{ width: "5rem" }}
                renderInput={(params) => <MDInput {...params} />}
              />
              <MDTypography variant="caption" color="secondary">
                &nbsp; Einträge pro Seite
              </MDTypography>
            </MDBox>
          )}
          {canSearch && (
            <MDBox width="12rem" ml="auto">
              <MDInput
                placeholder="Suchen..."
                value={search}
                size="small"
                fullWidth
                onChange={({ currentTarget }) => {
                  setSearch(search);
                  onSearchChange(currentTarget.value);
                }}
              />
            </MDBox>
          )}
        </MDBox>
      )}
      <div style={{ overflowX: "auto" }}>
        <Table {...getTableProps()}>
          <MDBox component="thead">
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <DataTableHeadCell
                    {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                    width={column.width ? column.width : "auto"}
                    align={column.align ? column.align : "left"}
                    sorted={setSortedValue(column)}
                  >
                    {column.render("Header")}
                  </DataTableHeadCell>
                ))}
              </TableRow>
            ))}
          </MDBox>
          <TableBody {...getTableBodyProps()}>
            {page.map((row, key) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <DataTableBodyCell
                      noBorder={noEndBorder && rows.length - 1 === key}
                      align={cell.column.align ? cell.column.align : "left"}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </DataTableBodyCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {loaded && !rows.length && (
          <MDBox p={3} textAlign="center">
            <MDTypography variant="h6">Keine Einträge gefunden</MDTypography>
          </MDBox>
        )}
      </div>

      <MDBox
        display="flex"
        flexDirection={{ xs: "column", lg: "row" }}
        justifyContent="space-between"
        gap={2}
        alignItems={{ xs: "center" }}
        p={!showTotalEntries && pageData.totalPages === 1 ? 0 : 3}
      >
        {showTotalEntries && (
          <MDBox mb={{ xs: 3, sm: 0 }}>
            <MDTypography variant="button" color="secondary" fontWeight="regular">
              Zeige {pageData.number * pageData.size + 1} bis{" "}
              {pageData.number * pageData.size + pageData.size} von {pageData.totalElements} Einträgen
            </MDTypography>
          </MDBox>
        )}
        {pageData.totalPages > 1 && (
          <MDPagination
            variant={pagination.variant ? pagination.variant : "gradient"}
            color={pagination.color ? pagination.color : "info"}
          >
            <MDPagination
              item
              disabled={pageData.first || !loaded}
              onClick={() => setPageData({ ...pageData, page: pageData.number - 1 })}
            >
              <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
            </MDPagination>
            {pageData.totalPages > 6 ? (
              <MDBox width="5rem" mx={1}>
                <MDInput
                  inputProps={{ type: "number", min: 1, max: pageData.totalPages || 1 }}
                  value={pageData.number + 1}
                  onChange={(e) => {
                    setPageData({ ...pageData, page: e.target.value - 1 });
                  }}
                />
              </MDBox>
            ) : (
              [...Array(pageData.totalPages).keys()].map((pageNumber, key) => (
                <MDPagination
                  item
                  key={pageNumber}
                  onClick={() => setPageData({ ...pageData, page: pageNumber })}
                  active={pageData?.number === pageNumber}
                  disabled={!loaded}
                >
                  {pageNumber + 1}
                </MDPagination>
              ))
            )}
            <MDPagination
              item
              onClick={() => setPageData({ ...pageData, page: pageData.number + 1 })}
              disabled={pageData.last || !loaded}
            >
              <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
            </MDPagination>
          </MDPagination>
        )}
      </MDBox>
    </TableContainer>
  );
}

// Typechecking props for the SimpleTable
SimpleTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark", "light"]),
  }),
  initialState: PropTypes.objectOf(PropTypes.any),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  pageSizes: PropTypes.arrayOf(PropTypes.number),
  pageData: PropTypes.objectOf(PropTypes.any),
  setPageData: PropTypes.func,
  loaded: PropTypes.bool,
};

export default SimpleTable;
