// @mui material components
import Grid from "@mui/material/Grid";

import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";
import DashboardLayout from "../../../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../../../examples/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import { faMessageSmile } from "@fortawesome/pro-duotone-svg-icons";
import { useParams } from "react-router-dom";
import FooterSimple from "../../../../../components/Footer/FooterSimple";
import ModuleHeader from "../components/ModuleHeader";
import Error404Modal from "../../../../../components/Dialog/Error404Modal";
import { HowItWorks } from "../components/HowItWorks";
import { AutoReactionTable } from "./AutoReactionTable";
import { AutoReactionAddForm } from "./AutoReactionAddForm";

const HelpContent = [
  {
    title: "Wozu brauche Ich das?",
    type: "text",

    description: `AutoReaction kann vielfältig genutzt werden, um beispielsweise bestimmte Emotionen, Zustimmungen oder Reaktionen auf bestimmte Inhalte im Channel auszudrücken. Es ermöglicht eine automatisierte Interaktion mit den Nachrichten, ohne dass Benutzer manuell Emojis hinzufügen müssen.`,
  },
  {
    type: "text",

    title: "Tipps & Tricks",
    description: `Wenn du ein Abstimmungs/Vote-System implementieren möchtest kannst du auch ein unserer anderen Module ausprobieren. Außerdem unterstützt der Bot auch ein Vorschlagsystem. Wenn du mehr darüber erfahren möchtest kannst du dir die anderen Module anschauen.`,
  },
  {
    title: "Was muss angegeben werden?",
    type: "table",

    params: [
      {
        name: "Channel",
        description: `Du musst einen Channel auswählen in welchem die Reaktionen hinzugefügt werden sollen.`,
      },
      {
        name: "Emoji",
        description: `Welches Emoji soll hinzugefügt werden?`,
      },
    ],
  },

  {
    title: "Bilder",
    type: "image",
    images: [
      {
        src: "https://zyonic-public-file-access.s3.eu-central-1.amazonaws.com/images/panel/modules/module_autoreaction.gif",
        alt: "AutoReaction in Discord",
      },
    ],
  },
];

function AutoReaction() {
  const { botId, serverId } = useParams();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Error404Modal />
      <MDBox mb={2} />
      <ModuleHeader
        name="AutoReaction"
        type="AUTO_REACTION"
        description="Füge Emojis zu jeder Nachricht in einem Channel hinzu."
        botId={botId}
        serverId={serverId}
        icon={faMessageSmile}
      />
      <MDBox mt={5} mb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <HowItWorks items={HelpContent} />
          </Grid>
          <Grid item xs={12} md={6} lg={7} xl={8}>
            <Card sx={{ height: "100%" }}>
              <MDBox pt={2} px={2}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  Aktuelle AutoReactions
                </MDTypography>
              </MDBox>
              <MDBox p={2}>
                <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                  <AutoReactionTable guildId={serverId} botId={botId} />
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={5} xl={4}>
            <AutoReactionAddForm botId={botId} guildId={serverId} fullHeight />
          </Grid>
        </Grid>
      </MDBox>
      <FooterSimple />
    </DashboardLayout>
  );
}

export default AutoReaction;
