import {forwardRef} from "react";

// prop-types is a library for typechecking of props.
// @mui material components
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import {Skeleton} from "@mui/material";
import {NavLink} from "react-router-dom";
import MDBadge from "../../../components/MDBadge";

// custom styles for the DefaultItem
function defaultItemIconBox(theme, ownerState) {
  const { functions, palette, borders } = theme;
  const { color } = ownerState;

  const { pxToRem, linearGradient } = functions;
  const { gradients, dark, white } = palette;
  const { borderRadius } = borders;

  return {
    display: "grid",
    placeItems: "center",
    width: pxToRem(48),
    height: pxToRem(48),
    borderRadius: borderRadius.md,
    color: color === "light" ? dark.mian : white.main,
    background: gradients[color]
      ? linearGradient(gradients[color].main, gradients[color].state)
      : linearGradient(gradients.info.main, gradients.info.state),
  };
}

const LoadingItem = forwardRef(
  (
    {
      color,
      enabled,
      icon,
      title,
      description,
      new: news,
      beta,
      loading,
      isLink,
      href: to,
      ...rest
    },
    ref
  ) => (
    <MDBox {...rest} ref={ref} display="flex" alignItems="center">
      {/* eslint-disable-next-line no-nested-ternary */}
      {enabled ? (
        isLink ? (
          <MDBox component={NavLink} to={to} sx={(theme) => defaultItemIconBox(theme, { color })}>
            <FontAwesomeIcon icon={icon} />
          </MDBox>
        ) : (
          <MDBox sx={(theme) => defaultItemIconBox(theme, { color })}>
            <FontAwesomeIcon icon={icon} />
          </MDBox>
        )
      ) : (
        <MDBox sx={(theme) => defaultItemIconBox(theme, { color })}>
          <FontAwesomeIcon icon={icon} />
        </MDBox>
      )}
      <MDBox ml={2} mt={0.5} lineHeight={1.4} sx={{ flexGrow: 1 }}>
        <MDTypography variant="button" fontWeight="medium" sx={{ display: "flex" }}>
          {title}{" "}
          <MDBox ml="auto">
            {news && <MDBadge badgeContent="Neu" size="xs" color="light" container />}
            {beta && (
              <MDBadge badgeContent="Beta" size="xs" color="light" container sx={{ ml: 2 }} />
            )}
          </MDBox>
        </MDTypography>
        {loading ? (
          <>
            <Skeleton variant="text" animation="wave">
              <MDTypography variant="button" fontWeight="regular" color="text">
                *************
              </MDTypography>
            </Skeleton>
          </>
        ) : (
          <>
            <MDTypography variant="button" fontWeight="regular" color="text">
              {description || (
                <MDBadge container size="xs" badgeContent="Bald verfügbar" color="warning" />
              )}
            </MDTypography>
          </>
        )}
      </MDBox>
    </MDBox>
  )
);
export default LoadingItem;
