/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";

import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";
import DashboardLayout from "../../../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../../../examples/Navbars/DashboardNavbar";
import FooterSimple from "../../../../../components/Footer/FooterSimple";
import Card from "@mui/material/Card";
import { faVacuum } from "@fortawesome/pro-duotone-svg-icons";
import { useParams } from "react-router-dom";
import ModuleHeader from "../components/ModuleHeader";
import Error404Modal from "../../../../../components/Dialog/Error404Modal";
import { HowItWorks } from "../components/HowItWorks";
import AutoClearTable from "./AutoClearTable";
import { AutoClearAddForm } from "./AutoClearAddForm";
import { AutoClearHelp } from "./AutoClearHelp";

function AutoClear() {
  const { botId, serverId } = useParams();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Error404Modal />
      <MDBox mb={2} />
      <ModuleHeader
        name="AutoClear"
        type="AUTO_CLEAR"
        description="Lasse Kanäle automatisch leeren."
        botId={botId}
        serverId={serverId}
        icon={faVacuum}
      />
      <MDBox mt={5} mb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <HowItWorks items={AutoClearHelp} />
          </Grid>
          <Grid item xs={12} md={6} lg={7}>
            <Card sx={{ height: "100%" }}>
              <MDBox pt={2} px={2}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  Aktuelle AutoClear
                </MDTypography>
              </MDBox>
              <MDBox p={2}>
                <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                  <AutoClearTable botId={botId} guildId={serverId} />
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={5}>
            <AutoClearAddForm botId={botId} guildId={serverId} fullHeight />
          </Grid>
        </Grid>
      </MDBox>
      <FooterSimple />
    </DashboardLayout>
  );
}

export default AutoClear;
