import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MDBox from "../../../../../components/MDBox";
import backgroundImage from "../../../../../assets/images/custom/plugin.png";
import MDTypography from "../../../../../components/MDTypography";
import breakpoints from "../../../../../assets/theme/base/breakpoints";
import apiMessages from "../../../../../apiMessages";
import { useGetModulesQuery, useUpdateModuleMutation } from "../../../../../store/modules/module-api";
import MDBadge from "../../../../../components/MDBadge";

export function PluginHeader({ botId, serverId, name, description, type, icon, disableSwitch }) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");

  const { data: modulesData = [] } = useGetModulesQuery({ botId, guildId: serverId });
  const [updateModule, { isLoading: updateModuleIsLoading }] = useUpdateModuleMutation();

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const moduleIsDisabled = modulesData.find((module) => module.moduleType === type)?.disabled;

  const handleModuleUpdate = (event, newValue) => {
    updateModule({
      botId,
      guildId: serverId,
      module: type,
      data: { disabled: newValue === 1 },
    }).then(({ data, error }) => {
      apiMessages.showMessage({
        type: "ModuleHeader",
        title: data ? (moduleIsDisabled ? "activated" : "deactivated") : error?.data?.error || error,
      });
    });
  };

  return (
    <>
      <MDBox position="relative" mb={3}>
        <MDBox
          display="flex"
          alignItems="center"
          position="relative"
          // minHeight="18.75rem"
          minHeight={{
            xs: "5rem",
            sm: "9rem",
            lg: "12rem",
          }}
          borderRadius="xl"
          sx={{
            backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.success.main, 0.6),
                rgba(gradients.success.state, 0.6)
              )}, url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            overflow: "hidden",
          }}
        />
        <Card
          sx={{
            position: "relative",
            mt: -1,
            mx: {
              xs: 1,
              sm: 3,
            },
            py: 2,
            px: 2,
          }}
        >
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <MDTypography variant="h5" fontWeight="medium" color="success">
                <FontAwesomeIcon icon={icon} size="3x" color="primary" />
              </MDTypography>
            </Grid>
            <Grid item>
              <MDBox height="100%" mt={0.5} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium">
                  {name}
                </MDTypography>
                <MDTypography variant="button" color="text" fontWeight="regular">
                  {description}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item sx={{ ml: "auto" }}>
              <MDBadge badgeContent="Plugin" variant="gradient" size="lg" color="success" />
            </Grid>
          </Grid>
        </Card>
      </MDBox>
    </>
  );
}
