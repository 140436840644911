import Card from "@mui/material/Card";
import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";
import MDButton from "../../../../../components/MDButton";
import Grid from "@mui/material/Grid";
import { useForm, useWatch } from "react-hook-form";
import { FormCategoryAutocomplete, FormInput } from "../../../../../components/Form/FormInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import apiMessages from "../../../../../apiMessages";
import { useCreateModuleDataMutation } from "../../../../../store/modules/module-api";
import { useGetCategoriesQuery, useGetTextChannelsQuery } from "../../../../../store/guild/guild-api";
import { useSelector } from "react-redux";
import Logger from "../../../../../Logger";
import { faBroomWide } from "@fortawesome/pro-solid-svg-icons";
import { AutoCompleteChannelIcon } from "../../../../../components/Form/AutoCompleteIcon";
import { durationString } from "../../../../../Utils";

export function AutoClearAddForm({ botId, guildId, fullHeight }) {
  const [update, { isLoading }] = useCreateModuleDataMutation();
  const {
    data: textChannelData = [],
    isFetching: textChannelIsFetching,
    refetch: textChannelRefetch,
  } = useGetTextChannelsQuery({ botId, guildId });
  const {
    data: categoriesData = [],
    isFetching: categoriesIsFetching,
    refetch: categoriesRefetch,
  } = useGetCategoriesQuery({ botId, guildId });
  const expertMode = useSelector((state) => state.ui?.expertMode);

  const formHandler = useForm({
    defaultValues: {
      duration: "00:05:00",
      channelId: null,
    },
  });
  const { control, handleSubmit, reset } = formHandler;

  const onReset = () => {
    reset({
      duration: "00:05:00",
      channelId: null,
    });
  };

  const onSubmit = async (e) => {
    update({
      botId,
      guildId,
      module: "autoclear",
      data: {
        ...e,
        duration: durationString(e.duration),
      },
    }).then(({ data, error }) => {
      Logger.info("SubmitEvent", { module: "SubmitForm" }, data, error);
      apiMessages.showMessage({
        type: "AutoClear",
        title: data ? "added" : error?.data?.error || error,
      });
    });
  };

  const newGuild = useWatch({
    control,
    name: "id",
  });

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      onReset={onReset}
      style={{
        height: fullHeight ? "100%" : "auto",
      }}
    >
      <Card sx={{ height: "100%" }}>
        <MDBox pt={2} px={2}>
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            Add AutoClear
          </MDTypography>
        </MDBox>
        <MDBox p={2}>
          <Grid item xs={12}>
            <MDBox pt={2} px={2}>
              <FormCategoryAutocomplete
                name="channelId"
                label="Channel"
                labelIcon={(option) => <AutoCompleteChannelIcon option={option} />}
                additionalParameters={["type"]}
                categories={categoriesData}
                options={[...textChannelData].sort((x, y) => x.category - y.category)}
                groupByFunction="default"
                formHandler={formHandler}
                refetch={() => {
                  textChannelRefetch();
                  categoriesRefetch();
                }}
                refetching={textChannelIsFetching || categoriesIsFetching}
                rules={{
                  required: "Du musst einen Channel angeben.",
                }}
              />
            </MDBox>
            <MDBox pt={2} px={2}>
              <FormInput
                name="duration"
                type="time"
                label="Ablaufzeit"
                formHandler={formHandler}
                hideStatusIcon
                inputProps={{ step: 1, min: "00:00:01", max: "23:59:59" }}
                rules={{
                  required: "Du musst eine Zeit angeben.",
                }}
              />
            </MDBox>
          </Grid>

          <Grid item xs={12}>
            <MDBox pt={2} px={2} display="flex" gap={1}>
              <MDButton
                type="submit"
                variant="contained"
                sx={{ width: "100%" }}
                disabled={isLoading}
                color="primary"
              >
                Hinzufügen
                {isLoading && (
                  <FontAwesomeIcon icon={faSpinnerThird} size="lg" spin style={{ marginLeft: "5px" }} />
                )}
              </MDButton>
              <MDButton type="reset" color="primary" variant="outlined" iconOnly>
                <FontAwesomeIcon icon={faBroomWide} size="lg" />
              </MDButton>
            </MDBox>
          </Grid>
        </MDBox>
      </Card>
    </form>
  );
}
