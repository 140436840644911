import { useForm } from "react-hook-form";
import {
  useGetCategoriesQuery,
  useGetGuildQuery,
  useGetTextChannelsQuery,
  useUpdateGuildMutation,
} from "../../../../../store/guild/guild-api";
import Logger from "../../../../../Logger";
import apiMessages from "../../../../../apiMessages";
import MDBox from "../../../../../components/MDBox";
import { FormCategoryAutocomplete } from "../../../../../components/Form/FormInput";
import MDButton from "../../../../../components/MDButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { faBroomWide } from "@fortawesome/pro-solid-svg-icons";
import { useEffect } from "react";
import MDTypography from "../../../../../components/MDTypography";
import Card from "@mui/material/Card";
import { AutoCompleteChannelIcon } from "../../../../../components/Form/AutoCompleteIcon";

export function DefaultChannelForm({ botId, guildId }) {
  const [update, { isLoading }] = useUpdateGuildMutation();

  const formHandler = useForm({
    defaultValues: {
      logsChannel: "",
    },
  });
  const { control, handleSubmit, reset, setValue } = formHandler;

  const {
    data: textChannelData = [],
    isLoading: textChannelsIsLoading,
    isFetching: textChannelIsFetching,
    refetch: textChannelRefetch,
  } = useGetTextChannelsQuery({
    botId,
    guildId,
  });

  const {
    data: categoriesData = [],
    isFetching: categoriesIsFetching,
    refetch: categoriesRefetch,
  } = useGetCategoriesQuery({
    botId,
    guildId,
  });
  const {
    data: guildData = {},
    isLoading: guildIsLoading,
    isFetching: guildIsFetching,
    refetch: guildRefetch,
  } = useGetGuildQuery({
    botId,
    guildId,
  });

  const onSubmit = async (e) => {
    update({
      botId,
      guildId,
      data: {
        ...guildData,
        ...e,
      },
    }).then(({ data, error }) => {
      Logger.info("SubmitEvent", { module: "SubmitForm" }, data, error);
      apiMessages.showMessage({
        type: "LogSystem",
        title: data ? "defaultUpdated" : error?.data?.error || error,
      });
    });
  };

  useEffect(() => {
    setValue("logsChannel", guildData.logsChannel);
  }, [guildData]);

  const onReset = () => {
    reset({
      logsChannel: guildData.logsChannel,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} onReset={onReset}>
      <Card sx={{ height: "100%" }}>
        <MDBox pt={2} px={2}>
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            Standard Einstellungen
          </MDTypography>
        </MDBox>
        <MDBox p={2}>
          <MDBox pt={1} pb={2} px={2}>
            <FormCategoryAutocomplete
              name="logsChannel"
              label="Log Kanal"
              categories={categoriesData}
              options={[...textChannelData].sort((x, y) => x.category - y.category)}
              groupByFunction="default"
              labelIcon={(option) => <AutoCompleteChannelIcon option={option} />}
              additionalParameters={["type"]}
              formHandler={formHandler}
              refetch={() => {
                textChannelRefetch();
                categoriesRefetch();
              }}
              refetching={textChannelIsFetching || categoriesIsFetching || guildIsFetching}
            />
            <MDTypography variant="button" fontWeight="light" color="text">
              Hier werden alle aktivierten Log Nachrichten hingesendet die keinen spezifischen Logs Channel
              haben.
            </MDTypography>
          </MDBox>

          <MDBox pt={2} px={2} display="flex" gap={1}>
            <MDButton
              type="submit"
              variant="contained"
              sx={{ width: "100%" }}
              disabled={isLoading}
              color="primary"
              iconOnly
            >
              Speichern
              {isLoading && (
                <FontAwesomeIcon icon={faSpinnerThird} size="lg" spin style={{ marginLeft: "5px" }} />
              )}
            </MDButton>
            <MDButton type="reset" variant="outlined" disabled={isLoading} color="primary">
              {isLoading ? (
                <FontAwesomeIcon icon={faSpinnerThird} size="lg" spin />
              ) : (
                <FontAwesomeIcon icon={faBroomWide} size="lg" />
              )}
            </MDButton>
          </MDBox>
        </MDBox>
      </Card>
    </form>
  );
}
