import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";
import DashboardLayout from "../../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import MDBox from "../../../../components/MDBox";
import FooterSimple from "../../../../components/Footer/FooterSimple";
import MDTypography from "../../../../components/MDTypography";
import MDBadge from "../../../../components/MDBadge";
import Error404Modal from "../../../../components/Dialog/Error404Modal";

export default function PluginNotFound() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Error404Modal />
      <MDBox mb={2} />
      <MDBox my={5}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Card>
              <MDBox px={2}>
                <MDBox my={3}>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12}>
                      <MDBox display="flex" alignItems="center">
                        <MDBox mr={2}>
                          <FontAwesomeIcon icon={faQuestionCircle} size="5x" color="primary" />
                        </MDBox>
                        <MDBox lineHeight={1}>
                          <MDTypography variant="h6" fontWeight="medium">
                            Das Plugin wurde nicht gefunden
                          </MDTypography>
                          <MDBox mb={2}>
                            <MDTypography variant="button" color="text">
                              Das Plugin wurde nicht gefunden
                            </MDTypography>
                          </MDBox>
                          <MDBadge
                            variant="gradient"
                            color="error"
                            size="xs"
                            badgeContent="Not Found"
                            container
                          />
                        </MDBox>
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <FooterSimple />
    </DashboardLayout>
  );
}
