import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { faAngleUp, faEye, faRotateForward, faSync } from "@fortawesome/pro-solid-svg-icons";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse, Skeleton } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useSearchParams } from "react-router-dom";
import MDBox from "../../../../../../components/MDBox";
import MDTypography from "../../../../../../components/MDTypography";
import MDButton from "../../../../../../components/MDButton";
import TicketSystemMessage from "./TicketSystemMessage";
import DataTable from "../../../../../../examples/Tables/DataTable";
import FormField from "../../../../../old/pages/account/components/FormField";
import { useGetModuleDataQuery } from "../../../../../../store/modules/module-api";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { useSelector } from "react-redux";
import MDAlert from "../../../../../../components/MDAlert";
import { getUnifiedEmoji, isUnifiedEmoji } from "../../../../../../Utils";
import { Emoji, EmojiStyle } from "emoji-picker-react";

function TicketSystemMessages({ botId, serverId }) {
  const [loaded, setLoaded] = useState(false);
  const [tickets, setTickets] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();
  const categoryParam = searchParams.get("category") || null;
  const ticketParam = searchParams.get("ticket");

  const expertMode = useSelector((state) => state.ui.expertMode);

  const {
    data: ticketPanelData,
    isLoading: ticketPanelLoading,
    isError: ticketPanelError,
    isFetching: ticketPanelFetching,
    refetch: ticketPanelRefetch,
  } = useGetModuleDataQuery({
    botId: botId,
    guildId: serverId,
    module: "ticket/panel",
  });

  const {
    data: ticketData,
    isLoading: ticketIsLoading,
    isFetching: ticketIsFetching,
    refetch: ticketRefetch,
  } = useGetModuleDataQuery({
    botId: botId,
    guildId: serverId,
    module: `ticket/panel/${categoryParam}/ticket`,
  });

  const [ticketCategory, setTicketCategory] = useState({});

  const [opened, setOpened] = useState(true);

  return (
    <>
      <Grid item xs={12}>
        <Card sx={{ height: "100%" }}>
          <MDBox p={2}>
            <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
              <Autocomplete
                placeholder="Select a category"
                onChange={(e, newData) => {
                  if (newData) searchParams.set("category", newData?.id);
                  else searchParams.delete("category");

                  setSearchParams(searchParams);
                }}
                isOptionEqualToValue={(option, value) => option.id?.toString() === value.id?.toString()}
                value={
                  (categoryParam &&
                    ticketPanelData
                      ?.map((ticketCategory) => ({
                        name: ticketCategory.name,
                        id: ticketCategory.id,
                        emoji: ticketCategory.emoji,
                      }))
                      .find((tc) => tc.id?.toString() === categoryParam)) ||
                  null
                }
                getOptionLabel={(option) => `${option.name}${expertMode ? ` - ${option.id}` : ""}`}
                noOptionsText={
                  <>
                    <MDButton
                      variant="outlined"
                      color="primary"
                      fullWidth
                      size="medium"
                      disabled={ticketPanelFetching}
                      onClick={ticketPanelRefetch}
                    >
                      {ticketPanelFetching ? (
                        <FontAwesomeIcon icon={faSpinnerThird} size="lg" spin fade />
                      ) : (
                        <>
                          <FontAwesomeIcon size="1x" style={{ marginRight: "10px" }} icon={faRotateForward} />
                          Klicke hier zum erneuten Laden
                        </>
                      )}
                    </MDButton>
                  </>
                }
                disabled={ticketPanelLoading || ticketPanelFetching}
                options={ticketPanelData?.map((ticketCategory) => ({
                  name: ticketCategory.name,
                  id: ticketCategory.id,
                  emoji: ticketCategory.emoji,
                }))}
                renderOption={(optionProps, option) => (
                  <li {...optionProps} key={option.id}>
                    <MDBox mr={1} display="flex" alignItems="center">
                      {isUnifiedEmoji(getUnifiedEmoji(option.emoji)) ? (
                        <Emoji
                          unified={getUnifiedEmoji(option.emoji)}
                          emojiStyle={EmojiStyle.TWITTER}
                          size={17.5}
                        />
                      ) : (
                        <img
                          alt={"Panel Icon " + option.emoji}
                          loading="lazy"
                          width="auto"
                          height="17.5px"
                          src={`https://cdn.discordapp.com/emojis/${option.emoji}.webp?size=96&quality=lossless`}
                        />
                      )}
                    </MDBox>
                    {`${option.name}${expertMode ? ` - ${option.id}` : ""}`}
                  </li>
                )}
                renderInput={(params) => (
                  <FormField
                    {...params}
                    variant="outlined"
                    label="Ticket Category"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </MDTypography>
          </MDBox>
        </Card>
      </Grid>

      <Grid item md={opened ? 4 : 12} xl={opened ? 3 : 12} xs={12}>
        <Card sx={{ height: "100%" }}>
          <MDBox p={2}>
            <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize" verticalAlign="middle">
              Tickets - {ticketPanelData?.find((tc) => tc.id.toString() === categoryParam)?.name}
              <MDBox
                display="flex"
                gap={1}
                justifyContent="flex-end"
                sx={{
                  float: "right",
                }}
              >
                <MDButton size="small" onClick={ticketRefetch} variant="gradient" color="primary" iconOnly>
                  <FontAwesomeIcon icon={faSync} spin={ticketIsFetching} />
                </MDButton>
                <MDButton
                  size="small"
                  onClick={() => setOpened(!opened)}
                  variant="gradient"
                  color="primary"
                  iconOnly
                >
                  <FontAwesomeIcon
                    icon={faAngleUp}
                    style={{
                      transform: opened ? "rotate(0)" : "rotate(-180deg)",
                      transition: "transform .5s",
                    }}
                  />
                </MDButton>
              </MDBox>
            </MDTypography>

            <Collapse in={opened} unmountOnExit>
              <DataTable
                showTotalEntries={false}
                entriesPerPage={false}
                table={{
                  columns: [
                    { Header: "", accessor: "actions", width: "0px" },

                    {
                      Header: "Name",
                      accessor: "name",
                    },
                    expertMode
                      ? {
                          Header: "Id",
                          accessor: "id",
                        }
                      : null,
                  ].filter((c) => c),
                  rows: !ticketIsLoading
                    ? ticketData?.map((_ticket) => ({
                        id: _ticket.id.toString(),
                        name: <>{_ticket.name || <i>Name nicht gefunden</i>}</>,
                        actions: (
                          <MDButton
                            size="small"
                            variant="gradient"
                            iconOnly
                            color={
                              searchParams.get("ticket") === _ticket.id.toString() ? "primary" : "secondary"
                            }
                            onClick={() => {
                              searchParams.set("ticket", _ticket.id.toString());
                              setSearchParams(searchParams);
                            }}
                          >
                            <FontAwesomeIcon icon={faEye} />
                          </MDButton>
                        ),
                      }))
                    : [1, 2, 3, 4, 5].map(() => ({
                        id: <Skeleton width={100} animation="wave" />,
                        name: <Skeleton width={100} animation="wave" />,
                      })),
                }}
              />
            </Collapse>
          </MDBox>
        </Card>
      </Grid>
      <Grid item md={opened ? 8 : 12} xl={opened ? 9 : 12} xs={12}>
        <Card sx={{ height: "100%" }}>
          <MDBox p={2}>
            <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
              <Autocomplete
                size="small"
                onChange={(e, newData) => {
                  if (newData) searchParams.set("ticket", newData?.id?.toString());
                  else searchParams.delete("ticket");
                  setSearchParams(searchParams);
                }}
                isOptionEqualToValue={(option, value) => option.id?.toString() === value?.id?.toString()}
                getOptionLabel={(option) => `${option.name}${expertMode ? ` - ${option.id}` : ""}`}
                value={
                  (ticketParam &&
                    ticketData
                      ?.map((channelCategory) => ({
                        name: channelCategory.name,
                        id: channelCategory.id,
                      }))
                      .find((tc) => tc.id?.toString() === ticketParam)) ||
                  null
                }
                disabled={ticketIsLoading || !categoryParam}
                options={ticketData?.map((channelCategory) => ({
                  name: channelCategory.name,
                  id: channelCategory.id,
                }))}
                noOptionsText={
                  <>
                    <MDButton
                      variant="outlined"
                      color="primary"
                      fullWidth
                      size="medium"
                      disabled={ticketIsFetching}
                      onClick={ticketRefetch}
                    >
                      {ticketIsFetching ? (
                        <FontAwesomeIcon icon={faSpinnerThird} size="lg" spin fade />
                      ) : (
                        <>
                          <FontAwesomeIcon size="1x" style={{ marginRight: "10px" }} icon={faRotateForward} />
                          Klicke hier zum erneuten Laden
                        </>
                      )}
                    </MDButton>
                  </>
                }
                renderInput={(params) => (
                  <FormField
                    {...params}
                    variant="outlined"
                    label="Ticket"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </MDTypography>
          </MDBox>

          {!categoryParam && (
            <MDBox px={2}>
              <MDAlert color="info">
                <MDTypography variant="body2">Du musst eine Kategorie auswählen.</MDTypography>
              </MDAlert>
            </MDBox>
          )}
          <TicketSystemMessage
            ticket={ticketData?.find((t) => t.id.toString() === ticketParam?.toString())}
            serverId={serverId}
            botId={botId}
          />
        </Card>
      </Grid>
    </>
  );
}

export default TicketSystemMessages;
