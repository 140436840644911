// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React imageExample components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FooterSimple from "../../components/Footer/FooterSimple";

import Card from "@mui/material/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRobot, faTicket } from "@fortawesome/pro-solid-svg-icons";
import backgroundImage from "../../assets/images/bg-profile.jpeg";
import Error404Modal from "../../components/Dialog/Error404Modal";
import MDButton from "../../components/MDButton";
import MDAlert from "../../components/MDAlert";
import InfoCard from "../../components/Card/InfoCard";
import { SupportPinCreateForm } from "./SupportPinCreateForm";

function SupportPinJoin() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Error404Modal />
      <MDBox mb={2} />
      <MDBox position="relative" mb={5}>
        <MDBox
          display="flex"
          alignItems="center"
          position="relative"
          minHeight="15rem"
          borderRadius="xl"
          sx={{
            backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.info.main, 0.6),
                rgba(gradients.info.state, 0.6)
              )}, url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            overflow: "hidden",
          }}
        />

        <Card
          sx={{
            position: "relative",
            mt: -5,
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <MDTypography variant="h5" fontWeight="medium">
                <FontAwesomeIcon icon={faRobot} size="2x" />
              </MDTypography>
            </Grid>
            <Grid item>
              <MDBox height="100%" mt={0.5} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium" sx={{ width: "100%" }}>
                  Support Pin
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        </Card>
      </MDBox>

      <MDBox mt={5} mb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={2} px={2}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  Tools für Supporter
                </MDTypography>
              </MDBox>
              <MDBox p={2}>
                <MDAlert color="info" sx={{ mb: 2 }}>
                  Tools werden bald verfügbar sein.
                </MDAlert>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={4}>
                    <InfoCard icon={faTicket} title="Tickets" description="Offene Tickets" value="0" />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox
                      py={1}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <MDButton variant="gradient" color="info" disabled>
                        Dokumentation für Supporter
                      </MDButton>
                      <MDButton variant="gradient" color="info" disabled>
                        Dokumentation zum Webinterface
                      </MDButton>
                      <MDButton variant="gradient" color="info" disabled>
                        Allgemeine Dokumentation
                      </MDButton>
                      <MDButton variant="gradient" color="info" disabled>
                        Allgemeine Regeln
                      </MDButton>
                      <MDButton variant="gradient" color="info" disabled>
                        Discord Einladung
                      </MDButton>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <InfoCard
                      icon={faTicket}
                      title="Supporter Online"
                      description="Anzahl der Supporter"
                      value="1"
                    />
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ height: "100%" }}>
              <MDBox pt={2} px={2}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  Regeln
                </MDTypography>
              </MDBox>
              <MDBox p={2} pl={4}>
                <MDTypography variant="button" fontWeight="light" sx={{ textAlign: "justify" }}>
                  <ol>
                    <li>
                      Höflichkeit: Sei immer höflich und respektvoll gegenüber dem Kunden, unabhängig von
                      dessen Anfrage oder Problem.
                    </li>
                    <li>
                      Aufmerksamkeit: Höre aufmerksam zu und stelle sicher, dass du das Problem des Kunden
                      vollständig verstehst, bevor du versuchst, es zu lösen.
                    </li>
                    <li>
                      Zeitmanagement: Versuche, das Problem des Kunden so schnell wie möglich zu lösen und gib
                      regelmäßig Rückmeldungen über den Fortschritt.
                    </li>
                    <li>
                      Kompetenz: Stelle sicher, dass du das notwendige Wissen und die Fähigkeiten besitzt, um
                      das Problem des Kunden zu lösen. Falls nicht, suche nach jemandem, der dir helfen kann
                      oder informiere den Kunden darüber, dass du das Problem nicht lösen kannst.
                    </li>
                    <li>
                      Datenschutz: Handle immer im Einklang mit den Datenschutzrichtlinien und gib keine
                      persönlichen Informationen des Kunden ohne dessen Einverständnis weiter.
                    </li>
                    <li>
                      Professionalität: Verwende eine angemessene Sprache und vermeide unangemessenen Inhalt.
                    </li>
                    <li>
                      Nachverfolgung: Stelle sicher, dass das Problem des Kunden vollständig gelöst wurde und
                      frage nach Feedback.
                    </li>
                  </ol>
                </MDTypography>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <SupportPinCreateForm />
            <Card sx={{ mt: 1 }}>
              <MDBox pt={2} px={2}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  Wichtige Hinweise
                </MDTypography>
              </MDBox>
              <MDBox p={2} pl={4}>
                <MDTypography variant="button" fontWeight="light" sx={{ textAlign: "justify" }}>
                  <ol>
                    <li>
                      Anweisungen zum Umgang mit Fragestellungen: Wenn Sie Fragen haben, wenden Sie sich bitte
                      an ein höheres Teammitglied. Dies gilt insbesondere dann, wenn Sie sich unsicher sind.
                    </li>
                    <li>
                      Empfehlung zum Nachfragen: Wenn Sie unsicher sind, empfehlen wir Ihnen, lieber
                      nachzufragen, anstatt eine falsche Entscheidung zu treffen.
                    </li>
                    <li>
                      Zusammenfassung: Das Ziel dieser Anweisungen ist es, sicherzustellen, dass das Team gut
                      informiert ist und dass Entscheidungen auf korrekten Informationen basieren. Wenden Sie
                      sich bei Fragen an ein höheres Teammitglied und zögern Sie nicht, nachzufragen, wenn Sie
                      unsicher sind.
                    </li>
                  </ol>
                </MDTypography>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <FooterSimple />
    </DashboardLayout>
  );
}

export default SupportPinJoin;
