import Card from "@mui/material/Card";
import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";
import MDButton from "../../../../../components/MDButton";
import Grid from "@mui/material/Grid";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import apiMessages from "../../../../../apiMessages";
import { useCreateModuleDataMutation } from "../../../../../store/modules/module-api";
import { useGetCategoriesQuery, useGetTextChannelsQuery } from "../../../../../store/guild/guild-api";
import Logger from "../../../../../Logger";
import { useSelector } from "react-redux";
import { GiveawayForm } from "./GiveawayForm";

// TODO - not finished
export function GiveawayAddForm({ botId, guildId, fullHeight }) {
  const darkMode = useSelector((state) => state.ui.darkMode);
  const [update, { isLoading }] = useCreateModuleDataMutation();

  const formHandler = useForm({
    defaultValues: {
      channelId: "",
      winnerNumber: 1,
      prize: "",
      endTime: "",
    },
  });
  const { control, handleSubmit, setValue } = formHandler;

  const onSubmit = async (e) => {
    update({
      botId,
      guildId,
      module: "giveaway",
      data: {
        ...e,
        expiresAt: e.expiresAt ? new Date(e.expiresAt).getTime() : null,
      },
    }).then(({ data, error }) => {
      Logger.info("SubmitEvent", { module: "SubmitForm" }, data, error);
      apiMessages.showMessage({
        type: "Giveaway",
        title: data ? "added" : error?.data?.error || error,
      });
    });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        height: fullHeight ? "100%" : "auto",
      }}
    >
      <Card sx={{ height: "100%" }}>
        <MDBox pt={2} px={2}>
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            Gewinnspiel hinzufügen
          </MDTypography>
        </MDBox>
        <MDBox p={2}>
          <GiveawayForm formHandler={formHandler} botId={botId} guildId={guildId} />

          <Grid item xs={12}>
            <MDBox pt={2} px={2}>
              <MDButton
                type="submit"
                variant="contained"
                sx={{ width: "100%" }}
                disabled={isLoading}
                color="primary"
              >
                Hinzufügen
                {isLoading && (
                  <FontAwesomeIcon icon={faSpinnerThird} size="lg" spin style={{ marginLeft: "5px" }} />
                )}
              </MDButton>
            </MDBox>
          </Grid>
        </MDBox>
      </Card>
    </form>
  );
}
