import Card from "@mui/material/Card";
import { Skeleton } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { useGetGuildQuery, useUpdateGuildMutation } from "../../../../store/guild/guild-api";
import MDTypography from "../../../../components/MDTypography";
import MDBox from "../../../../components/MDBox";
import { FormAutocomplete, FormInput } from "../../../../components/Form/FormInput";
import MDButton from "../../../../components/MDButton";
import MDInput from "../../../../components/MDInput";
import apiMessages from "../../../../apiMessages";

const languages = [
  {
    value: "English",
    label: "Englisch",
  },
  { value: "German", label: "Deutsch" },
];

export function GuildUpdateForm({ botId, guildId }) {
  const { data: guildData = {}, isLoading: guildIsLoading } = useGetGuildQuery({ botId, guildId });
  const [updateGuild, { isLoading: updateGuildIsLoading }] = useUpdateGuildMutation();

  const formHandler = useForm({
    defaultValues: {
      nickname: "",
      language: "",
    },
  });

  const { handleSubmit, setValue } = formHandler;

  useEffect(() => {
    if (!guildData) return;
    setValue("nickname", guildData.nickname || "");
    setValue("language", guildData.language);
  }, [guildData]);

  const onSubmit = async (e) => {
    updateGuild({
      botId,
      guildId,
      data: {
        ...guildData,
        ...e,
      },
    }).then(({ data, error }) => {
      apiMessages.showMessage({
        type: "ServerOverview",
        title: data ? "updated" : error?.data?.error || error,
      });
    });
  };

  if (guildIsLoading)
    return (
      <>
        <MDBox pt={1} pb={2} px={2}>
          {[1, 2, 3, 4].map((_, key) => (
            <Skeleton
              variant="rectangular"
              key={key}
              width="100%"
              animation="wave"
              style={{ borderRadius: "0.375rem", marginBottom: "1rem" }}
            >
              <MDInput />
            </Skeleton>
          ))}
        </MDBox>
        <MDBox pt={1} pb={2} px={2}>
          <Grid container spacing={1}>
            {[1, 2, 3].map((_, key) => (
              <Grid item xs={4} key={key}>
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  animation="wave"
                  style={{ borderRadius: "0.375rem", marginBottom: "1rem" }}
                >
                  <MDInput />
                </Skeleton>
              </Grid>
            ))}
          </Grid>
        </MDBox>
      </>
    );

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        height: "100%",
      }}
    >
      <Card sx={{ boxShadow: "none", height: "100%" }}>
        <MDBox p={2}>
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            Settings
          </MDTypography>
        </MDBox>

        <MDBox pt={1} pb={2} px={2}>
          <FormInput name="nickname" label="Nickname" formHandler={formHandler} />
        </MDBox>

        <MDBox pt={1} pb={2} px={2}>
          <FormAutocomplete
            label="Sprache"
            name="language"
            formHandler={formHandler}
            options={languages}
            rules={{
              required: "Du musst eine Sprache auswählen.",
            }}
          />
        </MDBox>

        <MDBox
          pb={2}
          px={2}
          sx={{
            marginTop: "auto",
            width: "100%",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MDButton
                variant="contained"
                sx={{ width: "100%" }}
                color="primary"
                type="submit"
                disabled={guildIsLoading || updateGuildIsLoading}
              >
                Speichern
                {updateGuildIsLoading && (
                  <FontAwesomeIcon icon={faSpinnerThird} spin style={{ marginLeft: "5px" }} />
                )}
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    </form>
  );
}
