import MDAvatar from "../MDAvatar";
import SidenavCollapse from "../../examples/Sidenav/SidenavCollapse";
import { useGetAccountQuery, useGetAccountRolesQuery } from "../../store/auth/auth-api";
import MDBox from "../MDBox";
import { NavLink, useLocation } from "react-router-dom";
import SidenavItem from "../../examples/Sidenav/SidenavItem";
import { useState } from "react";
import Divider from "@mui/material/Divider";
import { useSelector } from "react-redux";
import { faShield, faSignOut, faUser, faUserHeadset } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SidenavTitle } from "./SidenavUtils";
import { Skeleton } from "@mui/material";
import { FallbackComponent } from "../ErrorBoundary/FallbackComponent";
import * as Sentry from "@sentry/react";
import * as Utils from "../../Utils";

export function UserRoute({ search }) {
  const { data: userData, isLoading: userIsLoading } = useGetAccountQuery();
  const { data: userRolesData = [], isLoading: userRolesIsLoading } = useGetAccountRolesQuery();
  const location = useLocation();
  const showModuleIcons = useSelector((state) => state.ui?.showModuleIcons);

  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];

  const [openCollapseArray, setOpenCollapseArray] = useState([]);

  const toggleCollapse = (key) => {
    if (openCollapseArray.includes(key)) {
      const array = [...openCollapseArray];
      array.splice(openCollapseArray.indexOf(key), 1);
      setOpenCollapseArray(array);
    } else {
      setOpenCollapseArray([...openCollapseArray, key]);
    }
  };

  const collapseIsOpen = (key) => openCollapseArray.includes(key);

  const collapseIsActive = (key) => key === collapseName;

  if (userIsLoading || userRolesIsLoading)
    return (
      <MDBox px={2}>
        <Skeleton variant="text" animation="wave" width="100%" height={75} />
      </MDBox>
    );

  const searchIncludes = (key) => key.toLowerCase().includes(search.toLowerCase());

  return (
    <Sentry.ErrorBoundary
      fallback={({ eventId, error, componentStack, resetError }) => (
        <FallbackComponent
          eventId={eventId}
          error={error}
          componentStack={componentStack}
          resetError={resetError}
          display="flex"
          flexDirection="column"
        />
      )}
    >
      <SidenavCollapse
        key="user"
        name={
          userData?.username ? (
            <abbr title={userData?.username} style={{ textDecoration: "none" }}>
              {Utils.shortString(userData?.username, 14)}
            </abbr>
          ) : (
            "User"
          )
        }
        navKey="user"
        icon={
          userData && (
            <MDAvatar
              alt={userData?.username}
              src={
                userData?.avatar
                  ? `https://cdn.discordapp.com/avatars/${userData?.id}/${userData?.avatar}.${
                      userData?.avatar?.startsWith("a_") ? "gif" : "png"
                    }`
                  : "https://cdn.discordapp.com/embed/avatars/0.png"
              }
              size="sm"
            />
          )
        }
        active={collapseIsActive("user")}
        open={collapseIsOpen("user")}
        onClick={() => toggleCollapse("user")}
      >
        {[
          {
            name: "Account",
            key: "userOverview",
            route: "/account",
            icon: <FontAwesomeIcon icon={faUser} />,
          },
          {
            name: "Settings",
            key: "userSettings",
            route: "/account/settings",
            hidden: true,
          },

          {
            name: "Abmelden",
            key: "userLogout",
            route: "/account/logout",
            icon: <FontAwesomeIcon icon={faSignOut} />,
          },
        ]
          .filter((x) => !x.hidden && searchIncludes(x.name))
          .map(({ route, name, key, icon }) => (
            <MDBox mx={2} key={key}>
              <NavLink to={route} sx={{ textDecoration: "none" }}>
                <SidenavItem
                  navKey={key}
                  name={name}
                  active={route === pathname}
                  nested
                  icon={showModuleIcons && icon}
                />
              </NavLink>
            </MDBox>
          ))}
      </SidenavCollapse>
      <Divider />
      {userRolesData?.roles?.includes("SV_TEAM") && <SidenavTitle title={"Team"} textColor={"white"} />}
      {userRolesData?.roles?.includes("SV_ADMIN") && (
        <SidenavCollapse
          key="admin"
          name="Admin"
          navKey="admin"
          icon={<FontAwesomeIcon icon={faShield} />}
          active={collapseIsActive("admin")}
          open={collapseIsOpen("admin")}
          onClick={() => toggleCollapse("admin")}
        >
          {[
            {
              name: "Bot-Liste",
              key: "adminBotList",
              route: "/admin/bot",
            },
            {
              name: "Entity-Liste",
              key: "adminEntityList",
              route: "/admin/entity",
            },
            {
              name: "Pin-Liste",
              key: "adminPinList",
              route: "/admin/pin",
              hidden: true,
            },
          ]
            .filter((x) => !x.hidden && searchIncludes(x.name))
            .map(({ route, name, key }) => (
              <MDBox mx={2} key={key}>
                <NavLink to={route} sx={{ textDecoration: "none" }}>
                  <SidenavItem navKey={key} name={name} active={route === pathname} nested />
                </NavLink>
              </MDBox>
            ))}
        </SidenavCollapse>
      )}
      {userRolesData?.roles?.includes("SV_SUPPORT") && (
        <SidenavCollapse
          key="support"
          name="Support"
          navKey="support"
          icon={<FontAwesomeIcon icon={faUserHeadset} />}
          active={collapseIsActive("support")}
          open={collapseIsOpen("support")}
          onClick={() => toggleCollapse("support")}
        >
          {[
            {
              name: "SupportPin",
              key: "supportSupportPin",
              route: "/support/join",
            },
          ]
            .filter((x) => !x.hidden && searchIncludes(x.name))
            .map(({ route, name, key }) => (
              <MDBox mx={2} key={key}>
                <NavLink to={route} sx={{ textDecoration: "none" }}>
                  <SidenavItem navKey={key} name={name} active={route === pathname} nested />
                </NavLink>
              </MDBox>
            ))}
        </SidenavCollapse>
      )}
      {userRolesData?.roles?.includes("SV_TEAM") && <Divider />}
    </Sentry.ErrorBoundary>
  );
}
