import {
  DiscordActionRow,
  DiscordAttachments,
  DiscordButton,
  DiscordEmbed,
  DiscordEmbedDescription,
  DiscordEmbedField,
  DiscordEmbedFields,
  DiscordEmbedFooter,
  DiscordHeader,
  DiscordMention,
  DiscordMessage,
  DiscordMessages,
} from "@skyra/discord-components-react";
import { calculateInlineIndex } from "./Preview";
import { Fragment } from "react";

const ComponentObject = {
  1: "ActionRow",
  2: "Button",
  3: "StringSelect",
  4: "TextInput",
  5: "UserSelect",
  6: "RoleSelect",
  7: "MentionableSelect",
  8: "ChannelSelect",
};

const ButtonStyleObject = {
  1: "primary",
  2: "secondary",
  3: "success",
  4: "destructive",
  5: "primary",
};

function splitMessage(message) {
  // Teilt die Nachricht in Text und Mentions auf
  if (!message) return [];
  return message.split(/(<@[&!]?\d+>|<#\d+>|^#{1,3}\s*.*$)/gm).filter(Boolean);
}

// Konvertiert eine einzelne Mention oder einen Textabschnitt in ein React-Element
function convertToElement(part) {
  if (/<@!?(\d+)>/.test(part)) {
    const userId = part.match(/<@!?(\d+)>/)[1];
    return <DiscordMention type="user">user-{userId}</DiscordMention>;
  }
  if (/<@&(\d+)>/.test(part)) {
    const roleId = part.match(/<@&(\d+)>/)[1];
    return (
      <DiscordMention type="role" color="#ff5555">
        role-{roleId}
      </DiscordMention>
    );
  }
  if (/<#(\d+)>/.test(part)) {
    const channelId = part.match(/<#(\d+)>/)[1];
    return <DiscordMention type="channel">channel-{channelId}</DiscordMention>;
  }
  if (/^#{1,3}\s*(.+)/.test(part)) {
    const level = part.match(/^#{1,3}/)[0].length; // Bestimmt die Überschriftenebene
    const text = part.match(/^#{1,3}\s*(.+)/)[1];
    return <DiscordHeader level={level}>{text}</DiscordHeader>;
  }

  return part;
}

const parseString = (string) => {
  const parts = splitMessage(string); // Teilt die Nachricht in Teile

  return (
    <>
      {parts.map((part, index) => (
        <Fragment key={index}>{convertToElement(part)}</Fragment>
      ))}
    </>
  );
};

const parseComponents = (components) =>
  components?.map((component) => {
    if (component.type === 1) {
      return <DiscordActionRow>{parseComponents(component.components)}</DiscordActionRow>;
    } else if (component.type === 2) {
      // TODO Schauen wie man hier custom Emojis richtig anzeigt, bzw was ich da von Spark zurück bekomme neben 'name' im 'emoji'
      return (
        <DiscordButton
          type={ButtonStyleObject[component.style] || "primary"}
          disabled={component.disabled}
          url={component.url}
          emoji={component.emoji?.url}
          emojiName={component?.emoji?.name}
        >
          {component.label}
        </DiscordButton>
      );
    } else return <></>;
  });

const parseEmbeds = (embeds) =>
  embeds?.map((embed, i) => (
    <DiscordEmbed
      key={`embed${i}`}
      slot="embeds"
      authorImage={embed.author?.icon_url}
      authorName={embed.author?.name}
      authorUrl={embed.author?.url}
      color={embed.color ? `#${Number(embed?.color).toString(16)}` : undefined}
      embedTitle={embed.title}
      image={embed.image?.url}
      thumbnail={embed.thumbnail?.url}
      url={embed.url}
    >
      <DiscordEmbedDescription slot="description">{parseString(embed.description)}</DiscordEmbedDescription>
      <DiscordEmbedFields slot="fields">
        {embed.fields?.map((field, fieldIndex) => (
          <DiscordEmbedField
            fieldTitle={field.name}
            inline={field.inline}
            inlineIndex={field.inline ? calculateInlineIndex(embed.fields, fieldIndex) : 1}
          >
            <div>{parseString(field.value)}</div>
          </DiscordEmbedField>
        ))}
      </DiscordEmbedFields>
      <DiscordEmbedFooter
        slot="footer"
        footerImage={embed.footer?.icon_url}
        timestamp={(embed.timestamp && new Date(embed.timestamp)) || null}
      >
        <div>{embed.footer?.text}</div>
      </DiscordEmbedFooter>
    </DiscordEmbed>
  ));

/**
 * This function is used to parse a message object and return a DiscordMessage component.
 * It takes three parameters: message, renderAllMessages, and darkMode.
 *
 * @param {Object} message - The message object to be parsed. It should contain the following properties:
 *   - author: An object with properties 'name' and 'avatarUrl'.
 *   - content: An object with properties 'content', 'embeds', 'components', 'createdAt', and 'editedAt'.
 * @param {boolean} [renderAllMessages=true] - A boolean indicating whether to render all messages or just one. If true, all messages are rendered. If false, only one message is rendered.
 * @param {boolean} [darkMode=true] - A boolean indicating whether to use dark mode or light mode. If true, dark mode is used. If false, light mode is used.
 *
 * @returns {JSX.Element} - A DiscordMessage component with the parsed message data.
 */
export default function parseMessage(message, renderAllMessages = true, darkMode = true) {
  const { author, content, createdAt, editedAt } = message;

  const oneMessage = (
    <DiscordMessage
      author={author.name}
      avatar={author.avatarUrl}
      edited={(editedAt && new Date(editedAt).toLocaleDateString()) || null}
      timestamp={(createdAt && new Date(createdAt).toLocaleDateString()) || null}
      style={{
        color: darkMode ? "#dcddde" : "#2e3338",
      }}
    >
      {parseString(content.content)}

      {content.embeds && parseEmbeds(content.embeds)}
      {content.components && (
        <DiscordAttachments slot="components">{parseComponents(content?.components)}</DiscordAttachments>
      )}
    </DiscordMessage>
  );

  if (renderAllMessages)
    return (
      <>
        <DiscordMessages style={{ borderRadius: "10px" }} noBackground lightTheme={!darkMode}>
          {oneMessage}
        </DiscordMessages>
      </>
    );

  return oneMessage;
}

export { parseMessage as parseDiscordMessage };
export { parseString as parseDiscordString };
export { parseComponents as parseDiscordComponents };
export { parseEmbeds as parseDiscordEmbeds };
