/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";

// Material Dashboard 2 PRO React imageExample components
import DashboardLayout from "../../../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../../../examples/Navbars/DashboardNavbar";
import FooterSimple from "../../../../../components/Footer/FooterSimple";

// WelcomeLeave page components
// Images
import Card from "@mui/material/Card";
import {useParams} from "react-router-dom";
import Error404Modal from "../../../../../components/Dialog/Error404Modal";
import {GameRoleTable} from "./GameRoleTable";
import {GameRoleAddForm} from "./GameRoleAddForm";
import {PluginHeader} from "../components/PluginHeader";
import {faPuzzlePiece} from "@fortawesome/pro-solid-svg-icons";
import {HowItWorks} from "../../modules/components/HowItWorks";

const HelpContent = [
  {
    title: "Was ist das?",
    type: "text",

    description: `Diese Funktion ermöglicht es Usern auf dem Discord Server bestimmte Rollen an Freunde zu vergeben. Das ganze funktioniert über Discord 'Apps' Actions. Klicke dazu ganz einfach auf einen Nutzer und wähle die erstellte Action aus.
    Jeder User kann diese Rolle wieder verlassen, indem er die Action bei sich selbst ausführt.
    `,
  },
  {
    title: "Warum?",
    type: "text",
    description: `Diese Funktion ist für Games gedacht, du hast zb.: einen Garrys Mod Discord Server und möchtest das User sich gegenseitig Rollen geben können. Nicht unbedingt jeder soll mitspielen können aber jedem einzeln die Rolle zu geben ist zu aufwendig. Es ist also ein 'Invite Only System'.`,
  },
  {
    title: "Was muss angegeben werden?",
    type: "table",
    params: [
      {
        name: "Rolle",
        description: `Welche Rolle soll der Benutzer vergeben können?`,
      },
      {
        name: "Name",
        description: `Wie soll die Action heißen?`,
      },
    ],
  },
  {
    title: "Besonderheiten",
    type: "text",
    description: `Ein Nutzer mit der 'ADMINISTRATOR' Berechtigung kann die Action immer ausführen. Es können nur Rollen vergeben werden welche unter der Bot Rolle sind.`,
  },
];

export default function GameRole() {
  const { botId, serverId } = useParams();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Error404Modal />
      <MDBox mb={2} />
      <PluginHeader
        name="GameRole"
        description="Mitglieder von diesen Rollen können anderen Usern diese Rollen zuweisen."
        botId={botId}
        serverId={serverId}
        icon={faPuzzlePiece}
      />
      <MDBox mt={3} mb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <HowItWorks items={HelpContent} />
          </Grid>
          <Grid item xs={12} md={6} lg={7} xl={8}>
            <Card sx={{ height: "100%" }}>
              <MDBox pt={2} px={2}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  Aktuelle GameRoles
                </MDTypography>
              </MDBox>
              <MDBox p={2}>
                <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                  <GameRoleTable guildId={serverId} botId={botId} />
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={5} xl={4}>
            <GameRoleAddForm guildId={serverId} botId={botId} fullHeight />
          </Grid>
        </Grid>
      </MDBox>
      <FooterSimple />
    </DashboardLayout>
  );
}
