import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useGetPublicModuleDataQuery } from "../../store/global/global-api";
import PageLayout from "../../examples/LayoutContainers/PageLayout";
import MDTypography from "../../components/MDTypography";
import DefaultNavbar from "../../examples/Navbars/DefaultNavbar";
import MDBox from "../../components/MDBox";
import image from "../../assets/images/bg-sign-in-cover.jpeg";
import Grid from "@mui/material/Grid";
import Footer from "../old/authentication/components/Footer";
import Card from "@mui/material/Card";
import MDAvatar from "../../components/MDAvatar";
import Container from "@mui/material/Container";
import MDAlert from "../../components/MDAlert";
import MDButton from "../../components/MDButton";
import { Collapse, Skeleton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { useMemo, useState } from "react";
import MDProgress from "../../components/MDProgress";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { useGetUserQuery } from "../../store/guild/guild-api";
import { useSelector } from "react-redux";

const User = ({ userId }) => {
  const { botId, guildId } = useParams();
  const { data: userData, isLoading: userIsLoading } = useGetUserQuery({
    botId: botId,
    guildId: guildId,
    userId: userId,
  });

  if (userIsLoading || !userData) return <MDBox>{userId}</MDBox>;

  return (
    <MDBox display="flex" alignItems="center" gap={1}>
      <MDAvatar size="xs" src={userData?.avatarUrl} alt={userData?.name} />
      <MDTypography variant="body2">{userData?.name || userId}</MDTypography>
    </MDBox>
  );
};

const AnswerCard = ({ answer, index, totalVotes, darkMode, anonymous }) => {
  const [showVotes, setShowVotes] = useState(false);

  return (
    <Card
      sx={({ palette: { white, card }, functions: { rgba } }) => ({
        p: 2,
        backgroundColor: rgba(darkMode ? card.main : white.main, 0.75),
        backdropFilter: `blur(10px)`,
      })}
    >
      <MDBox
        key={index}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexDirection={{
          xs: "column",
          lg: "row",
        }}
      >
        <MDTypography
          variant="h3"
          sx={{
            fontSize: {
              xs: "1.25rem",
              sm: "1.25rem",
              md: "2.0rem",
              lg: "2.0rem",
            },
          }}
        >
          {answer.text}
        </MDTypography>
        <MDBox
          display="flex"
          gap={1}
          minWidth="200px"
          width={{
            xs: "100%",
            md: "50%",
          }}
        >
          <MDBox flexGrow={1} mr={2}>
            <MDProgress label value={(answer.votes.length / totalVotes) * 100 || 0} />
          </MDBox>
          {!anonymous && (
            <MDButton iconOnly color="info" variant="gradient" onClick={() => setShowVotes(!showVotes)}>
              <FontAwesomeIcon
                icon={faChevronRight}
                style={{
                  transform: showVotes ? "rotate(90deg)" : "rotate(0deg)",
                  transition: "transform 0.3s ease-in-out",
                }}
              />
            </MDButton>
          )}
        </MDBox>
      </MDBox>
      {!anonymous && (
        <Collapse in={showVotes} unmountOnExit>
          <MDBox display="flex" gap={1} mt={2} flexWrap="wrap">
            {answer.votes?.map((vote) => (
              <User userId={vote} />
            ))}
            {answer.votes?.length === 0 && <MDTypography variant="body2">Keine Votes</MDTypography>}
          </MDBox>
        </Collapse>
      )}
    </Card>
  );
};

export function PublicVote() {
  const { botId, guildId, id } = useParams();
  const navigate = useNavigate();
  const darkMode = useSelector((state) => state.ui.darkMode);
  const {
    data: voteData,
    isError: voteIsError,
    error: voteError,
    isLoading: voteIsLoading,
    isSuccess: voteIsSuccess,
  } = useGetPublicModuleDataQuery(
    {
      botId: botId,
      guildId: guildId,
      module: "vote",
      isModule: false,
      id: `vote/${id}`,
    },
    {
      pollingInterval: 1000 * 60,
    }
  );

  const totalVotes = useMemo(
    () => voteData?.answers?.reduce((sum, answer) => sum + answer?.votes?.length, 0),
    [voteData]
  );

  return (
    <>
      <PageLayout>
        <DefaultNavbar
          routes={[]}
          page="Abstimmungen"
          action={{
            type: "internal",
            route: "/bots",
            label: "Zum Bot Panel",
          }}
        />
        <MDBox
          position="absolute"
          width="100%"
          minHeight="100vh"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              image &&
              `${linearGradient(
                rgba(gradients.dark.main, 0.6),
                rgba(gradients.dark.state, 0.6)
              )}, url(${image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            zIndex: -1,
          }}
        />
        <MDBox px={1} pt={12} width="100%" height="100vh" mx="auto" maxWidth="100%">
          <Container
            maxWidth="md"
            sx={{
              height: "100%",
            }}
          >
            <MDBox
              justifyContent="space-between"
              display="flex"
              width="100%"
              height="100%"
              flexDirection="column"
              gap={1}
            >
              <MDBox display="flex" gap={2} flexDirection="column" width="100%">
                {voteIsLoading && (
                  <Card
                    sx={({
                      palette: { transparent: transparentColor, white, background },
                      functions: { rgba },
                    }) => ({
                      backgroundColor: rgba(darkMode ? background.sidenav : white.main, 0.8),
                      backdropFilter: `blur(10px)`,
                    })}
                  >
                    <Skeleton
                      variant="rounded"
                      width="100%"
                      height="600px"
                      animation="wave"
                      sx={{
                        maxHeight: "90vh",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <MDTypography>
                        <FontAwesomeIcon
                          icon={faSpinnerThird}
                          size="lg"
                          spin
                          style={{ visibility: "visible" }}
                        />
                      </MDTypography>
                    </Skeleton>
                  </Card>
                )}
                {voteIsError && (
                  <MDBox display="flex" flexDirection="column" gap={2}>
                    <MDAlert color="error">Diese Abstimmung wurde nicht gefunden.</MDAlert>
                    <NavLink to={`discord://discord.com/channels/${guildId}`} style={{ marginLeft: "auto" }}>
                      <MDButton
                        onClick={() => {
                          window.close();
                        }}
                        variant="contained"
                        color="secondary"
                      >
                        Zurück zum Discord
                      </MDButton>
                    </NavLink>
                  </MDBox>
                )}
                {voteIsSuccess && (
                  <MDBox>
                    <Grid
                      container
                      spacing={1}
                      sx={{
                        alignItems: "end",
                        justifyContent: "center",
                      }}
                    >
                      <Grid item xs={12}>
                        <Card>
                          <MDBox p={2}>
                            <MDTypography
                              variant="h1"
                              fontWeight="medium"
                              textTransform="capitalize"
                              horizontalAlign="center"
                              sx={{
                                fontSize: {
                                  xs: "1.5rem",
                                  sm: "1.5rem",
                                  md: "2.5rem",
                                  lg: "2.5rem",
                                },
                              }}
                            >
                              {voteData?.question}
                            </MDTypography>
                          </MDBox>
                        </Card>
                      </Grid>
                      {voteData?.answers?.map((answer, index) => (
                        <Grid item xs={12}>
                          <AnswerCard
                            answer={answer}
                            index={index}
                            totalVotes={totalVotes}
                            darkMode={darkMode}
                            anonymous={voteData?.isAnonymous}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </MDBox>
                )}
                {voteIsSuccess && (
                  <Card
                    sx={({ palette: { white, card }, functions: { rgba } }) => ({
                      p: 2,
                      backgroundColor: rgba(darkMode ? card.main : white.main, 0.75),
                      backdropFilter: `blur(10px)`,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    })}
                  >
                    <MDTypography variant="body2">
                      Wenn du Abstimmen willst, besuche den Discord Server und stimme dort ab.
                    </MDTypography>
                    <NavLink to={`discord://discord.com/channels/${guildId}/${voteData?.channelId}`}>
                      <MDButton variant="gradient" color="info" size="small">
                        Zum Discord Channel
                      </MDButton>
                    </NavLink>
                  </Card>
                )}
              </MDBox>
              <Footer light absolute={false} />
            </MDBox>
          </Container>
        </MDBox>
      </PageLayout>
    </>
  );
}
