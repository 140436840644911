import {
  DiscordEmbed,
  DiscordEmbedDescription,
  DiscordEmbedField,
  DiscordEmbedFields,
  DiscordEmbedFooter,
  DiscordMessage,
  DiscordMessages,
} from "@skyra/discord-components-react";
import MDTypography from "../MDTypography";
import MDBox from "../MDBox";
import { FallbackComponent } from "../ErrorBoundary/FallbackComponent";
import * as Sentry from "@sentry/react";
import { useSelector } from "react-redux";
import { parseDiscordString } from "./DiscordParser";

export const calculateInlineIndex = (fields, currentIndex) => {
  if (currentIndex === 0 || !fields[currentIndex - 1].inline) {
    // Wenn es das erste Feld ist oder das vorherige Feld nicht inline ist
    return 1;
  }
  let count = 1;
  for (let i = currentIndex - 1; i >= 0; i--) {
    if (!fields[i].inline) break; // Beenden, wenn ein Feld nicht inline ist
    count++;
  }
  return count % 3 === 0 ? 3 : count % 3;
};

export default function Preview({ bot, json }) {
  const darkMode = useSelector((state) => state.ui.darkMode);
  return (
    <Sentry.ErrorBoundary
      fallback={({ eventId, error, componentStack, resetError }) => (
        <FallbackComponent
          eventId={eventId}
          error={error}
          componentStack={componentStack}
          resetError={resetError}
          display="flex"
          flexDirection="column"
        />
      )}
    >
      <MDBox pt={1} pb={2} sx={{ position: "sticky", top: 0 }}>
        <MDBox>
          <DiscordMessages
            style={{ borderRadius: "10px", maxHeight: "98vh", overflowY: "auto" }}
            noBackground
            lightTheme={!darkMode}
          >
            <DiscordMessage
              author={bot?.name}
              avatar={bot?.avatarUrl}
              bot
              verified
              style={{
                color: darkMode ? "#dcddde" : "#2e3338",
              }}
            >
              {parseDiscordString(json.content)}
              {json.embeds?.map((embed, embedIndex) => (
                <DiscordEmbed
                  key={`embed${embedIndex}`}
                  slot="embeds"
                  authorImage={embed.author?.icon_url}
                  authorName={embed.author?.name}
                  authorUrl={embed.author?.url}
                  color={embed.color}
                  embedTitle={embed.title}
                  image={embed.image?.url}
                  thumbnail={embed.thumbnail?.url}
                  url={embed.url}
                >
                  <DiscordEmbedDescription slot="description">
                    {parseDiscordString(embed.description)}
                  </DiscordEmbedDescription>
                  <DiscordEmbedFields slot="fields">
                    {embed.fields?.map((field, fieldIndex, fieldArray) => {
                      // Überprüfen, ob das aktuelle Feld inline ist und ob es einen inline Vorgänger oder Nachfolger hat
                      const isInline = field.inline;
                      let inlineIndex = isInline ? calculateInlineIndex(embed.fields, fieldIndex) : 1;
                      return (
                        <DiscordEmbedField
                          key={`embed${embedIndex}field${fieldIndex}`}
                          fieldTitle={field.name}
                          inline={isInline}
                          inlineIndex={inlineIndex || 1}
                        >
                          {field.value}
                        </DiscordEmbedField>
                      );
                    })}
                  </DiscordEmbedFields>
                  <DiscordEmbedFooter
                    slot="footer"
                    footerImage={embed.footer?.icon_url}
                    timestamp={embed.timestamp && new Date(embed.timestamp).toLocaleString()}
                  >
                    {parseDiscordString(embed.footer?.text)}
                  </DiscordEmbedFooter>
                </DiscordEmbed>
              ))}
            </DiscordMessage>
          </DiscordMessages>

          <MDTypography
            color="info"
            textGradient
            sx={{ textAlign: "center", width: "100%", display: "none" }}
          >
            <small>
              Preview:{" "}
              <a
                href="https://www.npmjs.com/package/@skyra/discord-components-react"
                target="_blank"
                rel="noreferrer"
                referrerPolicy="no-referrer"
              >
                @skyra/discord-components-react
              </a>
            </small>
          </MDTypography>
        </MDBox>
      </MDBox>
    </Sentry.ErrorBoundary>
  );
}
