import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowUp,
  faChevronsDown,
  faChevronsUp,
  faFloppyDisk,
  faRectangleXmark,
  faTimes,
} from "@fortawesome/pro-solid-svg-icons";
import { Grid, InputAdornment, Skeleton } from "@mui/material";
import { fabric } from "fabric";
import MDTypography from "../MDTypography";
import Logger from "../../Logger";
import MDInput from "../MDInput";
import MDButton from "../MDButton";
import {
  decodedJsonToEncodedJson,
  decodeImage,
  decodeLoader,
  decodeRectangle,
  decodeText,
  encodedJsonToFabricArray,
  hexToRgba,
  inlineTryCatch,
  isHexColor,
  rgbaToHex,
} from "./components/FabricUtils";
import { initAligningGuidelines } from "./components/GuideLines";
import MDBox from "../MDBox";
import Container from "@mui/material/Container";
import Autocomplete from "@mui/material/Autocomplete";
import FormField from "../../layouts/old/pages/account/components/FormField";
import MDAlert from "../MDAlert";

export const imageExample = {
  id: "642ded7b71d8392ec63d5532",
  premium: false,
  default: true,
  imageType: "XP",
  data: {
    width: 900,
    height: 280,
    backgroundColor: "#FFFFFF",
    elements: [
      {
        url: "https://cdn.discordapp.com/attachments/805545336302075915/1092527529245540363/mainBackground.png",
        x: 0,
        y: 0,
        width: null,
        height: null,
        borderRadius: 0,
        type: "IMAGE",
      },
      {
        x: 20,
        y: 20,
        color: "#808080",
        width: 860,
        height: 240,
        borderRadius: 10,
        transparency: 0.7,
        type: "RECTANGLE",
      },
      {
        url: "%user_avatar%",
        x: 50,
        y: 30,
        width: 128,
        height: 128,
        borderRadius: 360,
        type: "IMAGE",
      },
      {
        text: "%user_name%",
        x: 200,
        y: 100,
        color: "#ffffff",
        font: "Poppins",
        fontSize: 70,
        type: "TEXT",
      },
      {
        text: "XP: %member_xp% / %member_max_xp%",
        x: 200,
        y: 150,
        color: "#ffffff",
        font: "Poppins",
        fontSize: 30,
        type: "TEXT",
      },
      {
        text: "Level: %member_level%",
        x: 500,
        y: 150,
        color: "#ffffff",
        font: "Poppins",
        fontSize: 30,
        type: "TEXT",
      },
      {
        x: 35,
        y: 170,
        width: 830,
        height: 70,
        progress: -1,
        barBorderRadius: 20,
        barColor: "#c0c0c0",
        barOffsetX: 7,
        barOffsetY: 7,
        backgroundBorderRadius: 20,
        backgroundColor: "#000000",
        borderColor: null,
        type: "LOADER",
      },
    ],
  },
};

const nothingSelected = (
  <>
    <MDBox>
      <MDAlert>
        <MDTypography variant="h6" fontWeight="bold">
          Du musst ein Element im Canvas auswählen um es zu bearbeiten!
        </MDTypography>
      </MDAlert>
    </MDBox>
  </>
);

export default function ImageEditorModal({
  canvasData = {},
  onSubmit = () => {},
  onClose = (x) => {},
  open = false,
  replacer = [],
  availableFonts = [],
}) {
  const fabricRef = useRef(null);
  const canvasRef = useRef(null);
  const focusRef = useRef(null);
  const [waitForFabric, setWaitForFabric] = useState(false);
  const [selectObjectToggle, setSelectObjectToggle] = useState(false);
  const [selectedMeta, setSelectedMeta] = useState(null);

  useEffect(() => {
    Logger.warning("Update CanvasData", { module: "ImageEditor" }, canvasData);
  }, [canvasData]);

  const close = () => onClose(false);

  const keyPressEvents = useCallback(
    (event) => {
      event = event || window.event;
      const activeObject = fabricRef.current.getActiveObject();
      const { ctrlKey, shiftKey, altKey } = event;
      const multiplier = ctrlKey ? 10 : shiftKey ? 0.1 : altKey ? 0.01 : 1;

      switch (event.code) {
        case "Backspace":
          event.preventDefault();
          fabricRef.current.discardActiveObject().renderAll();
          break;
        case "ArrowUp":
          event.preventDefault();
          activeObject.set({ top: Number(activeObject.top - 1 * multiplier) });
          activeObject.setCoords();
          fabricRef.current.renderAll();
          break;
        case "ArrowDown":
          event.preventDefault();
          activeObject.set({ top: Number(activeObject.top + 1 * multiplier) });
          activeObject.setCoords();
          fabricRef.current.renderAll();
          break;
        case "ArrowLeft":
          event.preventDefault();
          activeObject.set({ left: Number(activeObject.left - 1 * multiplier) });
          activeObject.setCoords();
          fabricRef.current.renderAll();
          break;
        case "ArrowRight":
          event.preventDefault();
          activeObject.set({ left: Number(activeObject.left + 1 * multiplier) });
          activeObject.setCoords();
          fabricRef.current.renderAll();
          break;

        default:
          console.debug("Unknown Key", event.code, event.keyCode, event);
      }
    },
    [fabricRef.current]
  );

  useEffect(() => {
    if (!canvasRef.current) return () => Logger.warning("Canvas ref is null");
    const initFabric = () => {
      fabricRef.current = new fabric.Canvas(canvasRef.current, {
        height: canvasData?.data?.height || imageExample.data.height || 800,
        width: canvasData?.data?.width || imageExample.data.width || 800,
        backgroundColor:
          canvasData?.data?.backgroundColor || imageExample.data.backgroundColor || "transparent",
        selection: false,
        renderOnAddRemove: true,
        preserveObjectStacking: true,
      });
      initAligningGuidelines(fabricRef.current);
      Logger.success("Initialized fabric", {
        module: "FabricCanvas",
      });
    };

    const disposeFabric = () => {
      //fabricRef.current.dispose();
      fabricRef.current = null;
      Logger.info("Disposed fabric", {
        module: "FabricCanvas",
      });
    };
    initFabric();

    function somethingSelected({ e, selected }) {
      setSelectObjectToggle(selected[0]);
    }

    // https://github.com/Zyonic-Software/SV-Webinterface/blob/master/iframes/canvas.html#LL921C5-L924C12

    fabricRef.current.on("selection:created", (options) => {
      Logger.info("Auswahl erstellt", {
        module: "FabricCanvas",
      });
      somethingSelected(options);
    });
    fabricRef.current.on("object:modified", (e) => {
      Logger.info("Object Modifiziert", {
        module: "FabricCanvas",
      });
      const object = e.target;

      switch (object.sv_type) {
        case "TEXT":
          object.fontSize *= object.scaleX;
          object.fontSize = object.fontSize.toFixed(2);
          object.scaleX = 1;
          object.scaleY = 1;
          object.dirty = true;
          break;
        case "RECTANGLE":
          object.width *= object.scaleX;
          object.height *= object.scaleY;
          object.scaleX = 1;
          object.scaleY = 1;
          object.dirty = true;
          break;
        case "IMAGE":
          object.scaleToWidth(object.width * object.scaleX);
          object.scaleToHeight(object.height * object.scaleY);
          object.clipPath.width = object.width;
          object.clipPath.height = object.height;
          object.clipPath.top = -object.height / 2;
          object.clipPath.left = -object.width / 2;

          object.dirty = false;
          object.clipPath.dirty = false;
          break;
        default:
          break;
      }

      fabricRef.current.renderAll();
      setSelectObjectToggle((prevState) => !prevState);
    });
    fabricRef.current.on("selection:updated", (options) => {
      Logger.info(
        "Auswahl geändert",
        {
          module: "FabricCanvas",
        },
        options
      );
      somethingSelected(options);
    });
    fabricRef.current.on("selection:cleared", (options) => {
      Logger.info("Auswahl aufgehoben", {
        module: "FabricCanvas",
      });
    });

    return () => {
      disposeFabric();
    };
  }, [canvasRef, canvasData, waitForFabric]);

  useEffect(() => {
    const wait = setInterval(() => {
      if (canvasRef.current) {
        setWaitForFabric(true);
        clearInterval(wait);
        Logger.info("Canvas ref is set", {
          module: "ImageEditor",
        });
      } else {
        Logger.warning("Waiting for canvas ref", {
          module: "ImageEditor",
        });
      }
    }, 1000);
    return () => {
      clearInterval(wait);
    };
  }, [canvasRef]);

  // Load elements
  useEffect(() => {
    if (!fabricRef.current) return;

    const loadElements = async () => {
      Logger.info(
        "Load elements",
        {
          module: "ImageEditor",
        },
        canvasData
      );

      const fabricArray = await encodedJsonToFabricArray(canvasData || imageExample);
      // eslint-disable-next-line no-restricted-syntax
      for (const argument of fabricArray) {
        fabricRef.current.add(argument);
      }

      fabricRef.current.renderAll();
    };

    loadElements();
  }, [fabricRef, waitForFabric, canvasData]);

  const encode = useCallback(async () => {
    if (!fabricRef.current) return;
    const decodedJson = fabricRef.current.toJSON([
      "sv_type",
      "sv_progress",
      "sv_barOffsetX",
      "sv_barOffsetY",
    ]);
    const encodedJson = await decodedJsonToEncodedJson(decodedJson, fabricRef?.current);
    Logger.info("decodedJson", { module: "ImageEditor" }, decodedJson);
    Logger.info("encodedJson", { module: "ImageEditor" }, encodedJson);

    return encodedJson;
  }, [fabricRef]);

  return (
    <Dialog maxWidth="xl" fullWidth fullScreen open={open} onClose={() => close()}>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <MDTypography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Image Editor
          </MDTypography>
          <IconButton
            edge="end"
            sx={{ mr: "1px" }}
            onClick={async () => {
              onSubmit(await encode());
            }}
          >
            <MDTypography>
              <FontAwesomeIcon icon={faFloppyDisk} />{" "}
            </MDTypography>
          </IconButton>
          <IconButton edge="end" onClick={() => close()} aria-label="close">
            <MDTypography>
              <FontAwesomeIcon icon={faRectangleXmark} />
            </MDTypography>
          </IconButton>
        </Toolbar>
      </AppBar>

      <Grid container spacing={5} p={2} direction="row" justifyContent="space-around" alignItems="flex-start">
        <Grid item xs="auto" xl={3}>
          {useMemo(() => {
            if (!fabricRef.current) return;

            // eslint-disable-next-line consistent-return
            return fabricRef.current
              .getObjects()
              .reverse()
              .map((object, index, array) => {
                const objectIndex = fabricRef.current.getObjects().indexOf(object);
                const activeObject = fabricRef.current.getActiveObject();
                const isActive = activeObject === object;

                return (
                  <Grid container item spacing={1} sx={{ mb: 0.75 }} key={`array${index}`}>
                    <MDButton
                      variant={isActive ? "contained" : "outlined"}
                      sx={{ flex: 1, m: 0.5 }}
                      color="info"
                      size="small"
                      onClick={() => {
                        setSelectObjectToggle(object);
                        fabricRef.current.setActiveObject(fabricRef.current.item(array.length - 1 - index));
                        focusRef.current.focus();

                        fabricRef.current.renderAll();
                      }}
                      fullWidth
                    >
                      {object?.sv_type} - {object.uniqueId}
                    </MDButton>
                    <MDButton
                      variant="contained"
                      color="secondary"
                      size="small"
                      disabled={objectIndex === array.length - 1}
                      onClick={() => {
                        object.bringToFront();
                        setSelectObjectToggle((prev) => !prev);
                      }}
                      fullWidth
                      sx={{ m: 0.5 }}
                      iconOnly
                    >
                      <FontAwesomeIcon icon={faChevronsUp} />
                    </MDButton>
                    <MDButton
                      size="small"
                      variant="contained"
                      color="secondary"
                      circular
                      disabled={objectIndex === array.length - 1}
                      onClick={() => {
                        object.bringForward();
                        setSelectObjectToggle((prev) => !prev);
                      }}
                      fullWidth
                      sx={{ m: 0.5 }}
                      iconOnly
                    >
                      <FontAwesomeIcon icon={faArrowUp} />
                    </MDButton>
                    <MDButton
                      variant="contained"
                      color="secondary"
                      size="small"
                      disabled={objectIndex === 0}
                      circular
                      onClick={() => {
                        object.sendBackwards();
                        setSelectObjectToggle((prev) => !prev);
                      }}
                      fullWidth
                      sx={{ m: 0.5 }}
                      iconOnly
                    >
                      <FontAwesomeIcon icon={faArrowDown} />
                    </MDButton>
                    <MDButton
                      variant="contained"
                      color="secondary"
                      size="small"
                      disabled={objectIndex === 0}
                      onClick={() => {
                        object.sendToBack();
                        setSelectObjectToggle((prev) => !prev);
                      }}
                      fullWidth
                      sx={{ m: 0.5 }}
                      iconOnly
                    >
                      <FontAwesomeIcon icon={faChevronsDown} />
                    </MDButton>
                    <MDButton
                      variant="contained"
                      color="error"
                      size="small"
                      onClick={() => {
                        fabricRef.current.remove(object);
                        setSelectObjectToggle((prev) => !prev);
                      }}
                      fullWidth
                      sx={{ m: 0.5 }}
                      iconOnly
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </MDButton>
                  </Grid>
                );
              });
          }, [fabricRef.current, selectObjectToggle])}
        </Grid>
        <Grid
          item
          xs={12}
          xl={7}
          order={{
            xs: 3,
            xl: 2,
          }}
        >
          <Container>
            <MDBox
              ref={focusRef}
              onKeyDown={keyPressEvents}
              tabIndex={2}
              sx={{
                outline: "none",
                "& .canvas-container": {
                  margin: "auto",
                },
                mb: 2,
              }}
            >
              <div
                style={{
                  display: !canvasRef.current ? "none" : "block",
                  width: "100%",
                  margin: "auto",
                  overflow: "auto",
                }}
              >
                <canvas
                  ref={canvasRef}
                  style={{
                    border: "1px solid black",
                  }}
                />
              </div>
              {!canvasRef.current && (
                <Skeleton
                  width="75%"
                  height={400}
                  animation="wave"
                  sx={{ animationDuration: "0.1s", margin: "auto" }}
                />
              )}
            </MDBox>

            {useMemo(() => {
              if (!fabricRef.current) return nothingSelected;
              const activeObject = fabricRef.current.getActiveObject();
              if (!activeObject) return nothingSelected;
              const fillHex = inlineTryCatch(() => rgbaToHex(activeObject?.fill), {
                color: "#000000",
                opacity: 1,
              });

              let object = {
                text: activeObject?.text,
                left: Number(activeObject?.left)?.toFixed(2),
                top: Number(activeObject?.top)?.toFixed(2),
                fillColor: isHexColor(activeObject?.fill) ? activeObject?.fill : fillHex.color,
                fontSize: Number(activeObject?.fontSize)?.toFixed(2),
                fontFamily: activeObject?.fontFamily,
                width: Number(activeObject?.width)?.toFixed(2),
                height: Number(activeObject?.height)?.toFixed(2),
                scaleX: Number(activeObject?.scaleX)?.toFixed(2),
                scaleY: Number(activeObject?.scaleY)?.toFixed(2),
                fillOpacity: Number.isNaN(Number(fillHex.opacity)) ? 1 : Number(fillHex.opacity)?.toFixed(2),
                clipPath: {
                  rx: Number(activeObject?.clipPath?.rx)?.toFixed(2),
                  ry: Number(activeObject?.clipPath?.ry)?.toFixed(2),
                },
                sv_nextUrl: activeObject?.sv_nextUrl,
                sv_url: activeObject?.sv_url,
                rx: Number(activeObject?.rx)?.toFixed(2),
                ry: Number(activeObject?.ry)?.toFixed(2),
              };

              if (activeObject?.sv_type === "LOADER") {
                const objects = activeObject?.getObjects();
                const backgroundColor = inlineTryCatch(() => rgbaToHex(objects[0].fill), {
                  color: "#000000",
                  opacity: 1,
                });
                const barColor = inlineTryCatch(() => rgbaToHex(objects[1].fill), {
                  color: "#919191",
                  opacity: 1,
                });
                object = {
                  ...object,
                  sv_barOffsetX: activeObject?.sv_barOffsetX,
                  sv_barOffsetY: activeObject?.sv_barOffsetY,
                  sv_progress: activeObject?.sv_progress,
                  borderRadius: Number(objects[0].rx)?.toFixed(2),
                  backgroundBorderRadius: Number(objects[1].rx)?.toFixed(2),
                  barColor: isHexColor(objects[1]?.fill) ? objects[1]?.fill : barColor.color,
                  backgroundColor: isHexColor(objects[0]?.fill) ? objects[0]?.fill : backgroundColor.color,
                };
              }

              if (JSON.stringify(selectedMeta) != JSON.stringify(object)) {
                return setSelectedMeta(object);
              }

              switch (activeObject?.sv_type) {
                case "TEXT":
                  return (
                    <Grid
                      container
                      sx={{
                        alignContent: "flex-start",
                      }}
                      spacing={2}
                    >
                      <Grid item xs={12}>
                        <MDTypography variant="h6">Text</MDTypography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <MDInput
                          label="Text"
                          value={selectedMeta.text || ""}
                          placeholder="Text"
                          fullWidth
                          onInput={(e) => {
                            activeObject.set({ text: e.target.value });
                            fabricRef.current.renderAll();
                            setSelectedMeta((prev) => ({ ...prev, text: e.target.value }));
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <MDInput
                          type="number"
                          label="x"
                          fullWidth
                          value={selectedMeta.left || ""}
                          onInput={(e) => {
                            activeObject.set({ left: Number(e.target.value) });
                            fabricRef.current.renderAll();
                            setSelectedMeta((prev) => ({
                              ...prev,
                              left: Number(e.target.value),
                            }));
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <MDInput
                          type="number"
                          label="y"
                          fullWidth
                          value={selectedMeta.top || ""}
                          onInput={(e) => {
                            activeObject.set({ top: Number(e.target.value) });
                            fabricRef.current.renderAll();
                            setSelectedMeta((prev) => ({
                              ...prev,
                              top: Number(e.target.value),
                            }));
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        {" "}
                        <MDInput
                          type="color"
                          label="Color"
                          value={selectedMeta.fillColor || ""}
                          onChange={(e) => {
                            const color = hexToRgba(e.target.value, object.fillOpacity);
                            activeObject.set({ fill: color });
                            fabricRef.current.renderAll();
                            setSelectedMeta((prev) => ({ ...prev, fullColor: color }));
                          }}
                          fullWidth
                          sx={{ minWidth: "200px" }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Autocomplete
                          options={availableFonts}
                          value={selectedMeta.fontFamily || ""}
                          disableClearable
                          onChange={(e, value) => {
                            activeObject.set({ fontFamily: value });
                            fabricRef.current.renderAll();
                            setSelectedMeta((prev) => ({ ...prev, fontFamily: value }));
                          }}
                          renderInput={(params) => (
                            <FormField
                              {...params}
                              variant="outlined"
                              label="Font"
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <MDInput
                          type="number"
                          inputProps={{ min: 0, max: 100, step: 0.5, pattern: "d+" }}
                          label="Font Size"
                          fullWidth
                          value={selectedMeta.fontSize || ""}
                          onChange={(e) => {
                            activeObject.set({ fontSize: Number(e.target.value) });
                            fabricRef.current.renderAll();
                            setSelectedMeta((prev) => ({
                              ...prev,
                              fontSize: Number(e.target.value),
                            }));
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <MDTypography variant="button">
                          Möglich Platzhalter:
                          {replacer.length > 0
                            ? replacer.map((r, index) => (
                                <MDButton
                                  key={`replacer${index}`}
                                  color="success"
                                  size="small"
                                  variant="text"
                                  onClick={() => {
                                    activeObject.set({
                                      text: `${selectedMeta.text} %${r.name}%`,
                                    });
                                    fabricRef.current.renderAll();
                                    setSelectedMeta((prev) => ({
                                      ...prev,
                                      text: `${selectedMeta.text} %${r.name}%`,
                                    }));
                                  }}
                                >
                                  %{r.name}%
                                </MDButton>
                              ))
                            : " Keine Platzhalter vorhanden"}
                        </MDTypography>
                      </Grid>
                    </Grid>
                  );
                case "RECTANGLE":
                  return (
                    <Grid
                      container
                      sx={{
                        alignContent: "flex-start",
                      }}
                      spacing={2}
                    >
                      <Grid item xs={12}>
                        <MDTypography variant="h6">Rectangle</MDTypography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <MDInput
                          type="number"
                          label="x"
                          value={selectedMeta.left || ""}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          onInput={(e) => {
                            activeObject.set({ left: Number(e.target.value) });
                            fabricRef.current.renderAll();
                            setSelectedMeta((prev) => ({
                              ...prev,
                              left: Number(e.target.value),
                            }));
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <MDInput
                          type="number"
                          label="y"
                          value={selectedMeta.top || ""}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          onInput={(e) => {
                            activeObject.set({ top: Number(e.target.value) });
                            fabricRef.current.renderAll();
                            setSelectedMeta((prev) => ({
                              ...prev,
                              top: Number(e.target.value),
                            }));
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        {" "}
                        <MDInput
                          type="number"
                          fullWidth
                          label="width"
                          inputProps={{ min: 0 }}
                          value={selectedMeta.width || ""}
                          InputLabelProps={{ shrink: true }}
                          onInput={(e) => {
                            activeObject.set({ width: Number(e.target.value) });
                            fabricRef.current.renderAll();
                            setSelectedMeta((prev) => ({
                              ...prev,
                              width: Number(e.target.value),
                            }));
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <MDInput
                          type="number"
                          label="height"
                          inputProps={{ min: 0 }}
                          fullWidth
                          value={selectedMeta.height || ""}
                          InputLabelProps={{ shrink: true }}
                          onInput={(e) => {
                            activeObject.set({ height: Number(e.target.value) });
                            fabricRef.current.renderAll();
                            setSelectedMeta((prev) => ({
                              ...prev,
                              height: Number(e.target.value),
                            }));
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        {" "}
                        <MDInput
                          type="color"
                          label="Color"
                          fullWidth
                          value={selectedMeta.fillColor || ""}
                          onChange={(e) => {
                            const color = hexToRgba(e.target.value, object.fillOpacity);
                            activeObject.set({ fill: color });
                            fabricRef.current.renderAll();
                            setSelectedMeta((prev) => ({ ...prev, fillColor: color }));
                          }}
                          sx={{ minWidth: "200px" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <MDInput
                          type="number"
                          label="transparency"
                          fullWidth
                          inputProps={{ min: 0, max: 1, step: 0.05 }}
                          value={rgbaToHex(activeObject.fill).opacity || ""}
                          onChange={(e) => {
                            const color = hexToRgba(object.fillColor, e.target.value);
                            activeObject.set({ fill: color });
                            fabricRef.current.renderAll();
                            setSelectedMeta((prev) => ({ ...prev, fill: color }));
                          }}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <MDInput
                          type="number"
                          fullWidth
                          label="borderRadius"
                          inputProps={{ min: 0 }}
                          value={selectedMeta.rx || ""}
                          onChange={(e) => {
                            activeObject.set({
                              rx: Number(e.target.value),
                              ry: Number(e.target.value),
                            });
                            fabricRef.current.renderAll();
                            setSelectedMeta((prev) => ({
                              ...prev,
                              rx: Number(e.target.value),
                              ry: Number(e.target.value),
                            }));
                          }}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                    </Grid>
                  );
                case "IMAGE":
                  return (
                    <Grid
                      container
                      sx={{
                        alignContent: "flex-start",
                      }}
                      spacing={2}
                    >
                      <Grid item xs={12}>
                        <MDTypography variant="h6">Image</MDTypography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <MDInput
                          type="url"
                          label="Url"
                          fullWidth
                          value={selectedMeta.sv_nextUrl || selectedMeta.sv_url || ""}
                          onChange={(e) => {
                            activeObject.set({
                              sv_nextUrl: e.target.value,
                            });
                            fabricRef.current.renderAll();
                            setSelectedMeta((prev) => ({
                              ...prev,
                              sv_nextUrl: e.target.value,
                            }));
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <MDButton
                                  variant="outlined"
                                  size="small"
                                  onClick={async () => {
                                    if (!selectedMeta.sv_nextUrl)
                                      return alert("Bitte geben Sie eine URL ein");
                                    fabricRef.current.remove(activeObject);
                                    await fabricRef.current.add(
                                      await decodeImage({
                                        x: selectedMeta.left,
                                        y: selectedMeta.top,
                                        width: selectedMeta.width * selectedMeta.scaleX,
                                        height: selectedMeta.height * selectedMeta.scaleY,
                                        borderRadius: selectedMeta.clipPath?.rx,
                                        url: selectedMeta.sv_nextUrl,
                                      })
                                    );

                                    fabricRef.current.renderAll();
                                  }}
                                >
                                  Ändern
                                </MDButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <MDInput
                          type="number"
                          label="x"
                          fullWidth
                          value={selectedMeta.left || ""}
                          InputLabelProps={{ shrink: true }}
                          onInput={(e) => {
                            activeObject.set({ left: Number(e.target.value) });
                            fabricRef.current.renderAll();
                            setSelectedMeta((prev) => ({
                              ...prev,
                              left: Number(e.target.value),
                            }));
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <MDInput
                          type="number"
                          label="y"
                          fullWidth
                          value={selectedMeta.top || ""}
                          InputLabelProps={{ shrink: true }}
                          onInput={(e) => {
                            activeObject.set({ top: Number(e.target.value) });
                            fabricRef.current.renderAll();
                            setSelectedMeta((prev) => ({
                              ...prev,
                              top: Number(e.target.value),
                            }));
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={3}>
                        <MDInput
                          type="number"
                          label="scaleX"
                          fullWidth
                          inputProps={{ min: 0, step: 0.01 }}
                          value={selectedMeta.scaleX || ""}
                          InputLabelProps={{ shrink: true }}
                          onInput={(e) => {
                            activeObject.set("scaleX", Number(e.target.value));
                            fabricRef.current.renderAll();
                            setSelectedMeta((prev) => ({
                              ...prev,
                              scaleX: Number(e.target.value),
                            }));
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <MDInput
                          type="number"
                          label="width"
                          fullWidth
                          disabled
                          value={selectedMeta.scaleX * selectedMeta.width || ""}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        {" "}
                        <MDInput
                          type="number"
                          label="scaleY"
                          fullWidth
                          inputProps={{ min: 0, step: 0.01 }}
                          value={selectedMeta.scaleY || ""}
                          InputLabelProps={{ shrink: true }}
                          onInput={(e) => {
                            activeObject.set({ scaleY: Number(e.target.value) });
                            fabricRef.current.renderAll();
                            setSelectedMeta((prev) => ({
                              ...prev,
                              scaleY: Number(e.target.value),
                            }));
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <MDInput
                          type="number"
                          label="height"
                          fullWidth
                          readOnly
                          disabled
                          value={selectedMeta.scaleX * selectedMeta.height || ""}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <MDInput
                          type="number"
                          label="borderRadius"
                          inputProps={{ min: 0, step: 0.5 }}
                          value={selectedMeta?.clipPath?.rx || ""}
                          fullWidth
                          onChange={(e) => {
                            activeObject.clipPath.rx = Number(e.target.value);
                            activeObject.clipPath.ry = Number(e.target.value);
                            activeObject.clipPath.dirty = true;
                            activeObject.dirty = true;

                            fabricRef.current.renderAll();
                            setSelectedMeta((prev) => ({
                              ...prev,
                              clipPath: {
                                ...prev.clipPath,
                                rx: Number(e.target.value),
                                ry: Number(e.target.value),
                              },
                            }));
                          }}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                    </Grid>
                  );
                case "LOADER":
                  return (
                    <Grid
                      container
                      sx={{
                        alignContent: "flex-start",
                      }}
                      spacing={2}
                    >
                      <Grid item xs={12}>
                        <MDTypography variant="h6">Loader </MDTypography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <MDInput
                          type="number"
                          label="x"
                          fullWidth
                          value={selectedMeta.left || ""}
                          InputLabelProps={{ shrink: true }}
                          onInput={(e) => {
                            activeObject.set({ left: Number(e.target.value) });
                            fabricRef.current.renderAll();
                            setSelectedMeta((prev) => ({
                              ...prev,
                              left: Number(e.target.value),
                            }));
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <MDInput
                          type="number"
                          label="y"
                          fullWidth
                          value={selectedMeta.top || ""}
                          InputLabelProps={{ shrink: true }}
                          onInput={(e) => {
                            activeObject.set({ top: Number(e.target.value) });
                            fabricRef.current.renderAll();
                            setSelectedMeta((prev) => ({
                              ...prev,
                              top: Number(e.target.value),
                            }));
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <MDInput
                          type="number"
                          label="width"
                          inputProps={{ min: 0 }}
                          value={selectedMeta.width || ""}
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          onInput={(e) => {
                            activeObject.set({ width: Number(e.target.value), dirty: true });
                            activeObject.getObjects()[0].set({
                              width: Number(e.target.value),
                              left: -Number(e.target.value) / 2,
                            });
                            activeObject.getObjects()[1].set({
                              left: -Number(e.target.value) * 0.5 + object.sv_barOffsetX,

                              width:
                                ((Number(e.target.value) - object.sv_barOffsetX * 2) * object.sv_progress) /
                                100,
                            });
                            fabricRef.current.renderAll();

                            setSelectedMeta((prev) => ({
                              ...prev,
                              width: Number(e.target.value),
                            }));
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <MDInput
                          type="number"
                          label="height"
                          inputProps={{ min: 0 }}
                          fullWidth
                          value={selectedMeta.height || ""}
                          InputLabelProps={{ shrink: true }}
                          onInput={(e) => {
                            activeObject.set({ height: Number(e.target.value) });
                            fabricRef.current.renderAll();
                            setSelectedMeta((prev) => ({
                              ...prev,
                              height: Number(e.target.value),
                            }));
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <MDInput
                          type="number"
                          label="progress"
                          inputProps={{ min: 0, step: 0.5, max: 100 }}
                          value={selectedMeta?.sv_progress || ""}
                          fullWidth
                          onChange={(e) => {
                            activeObject.set({ sv_progress: e.target.value });
                            activeObject.getObjects()[1].set({
                              width:
                                ((object.width - object.sv_barOffsetX * 2) * Number(e.target.value)) / 100,
                            });
                            fabricRef.current.renderAll();

                            setSelectedMeta((prev) => ({
                              ...prev,
                              sv_progress: Number(e.target.value),
                            }));
                          }}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        {" "}
                        <MDInput
                          type="number"
                          label="borderRadius"
                          inputProps={{ min: 0, step: 0.5 }}
                          value={selectedMeta?.borderRadius || ""}
                          fullWidth
                          onChange={(e) => {
                            activeObject.getObjects()[1].set({ rx: Number(e.target.value) });
                            fabricRef.current.renderAll();
                            setSelectedMeta((prev) => ({
                              ...prev,
                              borderRadius: Number(e.target.value),
                            }));
                          }}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <MDInput
                          type="number"
                          label="barOffsetX"
                          inputProps={{ min: 0, step: 0.5, max: object.width }}
                          value={selectedMeta?.sv_barOffsetX || ""}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          onChange={(e) => {
                            activeObject.set({
                              sv_barOffsetX: Number(e.target.value),
                              dirty: true,
                            });
                            activeObject.getObjects()[1].set({
                              dirty: true,
                              left: -object.width * 0.5 + Number(e.target.value),
                              width: ((object.width - Number(e.target.value) * 2) * object.sv_progress) / 100,
                            });
                            setSelectedMeta((prev) => ({
                              ...prev,
                              sv_barOffsetX: Number(e.target.value),
                            }));
                            fabricRef.current.renderAll();
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <MDInput
                          type="number"
                          label="barOffsetY"
                          inputProps={{ min: 0, step: 0.5, max: object.height }}
                          value={selectedMeta?.sv_barOffsetY || ""}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          onChange={(e) => {
                            activeObject.set({
                              sv_barOffsetY: Number(e.target.value),
                              dirty: true,
                            });
                            activeObject.getObjects()[1].set({
                              dirty: true,
                              top: -object.height * 0.5 + Number(e.target.value),
                              height: object.height - Number(e.target.value) * 2,
                            });
                            setSelectedMeta((prev) => ({
                              ...prev,
                              sv_barOffsetY: Number(e.target.value),
                            }));
                            fabricRef.current.renderAll();
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <MDInput
                          type="number"
                          label="backgroundBorderRadius"
                          value={selectedMeta?.backgroundBorderRadius || ""}
                          fullWidth
                          onChange={(e) => {
                            activeObject.getObjects()[0].set({ rx: Number(e.target.value) });
                            fabricRef.current.renderAll();
                            setSelectedMeta((prev) => ({
                              ...prev,
                              backgroundBorderRadius: Number(e.target.value),
                            }));
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <MDInput
                          type="color"
                          label="barColor"
                          value={selectedMeta.barColor || ""}
                          fullWidth
                          onChange={(e) => {
                            const color = hexToRgba(e.target.value, object.fillOpacity);
                            activeObject.getObjects()[1].set({ fill: color });
                            fabricRef.current.renderAll();
                            setSelectedMeta((prev) => ({ ...prev, barColor: color }));
                          }}
                          sx={{ minWidth: "200px" }}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <MDInput
                          type="color"
                          label="backgroundColor"
                          value={selectedMeta.backgroundColor || ""}
                          fullWidth
                          onChange={(e) => {
                            const color = hexToRgba(e.target.value, object.fillOpacity);
                            activeObject.getObjects()[0].set({ fill: color });
                            fabricRef.current.renderAll();
                            setSelectedMeta((prev) => ({ ...prev, backgroundColor: color }));
                          }}
                          sx={{ minWidth: "200px" }}
                        />
                      </Grid>
                    </Grid>
                  );
                default:
                  return null;
              }
            }, [selectObjectToggle, selectedMeta])}
          </Container>
        </Grid>

        <Grid
          item
          xs="auto"
          xl={2}
          order={{
            xs: 2,
            xl: 3,
          }}
        >
          <Grid
            container
            spacing={1}
            sx={{
              pl: 1,
              order: {
                xs: 2,
                md: 0,
              },
              mt: {
                xs: 2,
                md: 0,
              },
            }}
          >
            <MDButton
              variant="outlined"
              color="secondary"
              size="small"
              fullWidth
              sx={{ mt: 2 }}
              onClick={() => {
                fabricRef.current.discardActiveObject().renderAll();
              }}
            >
              Alles abwählen
            </MDButton>
            <MDBox
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                my: 2,
                width: "100%",
              }}
            >
              <MDButton variant="outlined" color="primary" size="small" fullWidth disabled onClick={() => {}}>
                Kreis hinzufügen
              </MDButton>
              <MDButton
                variant="outlined"
                fullWidth
                size="small"
                color="primary"
                onClick={() => {
                  const argument = decodeRectangle({
                    x: 100,
                    y: 100,
                    width: 100,
                    height: 100,
                    borderRadius: 5,
                    color: "#ffd400",
                    transparency: 0.5,
                  });
                  fabricRef.current.add(argument);
                }}
              >
                Rechteck hinzufügen
              </MDButton>
              <MDButton
                size="small"
                variant="outlined"
                color="primary"
                fullWidth
                onClick={() => {
                  const argument = decodeText({
                    text: "Text ändern",
                    x: 50,
                    y: 50,
                    color: "#750000",
                    font: "Poppins",
                    fontSize: 30,
                    type: "TEXT",
                  });
                  fabricRef.current.add(argument);
                }}
              >
                Text hinzufügen
              </MDButton>
              <MDButton
                size="small"
                variant="outlined"
                color="primary"
                fullWidth
                onClick={async () => {
                  const argument = await decodeImage({
                    x: 50,
                    y: 50,
                    width: 100,
                    height: 100,
                    borderRadius: 5,
                    url: "https://source.unsplash.com/featured/300x202",
                  });
                  fabricRef.current.add(argument);
                }}
              >
                Bild hinzufügen
              </MDButton>
              <MDButton variant="outlined" color="primary" fullWidth size="small" disabled onClick={() => {}}>
                Linie hinzufügen
              </MDButton>
              <MDButton variant="outlined" color="primary" fullWidth size="small" disabled onClick={() => {}}>
                Oval hinzufügen
              </MDButton>
              <MDButton
                variant="outlined"
                color="primary"
                fullWidth
                size="small"
                onClick={() => {
                  const argument = decodeLoader({
                    backgroundBorderRadius: 10,
                    backgroundColor: "#232323",
                    barBorderRadius: 10,
                    barColor: "#f96332",
                    barOffsetX: 7,
                    barOffsetY: 7,
                    height: 50,
                    progress: 50,
                    width: 300,
                    x: 50,
                    y: 50,
                  });
                  fabricRef.current.add(argument);
                }}
              >
                Loader hinzufügen
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
      </Grid>
      <Container sx={{ mt: "auto", mb: 0 }}>
        <MDButton
          onClick={async () => {
            onSubmit(await encode());
          }}
          fullWidth
          sx={{ mb: 2 }}
          color="success"
          variant="gradient"
        >
          Speichern
        </MDButton>
      </Container>
    </Dialog>
  );
}

ImageEditorModal.propTypes = {
  canvasData: PropTypes.objectOf(PropTypes.any),
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  replacer: PropTypes.arrayOf(PropTypes.any),
  availableFonts: PropTypes.arrayOf(PropTypes.any),
};
