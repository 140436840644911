import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullseyePointer, faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { faHandWave } from "@fortawesome/pro-solid-svg-icons";
import MDButton from "../../components/MDButton";
import PageLayout from "../../examples/LayoutContainers/PageLayout";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import Footer from "./components/Footer";
import MDAlert from "../../components/MDAlert";

export function WelcomeRedirect() {
  const navigate = useNavigate();
  const [known, setKnown] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      if (localStorage.getItem("known")) {
        navigate("/bots");
      } else {
        setKnown(false);
        localStorage.setItem("known", true);
      }
    }, 1);
  }, [navigate]);

  return (
    <PageLayout>
      <Container>
        {!known && (
          <MDBox position="relative" zIndex={10} mt={8} px={{ xs: 1, sm: 0 }}>
            <MDTypography variant="h2">
              <FontAwesomeIcon icon={faHandWave} shake style={{ "--fa-animation-duration": "7s" }} />{" "}
              Willkommen beim Structure Bot System!
            </MDTypography>
            <MDTypography variant="body2" sx={{ mb: 2 }}>
              Unser Discord Bot System bietet Ihnen die Möglichkeit, einen eigenen personalisierten Discord
              Bot zu haben, der durch Plugins beliebig erweiterbar ist. Hier im Webinterface können Sie Ihren
              Discord Bot beantragen und verwalten.
            </MDTypography>
            <MDTypography variant="body2" sx={{ mb: 2 }}>
              Was ist ein Discord Bot? Ein Discord Bot ist ein automatisierter Account, der in Ihrem
              Discord-Server agiert und verschiedene Funktionen ausführen kann. Mit unserem Bot System können
              Sie Ihren eigenen Bot erstellen und ihn an Ihre individuellen Bedürfnisse anpassen. Wie
              funktioniert es? Um einen Discord Bot zu erstellen, müssen Sie sich einfach hier auf unserer
              Website registrieren und Ihren Bot beantragen. Nach der Genehmigung erhalten Sie Zugriff auf ein
              benutzerfreundliches Dashboard, das es Ihnen ermöglicht, Ihren Bot zu konfigurieren und zu
              verwalten.
            </MDTypography>
            <MDAlert color="primary" sx={{ mb: 2 }}>
              <MDTypography variant="subtitle2" color="white">
                <b>Wichtig:</b> Unser Botsystem befindet sich noch in der Entwicklung. Nicht alle Funktionen
                sind jetzt schon verfügbar.
              </MDTypography>
            </MDAlert>
            <MDTypography variant="body2" sx={{ mb: 2 }}>
              Personalisierung: Unser Bot System erlaubt Ihnen, Ihren Bot ganz nach Ihren Vorstellungen
              anzupassen. Sie können den Namen, das Profilbild und die Präfixe festlegen, mit denen Ihr Bot
              auf Befehle reagiert. Darüber hinaus können Sie aus einer Vielzahl von Plugins auswählen, um
              Ihrem Bot bestimmte Funktionen hinzuzufügen. Plugin-Erweiterungen: Unsere Plattform bietet eine
              breite Auswahl an Plugins, mit denen Sie Ihren Bot um verschiedene Funktionen erweitern können.
              Sie können beispielsweise ein Musik-Plugin hinzufügen, um Ihren Bot Musik abspielen zu lassen,
              oder ein Moderations-Plugin, um Ihren Server zu moderieren. Die Plugins können einfach über das
              Dashboard installiert und konfiguriert werden.
            </MDTypography>
            <MDTypography variant="body2" sx={{ mb: 2 }}>
              Community und Support: Wir legen großen Wert auf die Unterstützung unserer Nutzer. Unser
              Community-Forum bietet Ihnen die Möglichkeit, sich mit anderen Bot-Eigentümern auszutauschen,
              Tipps zu erhalten und Fragen zu stellen. Zusätzlich steht Ihnen unser Support-Team zur
              Verfügung, um bei technischen Fragen oder Problemen zu helfen. Sicherheit und Zuverlässigkeit:
              Wir nehmen die Sicherheit und Zuverlässigkeit unserer Plattform ernst. Unser Bot System wurde
              entwickelt, um sicherzustellen, dass Ihre Daten geschützt sind und Ihr Bot reibungslos
              funktioniert. Wir aktualisieren regelmäßig unsere Systeme, um die bestmögliche Leistung zu
              gewährleisten. Beginnen Sie noch heute: Erstellen Sie Ihren eigenen Discord Bot und bringen Sie
              Ihren Server auf die nächste Stufe. Registrieren Sie sich jetzt und lassen Sie Ihrer Kreativität
              freien Lauf!
            </MDTypography>
            <MDTypography variant="body2" sx={{ mb: 1 }}>
              Hinweis: Unser Discord Bot System steht in Übereinstimmung mit den Nutzungsrichtlinien von
              Discord. Bitte stellen Sie sicher, dass Sie die Regeln und Bestimmungen einhalten, während Sie
              Ihren Bot nutzen.
            </MDTypography>

            <MDButton
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                navigate("/bots");
              }}
            >
              <FontAwesomeIcon icon={faBullseyePointer} fade style={{ marginRight: "5px" }} />
              Hier klicken um zu den Bots zu gelangen
            </MDButton>
          </MDBox>
        )}
      </Container>
      {!known && <Footer />}
      {known && (
        <MDBox
          sx={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FontAwesomeIcon
            icon={faSpinnerThird}
            size="7x"
            spin
            fade
            style={{
              margin: "auto",
              textAlign: "center",
            }}
          />
        </MDBox>
      )}
    </PageLayout>
  );
}
