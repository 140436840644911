/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";

// Material Dashboard 2 PRO React imageExample components
import DashboardLayout from "../../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import FooterSimple from "../../../../components/Footer/FooterSimple";
import Card from "@mui/material/Card";
import {
  faBadgeCheck,
  faBadgeDollar,
  faFolder,
  faHashtag,
  faRectangleHistory,
  faUsers,
  faUserTag,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Skeleton } from "@mui/material";
import { useParams } from "react-router-dom";
import MDAvatar from "../../../../components/MDAvatar";
import backgroundImage from "../../../../assets/images/bg-profile.jpeg";
import LoadingItem from "../../components/LoadingItem";
import ServerOverviewChart from "./ServerOverviewChart";
import ServerOverviewTab from "./ServerOverviewTab";
import Error404Modal from "../../../../components/Dialog/Error404Modal";
import { useGetGuildQuery } from "../../../../store/guild/guild-api";
import { GuildUpdateForm } from "./GuildUpdateForm";
import { useGetModulesQuery } from "../../../../store/modules/module-api";
import MDAlert from "../../../../components/MDAlert";
import apiMessages from "../../../../apiMessages";

const languages = [
  {
    name: "English",
    label: "Englisch",
  },
  { name: "German", label: "Deutsch" },
];

function ServerOverview() {
  const { botId, serverId } = useParams();

  const {
    data: guildData = {},
    isLoading: guildIsLoading,
    isError: guildIsError,
    error: guildError,
  } = useGetGuildQuery({
    botId,
    guildId: serverId,
  });
  const { data: moduleData = [], isLoading: moduleIsLoading } = useGetModulesQuery({
    botId,
    guildId: serverId,
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Error404Modal />
      <MDBox mb={2} />
      <MDBox position="relative" mb={5}>
        <MDBox
          display="flex"
          alignItems="center"
          position="relative"
          minHeight="18.75rem"
          borderRadius="xl"
          sx={{
            backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.info.main, 0.6),
                rgba(gradients.info.state, 0.6)
              )}, url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            overflow: "hidden",
          }}
        />

        <Card
          sx={{
            position: "relative",
            mt: -8,
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          <Grid container spacing={3} alignItems="center">
            {guildIsLoading ? (
              <>
                <Grid item>
                  <Skeleton variant="circular" width={60} height={60} animation="wave" />
                </Grid>
                <Grid item>
                  <MDBox height="100%" width="100%" mt={0.5} lineHeight={1}>
                    <Skeleton variant="text" width={200} animation="wave" />
                    <Skeleton variant="text" width={200} animation="wave" />
                    <Skeleton variant="text" width={200} animation="wave" />
                  </MDBox>
                </Grid>
              </>
            ) : (
              <>
                <Grid item>
                  <MDAvatar src={guildData.iconUrl} alt="profile-image" size="xl" />
                </Grid>
                <Grid item>
                  <MDBox height="100%" mt={0.5} lineHeight={1}>
                    <MDTypography variant="h5" fontWeight="medium" sx={{ width: "100%" }}>
                      {guildData.name}
                    </MDTypography>
                    <MDTypography variant="h5" fontWeight="medium" sx={{ width: "100%" }}>
                      {guildData.premium ? <FontAwesomeIcon icon={faBadgeCheck} /> : null}{" "}
                      {guildData.beta ? <FontAwesomeIcon icon={faBadgeDollar} /> : null}
                    </MDTypography>
                    <MDTypography variant="button" color="text" fontWeight="regular">
                      Owner: {guildData?.owner?.name}
                    </MDTypography>
                  </MDBox>
                </Grid>
              </>
            )}
          </Grid>
        </Card>
      </MDBox>
      <MDBox mt={5} mb={3}>
        {guildIsError && (
          <MDAlert color="error">
            <MDTypography variant="h6">
              {apiMessages.getErrorFromResponse("ServerOverview", guildError)}
            </MDTypography>
          </MDAlert>
        )}
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <GuildUpdateForm botId={botId} guildId={serverId} />
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <Card>
                  <MDBox p={2}>
                    <LoadingItem
                      icon={faRectangleHistory}
                      title="Aktivierte Module"
                      description={moduleData?.filter((value) => !value.disabled).length || "?"}
                      loading={moduleIsLoading}
                    />
                  </MDBox>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card>
                  <MDBox p={2}>
                    <LoadingItem
                      icon={faFolder}
                      title="Categories"
                      description={guildData.categoryCount || "?"}
                      loading={guildIsLoading}
                    />
                  </MDBox>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card>
                  <MDBox p={2}>
                    <LoadingItem
                      icon={faHashtag}
                      title="Channels"
                      description={guildData.channelCount || "?"}
                      loading={guildIsLoading}
                    />
                  </MDBox>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card>
                  <MDBox p={2}>
                    <LoadingItem
                      icon={faUserTag}
                      title="Roles"
                      description={guildData.rolesCount || "?"}
                      loading={guildIsLoading}
                    />
                  </MDBox>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <ServerOverviewChart
                  count={guildData.memberCount || "?"}
                  progress={23}
                  height="5rem"
                  botId={botId}
                  guildId={serverId}
                  loading={guildIsLoading}
                />{" "}
              </Grid>
            </Grid>
          </Grid>
          <ServerOverviewTab loaded botId={botId} serverId={serverId} />
        </Grid>
      </MDBox>
      <FooterSimple />
    </DashboardLayout>
  );
}

export default ServerOverview;
