import { useForm } from "react-hook-form";
import { useGetBotDetailsQuery, useUpdateBotDetailsMutation } from "../../../../store/admin/admin-api";
import Card from "@mui/material/Card";
import MDTypography from "../../../../components/MDTypography";
import MDBox from "../../../../components/MDBox";
import { FormAutocomplete, FormInput, FormSwitch } from "../../../../components/Form/FormInput";
import { useEffect } from "react";
import apiMessages from "../../../../apiMessages";
import MDButton from "../../../../components/MDButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";

export function UpdatePropertyForm({ botId }) {
  const formHandler = useForm({
    defaultValues: {
      botType: "PRIVATE_BOT",
      beta: true,
      premium: true,
      partner: true,
      shards: 0,
      adsDisabled: true,
      ownerId: 0,
      onlineStatus: "ONLINE",
    },
  });

  const { handleSubmit, setValue } = formHandler;

  const { data: propertyData } = useGetBotDetailsQuery({
    botId,
    type: "property",
  });

  const [updateProperty, { isLoading: updatePropertyIsLoading }] = useUpdateBotDetailsMutation();

  useEffect(() => {
    if (!propertyData) return;
    setValue("botType", propertyData.botType || "");
    setValue("beta", propertyData.beta || "");
    setValue("premium", propertyData.premium || "");
    setValue("partner", propertyData.partner || "");
    setValue("adsDisabled", propertyData.adsDisabled || "");
    setValue("shards", propertyData.shards || "");
    setValue("ownerId", propertyData.ownerId || "");
    setValue("onlineStatus", propertyData.onlineStatus || "");

    setValue("bot.priority", propertyData.bot.priority || "");
    setValue("bot.name", propertyData.bot.name || "");
    setValue("bot.botId", propertyData.bot.botId || "");
    setValue("bot.customImage", propertyData.bot.customImage || "");
    setValue("bot.traefikLabel", propertyData.bot.traefikLabel || "");
    setValue("bot.maxRam", propertyData.bot.maxRam || "");
  }, [propertyData]);

  const onSubmit = async (e) => {
    updateProperty({
      botId,
      type: "property",
      data: {
        ...e,
      },
    }).then(({ data, error }) => {
      apiMessages.showMessage({
        type: "AdminPanel",
        title: data ? "updated" : error?.data?.error || error,
      });
    });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        height: "100%",
      }}
    >
      <Card sx={{ p: 2, height: "100%" }}>
        <MDTypography variant="h5" gutterBottom horizontalAlign="center" fontWeight="medium">
          Properties
        </MDTypography>
        <MDBox mb={2}>
          <FormAutocomplete
            formHandler={formHandler}
            name="botType"
            label="Bot Type"
            options={[
              {
                value: "PUBLIC_BOT",
                label: "Public Bot",
              },
              {
                value: "PRIVATE_BOT",
                label: "Private Bot",
              },
              {
                value: "CUSTOM_BOT",
                label: "Custom Bot",
              },
            ]}
          />
        </MDBox>
        <MDBox mb={2}>
          <FormSwitch formHandler={formHandler} name="beta" label="Beta" />
          <FormSwitch formHandler={formHandler} name="premium" label="Premium" />
          <FormSwitch formHandler={formHandler} name="partner" label="Partner" />
          <FormSwitch formHandler={formHandler} name="adsDisabled" label="Ads Disabled" />
        </MDBox>
        <MDBox mb={2}>
          <FormInput name={"shards"} label={"Shards"} formHandler={formHandler} />
        </MDBox>
        <MDBox mb={2}>
          <FormInput name={"ownerId"} label={"Owner Id"} formHandler={formHandler} />
        </MDBox>
        <MDBox mb={2}>
          <FormAutocomplete
            formHandler={formHandler}
            name="onlineStatus"
            label="Online Status"
            options={[
              {
                value: "ONLINE",
                label: "Online",
              },
              {
                value: "OFFLINE",
                label: "Offline",
              },
              {
                value: "IDLE",
                label: "Idle",
              },
              {
                value: "DO_NOT_DISTURB",
                label: "Do not disturb",
              },
              {
                value: "INVISIBLE",
                label: "Invisible",
              },
            ]}
          />
        </MDBox>

        <MDButton variant="gradient" color="info" fullWidth type="submit">
          Properties aktualisieren
          {updatePropertyIsLoading && (
            <FontAwesomeIcon icon={faSpinnerThird} size="lg" spin style={{ marginLeft: "5px" }} />
          )}
        </MDButton>
      </Card>
    </form>
  );
}
