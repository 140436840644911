import Card from "@mui/material/Card";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import CoverLayout from "../old/authentication/components/CoverLayout";
import backgroundImage from "../../assets/images/custom/webinterface.png";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
import { useGetLoginStatusQuery } from "../../store/auth/auth-api";

const defaultTime = 10;

function handleErrors(response) {
  console.log(response);
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

export default function ServiceOffline() {
  const [time, setTime] = useState(defaultTime);
  const [connecting, setConnecting] = useState(false);
  const [startTimer, setStartTimer] = useState(defaultTime);
  const [currentTry, setCurrentTry] = useState(1);

  const {
    data: loggedInData = {},
    isLoading: loggedInIsLoading,
    isFetching: loggedInIsFetching,
    refetch: loggedInRefetch,
  } = useGetLoginStatusQuery(undefined, {});

  const restartTimer = () => {
    loggedInRefetch().then(() => {
      setCurrentTry((prevState) => prevState + 1);
      setStartTimer(defaultTime + currentTry ** 2);
    });
  };

  useEffect(() => {
    let i = startTimer;
    const interval = setInterval(() => {
      i--;
      setTime(i);
      if (i <= 0) {
        restartTimer();
        clearInterval(interval);
      }
    }, 1000);
    setTime(i);
    return () => clearInterval(interval);
  }, [startTimer]);

  useEffect(() => {
    if (!loggedInData) setStartTimer(5000);
    if (loggedInData && (loggedInData?.loggedIn === true || loggedInData?.loggedIn === false)) {
      const restore = sessionStorage.getItem("restoreOffline");
      if (restore) {
        window.location.href = restore;
      } else {
        window.location.href = "/";
      }
    }
  }, [loggedInData]);

  return (
    <>
      {" "}
      <CoverLayout image={backgroundImage}>
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={3}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              System nicht erreichbar
            </MDTypography>
            <MDTypography display="block" variant="button" color="white" my={1}>
              Derzeit ist das System nicht erreichbar. Bitte versuche es später erneut.
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form">
              <MDBox display="flex" alignItems="center" ml={-1}>
                <MDTypography display="block" variant="button" color="white" horizontalAlign="center" my={1}>
                  Wenn das Problem weiterhin besteht, wende dich an den{" "}
                  <a href="mailto:support@sv-studios.net">Support</a>.
                </MDTypography>
              </MDBox>
              <MDBox mt={5}>
                <MDButton variant="gradient" color="info" fullWidth>
                  Discord beitreten
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
        <Card sx={{ mt: 3, p: 2 }}>
          <MDTypography variant="button" fontWeight="regular" horizontalAlign="center">
            {connecting || loggedInIsFetching ? (
              <FontAwesomeIcon icon={faSpinnerThird} spin />
            ) : (
              <>
                Nächster Verbindungsversuch in <b>{time}</b> Sekunden.
              </>
            )}
          </MDTypography>
        </Card>
      </CoverLayout>
    </>
  );
}
