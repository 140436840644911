import Card from "@mui/material/Card";
import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";
import MDButton from "../../../../../components/MDButton";
import Grid from "@mui/material/Grid";
import { useForm } from "react-hook-form";
import { FormInput, FormSimpleAutocomplete } from "../../../../../components/Form/FormInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import apiMessages from "../../../../../apiMessages";
import { useCreateModuleDataMutation } from "../../../../../store/modules/module-api";
import { useGetRolesQuery } from "../../../../../store/guild/guild-api";
import Logger from "../../../../../Logger";
import { AutoCompleteRoleIcon } from "../../../../../components/Form/AutoCompleteIcon";

export function GameRoleAddForm({ botId, guildId, fullHeight }) {
  const [update, { isLoading }] = useCreateModuleDataMutation();

  const {
    data: roleData = [],
    isFetching: rolesIsFetching,
    refetch: rolesRefetch,
  } = useGetRolesQuery({ botId, guildId });

  const formHandler = useForm({
    defaultValues: {
      roleId: "",
      name: "",
    },
  });
  const { control, handleSubmit } = formHandler;

  const onSubmit = async (e) => {
    update({
      botId,
      guildId,
      isModule: false,
      isPlugin: true,
      plugin: "game",
      data: {
        ...e,
      },
    }).then(({ data, error }) => {
      Logger.info("SubmitEvent", { module: "SubmitForm" }, data, error);
      apiMessages.showMessage({
        type: "GameRole",
        title: data ? "added" : error?.data?.error || error,
      });
    });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        height: fullHeight ? "100%" : "auto",
      }}
    >
      <Card sx={{ height: "100%" }}>
        <MDBox pt={2} px={2}>
          <MDTypography variant="h6" fontWeight="medium">
            Neue GameRole
          </MDTypography>
        </MDBox>
        <MDBox p={2}>
          <Grid item xs={12}>
            <MDBox pt={2} px={2}>
              <FormSimpleAutocomplete
                name="roleId"
                label="Rolle"
                options={[...roleData].sort((x, y) => x.category - y.category)}
                formHandler={formHandler}
                additionalParameters={["colorHex"]}
                labelIcon={(option) => <AutoCompleteRoleIcon option={option} />}
                refetch={() => {
                  rolesRefetch();
                }}
                refetching={rolesIsFetching}
                rules={{
                  required: "Du musst eine Rolle angeben.",
                }}
              />
            </MDBox>
            <MDBox pt={2} px={2}>
              <FormInput
                name="name"
                label="Name"
                formHandler={formHandler}
                rules={{
                  required: "Du musst einen Namen angeben.",
                }}
              />
            </MDBox>
          </Grid>

          <Grid item xs={12}>
            <MDBox pt={2} px={2}>
              <MDButton
                type="submit"
                variant="contained"
                sx={{ width: "100%" }}
                disabled={isLoading}
                color="primary"
              >
                Erstellen
                {isLoading && (
                  <FontAwesomeIcon icon={faSpinnerThird} size="lg" spin style={{ marginLeft: "5px" }} />
                )}
              </MDButton>
            </MDBox>
          </Grid>
        </MDBox>
      </Card>
    </form>
  );
}
