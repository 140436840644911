import { useFieldArray, useWatch } from "react-hook-form";
import { useGetNotUsedBotsQuery } from "../../../../store/admin/admin-api";
import { FormAutocomplete, FormInput } from "../../../../components/Form/FormInput";
import MDBox from "../../../../components/MDBox";
import { InputAdornment } from "@mui/material";
import MDButton from "../../../../components/MDButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle } from "@fortawesome/pro-solid-svg-icons";
import MDTypography from "../../../../components/MDTypography";
import { useEffect } from "react";

export function EssentialsForm({ formHandler }) {
  const { data: notUsedBotsData } = useGetNotUsedBotsQuery();

  const totalTokens = notUsedBotsData?.totalElements;
  const tokenIsRequired = totalTokens === 0;

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control: formHandler.control, // control props comes from useForm (optional: if you are using FormContext)
    name: "essentials.whitelistedServerId", // unique name for your Field Array
  });

  const fieldArray = useWatch({ name: "essentials.whitelistedServerId", control: formHandler.control });

  useEffect(() => {
    if (fieldArray[fieldArray.length - 1]?.key !== "") {
      append({ key: "" });
    }
  }, [fieldArray]);

  return (
    <>
      <MDBox display="flex" flexDirection="column" gap={1}>
        <FormInput
          name="essentials.username"
          label="Username"
          formHandler={formHandler}
          rules={{
            required: "Bot Name wird benötigt.",
            minLength: {
              value: 3,
              message: "Der Name muss mindestens 3 Zeichen lang sein.",
            },
            maxLength: {
              value: 32,
              message: "Der Name darf maximal 32 Zeichen lang sein.",
            },
          }}
        />
        <FormInput name="essentials.avatar" label="Avatar" type="url" formHandler={formHandler} rules={{}} />
        <FormInput
          name="essentials.token"
          label="Token"
          formHandler={formHandler}
          autoComplete="off"
          rules={{
            required: tokenIsRequired ? "Token wird benötigt." : false,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <MDTypography color={tokenIsRequired ? "error" : "text"}>
                  {totalTokens} verfügbar
                </MDTypography>
              </InputAdornment>
            ),
          }}
        />
        <FormInput name="essentials.ownerId" label="Owner ID" formHandler={formHandler} rules={{}} />
        <FormAutocomplete
          formHandler={formHandler}
          name="essentials.priority"
          label="Priority"
          options={[
            {
              value: "HIGH",
              label: "Hoch",
            },
            {
              value: "MEDIUM",
              label: "Mittel",
            },
            {
              value: "LOW",
              label: "Niedrig",
            },
          ]}
        />
        {fields?.map((item, index, array) => (
          <MDBox display="flex" flexDirection="row" gap={1} key={item.id}>
            <FormInput
              formHandler={formHandler}
              type="number"
              label={`${index + 1}. Whitelisted Guild ID`}
              name={`essentials.whitelistedServerId[${index}].key`}
            />
            <MDButton
              color="info"
              iconOnly
              onClick={() => remove(index)}
              disabled={index === array.length - 1}
            >
              <FontAwesomeIcon icon={faMinusCircle} />
            </MDButton>
          </MDBox>
        ))}
      </MDBox>
    </>
  );
}
