import JSONBig from "json-bigint";
import config from "./config";
import Logger from "./Logger";
import { inlineTryCatch } from "./Utils";

export const BodyType = {
  FORM_DATA: "multipart/form-data",
  FORM_URL_ENCODED: "application/x-www-form-urlencoded",
  JSON: "application/json",
};

export const Method = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
};

export function requestData({
  basicAuth = { username: "", password: "" },
  path = "",
  method = Method.GET,
  headers = {},
  body = undefined,
  bodyType = BodyType.JSON,
  searchParams = null,
}) {
  return new Promise((resolve, reject) => {
    try {
      const baseUrl = config.local.enabled ? config.local.backend.url : config.backend.url;

      if (basicAuth.password) {
        // @ts-ignore
        headers["X-Password"] = basicAuth.password;
      }
      if (basicAuth.username) {
        // @ts-ignore
        headers["X-Username"] = basicAuth.username;
      }

      if (body) {
        if (!Object.values(BodyType).includes(bodyType)) {
          // eslint-disable-next-line no-throw-literal
          throw { error: `BodyType ${bodyType} is not supported!` };
        }
      }

      if (!Object.values(Method).includes(method)) {
        // eslint-disable-next-line no-throw-literal
        throw { error: `Method ${method} is not supported!` };
      }

      if (bodyType === BodyType.JSON) {
        // @ts-ignore
        headers["Content-Type"] = "application/json";
      }

      fetch(
        `${baseUrl}/api${path ? `/${path}` : ""}${
          searchParams ? `?${new URLSearchParams(searchParams)}` : ""
        }`,
        {
          method,
          headers: new Headers({
            accept: "application/json",
            ...headers,
          }),
          redirect: "manual",
          credentials: "include",
          mode: "cors",
          // eslint-disable-next-line no-nested-ternary
          body: body ? (bodyType === BodyType.JSON ? JSONBig.stringify(body) : body) : undefined,
        }
      )
        .then((response) => {
          if (response.status >= 500) {
            Logger.error(
              `Request to ${path} failed with status ${response.status}`,
              {
                module: "RequestLogger",
              },
              response
            );
          }
          return response;
        })
        .then(async (response) => {
          const text = await response.text();
          const json = inlineTryCatch(() => JSONBig.parse(text, {}), undefined);
          if (!response.ok) {
            throw json;
          }
          return json;
        })

        .then((result) => {
          Logger.info(`Request to ${path} was successful`, { module: "RequestLogger" }, result);
          resolve(result);
        })
        .catch((error) => {
          Logger.info(`Request to ${path} failed`, { module: "RequestLogger" }, error);
          reject(error);
        })
        .finally(() => {});
    } catch (error) {
      reject(error);
    }
  });
}
