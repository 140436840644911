import { useGetUserQuery } from "../../../../../store/guild/guild-api";
import { DiscordMessage, DiscordMessages } from "@skyra/discord-components-react";

export default function SuggestionPreview({ botId, guildId, userId, createdAt, message }) {
  const { data: userData } = useGetUserQuery({
    botId,
    guildId,
    userId,
  });

  return (
    <div className="hide-profile-skyra">
      <DiscordMessages style={{ borderRadius: "10px" }} noBackground>
        <DiscordMessage
          author={userData?.nickname || userData?.name}
          avatar={userData?.avatarUrl}
          timestamp={new Date(createdAt).toLocaleString()}
        >
          <div
            style={{
              whiteSpace: "pre-line",
              wordBreak: "break-word",
              overflowWrap: "break-word",
            }}
          >
            {message}
          </div>
        </DiscordMessage>
      </DiscordMessages>
    </div>
  );
}
