import { createApi } from "@reduxjs/toolkit/query/react";
import { defaultTransformErrorResponse, defaultTransformResponse, dynamicBaseQuery } from "../redux-utils.js";
import config, { devModeEnabled } from "../../config";

export const globalApi = createApi({
  reducerPath: "globalApi",
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    // Support Pin
    createSupportPin: builder.mutation({
      query: ({ botId }) => ({
        url: `api/cloud/support-pin/${botId}`,
        method: "POST",
      }),
      invalidatesTags: (result, error, { botId }) => [{ type: "SupportPin", id: botId }],
      transformResponse: defaultTransformResponse,
      transformErrorResponse: defaultTransformErrorResponse,
    }),
    redeemSupportPin: builder.mutation({
      query: ({ data }) => ({
        url: `api/cloud/support-pin/redeem-pin`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result, error, { botId }) => [{ type: "SupportPin", id: botId }],
      transformResponse: defaultTransformResponse,
      transformErrorResponse: defaultTransformErrorResponse,
    }),
    getPlugins: builder.query({
      query: ({ botId }) => ({
        url: `api/${botId}/plugins`,
        method: "GET",
      }),
      transformResponse: defaultTransformResponse,
      transformErrorResponse: defaultTransformErrorResponse,
    }),
    getPublicModuleData: builder.query({
      query: ({ botId, guildId, module, id, isModule = true }) => ({
        url: `api/${botId}/guild/${guildId}${isModule ? `/module/${module}` : ""}${id ? `/${id}` : ""}`,
        method: "GET",
      }),
      transformResponse: defaultTransformResponse,
      transformErrorResponse: defaultTransformErrorResponse,
    }),
  }),
});
export const {
  useCreateSupportPinMutation,
  useRedeemSupportPinMutation,
  useGetPublicModuleDataQuery,
  useGetPluginsQuery,
} = globalApi;
