import { defaultTransformErrorResponse, defaultTransformResponse, dynamicBaseQuery } from "../redux-utils.js";
import { createApi } from "@reduxjs/toolkit/query/react";

const defaultHeaders = {
  credentials: "include",
  mode: "cors",
  redirect: "manual",
};

export const adminApi = createApi({
  reducerPath: "adminApi",
  baseQuery: dynamicBaseQuery,
  tagTypes: ["admin", "entities", "loggers"],
  endpoints: (builder) => ({
    getEntities: builder.query({
      query: ({ botId }) => ({
        url: `api/${botId}/admin/entity`,
        ...defaultHeaders,
      }),
      transformResponse: defaultTransformResponse,
      transformErrorResponse: defaultTransformErrorResponse,
    }),
    getLoggers: builder.query({
      query: () => ({
        url: "management/loggers",
        ...defaultHeaders,
      }),
      providesTags: ["loggers"],
    }),
    updateLogger: builder.mutation({
      query: ({ logger, level }) => ({
        url: `management/loggers/${logger}`,
        ...defaultHeaders,
        method: "POST",
        body: {
          configuredLevel: level,
        },
      }),
      invalidatesTags: ["loggers"],
    }),
    getEntity: builder.query({
      query: ({ botId, entity, page = 0, size = 20, search = "", sort = "" }) => ({
        url: `api/${botId}/admin/entity/${entity}?page=${page}&size=${size}${sort}`,
        ...defaultHeaders,
      }),
      transformResponse: defaultTransformResponse,
      transformErrorResponse: defaultTransformErrorResponse,
      providesTags: (result, error, { entity, botId }) => [{ type: "entity", id: entity, botId }],
    }),
    updateEntity: builder.mutation({
      query: ({ botId, entity, id, body }) => ({
        url: `api/${botId}/admin/entity/${entity}${id ? `/${id}` : ""}`,
        ...defaultHeaders,

        method: "PUT",
        body: body,
      }),
      transformResponse: defaultTransformResponse,
      transformErrorResponse: defaultTransformErrorResponse,
      invalidatesTags: (result, error, { entity, botId }) => [{ type: "entity", id: entity, botId }],
    }),
    deleteEntity: builder.mutation({
      query: ({ botId, entity, id }) => ({
        url: `api/${botId}/admin/entity/${entity}/${id}`,
        ...defaultHeaders,

        method: "DELETE",
      }),
      transformResponse: defaultTransformResponse,
      transformErrorResponse: defaultTransformErrorResponse,
      invalidatesTags: (result, error, { entity, botId }) => [{ type: "entity", id: entity, botId }],
    }),
    getInfo: builder.query({
      query: () => ({
        url: `management/info`,
        ...defaultHeaders,
      }),
      providesTags: ["info"],
    }),
    getHealth: builder.query({
      query: () => ({
        url: `management/health`,
        ...defaultHeaders,
      }),
      providesTags: ["health"],
    }),
    getMappings: builder.query({
      query: () => ({
        url: `management/mappings`,
        ...defaultHeaders,
      }),
      providesTags: ["mappings"],
    }),

    // BOTS
    listBots: builder.query({
      query: ({ page = 0, size = 1000 }) => ({
        url: `api/admin/bot?page=${page}&size=${size}`,
        ...defaultHeaders,
      }),
      transformResponse: defaultTransformResponse,
      transformErrorResponse: defaultTransformErrorResponse,
      providesTags: (result, error) => [{ type: "AdminBot", id: "LIST" }],
    }),
    restartBot: builder.mutation({
      query: ({ botId }) => ({
        url: `api/admin/bot/${botId}/restart`,
        method: "POST",
        ...defaultHeaders,
      }),
      transformResponse: defaultTransformResponse,
      transformErrorResponse: defaultTransformErrorResponse,
      invalidatesTags: (result, error, { botId }) => [
        { type: "AdminBot", id: "LIST" },
        { type: "AdminBot", id: botId },
      ],
    }),
    deleteBot: builder.mutation({
      query: ({ botId }) => ({
        url: `api/admin/bot/${botId}`,
        method: "DELETE",
        ...defaultHeaders,
      }),
      transformResponse: defaultTransformResponse,
      transformErrorResponse: defaultTransformErrorResponse,
      invalidatesTags: (result, error, { botId }) => [
        { type: "AdminBot", id: "LIST" },
        { type: "AdminBot", id: botId },
      ],
    }),

    // BOT DETAILS

    getBotDetails: builder.query({
      query: ({ botId, type }) => ({
        url: `api/admin/bot/${botId}/${type}`,
        ...defaultHeaders,
      }),
      transformResponse: defaultTransformResponse,
      transformErrorResponse: defaultTransformErrorResponse,
      providesTags: (result, error, { botId }) => [{ type: "AdminBot", id: botId }],
    }),
    createBotDetails: builder.mutation({
      query: ({ botId, type, data }) => ({
        url: `api/admin/bot/${botId}/${type}`,
        method: "POST",
        ...defaultHeaders,

        body: data,
      }),
      transformResponse: defaultTransformResponse,
      transformErrorResponse: defaultTransformErrorResponse,
      invalidatesTags: (result, error, { botId }) => [{ type: "AdminBot", id: botId }],
    }),
    updateBotDetails: builder.mutation({
      query: ({ botId, type, data }) => ({
        url: `api/admin/bot/${botId}/${type}`,
        method: "PUT",
        ...defaultHeaders,

        body: data,
      }),
      transformResponse: defaultTransformResponse,
      transformErrorResponse: defaultTransformErrorResponse,
      invalidatesTags: (result, error, { botId }) => [{ type: "AdminBot", id: botId }],
    }),
    deleteBotDetails: builder.mutation({
      query: ({ botId, type, id }) => ({
        url: `api/admin/bot/${botId}/${type}/${id}`,
        method: "DELETE",
        ...defaultHeaders,
      }),
      transformResponse: defaultTransformResponse,
      transformErrorResponse: defaultTransformErrorResponse,
      invalidatesTags: (result, error, { botId }) => [{ type: "AdminBot", id: botId }],
    }),

    // Admin Bot

    createAdminBot: builder.mutation({
      query: ({ data }) => ({
        url: `api/admin/bot`,
        method: "POST",
        body: data,
        ...defaultHeaders,
      }),
      transformResponse: defaultTransformResponse,
      transformErrorResponse: defaultTransformErrorResponse,
      invalidatesTags: (result, error, { botId }) => [{ type: "AdminBot", id: botId }],
    }),
    updateAdminBot: builder.mutation({
      query: ({ botId, data }) => ({
        url: `api/admin/bot/${botId}`,
        method: "PUT",
        body: data,
        ...defaultHeaders,
      }),
      transformResponse: defaultTransformResponse,
      transformErrorResponse: defaultTransformErrorResponse,
      invalidatesTags: (result, error, { botId }) => [{ type: "AdminBot", id: botId }],
    }),

    getAdminBot: builder.query({
      query: ({ botId }) => ({
        url: `api/admin/bot/${botId}`,
        ...defaultHeaders,
      }),
      transformResponse: defaultTransformResponse,
      transformErrorResponse: defaultTransformErrorResponse,
      providesTags: (result, error, { botId }) => [{ type: "AdminBot", id: botId }],
    }),

    redeemAdminSupportPin: builder.mutation({
      query: ({ botId }) => ({
        url: `api/cloud/support-pin/${botId}/redeem-pin`,
        method: "POST",
        ...defaultHeaders,
      }),
      invalidatesTags: (result, error, { botId }) => [{ type: "SupportPin", id: botId }],
      transformResponse: defaultTransformResponse,
      transformErrorResponse: defaultTransformErrorResponse,
    }),
    getNotUsedBots: builder.query({
      query: () => ({
        url: `api/admin/not_used_bot`,
        ...defaultHeaders,
      }),
      transformResponse: defaultTransformResponse,
      transformErrorResponse: defaultTransformErrorResponse,
      providesTags: (result, error) => [{ type: "NotUsedBot", id: "LIST" }],
    }),
    createNotUsedBot: builder.mutation({
      query: ({ data }) => ({
        url: `api/admin/not_used_bot`,
        method: "POST",
        ...defaultHeaders,

        body: data,
      }),
      transformResponse: defaultTransformResponse,
      transformErrorResponse: defaultTransformErrorResponse,
      invalidatesTags: (result, error) => [{ type: "NotUsedBot", id: "LIST" }],
    }),
    deleteNotUsedBot: builder.mutation({
      query: ({ id }) => ({
        url: `api/admin/not_used_bot/${id}`,
        ...defaultHeaders,

        method: "DELETE",
      }),
      transformResponse: defaultTransformResponse,
      transformErrorResponse: defaultTransformErrorResponse,
      invalidatesTags: (result, error) => [{ type: "NotUsedBot", id: "LIST" }],
    }),
  }),
});

/**
 * @typedef {Object} UseQueryOptions
 * @property {number} [pollingInterval] - Interval in milliseconds for polling.
 * @property {boolean} [refetchOnReconnect] - Refetch data on reconnect.
 * @property {boolean} [refetchOnFocus] - Refetch data when the window gets focus.
 * @property {boolean} [skip] - Skip fetching.
 * @property {boolean|number} [refetchOnMountOrArgChange] - Refetch on component mount or argument change.
 * @property {function} [selectFromResult] - Selector function for picking specific data out of UseQueryStateDefaultResult.
 */

/**
 * @typedef {Object} UseQueryResult
 * @property {unknown} [originalArgs] - Arguments passed to the query.
 * @property {any} [data] - The latest returned result regardless of hook arg, if present.
 * @property {any} [currentData] - The latest returned result for the current hook arg, if present.
 * @property {unknown} [error] - Error result if present.
 * @property {string} [requestId] - A string generated by RTK Query.
 * @property {string} [endpointName] - The name of the given endpoint for the query.
 * @property {number} [startedTimeStamp] - Timestamp for when the query was initiated.
 * @property {number} [fulfilledTimeStamp] - Timestamp for when the query was completed.
 * @property {boolean} isUninitialized - Query has not started yet.
 * @property {boolean} isLoading - Query is currently loading for the first time. No data yet.
 * @property {boolean} isFetching - Query is currently fetching, but might have data from an earlier request.
 * @property {boolean} isSuccess - Query has data from a successful load.
 * @property {boolean} isError - Query is currently in an "error" state.
 * @property {() => void} refetch - A function to force refetch the query.
 */

export const {
  useGetEntitiesQuery,
  useGetLoggersQuery,
  useUpdateLoggerMutation,
  useGetEntityQuery,
  useUpdateEntityMutation,
  useDeleteEntityMutation,
  useGetInfoQuery,
  useGetHealthQuery,
  useGetMappingsQuery,
  useListBotsQuery,
  useRestartBotMutation,
  useDeleteBotMutation,
  useGetBotDetailsQuery,
  useUpdateBotDetailsMutation,
  useCreateBotDetailsMutation,
  useDeleteBotDetailsMutation,
  useRedeemAdminSupportPinMutation,
  useGetAdminBotQuery,
  useCreateAdminBotMutation,
  useUpdateAdminBotMutation,
  useGetNotUsedBotsQuery,
  useCreateNotUsedBotMutation,
  useDeleteNotUsedBotMutation,
} = adminApi;
