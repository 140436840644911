/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import MDButton from "../../../../components/MDButton";

// Material Dashboard 2 PRO React imageExample components
import DashboardLayout from "../../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import FooterSimple from "../../../../components/Footer/FooterSimple";

import Card from "@mui/material/Card";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBadgeCheck, faBadgeDollar} from "@fortawesome/pro-solid-svg-icons";
import {NavLink, useParams} from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import backgroundImage from "../../../../assets/images/bg-profile.jpeg";
import MDAvatar from "../../../../components/MDAvatar";
import ServerCard from "./ServerCard";
import Error404Modal from "../../../../components/Dialog/Error404Modal";
import {useGetBotQuery} from "../../../../store/bot/bot-api";
import {useSelector} from "react-redux";
import {Skeleton} from "@mui/material";
import {LoginState} from "../../../../Utils";

function ServerList() {
  const { botId } = useParams();

  const { data: botData = {}, isLoading: botIsLoading } = useGetBotQuery({ botId });
  const { user, status } = useSelector((state) => state.auth);

  return (
    <DashboardLayout>
      <DashboardNavbar isAbsolute={status === LoginState.unauthenticated} />
      {status === LoginState.unauthenticated ? <Error404Modal /> : <></>}
      <MDBox mb={2} />
      <MDBox position="relative" mb={5}>
        <MDBox
          display="flex"
          alignItems="center"
          position="relative"
          minHeight="18.75rem"
          borderRadius="xl"
          sx={{
            backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.info.main, 0.6),
                rgba(gradients.info.state, 0.6)
              )}, url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            overflow: "hidden",
          }}
        />

        <Card
          sx={{
            position: "relative",
            mt: -8,
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <MDAvatar src={botData.avatarUrl} alt="profile-image" size="xl" shadow="sm" />
            </Grid>
            <Grid item>
              <MDBox height="100%" mt={0.5} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium" sx={{ width: "100%" }}>
                  {botData.name}{" "}
                </MDTypography>
                <MDTypography variant="h5" fontWeight="medium" sx={{ width: "100%" }}>
                  {botData.premium ? (
                    <Tooltip title="Premium">
                      <span>
                        <FontAwesomeIcon icon={faBadgeCheck} />
                      </span>
                    </Tooltip>
                  ) : null}{" "}
                  {botData.beta ? (
                    <Tooltip title="Beta">
                      <span>
                        <FontAwesomeIcon icon={faBadgeDollar} />
                      </span>
                    </Tooltip>
                  ) : null}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        </Card>
      </MDBox>
      <MDBox pb={3}>
        <Grid container alignItems="center">
          <Grid item xs={12} md={7}>
            <MDBox mb={1}>
              <MDTypography variant="h5">
                Hier sehen sie alle Server auf denen der Bot sich befindet
              </MDTypography>
            </MDBox>
            <MDBox mb={2}>
              <MDTypography variant="body2" color="text">
                Neue Server können über die Whitelist hinzugefügt werden. Bitte beachten Sie, dass ein Bot
                eine Limitierung von x Servern hat.
              </MDTypography>
            </MDBox>
          </Grid>

          {user?.id?.toString() === botData.owner?.id?.toString() ? (
            <Grid item xs={12} md={5} sx={{ textAlign: "right" }}>
              <MDButton variant="gradient" color="info" component={NavLink} to={`/bots/${botId}/whitelist`}>
                Manage Whitelist
              </MDButton>
            </Grid>
          ) : null}
        </Grid>
        <MDBox mt={5}>
          <Grid container spacing={3}>
            {botData?.guilds?.map(({ id }) => (
              <Grid item xs={12} md={6} key={id}>
                <MDBox mb={1.5} mt={1.5}>
                  <ServerCard botId={botId} guildId={id} color="black" />
                </MDBox>
              </Grid>
            ))}
            {botData?.guilds?.length < 1 ? (
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={1.5} mt={1.5}>
                  <MDTypography>Keine Server gefunden</MDTypography>
                </MDBox>
              </Grid>
            ) : null}
            {botIsLoading &&
              [1, 2, 3].map((_, key) => (
                <Grid item xs={12} md={6} lg={4} key={key}>
                  <Skeleton variant="rounded" width="100%" height={200} />
                </Grid>
              ))}
          </Grid>
        </MDBox>
      </MDBox>
      <FooterSimple />
    </DashboardLayout>
  );
}

export default ServerList;
