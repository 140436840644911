import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { Grid } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import MDButton from "../../../../../../components/MDButton";
import { setSearchParam } from "../../../../../../Utils";

function TicketSystemCategoryCard({
  color = "secondary",
  ticketCategory,
  emoji,
  title,
  description,
  dropdown = false,
  tickets = 0,
  id = 0,
}) {
  const [searchParams, setSearchParams] = useSearchParams();

  console.log(ticketCategory);

  return (
    <Card>
      <MDBox p={2}>
        <MDBox display="flex" alignItems="center">
          <MDAvatar
            src={`https://cdn.discordapp.com/emojis/${emoji}.gif?size=96&quality=lossless`}
            alt={title}
            size="md"
            variant="rounded"
            bgColor={color}
            sx={{
              p: 1,
              mb: "auto",
              borderRadius: ({ borders: { borderRadius } }) => borderRadius.xl,
            }}
          />
          <MDBox ml={2} lineHeight={0}>
            <MDTypography variant="h6" textTransform="capitalize" fontWeight="medium">
              {title}
            </MDTypography>
            <MDBox my={1} lineHeight={1}>
              <MDTypography variant="button" fontWeight="light" color="text">
                {description}
              </MDTypography>
            </MDBox>
          </MDBox>
          {dropdown && (
            <MDTypography
              color="secondary"
              onClick={dropdown.action}
              sx={{
                ml: "auto",
                mt: -1,
                alignSelf: "flex-start",
                py: 1.25,
              }}
            >
              <Icon fontSize="default" sx={{ cursor: "pointer", fontWeight: "bold" }}>
                more_vert
              </Icon>
            </MDTypography>
          )}
          {dropdown.menu}
        </MDBox>
        <Divider />
        <MDBox display="flex" justifyContent="space-between" alignItems="center">
          <MDBox display="flex" flexDirection="column" lineHeight={0}>
            <MDTypography variant="button" fontWeight="medium">
              {tickets || "?"}
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="secondary">
              Tickets
            </MDTypography>
          </MDBox>
          <MDBox display="flex" flexDirection="column" lineHeight={0} sx={{ textAlign: "center" }}>
            <MDTypography variant="button" fontWeight="medium">
              {tickets || "?"}
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="secondary">
              Geschlossen
            </MDTypography>
          </MDBox>
          <MDBox display="flex" flexDirection="column" lineHeight={0} sx={{ textAlign: "end" }}>
            <MDTypography variant="button" fontWeight="medium">
              {tickets || "?"}
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="secondary">
              Offen
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox lineHeight={0} mt={2}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <MDButton
                color="secondary"
                size="small"
                sx={{ width: "100%" }}
                variant="contained"
                onClick={() =>
                  setSearchParam(searchParams, setSearchParams, {
                    category: id,
                    tab: "manage",
                    edit: true,
                  })
                }
              >
                Edit
                <FontAwesomeIcon icon={faArrowRight} size="sm" style={{ marginTop: -0.5, marginLeft: 5 }} />
              </MDButton>
            </Grid>
            <Grid item xs={12} md={6}>
              <MDButton
                color="primary"
                size="small"
                variant="contained"
                sx={{ width: "100%" }}
                onClick={() =>
                  setSearchParam(searchParams, setSearchParams, {
                    category: id,
                    tab: "tickets",
                  })
                }
              >
                Tickets
                <FontAwesomeIcon icon={faArrowRight} size="sm" style={{ marginTop: -0.5, marginLeft: 5 }} />
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Typechecking props for the ProfileInfoCard
TicketSystemCategoryCard.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark", "light"]),
  emoji: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
  tickets: PropTypes.number,
  dropdown: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      action: PropTypes.func,
      menu: PropTypes.node,
    }),
  ]),
  id: PropTypes.number,
  ticketCategory: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default TicketSystemCategoryCard;
