/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useEffect, useMemo, useRef, useState } from "react"; // porp-types is a library for typechecking of props
import { Line } from "react-chartjs-2"; // @mui material components
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import gradientChartLine from "assets/theme/functions/gradientChartLine"; // GradientLineChart configurations
import configs from "./UserChartOptions"; // Material Dashboard 2 PRO React base styles
import colors from "assets/theme/base/colors";
import "chartjs-adapter-moment";
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  TimeScale
);

export function UserChart({ icon, title, description, height, chart }) {
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState({
    data: {
      labels: [],
      datasets: [],
    },
  });
  const { data, options } = chartData;

  useEffect(() => {
    const chartDatasets = chart.datasets
      ? chart.datasets.map((dataset) => ({
          ...dataset,
          tension: 0,
          pointRadius: 0,
          borderWidth: 4,
          borderColor: colors[dataset.color] ? colors[dataset.color || "dark"].main : colors.dark.main,
          fill: true,
          maxBarThickness: 6,
          backgroundColor: gradientChartLine(
            chartRef.current.children[0],
            colors[dataset.color] ? colors[dataset.color || "dark"].main : colors.dark.main
          ),
        }))
      : [];

    setChartData(configs(chart?.labels || [], chartDatasets));
  }, [chart]);

  const renderChart = (
    <MDBox py={2} pr={2} pl={2}>
      {useMemo(
        () => (
          <MDBox ref={chartRef} sx={{ height }}>
            <Line data={data} options={options} />
          </MDBox>
        ),
        [chartData, height]
      )}
    </MDBox>
  );

  return title || description ? <Card>{renderChart}</Card> : renderChart;
}
