/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";

// @mui icons
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDiscord, faTwitter} from "@fortawesome/free-brands-svg-icons";
import {faEnvelope} from "@fortawesome/pro-solid-svg-icons";
import MDBadge from "../../../components/MDBadge";
import config from "../../../config";

function Footer() {
  return (
    <MDBox component="footer" py={6}>
      <Grid container justifyContent="center">
        <Grid item xs={10} lg={8}>
          <MDBox display="flex" justifyContent="center" flexWrap="wrap" mb={3}>
            <MDBox mr={{ xs: 2, lg: 3, xl: 6 }}>
              <MDTypography component="a" href="/" variant="body2" fontWeight="regular" color="secondary">
                Startseite
              </MDTypography>
            </MDBox>
            <MDBox mr={{ xs: 2, lg: 3, xl: 6 }}>
              <MDTypography
                component="a"
                href="https://sv-studios.net/contact"
                variant="body2"
                fontWeight="regular"
                color="secondary"
              >
                Support
              </MDTypography>
            </MDBox>
            <MDBox mr={{ xs: 0, lg: 3, xl: 6 }}>
              <MDTypography
                component="a"
                href="https://status.sv-studios.net"
                variant="body2"
                fontWeight="regular"
                color="secondary"
              >
                Status
              </MDTypography>
            </MDBox>
            <MDBox mr={{ xs: 2, lg: 3, xl: 6 }}>
              <MDTypography
                component="a"
                href="https://zyonicsoftware.com/datenschutz"
                variant="body2"
                fontWeight="regular"
                color="secondary"
              >
                Datenschutz
              </MDTypography>
            </MDBox>
            <MDBox mr={{ xs: 2, lg: 3, xl: 6 }}>
              <MDTypography
                component="a"
                href="zyonicsoftware"
                variant="body2"
                fontWeight="regular"
                color="secondary"
              >
                AGB
              </MDTypography>
            </MDBox>
            <MDBox>
              <MDTypography
                component="a"
                href="https://zyonicsoftware.com/impressum"
                variant="body2"
                fontWeight="regular"
                color="secondary"
              >
                Impressum
              </MDTypography>
            </MDBox>
          </MDBox>
        </Grid>
        <Grid item xs={12} lg={8}>
          <MDBox display="flex" justifyContent="center" mt={1} mb={3}>
            <MDBox mr={3} color="secondary" component="a" href="https://dsc.gg/sv">
              <FontAwesomeIcon icon={faDiscord} />
            </MDBox>
            <MDBox mr={3} color="secondary" component="a" href="https://twitter.com/svstudiosnet">
              <FontAwesomeIcon icon={faTwitter} />
            </MDBox>
            <MDBox color="secondary" component="a" href="mailto:contact@sv-studios.net">
              <FontAwesomeIcon icon={faEnvelope} />
            </MDBox>
          </MDBox>
        </Grid>
        <Grid item xs={12} lg={8} sx={{ textAlign: "center" }}>
          <MDTypography variant="body2" color="secondary">
            &copy; 2020 - {new Date().getFullYear()} SV-Studios | Hasewinkel und Rempe GbR
          </MDTypography>
          <MDBadge
            badgeContent={config.version}
            variant="gradient"
            container
            color="secondary"
            size="xs"
            sx={{ mr: 1 }}
          />
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default Footer;
