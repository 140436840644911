import { useEffect } from "react";
import Card from "@mui/material/Card";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faSignOut } from "@fortawesome/pro-solid-svg-icons";
import { useSnackbar } from "notistack";
import CoverLayout from "../old/authentication/components/CoverLayout";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
import backgroundImage from "../../assets/images/custom/webinterface.png";
import config from "../../config";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import { LoginState } from "../../Utils";

function Logout() {
  const { enqueueSnackbar } = useSnackbar();
  const status = useSelector((state) => state.auth.status);
  const navigate = useNavigate();

  useEffect(() => {
    if (status === LoginState.unauthenticated) {
      enqueueSnackbar("Du musst angemeldet sein um auf diese Seite zuzugreifen!", {
        variant: "error",
        preventDuplicate: true,
      });
      navigate("/account/login");
    }
  }, [status]);

  return (
    <CoverLayout image={backgroundImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="error"
          borderRadius="lg"
          coloredShadow="error"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Abmelden
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox display="flex" alignItems="center" ml={-1}>
              <MDTypography variant="button" fontWeight="regular" color="text" sx={{ mx: "auto" }}>
                Wollen Sie sich wirklich abmelden?
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="error"
                fullWidth
                onClick={() => {
                  Cookies.remove("support_pin");
                  window.location.href = `${
                    config.local.enabled ? config.local.backend.url : config.backend.url
                  }/api/${config.local.enabled ? `` : `cloud/`}logout`;
                }}
              >
                <FontAwesomeIcon icon={faSignOut} size="lg" style={{ marginRight: "10px" }} /> Vom Dashboard
                abmelden
              </MDButton>
              <MDButton
                variant="outlined"
                color="error"
                sx={{ my: 2 }}
                fullWidth
                onClick={() => {
                  window.history.back();
                }}
              >
                <FontAwesomeIcon icon={faArrowLeft} size="lg" style={{ marginRight: "10px" }} />
                Zurück
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Bei Problemen wende dich an den Support{" "}
                <MDTypography variant="button" color="error" fontWeight="medium" textGradient>
                  <a href="mailto:support@sv-studios.net">support@sv-studios.net</a>
                </MDTypography>
              </MDTypography>
              <br />

              <MDTypography variant="button" color="text" sx={{ mt: "2", display: "none" }}>
                Wenn Sie ihren Account löschen möchten, klicken Sie{" "}
                <MDTypography
                  component={Link}
                  to="/account/delete"
                  variant="button"
                  color="error"
                  fontWeight="medium"
                  textGradient
                >
                  hier
                </MDTypography>
                .
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Logout;
