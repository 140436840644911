import apiErrorMessages from "../../../../apiMessages";
import { useMemo, useState } from "react";
import MDButton from "../../../../components/MDButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { faRobot, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useDeleteNotUsedBotMutation, useGetNotUsedBotsQuery } from "../../../../store/admin/admin-api";
import DataTable from "../../../../examples/Tables/DataTable";
import DashboardLayout from "../../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import Error404Modal from "../../../../components/Dialog/Error404Modal";
import MDBox from "../../../../components/MDBox";
import backgroundImage from "../../../../assets/images/bg-profile.jpeg";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDTypography from "../../../../components/MDTypography";
import FooterSimple from "../../../../components/Footer/FooterSimple";
import { AdminBotTokenForm } from "./AdminBotTokenForm";

export function AdminBotToken({}) {
  const { data: tokenData = {}, tokenIsLoading } = useGetNotUsedBotsQuery();
  const [deleteToken, { isLoading: deleteModuleDataIsLoading }] = useDeleteNotUsedBotMutation();
  const [showToken, setShowToken] = useState(false);

  const totalElements = tokenData?.totalElements || 0;

  const deleteDataEvent = (id) => {
    deleteToken({ id }).then(({ data, error }) => {
      apiErrorMessages.showMessage({
        type: "AdminToken",
        title: data ? "deleted" : error?.data?.error || error,
      });
    });
  };

  const tableColumns = useMemo(
    () =>
      [
        {
          Header: "Token",
          accessor: "token",
        },
        {
          Header: "Actions",
          accessor: "actions",
          disableSort: true,
          width: "0%",
        },
      ].filter((x) => x),
    []
  );

  const tableRows = useMemo(
    () => (row) => ({
      token: showToken ? row.token : row.token.replace(/./g, "*"),
      actions: (
        <MDButton
          variant="contained"
          iconOnly
          disabled={deleteModuleDataIsLoading}
          color="primary"
          onClick={() => deleteDataEvent(row.id)}
        >
          {deleteModuleDataIsLoading ? (
            <FontAwesomeIcon icon={faSpinnerThird} size="lg" spin />
          ) : (
            <FontAwesomeIcon icon={faTrash} size="lg" />
          )}
        </MDButton>
      ),
    }),
    [tokenData, deleteModuleDataIsLoading, showToken]
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Error404Modal />
      <MDBox mb={2} />
      <MDBox position="relative" mb={5}>
        <MDBox
          display="flex"
          alignItems="center"
          position="relative"
          minHeight="15rem"
          borderRadius="xl"
          sx={{
            backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.info.main, 0.6),
                rgba(gradients.info.state, 0.6)
              )}, url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            overflow: "hidden",
          }}
        />

        <Card
          sx={{
            position: "relative",
            mt: -5,
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <MDTypography variant="h5" fontWeight="medium">
                <FontAwesomeIcon icon={faRobot} size="2x" />
              </MDTypography>
            </Grid>
            <Grid item>
              <MDBox height="100%" mt={0.5} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium" sx={{ width: "100%" }}>
                  Admin Bot Token
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        </Card>
      </MDBox>

      <MDBox mb={2}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} lg={7} xl={8}>
            <Card sx={{ height: "100%" }}>
              <MDBox pt={2} px={2}>
                <MDTypography
                  variant="h6"
                  fontWeight="medium"
                  textTransform="capitalize"
                  sx={{
                    display: "inline-block",
                  }}
                >
                  Token {(totalElements > 0 && `(${totalElements})`) || ""}
                </MDTypography>
                <MDBox
                  sx={{
                    float: "right",
                  }}
                >
                  <FormControlLabel
                    p={0}
                    color="primary"
                    control={
                      <Checkbox color="primary" onChange={() => setShowToken((prevState) => !prevState)} />
                    }
                    label="Show Token"
                  />
                </MDBox>
              </MDBox>
              <MDBox p={2}>
                <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                  <DataTable
                    table={{
                      columns: tableColumns,
                      rows: tokenData?.content?.map(tableRows) || [],
                    }}
                    canSearch
                  />
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={5} xl={4}>
            <AdminBotTokenForm fullHeight />
          </Grid>
        </Grid>
      </MDBox>
      <FooterSimple />
    </DashboardLayout>
  );
}
