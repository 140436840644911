import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faEye, faEyeSlash } from "@fortawesome/pro-solid-svg-icons";
import { useState } from "react";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import MDInput from "./MDInput";
import MDButton from "./MDButton";
import MDBox from "./MDBox";

export default function ApiKeyInput({ value = "", label = "" }) {
  const { enqueueSnackbar } = useSnackbar();

  const [show, setShow] = useState(false);
  const [copied, setCopied] = useState(false);

  return (
    <MDBox sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
      <MDBox sx={{ flexGrow: 1 }}>
        <MDInput
          label={label}
          fullWidth
          type={show ? "text" : "password"}
          placeholder="Der Pin muss zuerst generiert werden."
          value={show ? value : value.replaceAll(/(.)/g, "*")}
          readOnly
        />
      </MDBox>
      <MDBox sx={{ flexGrow: 0 }}>
        {show ? (
          <MDButton
            color="secondary"
            iconOnly
            onClick={() => {
              setShow(false);
            }}
          >
            <FontAwesomeIcon icon={faEye} size="lg" />
          </MDButton>
        ) : (
          <MDButton
            iconOnly
            color="secondary"
            onClick={() => {
              setShow(true);
            }}
          >
            <FontAwesomeIcon icon={faEyeSlash} size="lg" />
          </MDButton>
        )}
      </MDBox>
      <MDBox sx={{ flexGrow: 0 }}>
        {copied ? (
          <Tooltip title="Kopiert!">
            <MDButton variant="outlined" color="secondary" iconOnly>
              <FontAwesomeIcon icon={faCopy} size="lg" />
            </MDButton>
          </Tooltip>
        ) : (
          <MDButton
            iconOnly
            color="secondary"
            onClick={() => {
              window.navigator.clipboard
                .writeText(value)
                .then(() => {
                  setCopied(true);
                  setTimeout(() => {
                    setCopied(false);
                  }, 3000);
                })
                .catch(() => {
                  enqueueSnackbar("Kopieren fehlgeschlagen!", { variant: "error" });
                });
            }}
          >
            <FontAwesomeIcon icon={faCopy} size="lg" />
          </MDButton>
        )}
      </MDBox>
    </MDBox>
  );
}

ApiKeyInput.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
};
