import Card from "@mui/material/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/pro-solid-svg-icons";
import { Collapse } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";
import MDButton from "../../../components/MDButton";
import MDTypography from "../../../components/MDTypography";
import MDBox from "../../../components/MDBox";
import { useGetPluginsQuery } from "../../../store/global/global-api";
import MDBadge from "../../../components/MDBadge";
import Tooltip from "@mui/material/Tooltip";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";

export default function PluginCard() {
  const { botId } = useParams();

  const [opened, setOpened] = useState(false);

  const {
    data: pluginsData = [],
    isFetching: pluginsIsFetching,
    isLoading: pluginsIsLoading,
    refetch: pluginsRefetch,
  } = useGetPluginsQuery({ botId });

  return (
    <Card>
      <MDBox
        p={2}
        width="100%"
        display="flex"
        justifyContent="space-between"
        onClick={() => setOpened(!opened)}
      >
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          Bot Plugins {pluginsIsFetching && <FontAwesomeIcon icon={faSpinnerThird} spin />}{" "}
          {!pluginsIsLoading && `(${pluginsData.length || 0})`}
        </MDTypography>
        <MDButton color="primary" iconOnly size="small" sx={{ marginLeft: "auto" }}>
          <FontAwesomeIcon
            icon={faAngleUp}
            style={{
              transform: opened ? "rotate(0)" : "rotate(-180deg)",
              transition: "transform .5s",
            }}
          />
        </MDButton>
      </MDBox>
      <Collapse in={opened} unmountOnExit>
        <MDBox pt={1} pb={2} px={2}>
          <MDTypography variant="h6" fontWeight="light" horizontalAlign="justify" gutterBottom pb={2}>
            Hier siehst du alle installierten Plugins. Du kannst gerne eigene Plugins beantragen.
          </MDTypography>
          {pluginsData.map(({ name, description, version, icon }) => (
            <Card key={name} sx={{ p: 2, mb: 2 }}>
              <MDBox display="flex" gap={2}>
                {icon && (
                  <MDBox width="100%" maxWidth={200}>
                    <img
                      src={icon}
                      style={{
                        width: "100%",
                        maxWidth: 200,
                        height: "auto",
                        borderRadius: 10,
                      }}
                      alt="Plugin Logo"
                    />
                  </MDBox>
                )}

                <MDBox>
                  <MDTypography variant="h6" fontWeight="medium" horizontalAlign="justify">
                    {name}
                  </MDTypography>
                  <MDBadge container color="light" badgeContent={version} />
                  <MDTypography variant="h6" fontWeight="light" horizontalAlign="justify" gutterBottom>
                    {description}
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Card>
          ))}
          {!pluginsIsLoading && pluginsData.length === 0 && (
            <MDTypography variant="h6" fontWeight="light" horizontalAlign="justify" gutterBottom>
              Es wurden keine Plugins gefunden.
            </MDTypography>
          )}

          <Tooltip title="Diese Funktion ist bald verfügbar, wenn du jetzt schon wünsche hast besuche unseren Discord Server">
            <span>
              <MDButton variant="gradient" color="primary" sx={{ mt: 2 }} disabled>
                Plugin beantragen
              </MDButton>
            </span>
          </Tooltip>
        </MDBox>
      </Collapse>
    </Card>
  );
}
