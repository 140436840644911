import { useMemo, useState } from "react";
import PropTypes from "prop-types";
import SimpleTable from "./SimpleTable";
import { useGetModuleTableDataQuery } from "../../store/modules/module-api";
import MDButton from "../MDButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/pro-solid-svg-icons";
import apiMessages from "../../apiMessages";
import MDAlert from "../MDAlert";
import MDTypography from "../MDTypography";

export default function ReloadTable({
  columns,
  rows,
  rowsLoad,
  update = false,
  rowsLoadLimit = null,
  path,
  defaultSize = 20,
  loaded = false,
  ...props
}) {
  const { botId, guildId, module, isModule = true, plugin, isPlugin = false, ...pathParams } = path;

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(defaultSize);
  const [sort, setSort] = useState([]);

  const {
    data: moduleData = [],
    isLoading: moduleIsLoading,
    refetch: refreshModule,
    isFetching: moduleIsFetching,
    error: moduleError,
  } = useGetModuleTableDataQuery({
    botId,
    guildId,
    module,
    isModule,
    isPlugin,
    plugin,
    page,
    size,
  });

  const refreshEvent = () => {
    refreshModule().then(() => {
      apiMessages.showMessage({
        title: "refreshed",
        preventDuplicate: true,
      });
    });
  };

  const refreshButton = (
    <MDButton
      iconOnly
      variant="outlined"
      color="primary"
      size="medium"
      disabled={moduleIsFetching}
      onClick={refreshEvent}
      sx={{ mr: 1, p: 1 }}
    >
      <FontAwesomeIcon icon={faSync} spin={moduleIsFetching} />
    </MDButton>
  );

  const pageData = useMemo(
    () => ({
      number: moduleData.number || 0,
      numberOfElements: moduleData.numberOfElements || 0,
      size: moduleData.size || 10,
      totalElements: moduleData.totalElements || 0,
      totalPages: moduleData.totalPages || 0,
      first: moduleData.first,
      last: moduleData.last,
      empty: moduleData.empty,
    }),
    [moduleData]
  );
  return (
    <>
      {moduleError && (
        <MDAlert color="error" sx={{ mb: 2 }}>
          <MDTypography variant="h6" fontWeight="medium">
            {moduleError?.data?.error || "Es ist ein Fehler beim Laden der Daten aufgetreten"}
          </MDTypography>
        </MDAlert>
      )}
      <SimpleTable
        {...props}
        refreshButton={refreshButton}
        columns={columns}
        showTotalEntries
        rows={
          moduleIsLoading
            ? [...Array(rowsLoadLimit || pageData?.size).keys()].map(rowsLoad)
            : moduleData.content?.map(rows) || []
        }
        loaded={!moduleIsLoading}
        pageData={pageData}
        setPageData={({ page: x, size: y }) => {
          setPage(x || 0);
          setSize(y);
          // setSort();
        }}
      />
    </>
  );
}

ReloadTable.propTypes = {
  update: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  rows: PropTypes.func.isRequired,
  rowsLoad: PropTypes.func.isRequired,
  loaded: PropTypes.bool,
  path: PropTypes.objectOf(PropTypes.any).isRequired,
};
