import { useMemo, useState } from "react";
import { useDeleteEntityMutation, useGetEntityQuery } from "../../../store/admin/admin-api";
import SimpleTable from "../../../components/Table/SimpleTable";
import MDBox from "../../../components/MDBox";
import { ButtonGroup } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye, faSync, faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import MDButton from "../../../components/MDButton";

const truncate = function (str, length = 100) {
  let string = str;
  string = str.length > length + 3 ? str.substring(0, length) + "..." : str;
  return (
    <span
      onClick={() => {
        string = str;
        console.log(string);
      }}
    >
      {string}
    </span>
  );
};

const Truncate = ({ children, limit }) => {
  const [isTruncated, setIsTruncated] = useState(true);
  const truncatedText = children.length > limit + 3 ? children.substring(0, limit) + "..." : children;

  return (
    <span
      onClick={() => {
        setIsTruncated(!isTruncated);
      }}
    >
      {isTruncated ? truncatedText : children}
    </span>
  );
};

export function AdminEntitiesTable({ botId, entityName }) {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);
  const [filter, setFilter] = useState(["id"]);
  const [sort, setSort] = useState({});

  // sort should been merged together in a string
  // e.g. sort=id,asc&sort=name,desc
  const sortString = useMemo(() => {
    let sortString = "";
    for (const [key, value] of Object.entries(sort)) {
      sortString += `&sort=${key},${value}`;
    }
    return sortString;
  }, [sort]);

  const {
    data: entityData,
    isError: entityIsError,
    error: entityError,
    isSuccess: entityIsSuccess,
    isFetching: entityIsFetching,
    isLoading: entityIsLoading,
    refetch: refreshEntity,
  } = useGetEntityQuery({
    botId,
    entity: entityName,
    page,
    size,
    search,
    sort: sortString,
  });
  const [deleteEntity, {}] = useDeleteEntityMutation();

  const pageData = useMemo(
    () => ({
      number: entityData?.number,
      numberOfElements: entityData?.numberOfElements,
      size: entityData?.size,
      totalElements: entityData?.totalElements,
      totalPages: entityData?.totalPages,
      first: entityData?.first,
      last: entityData?.last,
      empty: entityData?.empty,
    }),
    [entityData]
  );

  const TABLE_HEAD =
    entityData?.content?.length > 0
      ? Object.keys(entityData?.content[0]).map((key) => ({
          Header: key,
          accessor: key,
        }))
      : [];

  const TABLE_HEAD_FILTERED = TABLE_HEAD.filter((f) => !filter.includes(f));

  const TABLE_ROWS = entityData?.content;

  const reformattedData = (data) => {
    if (typeof data === "object") {
      return JSON.stringify(data);
    }

    return data;
  };

  const actions = {
    view: (entity) => {},
    edit: (entity) => {},
    delete: (entity) => {
      confirm(`Soll die Entität [${entity.id}] wirklich gelöscht werden?`) &&
        deleteEntity({ botId, entity: entityName, id: entity.id })
          .then(() => {
            alert(`Entität [${entity.id}] wurde erfolgreich gelöscht`);
          })
          .catch((e) => {
            alert("Fehler beim Löschen der Entität");
          });
    },
  };

  return (
    <MDBox px={2}>
      <SimpleTable
        refreshButton={
          <MDButton iconOnly onClick={() => refreshEntity()} color="primary" sx={{ mr: 1 }}>
            <FontAwesomeIcon icon={faSync} spin={entityIsFetching} />
          </MDButton>
        }
        // columns + actions
        columns={[
          ...TABLE_HEAD,
          {
            Header: "Actions",
            accessor: "actions",
            disableSort: true,
            width: "0%",
          },
        ]}
        showTotalEntries
        // rows with actions
        rows={
          TABLE_ROWS?.map((row) => {
            const truncatedRow = Object.entries(row).reduce((acc, [key, value]) => {
              // Nicht alle Werte müssen möglicherweise gekürzt werden, z.B. wenn es sich um ein nicht-string-Wert handelt
              if (typeof value === "string") {
                acc[key] = <Truncate limit={100}>{value}</Truncate>;
              } else if (typeof value === "object" && Array.isArray(value)) {
                acc[key] = <Truncate limit={100}>{JSON.stringify(value, null, 2)}</Truncate>;
              } else {
                acc[key] = value;
              }
              return acc;
            }, {});

            return {
              ...truncatedRow,
              actions: (
                <ButtonGroup>
                  <MDButton color="secondary" disabled iconOnly onClick={() => actions.view(row)}>
                    <FontAwesomeIcon icon={faEye} />
                  </MDButton>
                  <MDButton color="secondary" disabled iconOnly onClick={() => actions.edit(row)}>
                    <FontAwesomeIcon icon={faEdit} />
                  </MDButton>
                  <MDButton color="secondary" iconOnly onClick={() => actions.delete(row)}>
                    <FontAwesomeIcon icon={faTrashCan} />
                  </MDButton>
                </ButtonGroup>
              ),
            };
          }) || []
        }
        loaded={!entityIsLoading}
        pageData={pageData}
        setPageData={({ page: x, size: y }) => {
          setPage(x || 0);
          setSize(y);
          // setSort();
        }}
      />
    </MDBox>
  );
}
