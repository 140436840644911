import { useEffect, useMemo } from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowPointer, faRectangleXmark, faSquareCheck, faXmark } from "@fortawesome/pro-solid-svg-icons";
import { Grid, Skeleton } from "@mui/material";
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";
import MDButton from "../MDButton";
import ReloadTable from "../Table/ReloadTable";
import parseMessage from "../EmbedBuilder/DiscordParser";
import { useGetCategoriesQuery, useGetTextChannelsQuery } from "../../store/guild/guild-api";
import { useForm, useWatch } from "react-hook-form";
import { FormCategoryAutocomplete } from "../Form/FormInput";
import { useSelector } from "react-redux";
import MDAlert from "../MDAlert";
import { faCircleXmark } from "@fortawesome/pro-duotone-svg-icons";

/**
 * `LastMessageModal` is a React functional component that renders a modal dialog.
 * This modal is used to display the last message from a selected channel.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.botId - The ID of the bot.
 * @param {string} props.serverId - The ID of the server (guild).
 * @param {string|null} [props.defaultChannel=null] - The default channel ID.
 * @param {JSX.Element} [props.infoText=<div />] - Additional information text to display.
 * @param {Array} [props.allowedMessages=[]] - Array of allowed message types. Each element should be one of the following: "selfBot", "selfUser", "otherUser", "otherBot".
 * @param {function} [props.onClose=(x, d) => {}] - Function to call when the modal is closed.
 * @param {boolean} [props.open=false] - Whether the modal is open or not.
 *
 * @returns {React.Node} The `LastMessageModal` component.
 */
function LastMessageModal({
  botId,
  serverId: guildId,
  defaultChannel = null,
  infoText = <></>,
  allowedMessages = [],
  onClose = (x, d) => {},
  open = false,
}) {
  const {
    data: textChannelData = [],
    isLoading: textChannelIsLoading,
    refetch: textChannelsRefetch,
    isFetching: textChannelsIsFetching,
  } = useGetTextChannelsQuery({
    botId,
    guildId,
  });
  const {
    data: categoriesData = [],
    isLoading: categoriesIsLoading,
    refetch: categoriesRefetch,
    isFetching: categoriesIsFetching,
  } = useGetCategoriesQuery({
    botId,
    guildId,
  });

  const formHandler = useForm({
    defaultValues: {
      channelId: "",
      channelName: "",
      userLimit: "",
    },
  });
  const { control, handleSubmit, setValue } = formHandler;

  const expertMode = useSelector((state) => state.ui.expertMode);
  const darkMode = useSelector((state) => state.ui.darkMode);

  useEffect(() => {
    if (defaultChannel) {
      setValue("channelId", defaultChannel);
    }
  }, [defaultChannel]);

  const close = () => onClose(false, null);

  const channel = useWatch({
    control,
    name: "channelId",
  });

  const tableColumns = useMemo(
    () =>
      [
        {
          accessor: "actions",
          Header: "",
          size: "small",
          sortable: false,
          disableSort: true,
          width: "0%",
        },
        expertMode
          ? {
              Header: "Nachricht",
              accessor: "message",
            }
          : null,
        { Header: "Nachricht", accessor: "parse" },
      ].filter((x) => x),
    [expertMode]
  );

  const disableRow = (message) => {
    if (allowedMessages.includes("selfBot")) return message.author.id !== botId;
    if (allowedMessages.includes("selfUser")) return message.author.id !== botId;
    if (allowedMessages.includes("otherUser")) return message.author.id === botId;
    if (allowedMessages.includes("otherBot")) return message.author.id !== botId;

    return false;
  };

  const tableRows = useMemo(
    () => (message) => ({
      message: message.id?.toString(),
      parse: parseMessage(message, true, darkMode),
      actions: !disableRow(message) && (
        <MDButton
          variant="contained"
          color="primary"
          iconOnly
          onClick={() => {
            onClose(false, {
              channel,
              message: message.id,
            });
          }}
        >
          <FontAwesomeIcon icon={faSquareCheck} size="xl" />
          <span className="sr-only">Nachricht auswählen</span>
        </MDButton>
      ),
    }),
    [channel, darkMode]
  );

  return (
    <Dialog maxWidth="xl" fullWidth open={open} onClose={() => close()}>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <MDTypography sx={{ flex: 1 }} variant="h6" component="div">
            Nachricht auswählen
          </MDTypography>
          <IconButton
            edge="end"
            onClick={() => close()}
            aria-label="close"
            sx={{
              borderRadius: "0px",
            }}
          >
            <MDTypography>
              <FontAwesomeIcon icon={faXmark} />
            </MDTypography>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <MDBox pt={1} px={2}>
            <FormCategoryAutocomplete
              name="channelId"
              label="Channel"
              categories={categoriesData}
              options={[...textChannelData].sort((x, y) => x.category - y.category)}
              groupByFunction="default"
              formHandler={formHandler}
              refetch={() => {
                textChannelsRefetch();
                categoriesRefetch();
              }}
              refetching={textChannelsIsFetching || categoriesIsFetching}
              rules={{
                required: "Du musst einen Channel auswählen.",
              }}
            />
          </MDBox>
          <MDBox px={2} pb={2}>
            <MDTypography variant="subtitle2">
              Es können nur Nachrichten ausgewählt werden, die vom Bot gesendet wurden, die Nachricht muss
              eine der letzten 30 Nachrichten sein. <br />
              Wenn mehr als 30 Nachrichten vorhanden sind, können alte mit dem Befehl `/clear` gelöscht
              werden.
            </MDTypography>
          </MDBox>
          <MDBox px={2} pb={2}>
            {!channel && (
              <MDAlert color="warning">
                <MDTypography variant="body2">
                  Du musst einen Channel auswählen, um die Nachrichten zu sehen.
                </MDTypography>
              </MDAlert>
            )}
            {infoText}
            {channel && (
              <ReloadTable
                path={{
                  botId,
                  guildId,
                  module: `channel/${channel}/messages`,
                  isModule: false,
                }}
                rowsLoadLimit={3}
                rowsLoad={() => ({
                  message: <Skeleton width={100} animation="wave" />,
                  parse: <Skeleton width={400} animation="wave" />,
                  actions: <Skeleton width={50} animation="wave" />,
                })}
                columns={tableColumns}
                rows={tableRows}
                canSearch
                showTotalEntries
                headerPadding={2}
                defaultSize={15}
                pageSizes={[15, 30]}
              />
            )}
          </MDBox>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default LastMessageModal;
