import { FallbackComponent } from "./FallbackComponent";
import * as Sentry from "@sentry/react";

export function ErrorBoundary({ children }) {
  return (
    <Sentry.ErrorBoundary
      fallback={({ eventId, error, componentStack, resetError }) => (
        <FallbackComponent
          eventId={eventId}
          error={error}
          componentStack={componentStack}
          resetError={resetError}
          display="flex"
          flexDirection="column"
        />
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}
