import JSONBig from "json-bigint";
import config from "../../../config";
import { BodyType, Method, requestData } from "../../../RequestUtils";

/**
 * It fetches data from the backend and caches it in the localStorage
 * @param bot {bigint}
 * @param guild {bigint || null}
 * @param type {string || null}
 * @param module {string || null}
 * @param [callback] {function}
 * @param errorCallback {function}
 * @param [renew] {boolean}
 * @param force {boolean}
 * @param searchParams
 * @returns {Promise<any>} A promise that resolves to the data from the serverId.
 */
function getData({
  bot,
  guild = null,
  type = null,
  module = null,
  callback = () => {},
  errorCallback = () => {},
  renew = false,
  force = false,
  searchParams = null,
}) {
  return new Promise((resolve, reject) => {
    const path = `bot/${bot}${guild !== null ? `/guild/${guild}` : ""}${
      module !== null ? `/module/${module}` : ""
    }${type !== null ? `/${type}` : ""}`;
    requestData({
      path: `${bot}${guild !== null ? `/guild/${guild}` : ""}${
        module !== null ? `/module/${module}` : ""
      }${type !== null ? `/${type}` : ""}`,
      searchParams,
      method: "GET",
    })
      .then((result) => {
        callback(result);
        resolve(result);
      })
      .catch((error) => {
        errorCallback(error);
        reject(error);
      });
  });
}

function saveData({ bot, guild = null, module = null, type = null, id = null, data = {} }) {
  const path = `${config.local.enabled ? config.local.backend.url : config.backend.url}/api/${bot}${
    guild !== null ? `/guild/${guild}` : ""
  }${module !== null ? `/module/${module}` : ""}${type !== null ? `/${type}` : ""}${
    id !== null ? `/${id}` : ""
  }`;
  localStorage.setItem(
    path,
    JSONBig.stringify({
      data,
      expire: new Date(new Date().getTime() + config.cacheTimes.server.modules),
    })
  );
}

/**
 * It sends a PUT request to the backend with the given parameters
 * @param bot
 * @param guild
 * @param module
 * @param type
 * @param id
 * @param data
 * @param callback
 * @param errorFunction
 * @param searchParams
 */
function putData({
  bot,
  guild = null,
  module = null,
  type = null,
  id = null,
  data = {},
  callback = () => {},
  errorFunction = () => {},
  searchParams = null,
}) {
  requestData({
    path: `${bot}${guild !== null ? `/guild/${guild}` : ""}${
      module !== null ? `/module/${module}` : ""
    }${type !== null ? `/${type}` : ""}`,
    searchParams,
    method: Method.PUT,
    body: data,
    type: BodyType.JSON,
  })
    .then((result) => {
      callback(result);
    })
    .catch((error) => {
      errorFunction(error);
    });
}

/**
 * It posts data to the backend
 * @param bot
 * @param server
 * @param module
 * @param type
 * @param data
 * @param callback
 * @param errorFunction
 */
function postData({
  bot,
  guild = null,
  module = null,
  type = null,
  data = null,
  callback = () => {},
  errorFunction = () => {},
  searchParams = null,
}) {
  requestData({
    path: `${bot}${guild !== null ? `/guild/${guild}` : ""}${
      module !== null ? `/module/${module}` : ""
    }${type !== null ? `/${type}` : ""}`,
    searchParams,
    method: Method.POST,
    body: data,
    type: BodyType.JSON,
  })
    .then((result) => {
      callback(result);
    })
    .catch((error) => {
      errorFunction(error);
    });
}

/**
 * It deletes data from the backend
 * @param bot
 * @param guild
 * @param module
 * @param type
 * @param id
 * @param callback
 * @param errorFunction
 * @param searchParams
 */
function deleteData({
  bot,
  guild = null,
  module = null,
  type = null,
  id = null,
  callback = () => {},
  errorFunction = () => {},
  searchParams = null,
}) {
  requestData({
    path: `${bot}${guild !== null ? `/guild/${guild}` : ""}${
      module !== null ? `/module/${module}` : ""
    }${type !== null ? `/${type}` : ""}${id !== null ? `/${id}` : ""}`,
    searchParams,
    method: Method.DELETE,
  })
    .then((result) => {
      callback(result);
    })
    .catch((error) => {
      errorFunction(error);
    });
}

export const hexToDecimal = (hex) => parseInt(hex, 16);

// eslint-disable-next-line import/prefer-default-export
export { getData, deleteData, postData, saveData, putData };
