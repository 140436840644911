import { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import { Skeleton } from "@mui/material";
import Grid from "@mui/material/Grid";
import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";
import MDButton from "../../../../../components/MDButton";
import MDBadge from "../../../../../components/MDBadge";
import MDAlert from "../../../../../components/MDAlert";
import ReloadTable from "../../../../../components/Table/ReloadTable";

function LogsOverviewTab({ botId, serverId }) {
  const [loaded, setLoaded] = useState(false);
  const [logs, setLogs] = useState([]);

  const [update, setUpdate] = useState(false);

  useEffect(async () => {
    setLoaded(true);
    return () => {
      setLoaded(false);
    };
  }, [window.location.pathname]);

  // TODO - add a way to filter the logs by category + pagination system

  const tableColumns = useMemo(
    () => [
      {
        Header: "LogType",
        accessor: "type",
      },
      {
        Header: "Titel",
        accessor: "titles",
      },
      {
        Header: "Member",
        accessor: "member",
      },
      {
        Header: "Zeitpunkt",
        accessor: "time",
      },
      {
        Header: "Aktionen",
        accessor: "action",
      },
    ],
    []
  );

  const tableRows = useMemo(
    () => (log) => ({
      type: log.logType,
      titles: log.message?.embeds?.map((embed) => embed.title).join(", ") || "?",
      time: new Date().toLocaleString(),
      member: log.message?.embeds?.map((embed) =>
        embed.fields
          ?.filter((field) => field.name === "Member" || field.name === "Executed by")
          .map((field) => (
            <MDBadge
              sx={{ marginX: 1 }}
              badgeContent={field.value}
              variant="contained"
              color="secondary"
              size="sm"
              container
            />
          ))
      ),
      action: (
        <MDButton variant="contained" color="primary" disabled>
          Details
        </MDButton>
      ),
    }),
    []
  );

  return (
    <>
      <Grid item xs={12}>
        <MDAlert>Es werden bald alle kürzlichen Logs hier angezeigt.</MDAlert>
        <Card sx={{ height: "100%" }}>
          <MDBox pt={2} px={2}>
            <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
              Logs
            </MDTypography>
          </MDBox>
          <MDBox p={2}>
            <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
              {useMemo(
                () => (
                  <ReloadTable
                    path={{
                      bot: botId,
                      guild: serverId,
                      module: "log",
                      type: "messages",
                      searchParams: [
                        ["logTypes", "TYPE_AUDIT_LOG"],
                        ["logTypes", "TYPE_MODERATION_LOG"],
                        ["logTypes", "TYPE_MONEY_LOG"],
                        ["logTypes", "TYPE_TICKET_LOG"],
                        ["logTypes", "CHANNEL_CREATE"],
                        ["logTypes", "MESSAGE_UPDATE"],
                        ["logTypes", "CHANNEL_DELETE"],
                        ["logTypes", "MESSAGE_DELETE"],
                        ["logTypes", "MEMBER_JOIN"],
                        ["logTypes", "MEMBER_LEAVE"],
                        ["logTypes", "INVITE_CREATE"],
                        ["logTypes", "INVITE_DELETE"],
                        ["logTypes", "NICKNAME_CHANGE"],
                        ["logTypes", "ROLE_ADD"],
                        ["logTypes", "ROLE_REMOVE"],
                        ["logTypes", "GUILD_UPDATE_NAME"],
                        ["logTypes", "TYPE_MONEY_LOG"],
                        ["logTypes", "TYPE_MODERATION_LOG"],
                        ["logTypes", "TYPE_WORD_BLACKLIST"],
                        ["logTypes", "TYPE_PUNISHMENT"],
                        ["logTypes", "TYPE_AUDIT_LOG"],
                        ["logTypes", "TEMP_BAN"],
                        ["logTypes", "KICK"],
                        ["logTypes", "WARN"],
                        ["logTypes", "BAN"],
                        ["logTypes", "WARNS_CLEAR"],
                        ["logTypes", "UN_BAN"],
                        ["logTypes", "CLEAR"],
                        ["logTypes", "CHANNEL_CLEAR"],
                        ["logTypes", "TICKET_CREATE"],
                        ["logTypes", "TICKET_CLOSE"],
                        ["logTypes", "BLACKLISTED_WORD_SEND"],
                      ],
                    }}
                    update={update}
                    loaded={loaded}
                    rowsLoad={() => ({
                      question: <Skeleton width={100} animation="wave" />,
                      answers: <Skeleton width={100} height={200} animation="wave" />,
                      expiresAt: <Skeleton width={100} animation="wave" />,
                      actions: <Skeleton width={100} animation="wave" />,
                    })}
                    columns={tableColumns}
                    rows={tableRows}
                    canSearch
                    showTotalEntries
                  />
                ),
                [tableColumns, tableRows, loaded, update, botId, serverId]
              )}
            </MDBox>
          </MDBox>
        </Card>
      </Grid>
    </>
  );
}

export default LogsOverviewTab;
LogsOverviewTab.propTypes = {
  botId: PropTypes.string.isRequired,
  serverId: PropTypes.string.isRequired,
};
