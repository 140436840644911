import { useEffect, useState } from "react";
import Cookies from "js-cookie";

/**
 * It returns a pair of values. The first value is the value of the URL parameter, and the second value is a function that
 * sets the value of the URL parameter
 * @param param - The name of the parameter to use.
 * @param [automaticallyAdd=true] - If true, the default value will be added to the URL when the component is mounted.
 * @param [defaultValue] - The default value to use if the parameter is not set.
 * @returns The first return value is the value of the parameter. The second return value is a function that sets the value
 * of the parameter.
 */
export function useUrlParameter(param, automaticallyAdd = true, defaultValue = "") {
  const url = new URLSearchParams(window.location.search);
  const [value, setValue] = useState(url.get(param));

  useEffect(() => {
    if (!value && automaticallyAdd) {
      url.set(param, defaultValue);
      window.history.pushState(null, null, `?${url.toString()}`);

      setValue(defaultValue);
    }
  }, [value, automaticallyAdd, defaultValue, param]);

  return [
    !value && automaticallyAdd ? defaultValue : value,
    // eslint-disable-next-line no-shadow
    (value) => {
      url.set(param, value);
      window.history.pushState(null, null, `?${url.toString()}`);

      setValue(value);
    },
  ];
}

/**
 * It returns a pair of values. The first value is the value of the cookie. The second value is a function that sets the
 * value of the cookie
 * @param param - The name of the cookie parameter.
 * @param [automaticallyAdd=true] - If true, the cookie will be set to the default value if it doesn't exist.
 * @param [defaultValue] - The default value to use if the cookie doesn't exist.
 * @returns The first value is the value of the cookie. The second value is a function that sets the value of the cookie.
 */
export function useCookieParameter(param, automaticallyAdd = true, defaultValue = "") {
  // eslint-disable-next-line no-use-before-define
  const tempValue = Cookies.get(param);

  // eslint-disable-next-line no-use-before-define
  const [value, setValue] = useState(convertToType(defaultValue, tempValue));

  useEffect(() => {
    if (!value && typeof value !== "boolean" && automaticallyAdd) {
      // eslint-disable-next-line no-use-before-define
      Cookies.set(param, defaultValue);

      setValue(defaultValue);
    }
  }, [value, automaticallyAdd, defaultValue, param, sessionStorage]);

  return [
    !value && typeof value !== "boolean" && automaticallyAdd ? defaultValue : value,
    // eslint-disable-next-line no-shadow
    (value) => {
      // eslint-disable-next-line no-use-before-define
      Cookies.set(param, value);

      setValue(value);
    },
  ];
}

/**
 * It returns a pair of values. The first value is the value of the session parameter. The second value is a function that
 * sets the value of the session parameter
 * @param param - The name of the session parameter.
 * @param [automaticallyAdd=true] - If true, the default value will be added to the session storage if it doesn't exist.
 * @param [defaultValue] - The default value to use if the session storage value is not set.
 * @param [sessionStorage] - The session storage object to use.
 * @returns The first return value is the value of the parameter. The second return value is a function that can be used to
 * set the value of the parameter.
 */
export function useSessionParameter(
  param,
  automaticallyAdd = true,
  defaultValue = "",
  sessionStorage = window.sessionStorage
) {
  const tempValue = sessionStorage.getItem(param);

  // eslint-disable-next-line no-use-before-define
  const [value, setValue] = useState(convertToType(defaultValue, tempValue));

  useEffect(() => {
    if (!value && typeof value !== "boolean" && automaticallyAdd) {
      sessionStorage.setItem(param, defaultValue);

      setValue(defaultValue);
    }
  }, [value, automaticallyAdd, defaultValue, param, sessionStorage]);

  return [
    !value && typeof value !== "boolean" && automaticallyAdd ? defaultValue : value,
    // eslint-disable-next-line no-shadow
    (value) => {
      sessionStorage.setItem(param, value);

      setValue(value);
    },
  ];
}

/**
 * If the type of the value is boolean, then convert the value to a boolean. Otherwise, convert the value to the type of
 * the value
 * @param t - The type of the value to be converted.
 * @param e - The value to be converted.
 * @returns The value of e converted to the type of t.
 */
function convertToType(t, e) {
  if (typeof t === "boolean") {
    return e === "true";
  }

  return t.constructor(e);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  useCookieParameter,
  useUrlParameter,
  useSessionParameter,
};
