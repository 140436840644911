import { useGetModuleDataQuery, useUpdateModuleDataMutation } from "../../../../../store/modules/module-api";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import apiMessages from "../../../../../apiMessages";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MDTypography from "../../../../../components/MDTypography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRectangleXmark } from "@fortawesome/pro-solid-svg-icons";
import { Grid } from "@mui/material";
import MDBox from "../../../../../components/MDBox";
import MDButton from "../../../../../components/MDButton";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import Dialog from "@mui/material/Dialog";
import VoteSystemForm from "./VoteSystemForm";
import { DateTime } from "luxon";

export function VoteSystemModal({ botId, guildId, editEntityId, onClose }) {
  const [update, { isLoading }] = useUpdateModuleDataMutation();

  const {
    data: voteSystemData = {},
    isLoading: voteSystemIsLoading,
    refetch: voteSystemRefetch,
    isRefetching: voteSystemIsRefetching,
  } = useGetModuleDataQuery(
    {
      botId,
      guildId,
      module: "vote",
      id: editEntityId,
      isModule: false,
    },
    {
      skip: editEntityId === null,
    }
  );

  const formHandler = useForm({
    defaultValues: {
      channelId: "",
      question: "",
      answers: [],
      expiresAt: "",
      isAnonymous: false,
      isMultiVote: false,
    },
  });

  const { control, handleSubmit, setValue, getValues } = formHandler;

  useEffect(() => {
    if (!voteSystemData) return;
    setValue("channelId", voteSystemData.channelId);
    setValue("question", voteSystemData.question);
    console.log(voteSystemData.answers);
    console.log(voteSystemData.answers?.map((a) => ({ text: a.text })));
    setValue(
      "answers",
      voteSystemData.answers?.map((a) => ({ text: a.text }))
    );
    voteSystemData.expiresAt &&
      setValue(
        "expiresAt",
        DateTime.fromFormat(voteSystemData.expiresAt, "yyyy-MM-dd HH:mm:ss", { zone: "utc" })
          .toLocal()
          .toFormat("yyyy-MM-dd HH:mm")
      );
    setValue("isAnonymous", voteSystemData.isAnonymous);
    setValue("isMultiVote", voteSystemData.isMultiVote);
  }, [voteSystemData]);

  const close = () => {
    onClose(false);
  };

  const onSubmit = async (e) => {
    update({
      botId,
      guildId,
      module: "vote",
      isModule: false,
      id: editEntityId,
      data: {
        ...e,
        expiresAt: e.expiresAt ? new Date(e.expiresAt).getTime() : null,
      },
    }).then(({ data, error }) => {
      apiMessages.showMessage({
        type: "VoteSystem",
        title: data ? "updated" : error?.data?.error || error,
      });
      if (data) onClose();
    });
  };

  return (
    <Dialog maxWidth="lg" open={editEntityId !== null} onClose={() => close()}>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <MDTypography sx={{ flex: 1 }} variant="h6" component="div">
            Abstimmung bearbeiten
          </MDTypography>
          <IconButton edge="end" onClick={() => close()} aria-label="close">
            <MDTypography>
              <FontAwesomeIcon icon={faRectangleXmark} />
            </MDTypography>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <VoteSystemForm guildId={guildId} botId={botId} formHandler={formHandler} />
            <MDBox pt={1} pb={2} px={2}>
              <MDButton variant="contained" type="submit" color="primary" fullWidth disabled={isLoading}>
                Vote aktualisieren
                {isLoading && (
                  <FontAwesomeIcon
                    icon={faSpinnerThird}
                    size="lg"
                    spin
                    style={{
                      marginLeft: "5px",
                    }}
                  />
                )}
              </MDButton>
            </MDBox>
          </form>
        </Grid>
      </Grid>
    </Dialog>
  );
}
