/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// Material Dashboard 2 PRO React imageExample components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FooterSimple from "../../components/Footer/FooterSimple";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";

// WelcomeLeave page components
import PlatformSettings from "layouts/old/pages/profile/profile-overview/components/PlatformSettings";
import Header from "./components/OverviewHeader";
import DataTable from "../../examples/Tables/DataTable";
import MDTypography from "../../components/MDTypography";
import Error404Modal from "../../components/Dialog/Error404Modal";
import MDAlert from "../../components/MDAlert";
import {useGetAccountQuery, useGetAccountRolesQuery} from "../../store/auth/auth-api";
import MDBadge from "../../components/MDBadge";
import Card from "@mui/material/Card";

// Data

function Overview() {
  const { data: accountData } = useGetAccountQuery();
  const { data: accountRolesData } = useGetAccountRolesQuery();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Error404Modal />
      <MDBox mb={2} />
      <Header account={accountData}>
        <MDBox mt={5} mb={3} sx={{ display: "none" }}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <PlatformSettings />
            </Grid>
            <Grid item xs={12} md={4} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <ProfileInfoCard
                title="profile information"
                description="Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality)."
                info={{
                  fullName: accountData?.username,
                  mobile: "(44) 123 1234 123",
                  email: "alecthompson@mail.com",
                  location: "USA",
                }}
                social={[
                  {
                    link: "https://www.facebook.com/CreativeTim/",
                    icon: <FacebookIcon />,
                    color: "facebook",
                  },
                  {
                    link: "https://twitter.com/creativetim",
                    icon: <TwitterIcon />,
                    color: "twitter",
                  },
                  {
                    link: "https://www.instagram.com/creativetimofficial/",
                    icon: <InstagramIcon />,
                    color: "instagram",
                  },
                ]}
                action={{ route: "", tooltip: "Edit Profile" }}
                shadow={false}
              />
              <Divider orientation="vertical" sx={{ mx: 0 }} />
            </Grid>
            <Grid item xs={12} md={4}>
              <MDBox p={2}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  Logs
                </MDTypography>
              </MDBox>
              <DataTable
                table={{
                  columns: [
                    { Header: "Id", accessor: "name", width: "12%" },
                    { Header: "Name", accessor: "position", width: "11%" },
                    { Header: "Zeit", accessor: "office", width: "11%" },
                    { Header: "Aktionen", accessor: "age", width: "11%" },
                  ],
                  rows: [],
                }}
              />
            </Grid>
          </Grid>
        </MDBox>
      </Header>
      <MDAlert color="info">Diese Seite ist noch in der Entwicklung.</MDAlert>

      <Card sx={{ p: 2 }}>
        <MDTypography variant="h5" fontWeight="medium" textTransform="capitalize">
          Rollen
        </MDTypography>
        <MDBox display="flex" flexWrap="wrap" gap={1} sx={{ mt: 2 }}>
          {accountRolesData?.roles?.map((role) => {
            return (
              <MDBadge
                container
                gap={2}
                badgeContent={role
                  .split("_")
                  .filter((x) => x !== "SV")
                  .join(" ")}
                color="primary"
                size="md"
              />
            );
          })}
        </MDBox>
      </Card>

      <FooterSimple />
    </DashboardLayout>
  );
}

export default Overview;
