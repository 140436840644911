import { useMemo } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";

// Material Dashboard 2 PRO React imageExample components
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import FooterSimple from "../../../components/Footer/FooterSimple";

import Card from "@mui/material/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faRobot, faSync, faTag } from "@fortawesome/pro-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "@mui/material";
import backgroundImage from "../../../assets/images/bg-profile.jpeg";
import Error404Modal from "../../../components/Dialog/Error404Modal";
import MDButton from "../../../components/MDButton";
import MDAlert from "../../../components/MDAlert";
import apiMessages from "../../../apiMessages";
import {
  useDeleteBotMutation,
  useListBotsQuery,
  useRedeemAdminSupportPinMutation,
  useRestartBotMutation,
} from "../../../store/admin/admin-api";
import { useDispatch } from "react-redux";
import { cookieActions } from "../../../store/cookie/cookie-slice";
import { AdminBotPanelCard } from "./AdminBotPanelCard";
import Cookies from "js-cookie";

function AdminBotPanel() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    data: botList = [],
    isLoading: botListIsLoading,
    isFetching: botListIsFetching,
    error: botListError,
    refetch: botListRefetch,
  } = useListBotsQuery(
    {
      page: 0,
      size: 1000,
    },
    {
      pollingInterval: 1000 * 60,
    }
  );

  const [deleteBot, { isLoading: deleteBotIsLoading }] = useDeleteBotMutation();
  const [restartBot, { isLoading: restartBotIsLoading }] = useRestartBotMutation();
  const [redeemAdminSupportPin, {}] = useRedeemAdminSupportPinMutation();

  const pageData = useMemo(
    () => ({
      number: botList.number || 0,
      numberOfElements: botList.numberOfElements || 0,
      size: botList.size || 1000,
      totalElements: botList.totalElements || 0,
      totalPages: botList.totalPages || 0,
      first: botList.first,
      last: botList.last,
      empty: botList.empty,
    }),
    [botList]
  );

  const deleteBotEvent = (botId, name, type) => {
    const prompt = window.prompt(
      `Bist du dir sicher das du den Bot [${name}] (${botId}) löschen möchtest? Falls ja, schreibe den Bot Namen oder seine ID in das Feld.`
    );
    if (!(prompt === botId || prompt === name))
      return alert("Du hast den Bot Namen oder die ID falsch eingegeben!");

    if (type !== "PRIVATE_BOT") {
      const confirm = window.confirm(`Bist du dir sicher das du einen ${type} löschen möchtest?`);
      if (!confirm) return alert("Abgebrochen!");
    }

    deleteBot({ botId }).then(({ data, error }) => {
      apiMessages.showMessage({
        type: "AdminPanel",
        title: data ? "deleted" : error?.data?.error || error,
      });
    });
  };

  const redeemSupportPinEvent = (botId) => {
    redeemAdminSupportPin({ botId }).then(({ data, error }) => {
      const supportPinCookie = Cookies.get("support_pin");
      dispatch(cookieActions.setSupportPin(supportPinCookie));
      apiMessages.showMessage({
        type: "AdminPanel",
        title: data ? "supportPinSuccess" : error?.data?.error || error,
      });
    });
  };

  const restartBotEvent = (botId) => {
    restartBot({ botId }).then(({ data, error }) => {
      apiMessages.showMessage({
        type: "AdminPanel",
        title: data
          ? "restart"
          : (alert(
              "Bot wird neugestartet, ... - Diese Nachricht verschwindet wenn ich eine JSON vom Backend bekomme, ..."
            ) &&
              error?.data?.error) ||
            error,
      });
    });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Error404Modal />
      <MDBox mb={2} />
      <MDBox position="relative" mb={5}>
        <MDBox
          display="flex"
          alignItems="center"
          position="relative"
          minHeight="15rem"
          borderRadius="xl"
          sx={{
            backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.info.main, 0.6),
                rgba(gradients.info.state, 0.6)
              )}, url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            overflow: "hidden",
          }}
        />

        <Card
          sx={{
            position: "relative",
            mt: -5,
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <MDTypography variant="h5" fontWeight="medium">
                <FontAwesomeIcon icon={faRobot} size="2x" />
              </MDTypography>
            </Grid>
            <Grid item>
              <MDBox height="100%" mt={0.5} lineHeight={1}>
                <MDTypography
                  variant="h5"
                  fontWeight="medium"
                  sx={{ width: "100%", display: "inline-block" }}
                >
                  Admin Bot Panel
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid
              item
              sx={{
                marginLeft: "auto",
              }}
            >
              <MDBox display="flex" alignItems="center" mt={1} gap={1}>
                <MDButton
                  color="info"
                  onClick={botListRefetch}
                  iconOnly
                  title="Liste aktualisieren"
                  disabled={botListIsFetching}
                >
                  <FontAwesomeIcon icon={faSync} />
                </MDButton>
                <MDButton
                  color="info"
                  onClick={() => {
                    navigate("/admin/bot/token");
                  }}
                  iconOnly
                  title="Token verwalten"
                >
                  <FontAwesomeIcon icon={faTag} />
                </MDButton>
                <MDButton
                  color="info"
                  onClick={() => {
                    navigate("/admin/bot/create");
                  }}
                  title="Neuen Bot erstellen"
                  iconOnly
                >
                  <FontAwesomeIcon icon={faPlus} />
                </MDButton>
              </MDBox>
            </Grid>
          </Grid>
        </Card>
      </MDBox>
      {botListError && (
        <MDAlert color="error">
          [{botListError.status}] {botListError.error}
        </MDAlert>
      )}

      {botListIsLoading ? (
        <MDBox p={4}>
          <Grid container spacing={3}>
            {[1, 2, 3, 4, 5, 6].map((_, key) => (
              <Grid item xs={12} md={6} lg={4} key={key}>
                <Skeleton variant="rectangular" height={300} />
              </Grid>
            ))}
          </Grid>
        </MDBox>
      ) : (
        <>
          <MDBox pb={3}>
            <MDBox mt={5}>
              <Grid container spacing={3}>
                {Array.isArray(botList.content) &&
                  botList?.content?.map((bot, index) => (
                    <Grid item xs={12} md={6} lg={4} key={index}>
                      <AdminBotPanelCard
                        bot={bot}
                        deleteBotEvent={deleteBotEvent}
                        redeemSupportPinEvent={redeemSupportPinEvent}
                        restartBotEvent={restartBotEvent}
                      />
                    </Grid>
                  ))}
              </Grid>
            </MDBox>
          </MDBox>
          <MDBox mb={5} display="flex" gap={2} flexDirection={{ xs: "column", md: "row" }}>
            <MDButton color="dark" fullWidth onClick={botListRefetch} disabled={botListIsFetching}>
              <FontAwesomeIcon
                icon={faSync}
                style={{
                  marginRight: "5px",
                }}
              />
              Liste aktualisieren {botListIsFetching && <FontAwesomeIcon icon={faSync} spin />}
            </MDButton>
            <MDButton
              color="dark"
              fullWidth
              onClick={() => {
                navigate("/admin/bot/token");
              }}
            >
              <FontAwesomeIcon
                icon={faTag}
                style={{
                  marginRight: "5px",
                }}
              />
              Token verwalten
            </MDButton>
            <MDButton
              color="dark"
              fullWidth
              onClick={() => {
                navigate("/admin/bot/create");
              }}
            >
              <FontAwesomeIcon
                icon={faPlus}
                style={{
                  marginRight: "5px",
                }}
              />
              Neuen Bot erstellen
            </MDButton>
          </MDBox>
        </>
      )}

      <FooterSimple />
    </DashboardLayout>
  );
}

export default AdminBotPanel;
