import { useForm } from "react-hook-form";
import {
  useGetAdminBotQuery,
  useGetBotDetailsQuery,
  useUpdateAdminBotMutation,
} from "../../../../store/admin/admin-api";
import Card from "@mui/material/Card";
import MDTypography from "../../../../components/MDTypography";
import MDBox from "../../../../components/MDBox";
import { FormAutocomplete, FormCheckbox, FormInput } from "../../../../components/Form/FormInput";
import { useEffect } from "react";
import apiMessages from "../../../../apiMessages";
import MDButton from "../../../../components/MDButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";

export function UpdateBotForm({ botId }) {
  const formHandler = useForm({
    defaultValues: {
      priority: "",
      name: "",
      botId: "",
      customImage: "",
      traefikLabel: "",
      maxRam: "",
    },
  });

  const { handleSubmit, setValue } = formHandler;

  const { data: propertyData } = useGetBotDetailsQuery({
    botId,
    type: "property",
  });

  const realBotId = propertyData?.bot?.botId;

  const { data: botData } = useGetAdminBotQuery(
    {
      botId: realBotId,
    },
    {
      skip: !realBotId,
    }
  );

  const [updateBot, { isLoading: updateBotIsLoading }] = useUpdateAdminBotMutation();

  useEffect(() => {
    if (!botData) return;
    setValue("priority", botData.priority || "");
    setValue("name", botData.name || "");
    setValue("botId", botData.botId || "");
    setValue("customImage", botData.customImage || "");
    setValue("traefikLabel", botData.traefikLabel || "");
    setValue("maxRam", botData.maxRam || "");
  }, [botData]);

  const onSubmit = async (e) => {
    updateBot({
      botId: realBotId,
      data: {
        ...e,
      },
    }).then(({ data, error }) => {
      apiMessages.showMessage({
        type: "AdminPanel",
        title: data ? "updated" : error?.data?.error || error,
      });
    });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        height: "100%",
      }}
    >
      <Card sx={{ p: 2, height: "100%" }}>
        <MDTypography variant="h5" gutterBottom horizontalAlign="center" fontWeight="medium">
          Bot
        </MDTypography>

        <MDBox mb={2}>
          <FormAutocomplete
            formHandler={formHandler}
            name="priority"
            label="Priority"
            options={[
              {
                value: "HIGH",
                label: "High",
              },
              {
                value: "MEDIUM",
                label: "Medium",
              },
              {
                value: "LOW",
                label: "Low",
              },
            ]}
          />
        </MDBox>
        <MDBox mb={2}>
          <FormInput name={"name"} label={"Name"} formHandler={formHandler} />
        </MDBox>
        <MDBox mb={2}>
          <FormInput name={"botId"} label={"Bot Id"} formHandler={formHandler} disabled />
        </MDBox>
        <MDBox mb={2}>
          <FormInput name={"customImage"} label={"Custom Image"} formHandler={formHandler} />
        </MDBox>
        <MDBox mb={2}>
          <FormCheckbox formHandler={formHandler} name="traefikLabel" label="Traefik Label" />
        </MDBox>
        <MDBox mb={2}>
          <FormInput name={"maxRam"} label={"Max Ram"} formHandler={formHandler} />
        </MDBox>

        <MDButton variant="gradient" color="info" fullWidth type="submit" sx={{ mt: "auto" }}>
          Bot aktualisieren
          {updateBotIsLoading && (
            <FontAwesomeIcon icon={faSpinnerThird} size="lg" spin style={{ marginLeft: "5px" }} />
          )}
        </MDButton>
      </Card>
    </form>
  );
}
