import Card from "@mui/material/Card";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDButton from "../../../components/MDButton";
import Grid from "@mui/material/Grid";
import { useUpdateWhitelistMutation } from "../../../store/bot/bot-api";
import { useForm, useWatch } from "react-hook-form";
import { FormInput } from "../../../components/Form/FormInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import Link from "@mui/material/Link";
import apiMessages from "../../../apiMessages";

export function WhitelistAddForm({ botId }) {
  const [updateWhitelist, { isLoading: updateWhitelistIsLoading }] = useUpdateWhitelistMutation();

  const formHandler = useForm({
    defaultValues: {
      guildId: "",
    },
  });
  const { control, handleSubmit } = formHandler;

  const onSubmit = async (e) => {
    updateWhitelist({
      botId,
      data: {
        ...e,
      },
    }).then(({ data, error }) => {
      console.log(data, error);
      apiMessages.showMessage({
        type: "Whitelist",
        title: data ? "added" : error?.data?.error || error,
      });
    });
  };

  const newGuild = useWatch({
    control,
    name: "guildId",
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ height: "100%" }}>
        <MDBox pt={2} px={2}>
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            Add Guild
          </MDTypography>
        </MDBox>
        <MDBox p={2}>
          <Grid item xs={12}>
            <MDBox pt={2} px={2}>
              <FormInput
                name="guildId"
                label="Guild ID"
                formHandler={formHandler}
                rules={{
                  required: "Bitte geben Sie eine gültige Guild ID an.",
                }}
              />
              <MDTypography variant="subtitle2" sx={{ my: 1 }}>
                Sie müssen ihren Guild erst hier hinzufügen um einen privaten Bot darauf nutzen zu können.{" "}
                <Link
                  target="_blank"
                  href="https://support.discord.com/hc/de/articles/206346498"
                  color="primary"
                >
                  Wie finde ich heraus, welche ID mein Guild hat?
                </Link>{" "}
              </MDTypography>
            </MDBox>
          </Grid>

          <Grid item xs={12}>
            <MDBox pt={2} px={2}>
              <MDButton
                type="submit"
                variant="contained"
                sx={{ width: "100%" }}
                disabled={updateWhitelistIsLoading}
                color="primary"
              >
                Server hinzufügen
                {updateWhitelistIsLoading && (
                  <FontAwesomeIcon icon={faSpinnerThird} size="lg" spin style={{ marginLeft: "5px" }} />
                )}
              </MDButton>
            </MDBox>
          </Grid>

          <Grid item xs={12}>
            <MDBox py={2} px={2}>
              <Link
                href={`https://discord.com/api/oauth2/authorize?client_id=${botId}&permissions=1636345310455&scope=bot%20applications.commands&guild_id=${newGuild}`}
                target="_blank"
              >
                <MDButton
                  variant="contained"
                  sx={{ width: "100%" }}
                  disabled={updateWhitelistIsLoading}
                  color="info"
                >
                  Bot einladen
                </MDButton>
              </Link>
            </MDBox>
          </Grid>
        </MDBox>
      </Card>
    </form>
  );
}
