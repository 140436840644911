import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "../../../../../../components/MDBox";
import MDTypography from "../../../../../../components/MDTypography";
import { TicketActionTable } from "./reaction/TicketActionTable";
import { TicketSystemNewActionForm } from "./reaction/TicketSystemNewActionForm";
import { TicketSystemNewCategoryForm } from "./category/TicketSystemNewCategoryForm";
import { TicketSystemTable } from "./category/TicketSystemTable";

export function TicketSystemManage({ botId, serverId }) {
  return (
    <>
      <Grid item xs={12} lg={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TicketSystemTable serverId={serverId} botId={botId} />
          </Grid>
          <Grid item xs={12}>
            <TicketSystemNewCategoryForm serverId={serverId} botId={botId} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card sx={{ height: "100%" }}>
              <MDBox pt={2} px={2}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  Ticket System Actions
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular">
                  Um Tickets über Actions öffnen zu können, muss hier eine Action hinzugefügt werden.
                </MDTypography>
              </MDBox>
              <MDBox p={2}>
                <TicketActionTable guildId={serverId} botId={botId} />
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <TicketSystemNewActionForm guildId={serverId} botId={botId} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
