import { useFieldArray, useWatch } from "react-hook-form";
import { FormInput } from "../../../../components/Form/FormInput";
import MDBox from "../../../../components/MDBox";
import { useEffect } from "react";
import MDButton from "../../../../components/MDButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle } from "@fortawesome/pro-solid-svg-icons";

export function EnvironmentForm({ formHandler }) {
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control: formHandler.control, // control props comes from useForm (optional: if you are using FormContext)
    name: "environment", // unique name for your Field Array
  });

  const fieldArray = useWatch({ name: "environment", control: formHandler.control });
  useEffect(() => {
    if (fieldArray[fieldArray.length - 1]?.key !== "" || fieldArray[fieldArray.length - 1]?.value !== "") {
      append({ key: "", value: "" });
    }
  }, [fieldArray]);

  return (
    <>
      <MDBox display="flex" flexDirection="column" gap={1}>
        {fields.map((item, index, array) => (
          <MDBox display="flex" flexDirection="row" gap={1} key={item.id}>
            <FormInput
              formHandler={formHandler}
              name={`environment[${index}].key`}
              placeholder={`${index + 1}. Environment Key`}
            />
            <FormInput
              formHandler={formHandler}
              name={`environment[${index}].value`}
              placeholder={`${index + 1}. Environment Value`}
            />
            <MDButton
              color="info"
              iconOnly
              onClick={() => remove(index)}
              disabled={index === array.length - 1}
            >
              <FontAwesomeIcon icon={faMinusCircle} />
            </MDButton>
          </MDBox>
        ))}
      </MDBox>
    </>
  );
}
