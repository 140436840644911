/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useEffect, useMemo, useState } from "react";

// react-router components
import { Routes, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 PRO React components
// Material Dashboard 2 PRO React imageExample components
import Configurator from "./examples/Configurator/Configurator";

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";

// RTL plugins
// Material Dashboard 2 PRO React contexts
// Images
import logo from "assets/images/custom/logo.png";
import { SnackbarProvider } from "notistack";
import { rawRoutes } from "./routes";
import Logger from "./Logger";
import { useGetAccountQuery, useGetLoginStatusQuery } from "./store/auth/auth-api";
import { authActions } from "./store/auth/auth-slice";
import { useDispatch, useSelector } from "react-redux";
import { SidenavWrapper } from "./components/Sidenav/Sidenav";
import { CookieHandler } from "./context/cookieHandler";
import { useUnleashContext } from "@unleash/proxy-client-react";
import { loadAndInitializeToggles } from "./store/flags/flags-actions";
import * as Sentry from "@sentry/react";

const routesList = rawRoutes();

export default function App() {
  const { data: userData } = useGetAccountQuery();
  const { data: loggedIn, error: loggedInError } = useGetLoginStatusQuery(undefined, {
    refetchOnReconnect: true,
    pollingInterval: 1000 * 60 * 10,
  });
  const preferences = useSelector((state) => state.cookie.preferences);
  const dispatch = useDispatch();
  const unleashContext = useUnleashContext();
  const url = window.location.href;
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

  useEffect(() => {
    if (!loggedIn) return undefined;
    if (!loggedIn?.loggedIn) {
      Logger.warning("Not logged in", { module: "AuthActions" });
      dispatch(authActions.logout());
      return undefined;
    }
    if (!userData) return undefined;
    Logger.success(
      "Fetched user data",
      {
        module: "AuthActions",
      },
      userData
    );
    dispatch(authActions.setUser(userData));
    dispatch(authActions.login());
    preferences &&
      unleashContext({ userId: userData.id })
        .then((r) => {
          Logger.debug("Unleash context", { module: "AuthActions" }, r);
          dispatch(loadAndInitializeToggles());
        })
        .catch((e) => {
          Logger.error("Error while setting unleash context", { module: "AuthActions" }, e);
        });
  }, [userData, loggedIn]);

  useEffect(() => {
    if (loggedInError) {
      Logger.error("Error while fetching login status", { module: "AuthActions" }, loggedInError);
      if (url.includes("/service/unreachable")) return;
      sessionStorage.setItem("restoreOffline", url);
      window.location.href = "/service/unreachable";
    }
  }, [loggedInError]);

  const direction = "ltr";
  const layout = useSelector((state) => state.ui.layout);
  const darkMode = useSelector((state) => state.ui.darkMode);
  const [location] = useState(window.location.pathname);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    location && navigate(location, { replace: true });
  }, [location]);

  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  return useMemo(
    () => (
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        <CookieHandler />
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          {layout === "dashboard" && (
            <>
              <SidenavWrapper brand={logo} brandName="Webinterface" sx={{ zIndex: 10 }} />
              <Configurator />
            </>
          )}
          <SentryRoutes>{routesList}</SentryRoutes>
        </SnackbarProvider>
      </ThemeProvider>
    ),
    [darkMode, routesList, layout]
  );
}
