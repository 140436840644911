// Hook
import { useCallback, useEffect, useState } from "react";

/**
 * It returns a stateful value and a function to update it
 * @param key - The key to store the value in localStorage under.
 * @param initialValue - The initial value to be returned from the hook.
 * @returns An array with two elements. The first element is the value of the state, and the second element is a function
 * that can be used to update the state.
 */
function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === "undefined") {
      return initialValue;
    }

    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      if (typeof window !== "undefined") {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };
  return [storedValue, setValue];
}

/**
 * It returns a tuple of the current hash and a function to update the hash
 * @returns An array with two elements. The first element is the current hash value. The second element is a function that
 * updates the hash value.
 */
const useHash = () => {
  const [hash, setHash] = useState(() => window.location.hash);

  const hashChangeHandler = useCallback(() => {
    setHash(window.location.hash);
  }, []);

  useEffect(() => {
    window.addEventListener("hashchange", hashChangeHandler);
    return () => {
      window.removeEventListener("hashchange", hashChangeHandler);
    };
  }, []);

  const updateHash = useCallback(
    (newHash) => {
      if (newHash !== hash) window.location.hash = newHash;
    },
    [hash]
  );

  return [hash, updateHash];
};

/**
 * It returns a pair of values. The first value is the value of the URL parameter, and the second value is a function that
 * sets the value of the URL parameter
 * @param param - The name of the parameter to use.
 * @param [automaticallyAdd=true] - If true, the default value will be added to the URL when the component is mounted.
 * @param [defaultValue] - The default value to use if the parameter is not set.
 * @returns The first return value is the value of the parameter. The second return value is a function that sets the value
 * of the parameter.
 */
export function useUrlParameter(param, automaticallyAdd = true, defaultValue = "") {
  const url = new URLSearchParams(window.location.search);
  const [value, setValue] = useState(url.get(param));

  useEffect(() => {
    if (!value && automaticallyAdd) {
      url.set(param, defaultValue);
      window.history.pushState(null, null, `?${url.toString()}`);

      setValue(defaultValue);
    }
  }, [value, automaticallyAdd, defaultValue, param]);

  return [
    !value && automaticallyAdd ? defaultValue : value,
    // eslint-disable-next-line no-shadow
    function getParameter(value) {
      url.set(param, value);
      window.history.pushState(null, null, `?${url.toString()}`);

      setValue(value);
    },
  ];
}

/**
 * Return the first count characters of text, and if text is longer than count, append three dots to the end.
 * @param [text] - The text to shorten.
 * @param [count] - The number of characters to return.
 * @param [insertDots=true] - If true, the function will add three dots to the end of the string.
 * @returns The function shortString() is being returned.
 */
function shortString(text = "", count = text.length, insertDots = true) {
  return text.slice(0, count) + (text.length > count && insertDots ? "…" : "");
}

/**
 * It takes a hook, a setHook function, and a params object, and then it sets the hook to the hook merged with the params
 * object
 * @param hook - The hook that you want to set the params for.
 * @param setHook - This is the function that will be used to set the hook.
 * @param params - The parameters to set.
 */
function setSearchParam(hook, setHook, params = {}) {
  setHook({
    ...Object.fromEntries([...hook]),
    ...params,
  });
}

/**
 * It removes a search parameter from the hook
 * @param hook - The hook object
 * @param setHook - This is the hook that you want to set the new state to.
 * @param [param] - The parameter you want to remove from the hook.
 */
function removeSearchParam(hook, setHook, param = []) {
  param.forEach((p) => {
    delete hook[p];
  });
  setHook({
    ...Object.fromEntries([...hook]),
  });
}

/**
 * Executes a function within a try-catch block and returns either the result of the function or a default value.
 *
 * @param {Function} fn - The function to execute.
 * @param {*} defaultReturn - The value to return if the function throws an error.
 * @param {...*} args - The arguments to pass to the function.
 * @returns {*} The result of the function if it does not throw an error, otherwise the default value.
 *
 * @example
 * // returns 2
 * inlineTryCatch((a, b) => a + b, 0, 1, 1);
 *
 * @example
 * // returns 0
 * inlineTryCatch((a, b) => a + b, 0, 1, "1");
 */
export const inlineTryCatch = (fn, defaultReturn, ...args) => {
  try {
    return fn(...args);
  } catch (e) {
    console.debug("inlineTryCatchError", e);
    return defaultReturn;
  }
};

/**
 * Converts a given emoji into a unified emoji.
 *
 * A unified emoji is a sequence of hexadecimal unicode code points separated by hyphens ("-").
 * The function first converts the input emoji into an array of characters using the spread operator.
 * Then, it maps each character to its unicode code point in hexadecimal format.
 * Finally, it joins the array of hexadecimal code points into a string separated by hyphens.
 *
 * @param {string} emoji - The emoji to convert.
 * @returns {string} The unified emoji.
 */
export function getUnifiedEmoji(emoji) {
  return [...emoji].map((e) => e.codePointAt(0).toString(16)).join(`-`);
}

function isHex(h) {
  const a = parseInt(h, 16);
  return a.toString(16) === h;
}

/**
 * Checks if a given string is a valid unified emoji.
 *
 * A unified emoji is a sequence of hexadecimal unicode code points separated by hyphens ("-").
 * The function first splits the input string by hyphens and checks if the resulting array has more than 6 elements.
 * If it does, the function returns false as a valid unified emoji can't have more than 6 code points.
 * If the array has 6 or less elements, the function checks if each element is a valid hexadecimal number.
 * If all elements are valid hexadecimal numbers, the function returns true. Otherwise, it returns false.
 *
 * @param {string} emoji - The string to check.
 * @returns {boolean} True if the input string is a valid unified emoji, false otherwise.
 */
export function isUnifiedEmoji(emoji) {
  const split = emoji.split("-");
  if (split.length > 6) return false;
  return split.every((x) => isHex(x));
}

/**
 * Parses an ISO 8601 duration string into an object with each duration component.
 *
 * @param {string} durationStr - The ISO 8601 duration string to parse.
 * @returns {Object} An object with the parsed duration components. Each component is a key in the object with its value
 * being the parsed integer value from the duration string or 0 if the component is not present in the duration string.
 * The keys in the returned object are: sign, years, months, weeks, days, hours, minutes, seconds.
 *
 * @example
 * // returns { sign: "+", years: 1, months: 2, weeks: 0, days: 3, hours: 4, minutes: 5, seconds: 6 }
 * parseISODuration("P1Y2M3DT4H5M6S");
 */
export function parseISODuration(durationStr) {
  const iso8601DurationRegex =
    /(-)?P(?:([.,\d]+)Y)?(?:([.,\d]+)M)?(?:([.,\d]+)W)?(?:([.,\d]+)D)?T(?:([.,\d]+)H)?(?:([.,\d]+)M)?(?:([.,\d]+)S)?/;
  const matches = durationStr.match(iso8601DurationRegex);

  return {
    sign: matches[1] === undefined ? "+" : "-",
    years: matches[2] === undefined ? 0 : matches[2],
    months: matches[3] === undefined ? 0 : matches[3],
    weeks: matches[4] === undefined ? 0 : matches[4],
    days: matches[5] === undefined ? 0 : matches[5],
    hours: matches[6] === undefined ? 0 : matches[6],
    minutes: matches[7] === undefined ? 0 : matches[7],
    seconds: matches[8] === undefined ? 0 : matches[8],
  };
}

export const LoginState = {
  authenticated: "authenticated",
  unauthenticated: "unauthenticated",
  loading: "loading",
};

/**
 * Checks if a given date is before the current date and time.
 *
 * @param {string} date - The date to check. This should be a string that can be parsed into a Date object.
 * @returns {boolean} Returns true if the given date is not defined or if it is before the current date and time. Returns false if the given date is after the current date and time.
 *
 * @example
 * // returns true
 * dateIsBeforeNow("2022-01-01T00:00:00Z");
 *
 * @example
 * // returns false
 * dateIsBeforeNow("2099-01-01T00:00:00Z");
 */
export const dateIsBeforeNow = (date) => {
  if (!date) {
    return true;
  }
  const newDate = new Date(date);
  const today = new Date();
  return newDate > today;
};

/**
 * Converts a duration string of the format "HH:MM:SS" into an ISO 8601 duration string.
 *
 * The function first splits the input duration string by colons and maps each part to a number.
 * If the seconds part is not a number (which can happen if the input duration string is of the format "HH:MM"), it is set to 0.
 * Then, the function returns an ISO 8601 duration string of the format "PTHHMMSS".
 *
 * @param {string} duration - The duration string to convert. This should be a string of the format "HH:MM:SS" or "HH:MM".
 * @returns {string} The ISO 8601 duration string.
 *
 * @example
 * // returns "PT1H30M0S"
 * durationString("1:30");
 *
 * @example
 * // returns "PT2H45M15S"
 * durationString("2:45:15");
 */
export const durationString = (duration) => {
  let [hours, minutes, seconds] = duration?.split(":").map(Number);
  if (isNaN(seconds)) seconds = 0; // If no seconds are present

  // Create duration string for Java (ISO 8601 duration format)
  return "PT" + hours + "H" + minutes + "M" + seconds + "S";
};

// eslint-disable-next-line import/prefer-default-export
export { useLocalStorage, shortString, useHash, setSearchParam, removeSearchParam };
