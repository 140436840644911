import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { getData } from "../../../Tools";
import TicketSystemCategoryCard from "./TicketSystemCategoryCard";
import TicketSystemCategoryCardSkeleton from "./TicketSystemCategoryCardSkeleton";

function TicketSystemCategories({ botId, serverId, updateCategory, updateEdit }) {
  const [loaded, setLoaded] = useState(false);

  const [ticketCategories, setTicketCategories] = useState([]);

  useEffect(async () => {
    setTicketCategories(
      await getData({
        bot: botId,
        guild: serverId,
        type: `module/ticket/panel`,
        callback: (data) => setTicketCategories(data),
        renew: false,
      })
    );
    setLoaded(true);
  }, [botId, serverId]);

  return (
    <>
      {loaded
        ? ticketCategories.map((ticketCategory) => (
            <Grid item xs={12} md={6} lg={4}>
              <TicketSystemCategoryCard
                ticketCategory={ticketCategory}
                image={ticketCategory.emoji}
                title={ticketCategory.ticketName}
                id={ticketCategory.id}
                description={<></>}
                setCategory={updateCategory}
                category={ticketCategory.ticketName}
                setEdit={updateEdit}
              />
            </Grid>
          ))
        : [1, 2, 3, 4, 5, 6].map(() => (
            <Grid item xs={12} md={6} lg={4}>
              <TicketSystemCategoryCardSkeleton />
            </Grid>
          ))}
    </>
  );
}

TicketSystemCategories.propTypes = {
  botId: PropTypes.objectOf(PropTypes.any).isRequired,
  serverId: PropTypes.objectOf(PropTypes.any).isRequired,
  updateCategory: PropTypes.func.isRequired,
  updateEdit: PropTypes.func.isRequired,
};
export default TicketSystemCategories;
