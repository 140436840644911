/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useEffect } from "react";

// react-router-dom components
import { useLocation, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import * as Sentry from "@sentry/react";

// Material Dashboard 2 PRO React context
import { useDispatch, useSelector } from "react-redux";
import { FallbackComponent } from "../../../components/ErrorBoundary/FallbackComponent";
import { LoginState } from "../../../Utils";
import { uiActions } from "../../../store/ui/ui-slice";

const url = new URL(window.location.href);

function DashboardLayout({ children }) {
  const dispatch = useDispatch();

  const { miniSidenav, tempMiniSidenav } = useSelector((state) => state.ui);
  const status = useSelector((state) => state.auth.status);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(uiActions.setLayout("dashboard"));
  }, [pathname]);

  useEffect(() => {
    if (status === LoginState.unauthenticated) {
      navigate("/login");
      sessionStorage.setItem("restoreOffline", url.href);
    }
  }, [status]);

  //TODO - Add a fallback component
  return (
    <MDBox
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        p: 3,
        position: "relative",

        [breakpoints.up("xl")]: {
          marginLeft: !miniSidenav && tempMiniSidenav ? pxToRem(274) : pxToRem(120),
          transition: transitions.create(["margin-left", "margin-right"], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
      })}
    >
      <Sentry.ErrorBoundary
        fallback={({ eventId, error, componentStack, resetError }) => (
          <FallbackComponent
            eventId={eventId}
            error={error}
            componentStack={componentStack}
            resetError={resetError}
          />
        )}
      >
        {children}
      </Sentry.ErrorBoundary>
    </MDBox>
  );
}

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;
