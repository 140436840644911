/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-types is library for typechecking of props

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import MDAvatar from "../../../../components/MDAvatar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {NavLink} from "react-router-dom";
import Grid from "@mui/material/Grid";
import {faChevronRight} from "@fortawesome/pro-solid-svg-icons";
import MDButton from "../../../../components/MDButton";
import {useGetGuildQuery} from "../../../../store/guild/guild-api";
import {Skeleton} from "@mui/material";
import {useSelector} from "react-redux";

function ServerCard({ guildId, botId }) {
  const { data: guildData = {}, isLoading: guildIsLoading } = useGetGuildQuery({ botId, guildId });
  const expertMode = useSelector((state) => state.ui?.expertMode);

  if (guildIsLoading) return <Skeleton variant="rounded" width="100%" height={250} animation="wave" />;

  return (
    <Card>
      <MDBox p={2}>
        <MDBox display="flex" alignItems="center">
          <MDAvatar
            src={guildData?.iconUrl}
            alt={guildData?.name}
            size="xl"
            variant="rounded"
            bgColor="dark"
            sx={{ p: 1, mt: -6, borderRadius: ({ borders: { borderRadius } }) => borderRadius.xl }}
          />
          <MDBox ml={2} lineHeight={1}>
            <MDTypography variant="h6" textTransform="capitalize" fontWeight="medium" sx={{ marginTop: 1 }}>
              {guildData?.name}
            </MDTypography>
          </MDBox>
        </MDBox>
        {expertMode && (
          <MDBox my={2} lineHeight={1}>
            <MDTypography variant="button" fontWeight="light" color="text">
              {guildData?.id}
            </MDTypography>
          </MDBox>
        )}
        <Divider />
        <MDBox my={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <MDTypography variant="h6" fontWeight="regular">
                <FontAwesomeIcon size="1x" icon={faChevronRight} style={{ marginRight: "10px" }} />{" "}
                Mitglieder: {guildData?.memberCount}
              </MDTypography>
            </Grid>
            <Grid item xs={12} md={6}>
              <MDTypography variant="h6" fontWeight="regular">
                <FontAwesomeIcon size="1x" icon={faChevronRight} style={{ marginRight: "10px" }} /> Owner:{" "}
                {guildData?.owner?.name}
              </MDTypography>
            </Grid>

            <Grid item xs={12} md={6}>
              <MDTypography variant="h6" fontWeight="regular">
                <FontAwesomeIcon size="1x" icon={faChevronRight} style={{ marginRight: "10px" }} /> Sprache:{" "}
                {guildData?.language}
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox display="flex" justifyContent="space-between" alignItems="center">
          <MDButton
            variant="contained"
            color="info"
            component={NavLink}
            to={`./${guildId}`}
            sx={{ width: "100%" }}
          >
            Zum Server
          </MDButton>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default ServerCard;
