import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";

// Material Dashboard 2 PRO React imageExample components
import DashboardLayout from "../../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import FooterSimple from "../../../../components/Footer/FooterSimple";

import Card from "@mui/material/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRobot } from "@fortawesome/pro-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import Stepper from "@mui/material/Stepper";
import StepLabel from "@mui/material/StepLabel";
import Step from "@mui/material/Step";
import backgroundImage from "../../../../assets/images/bg-profile.jpeg";
import Error404Modal from "../../../../components/Dialog/Error404Modal";
import MDButton from "../../../../components/MDButton";
import apiErrorMessages from "../../../../apiMessages";
import { useSelector } from "react-redux";
import { EssentialsForm } from "./EssentialsForm";
import { useForm } from "react-hook-form";
import { PropertyForm } from "./PropertyForm";
import { StatusForm } from "./StatusForm";
import { EnvironmentForm } from "./EnvironmentForm";
import {
  useCreateAdminBotMutation,
  useCreateBotDetailsMutation,
  useUpdateBotDetailsMutation,
} from "../../../../store/admin/admin-api";
import Logger from "../../../../Logger";
import { LoginState } from "../../../../Utils";

function getSteps() {
  return ["Essentials", "Property", "Custom Status", "Environment Variables"];
}

function AdminBotPanel() {
  const status = useSelector((state) => state.auth.status);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [environmentInput, setEnvironmentInput] = useState([]);

  const formHandler = useForm({
    defaultValues: {
      essentials: {
        username: "",
        avatar: "",
        token: "",
        ownerId: "",
        whitelistedServerId: [],
        priority: "LOW",
      },
      properties: {
        botType: "PRIVATE_BOT",
        beta: false,
        premium: false,
        partner: false,
        shards: 1,
        adsDisabled: false,
        ownerId: 0,
        onlineStatus: "ONLINE",
      },
      status: {
        activity: "WATCHING",
        statusText: "auf sv-studios.net",
        streamUrl: "",
      },
      environment: [],
    },
  });

  const [updateData, { isLoading: createDataIsLoading }] = useUpdateBotDetailsMutation();
  const [createData, { isLoading: updateDataIsLoading }] = useCreateBotDetailsMutation();
  const [createBot, { isLoading: createBotIsLoading }] = useCreateAdminBotMutation();

  const { handleSubmit, setValue, getValues } = formHandler;

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const isLastStep = activeStep === steps.length - 1;

  const handleBack = () => setActiveStep(activeStep - 1);

  useEffect(async () => {
    if (status === LoginState.unauthenticated) {
      navigate("/account/login");
    }
  }, [status]);

  const submitAll = () => {
    const { essentials, properties, status, environment } = getValues();

    Logger.debug("Create Bot with following data", {}, { essentials, properties, status, environment });

    createBot({
      data: {
        ...essentials,
        token: essentials.token || null,
        whitelistedServerId: essentials.whitelistedServerId.filter((id) => id.key !== "").map((id) => id.key),
      },
    }).then(({ data, error }) => {
      if (error) {
        Logger.error("Error while creating bot", {}, error);
        return apiErrorMessages.showMessage({
          type: "AdminBot",
          title: error?.data?.error || error,
        });
      }

      Promise.all([
        updateData({
          botId: data.id,
          type: "property",
          data: { ...properties },
        }),
        updateData({
          botId: data.id,
          type: "custom_status",
          data: { ...status },
        }),
        ...environment
          .filter((env) => env?.key !== "" && env?.value !== "")
          .map((env) =>
            createData({
              botId: data.id,
              type: "environment",
              data: { environment: env?.key + "=" + env?.value },
            })
          ),
      ])
        .then((data) => {
          apiErrorMessages.showMessage({
            type: "AdminBot",
            preventDuplicate: true,
            title: "created",
          });
          navigate("/admin/bot");
        })
        .catch((error) => {
          Logger.error("Error while creating bot variables", {}, error);
          apiErrorMessages.getErrorMessage({
            type: "AdminBot",
            title: error?.error || error?.message,
          });
        });
    });
  };

  const submitEvent = async (e) => {
    if (isLastStep) {
      submitAll();
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Error404Modal />
      <MDBox mb={2} />
      <MDBox position="relative" mb={5}>
        <MDBox
          display="flex"
          alignItems="center"
          position="relative"
          minHeight="15rem"
          borderRadius="xl"
          sx={{
            backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.info.main, 0.6),
                rgba(gradients.info.state, 0.6)
              )}, url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            overflow: "hidden",
          }}
        />

        <Card
          sx={{
            position: "relative",
            mt: -5,
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <MDTypography variant="h5" fontWeight="medium">
                <FontAwesomeIcon icon={faRobot} size="2x" />
              </MDTypography>
            </Grid>
            <Grid item>
              <MDBox height="100%" mt={0.5} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium" sx={{ width: "100%" }}>
                  Admin Bot Create
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        </Card>
      </MDBox>

      <MDBox mb={5}>
        <form onSubmit={handleSubmit(submitEvent)}>
          <Card sx={{ height: "100%" }}>
            <MDBox mx={2} mt={-3}>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </MDBox>
            <MDBox p={3}>
              <MDBox>
                {activeStep === 0 && (
                  <>
                    <EssentialsForm formHandler={formHandler} />
                  </>
                )}
                {activeStep === 1 && (
                  <>
                    <PropertyForm formHandler={formHandler} />
                  </>
                )}
                {activeStep === 2 && (
                  <>
                    <StatusForm formHandler={formHandler} />
                  </>
                )}
                {activeStep === 3 && (
                  <>
                    <EnvironmentForm formHandler={formHandler} />
                  </>
                )}
                <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
                  <MDButton variant="gradient" color="info" disabled={activeStep === 0} onClick={handleBack}>
                    Vorheriger Schritt
                  </MDButton>

                  <MDButton type="submit" variant="gradient" color={isLastStep ? "success" : "info"}>
                    {isLastStep ? "Bot erstellen" : "Nächster Schritt"}
                  </MDButton>
                </MDBox>
              </MDBox>
            </MDBox>
          </Card>
        </form>
      </MDBox>

      <FooterSimple />
    </DashboardLayout>
  );
}

export default AdminBotPanel;
