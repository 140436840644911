// eslint-disable-next-line import/prefer-default-export
import config from "./config";

export const initText = () => {
  console.group("Welcome to SV-Studios Bot Panel");
  console.log(
    `%c  ______                 _                 __ _                          
 |___  /                (_)               / _| |                         
    / /_   _  ___  _ __  _  ___ ___  ___ | |_| |___      ____ _ _ __ ___ 
   / /| | | |/ _ \\| '_ \\| |/ __/ __|/ _ \\|  _| __\\ \\ /\\ / / _\` | '__/ _ \\
  / /_| |_| | (_) | | | | | (__\\__ \\ (_) | | | |_ \\ V  V / (_| | | |  __/
 /_____\\__, |\\___/|_| |_|_|\\___|___/\\___/|_|  \\__| \\_/\\_/ \\__,_|_|  \\___|
        __/ |                                                            
       |___/               `,
    "font-weight: bold; color: #5aa1ed ; text-shadow: 3px 0px 0px #ee5f57;"
  );
  console.log(
    "%cCopyright © 2023 SV-Studios by ZyonicSoftware | Hasewinkel und Rempe GbR",
    "color: #5aa1ed; text-shadow: 1px 0px 1px #ee5f57;"
  );
  console.log("%cWebsite created by SV-Studios", "color: #ee5f57; text-shadow: 1px -1px 1px #5aa1ed;");
  console.log("%cDesign by Creative Tim", "color: #5aa1ed; text-shadow: 1px -1px 1px #ee5f57;");
  console.log("Panel Version: %s", config.version);
  console.groupEnd();
};
