/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  This file is used for controlling the global states of the components,
  you can customize the states for the different components here.
*/

import { createContext, memo, useContext, useReducer } from "react";

import PropTypes from "prop-types";
import Logger from "../Logger";

const Dashboard = createContext({});

Dashboard.displayName = "SV-PanelContext";

function reducer(state, action) {
  switch (action.type) {
    case "MINI_SIDENAV": {
      Logger.warning("MINI_SIDENAV is deprecated please use the new settings menu", {
        module: "DashboardContext",
      });
      return { ...state, miniSidenav: action.value };
    }
    case "TRANSPARENT_SIDENAV": {
      Logger.warning("TRANSPARENT_SIDENAV is deprecated please use the new settings menu", {
        module: "DashboardContext",
      });
      return { ...state, transparentSidenav: action.value };
    }
    case "WHITE_SIDENAV": {
      Logger.warning("WHITE_SIDENAV is deprecated please use the new settings menu", {
        module: "DashboardContext",
      });
      return { ...state, whiteSidenav: action.value };
    }
    case "SIDENAV_COLOR": {
      Logger.warning("SIDENAV_COLOR is deprecated please use the new settings menu", {
        module: "DashboardContext",
      });
      return { ...state, sidenavColor: action.value };
    }
    case "TRANSPARENT_NAVBAR": {
      Logger.warning("TRANSPARENT_NAVBAR is deprecated please use the new settings menu", {
        module: "DashboardContext",
      });
      return { ...state, transparentNavbar: action.value };
    }
    case "FIXED_NAVBAR": {
      Logger.warning("FIXED_NAVBAR is deprecated please use the new settings menu", {
        module: "DashboardContext",
      });
      return { ...state, fixedNavbar: action.value };
    }
    case "OPEN_CONFIGURATOR": {
      Logger.warning("OPEN_CONFIGURATOR is deprecated please use the new settings menu", {
        module: "DashboardContext",
      });
      return { ...state, openConfigurator: action.value };
    }
    case "DIRECTION": {
      return { ...state, direction: action.value };
    }
    case "LAYOUT": {
      return { ...state, layout: action.value };
    }
    case "DARKMODE": {
      return { ...state, darkMode: action.value };
    }
    case "EXPERTMODE": {
      Logger.warning("EXPERTMODE is deprecated please use the new settings menu", {
        module: "DashboardContext",
      });
      return { ...state, expertMode: action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// Material Dashboard 2 PRO React context provider
function DashboardControllerProvider({ children }) {
  const initialState = {
    miniSidenav: false,
    transparentSidenav: false,
    whiteSidenav: false,
    sidenavColor: "info",
    transparentNavbar: true,
    fixedNavbar: true,
    openConfigurator: false,
    direction: "ltr",
    layout: "dashboard",
    darkMode: false,
  };

  const [controller, dispatch] = useReducer(reducer, initialState);

  return <Dashboard.Provider value={[controller, dispatch]}>{children}</Dashboard.Provider>;
}

// Material Dashboard 2 PRO React custom hook for using context
function useDashboardController() {
  const context = useContext(Dashboard);

  if (!context) {
    throw new Error("useDashboardController should be used inside the DashboardControllerProvider.");
  }

  return context;
}

// Typechecking props for the DashboardControllerProvider
DashboardControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setMiniSidenav = (dispatch, value) => dispatch({ type: "MINI_SIDENAV", value });
const setWhiteSidenav = (dispatch, value) => dispatch({ type: "WHITE_SIDENAV", value });
const setSidenavColor = (dispatch, value) => dispatch({ type: "SIDENAV_COLOR", value });
const setTransparentNavbar = (dispatch, value) => dispatch({ type: "TRANSPARENT_NAVBAR", value });
const setFixedNavbar = (dispatch, value) => dispatch({ type: "FIXED_NAVBAR", value });
const setOpenConfigurator = (dispatch, value) => dispatch({ type: "OPEN_CONFIGURATOR", value });
const setDirection = (dispatch, value) => dispatch({ type: "DIRECTION", value });
const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
const setDarkMode = (dispatch, value) => dispatch({ type: "DARKMODE", value });
const setExpertMode = (dispatch, value) => dispatch({ type: "EXPERTMODE", value });

export {
  DashboardControllerProvider,
  useDashboardController,
  setMiniSidenav,
  setWhiteSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setDirection,
  setLayout,
  setDarkMode,
  setExpertMode,
};
