import { useParams } from "react-router-dom";
import { useMemo } from "react";
import PluginNotFound from "./PluginNotFound";
import GameRole from "./GameRole/GameRole";

function ModuleManager() {
  const { botId, serverId, plugin } = useParams();

  return useMemo(() => {
    //if (!plugin) return <ModuleOverview botId={botId} serverId={serverId} />;

    switch (plugin) {
      case "game-role":
        return <GameRole />;
      default:
        return <PluginNotFound />;
    }
  }, [botId, serverId, module]);
}

export default ModuleManager;
