import Card from "@mui/material/Card";
import { Dialog } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/pro-solid-svg-icons";
import MDTypography from "../MDTypography";
import MDBox from "../MDBox";
import { useSelector } from "react-redux";
import { LoginState } from "../../Utils";

function Error404Modal() {
  const status = useSelector((state) => state.auth.status);

  return (
    <>
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ zIndex: 12 }}
        scroll="paper"
        open={status === LoginState.unauthenticated}
      >
        <Card sx={{ p: 2 }}>
          <MDBox p={2} sx={{ textAlign: "center" }}>
            <MDTypography color="primary" sx={{ my: 2 }}>
              <FontAwesomeIcon icon={faTriangleExclamation} size="4x" fade />
            </MDTypography>
            <MDTypography id="modal-modal-title" variant="h4" component="h2">
              Unauthorized (401)
            </MDTypography>
            <MDTypography id="modal-modal-description" sx={{ my: 2 }}>
              Sie müssen sich erst anmelden, um diese Seite aufzurufen.
            </MDTypography>
          </MDBox>
        </Card>
      </Dialog>
    </>
  );
}

export default Error404Modal;
