class Logger {
  constructor(colors) {
    this.colors = colors;
  }

  log(message, options = {}, ...data) {
    const { color = "white", type = "log", module = "" } = options;
    const consoleMethod = type;
    console[consoleMethod](
      `${module ? `[${module}] ` : ""}%c${message}`,
      `color:${this.colors[color] || this.colors["default"]};font-weight:bold`,
      ...data
    );
  }

  error(message, options, ...data) {
    const { color = "error", type = "error" } = options || {};
    this.log(message, { color, type, ...options }, ...data);
  }

  warning(message, options, ...data) {
    const { color = "warning", type = "warn" } = options || {};
    this.log(message, { color, type, ...options }, ...data);
  }

  success(message, options, ...data) {
    const { color = "success", type = "log" } = options || {};
    this.log(message, { color, type, ...options }, ...data);
  }

  info(message, options, ...data) {
    const { color = "info", type = "log" } = options || {};
    this.log(message, { color, type, ...options }, ...data);
  }

  trace(message, options, ...data) {
    const { color = "trace", type = "trace" } = options || {};
    this.log(message, { color, type, ...options }, ...data);
  }
  debug(message, options, ...data) {
    const { color = "debug", type = "debug" } = options || {};
    this.log(message, { color, type, ...options }, ...data);
  }
}

const logger = new Logger({
  default: "#ffffff",
  darkDefault: "#000000",
  error: "#f44336",
  darkError: "#b71c1c",
  warning: "#ffeb3b",
  darkWarning: "#f57f17",
  success: "#4caf50",
  darkSuccess: "#1b5e20",
  info: "#2196f3",
  darkInfo: "#0d47a1",
});

export default logger;
