/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";

// Material Dashboard 2 PRO React imageExample components
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import FooterSimple from "../../../components/Footer/FooterSimple";
import Card from "@mui/material/Card";
import { faArrowUp, faBadgeCheck, faBadgeDollar } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useParams } from "react-router-dom";
import { Skeleton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import backgroundImage from "../../../assets/images/bg-profile.jpeg";
import MDAvatar from "../../../components/MDAvatar";
import MDButton from "../../../components/MDButton";
import MDBadge from "../../../components/MDBadge";
import DataTable from "../../../examples/Tables/DataTable";
import Error404Modal from "../../../components/Dialog/Error404Modal";
import CreateSupportPinCard from "./CreateSupportPinCard";
import { useGetBotQuery } from "../../../store/bot/bot-api";
import { useDispatch, useSelector } from "react-redux";
import { BotUpdateForm } from "./BotUpdateForm";
import MDAlert from "../../../components/MDAlert";
import apiMessages from "../../../apiMessages";
import PluginCard from "./PluginCard";
import { uiActions } from "../../../store/ui/ui-slice";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import { LoginState } from "../../../Utils";

function BotOverview() {
  const { botId } = useParams();
  const {
    data: botData = {},
    isLoading: botIsLoading,
    isError: botIsError,
    error: botError,
  } = useGetBotQuery({ botId });
  const status = useSelector((state) => state.auth.status);
  const { miniSidenav, expertMode } = useSelector((state) => state.ui);
  const dispatch = useDispatch();
  const handleMiniSidenav = () => dispatch(uiActions.setMiniSidenav(!miniSidenav));
  return (
    <DashboardLayout>
      <DashboardNavbar isAbsolute={status === LoginState.unauthenticated} />
      {status === LoginState.unauthenticated ? <Error404Modal /> : <></>}
      <MDBox mb={2} />
      <MDBox position="relative" mb={5}>
        <MDBox
          display="flex"
          alignItems="center"
          position="relative"
          minHeight="18.75rem"
          borderRadius="xl"
          sx={{
            backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.info.main, 0.6),
                rgba(gradients.info.state, 0.6)
              )}, url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            overflow: "hidden",
          }}
        />

        <Card
          sx={{
            position: "relative",
            mt: -8,
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          <Grid container spacing={3} alignItems="center">
            {botIsLoading ? (
              <>
                <Grid item>
                  <Skeleton variant="circular" width={60} height={60} animation="wave" />
                </Grid>
                <Grid item>
                  <MDBox height="100%" width="100%" mt={0.5} lineHeight={1}>
                    <Skeleton variant="text" width={200} animation="wave" />
                    <Skeleton variant="text" width={200} animation="wave" />
                    <Skeleton variant="text" width={200} animation="wave" />
                  </MDBox>
                </Grid>
              </>
            ) : (
              <>
                <Grid item>
                  <MDAvatar src={botData.avatarUrl} alt="profile-image" size="xl" shadow="sm" />
                </Grid>
                <Grid item>
                  <MDBox height="100%" mt={0.5} lineHeight={1}>
                    <MDTypography variant="h5" fontWeight="medium" sx={{ width: "100%" }}>
                      {botData?.name}{" "}
                      {botData?.version && (
                        <MDBadge
                          container
                          variant="gradient"
                          size="sm"
                          color="light"
                          sx={{ marginLeft: 1 }}
                          badgeContent={`v${botData.version}`}
                        />
                      )}
                    </MDTypography>
                    <MDTypography variant="h5" fontWeight="medium" sx={{ width: "100%" }}>
                      {botData?.premium && (
                        <Tooltip title="Premium">
                          <span>
                            <FontAwesomeIcon icon={faBadgeCheck} />
                          </span>
                        </Tooltip>
                      )}{" "}
                      {botData?.beta && (
                        <Tooltip title="Beta">
                          <span>
                            <FontAwesomeIcon icon={faBadgeDollar} />
                          </span>
                        </Tooltip>
                      )}
                    </MDTypography>
                    {botData?.owner && (
                      <MDTypography variant="button" color="text" fontWeight="regular">
                        Owner: {botData.owner?.name}
                      </MDTypography>
                    )}
                  </MDBox>
                </Grid>
              </>
            )}
          </Grid>
        </Card>
      </MDBox>
      <MDBox mt={5} mb={3}>
        {botIsError && (
          <MDAlert color="error">
            <MDTypography variant="h6">
              {apiMessages.getErrorMessage("BotOverview", botError?.error)}
            </MDTypography>
          </MDAlert>
        )}
        <Grid container spacing={1}>
          <>
            <Grid item xs={12} md={6}>
              <BotUpdateForm botId={botId} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Card sx={{ height: "100%" }}>
                <MDBox pt={2} px={2}>
                  <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                    {`Server ${botData.guilds?.length || 0} / ${botData.maxWhitelist || "0"}`}
                  </MDTypography>
                </MDBox>
                <MDBox p={2}>
                  <DataTable
                    entriesPerPage={false}
                    noEndBorder
                    table={{
                      columns: [
                        {
                          Header: "Icon",
                          accessor: "icon",
                          width: "7%",
                        },
                        {
                          Header: expertMode ? "Name und ID" : "Name",
                          accessor: "name",
                        },
                        {
                          Header: "Actions",
                          accessor: "actions",
                          width: "7%",
                        },
                      ],
                      rows: botIsLoading
                        ? [1, 2, 3, 4, 5].map(() => ({
                            image: <Skeleton variant="circular" animation="wave" width={40} height={40} />,
                            name: (
                              <>
                                <Skeleton width={100} animation="wave" />
                                <Skeleton width={100} animation="wave" />
                              </>
                            ),
                            actions: (
                              <Skeleton
                                width={100}
                                height={50}
                                animation="wave"
                                style={{ borderRadius: "0.375rem" }}
                              />
                            ),
                          }))
                        : botData?.guilds
                            ?.map((guild) => ({
                              image: guild?.iconUrl,
                              name: guild.name,
                              description: guild.id.toString(),
                              action: [
                                {
                                  type: "internal",
                                  route: `./servers/${guild.id}`,
                                  color: "primary",
                                  label: "Anschauen",
                                },
                              ],
                            }))
                            .map(({ image, name, description, action: actions }) => ({
                              icon: <MDAvatar src={image} alt="ServerIcon" />,
                              name: (
                                <MDBox
                                  display="flex"
                                  flexDirection="column"
                                  alignItems="flex-start"
                                  justifyContent="center"
                                >
                                  <MDTypography
                                    variant={expertMode ? "button" : "inherit"}
                                    fontWeight="medium"
                                  >
                                    {name}
                                  </MDTypography>
                                  {expertMode ? (
                                    <MDTypography variant="caption" color="text">
                                      {description}
                                    </MDTypography>
                                  ) : null}
                                </MDBox>
                              ),
                              actions: actions.map((action, index) => (
                                <MDBox ml="auto" key={index + action.label}>
                                  {action.type === "internal" ? (
                                    <MDButton
                                      component={Link}
                                      to={action.route}
                                      variant="contained"
                                      color={action.color}
                                    >
                                      {action.label}
                                    </MDButton>
                                  ) : (
                                    <MDButton
                                      component="a"
                                      href={action.route}
                                      target="_blank"
                                      rel="noreferrer"
                                      variant="text"
                                      color={action.color}
                                    >
                                      {action.label}
                                    </MDButton>
                                  )}
                                </MDBox>
                              )),
                            })) || [],
                    }}
                    showTotalEntries={false}
                  />
                </MDBox>
                <MDBox p={2} mt={"auto"}>
                  <MDTypography variant="body2">
                    Wähle hier einen Server aus, um weitere Einstellungen zu finden.{" "}
                    <FontAwesomeIcon icon={faArrowUp} />
                  </MDTypography>
                  <MDTypography variant="body2">
                    Alternativ kannst du zur Navigation auch die <strong>Seitenleiste</strong>{" "}
                    <IconButton onClick={handleMiniSidenav} size="small" disableRipple>
                      <Icon
                        fontSize="medium"
                        sx={({ palette: { dark, white, text }, functions: { rgba } }) => ({
                          color: () => {
                            return text.main;
                          },
                        })}
                      >
                        {miniSidenav ? "menu_open" : "menu"}
                      </Icon>
                    </IconButton>{" "}
                    verwenden.
                  </MDTypography>
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12} lg={6}>
              <CreateSupportPinCard />
            </Grid>
            <Grid item xs={12} lg={6}>
              <PluginCard />
            </Grid>
          </>
        </Grid>
      </MDBox>
      <FooterSimple />
    </DashboardLayout>
  );
}

export default BotOverview;
