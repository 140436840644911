/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "../../../components/MDBox";

// Material Dashboard 2 PRO React imageExample components
// WelcomeLeave page components
// Images
import { useMemo } from "react";
import { Skeleton } from "@mui/material";
import Link from "@mui/material/Link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import { faEye, faTrash } from "@fortawesome/pro-solid-svg-icons";
import MDAvatar from "../../../components/MDAvatar";
import MDButton from "../../../components/MDButton";
import DataTable from "../../../examples/Tables/DataTable";
import { useDeleteWhitelistMutation, useGetWhitelistQuery } from "../../../store/bot/bot-api";
import { useSelector } from "react-redux";
import apiMessages from "../../../apiMessages";

function Whitelist({ botId }) {
  const { data: whiteListData, isLoading: whitelistIsLoading } = useGetWhitelistQuery({ botId });
  const [deleteWhitelist, { isLoading: deleteWhitelistIsLoading }] = useDeleteWhitelistMutation();
  const expertMode = useSelector((state) => state.ui.expertMode);

  const deleteWhitelistEvent = (guild) => {
    deleteWhitelist({ botId, id: guild?.id }).then(({ data, error }) => {
      apiMessages.showMessage({
        type: "Whitelist",
        title: data ? "deleted" : error?.data?.error || error,
      });
    });
  };

  const tableColumns = useMemo(
    () =>
      [
        {
          Header: "Avatar",
          accessor: "avatar",
        },
        {
          Header: "Name",
          accessor: "name",
        },
        expertMode ? { Header: "ID", accessor: "id", width: "7%" } : null,
        {
          Header: "Actions",
          accessor: "actions",
          align: "right",
        },
      ].filter((x) => x),
    [expertMode]
  );

  const tableData = whiteListData?.map((guild) => ({
    name: guild?.guildName?.toString() || "Unbekannt",
    id: guild?.guildId?.toString() || "Unbekannt",
    avatar: (
      <MDBox mr={2}>
        {guild?.avatarUrl ? (
          <MDAvatar src={guild?.avatarUrl || ""} alt="Server Icon" />
        ) : (
          <MDAvatar bgColor="dark" />
        )}
      </MDBox>
    ),
    actions: (
      <>
        <Grid container spacing="4">
          <Grid item>
            {guild?.joined ? (
              <MDButton
                variant="contained"
                color="info"
                iconOnly
                component={NavLink}
                to={`./../servers/${guild?.guildId}`}
              >
                <FontAwesomeIcon icon={faEye} />
              </MDButton>
            ) : (
              <Link
                href={`https://discord.com/api/oauth2/authorize?client_id=${botId}&permissions=1636345310455&scope=bot%20applications.commands&guild_id=${guild.guildId}`}
                target="_blank"
              >
                <MDButton variant="contained" color="info">
                  Einladen
                </MDButton>
              </Link>
            )}
          </Grid>
          <Grid item>
            <MDButton
              variant="contained"
              color="error"
              iconOnly
              disabled={deleteWhitelistIsLoading}
              onClick={() => deleteWhitelistEvent(guild)}
            >
              <FontAwesomeIcon icon={faTrash} />
            </MDButton>
          </Grid>
        </Grid>
      </>
    ),
  }));

  return (
    <DataTable
      table={{
        columns: tableColumns,
        rows: whitelistIsLoading
          ? [1, 2, 3, 4, 5].map(() => ({
              name: <Skeleton variant="text" animation="wave" width={100} />,
              id: <Skeleton variant="text" animation="wave" width={100} />,
              avatar: <Skeleton variant="circular" width={30} height={30} animation="wave" />,
              actions: <Skeleton variant="text" animation="wave" width={100} />,
            }))
          : tableData,
      }}
      canSearch
    />
  );
}

export default Whitelist;
