import Card from "@mui/material/Card";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import { InputAdornment } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import MDButton from "../../../components/MDButton";
import Grid from "@mui/material/Grid";
import { useGetBotQuery, useUpdateBotMutation } from "../../../store/bot/bot-api";
import { useForm, useWatch } from "react-hook-form";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { FormAutocomplete, FormInput } from "../../../components/Form/FormInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import apiMessages from "../../../apiMessages";

const options = [
  {
    label: "Hört...",
    value: "LISTENING",
  },
  {
    label: "Streamt...",
    value: "STREAMING",
  },
  {
    label: "Schaut...",
    value: "WATCHING",
  },
  {
    label: "Custom... ",
    value: "CUSTOM_STATUS",
  },
  {
    label: "Spielt...",
    value: "PLAYING",
  },
];

export function BotUpdateForm({ botId }) {
  const { data: botData, isLoading: botIsLoading } = useGetBotQuery({ botId });
  const [updateBot, { isLoading: updateBotIsLoading }] = useUpdateBotMutation();
  const user = useSelector((state) => state.auth.user);

  const formHandler = useForm({
    defaultValues: {
      name: "",
      avatarUrl: "",
      maxWhitelist: 0,
      status: {
        activityType: "PLAYING",
        text: "",
        streamUrl: "",
      },
    },
  });
  const { watch, control, handleSubmit, setValue, register, getValues } = formHandler;

  const allowedToChange = botData?.owner?.id?.toString() === user?.id?.toString();

  useEffect(() => {
    if (!botData) return;
    setValue("name", botData.name);
    setValue("avatarUrl", botData.avatarUrl);
    setValue("maxWhitelist", botData.maxWhitelist);
    setValue("status.activityType", botData.status?.activityType);
    setValue("status.text", botData.status?.text);
  }, [botData]);

  const onSubmit = async (e) => {
    updateBot({
      botId,
      data: {
        ...e,
      },
    }).then(({ data, error }) => {
      apiMessages.showMessage({
        type: "BotOverview",
        title: data ? "updated" : error?.data?.error || error,
      });
    });
  };

  const watchValues = useWatch({ control, disabled: botIsLoading });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ boxShadow: "none" }}>
        <MDBox p={2}>
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            Settings
          </MDTypography>
        </MDBox>

        <MDBox pt={1} pb={2} px={2}>
          <FormInput
            name="name"
            label="Bot Name"
            formHandler={formHandler}
            rules={{
              required: "Bot Name wird benötigt.",
              minLength: {
                value: 2,
                message: "Der Name muss mindestens 2 Zeichen lang sein.",
              },
              maxLength: {
                value: 32,
                message: "Der Name darf maximal 32 Zeichen lang sein.",
              },
              disabled: !allowedToChange || botIsLoading,
            }}
          />
        </MDBox>

        <MDBox pt={1} pb={2} px={2}>
          <FormInput
            name="avatarUrl"
            label="Avatar Url"
            type="url"
            formHandler={formHandler}
            rules={{
              disabled: !allowedToChange || botIsLoading,
              pattern: {
                value:
                  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/,
                message: "Du musst eine gültige Url angeben.",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ paddingRight: "0px" }}>
                  <Tooltip title="Diese Funktion wird in einem zukünftigem Update verfügbar sein.">
                    <span>
                      <MDButton
                        aria-label="Add"
                        edge="end"
                        color="info"
                        variant="contained"
                        size="small"
                        disabled
                      >
                        Hochladen
                      </MDButton>
                    </span>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </MDBox>

        <MDBox pt={1} pb={2} px={2}>
          <FormInput name="maxWhitelist" label="Max Whitelisted Guilds" formHandler={formHandler} disabled />
        </MDBox>

        <MDBox pt={1} pb={2} px={2}>
          <FormAutocomplete
            label="Activity Type"
            name="status.activityType"
            formHandler={formHandler}
            options={options}
            rules={{
              required: "Du musst eine Activity Type auswählen.",
              disabled: !allowedToChange || botIsLoading,
            }}
          />
        </MDBox>

        {watchValues?.status?.activityType === "STREAMING" ? (
          <MDBox pt={1} pb={2} px={2}>
            <FormInput
              name="status.streamUrl"
              label="Streaming Url"
              type="url"
              formHandler={formHandler}
              rules={{
                required: "Du musst eine Streaming Url angeben.",
                disabled: !allowedToChange || botIsLoading,
                pattern: {
                  value:
                    /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/,
                  message: "Du musst eine gültige Url angeben.",
                },
              }}
            />
          </MDBox>
        ) : null}

        <MDBox pt={1} pb={2} px={2} mb={7}>
          <FormInput
            name="status.text"
            label="Activity Text"
            type="text"
            formHandler={formHandler}
            rules={{
              required: true,
            }}
            disabled={!allowedToChange || botIsLoading}
          />
        </MDBox>

        <MDBox
          pb={2}
          px={2}
          sx={{
            marginTop: "auto",
            width: "100%",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Tooltip title="Diese Funktion wird in einem zukünftigem Update verfügbar sein.">
                <span>
                  <MDButton variant="outlined" sx={{ width: "100%" }} color="info" disabled>
                    Inhaber ändern
                  </MDButton>
                </span>
              </Tooltip>
            </Grid>

            <Grid item xs={12} md={6}>
              <MDButton
                type="submit"
                variant="contained"
                sx={{ width: "100%" }}
                color="primary"
                disabled={!allowedToChange || botIsLoading || updateBotIsLoading}
              >
                Speichern
                {updateBotIsLoading && (
                  <FontAwesomeIcon icon={faSpinnerThird} spin style={{ marginLeft: "5px" }} />
                )}
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    </form>
  );
}
