import Card from "@mui/material/Card";
import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";
import MDButton from "../../../../../components/MDButton";
import Grid from "@mui/material/Grid";
import { useForm } from "react-hook-form";
import { FormCategoryAutocomplete, FormInput } from "../../../../../components/Form/FormInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import apiMessages from "../../../../../apiMessages";
import {
  useGetCategoriesQuery,
  useGetGuildQuery,
  useGetTextChannelsQuery,
  useUpdateGuildMutation,
} from "../../../../../store/guild/guild-api";
import Logger from "../../../../../Logger";
import { useEffect } from "react";
import { AutoCompleteChannelIcon } from "../../../../../components/Form/AutoCompleteIcon";

export function GreetingUpdateForm({ botId, guildId, fullHeight, setModals }) {
  const [update, { isLoading }] = useUpdateGuildMutation();

  const {
    data: guildData = {},
    isLoading: guildIsLoading,
    refetch: guildRefetch,
    isRefetching: guildIsRefetching,
  } = useGetGuildQuery({
    botId,
    guildId,
  });

  const {
    data: categoriesData = [],

    isFetching: categoriesIsFetching,
    refetch: categoriesRefetch,
  } = useGetCategoriesQuery({
    botId,
    guildId,
  });
  const {
    data: textChannelData = [],
    refetch: textChannelsRefetch,
    isFetching: textChannelsIsFetching,
  } = useGetTextChannelsQuery({
    botId,
    guildId,
  });

  const formHandler = useForm({
    defaultValues: {
      joinMessageChannel: 0,
      joinMessage: "",
      leaveMessageChannel: 0,
      leaveMessage: "",
    },
  });
  const { control, handleSubmit, setValue, getValues } = formHandler;

  useEffect(() => {
    if (!guildData) return;
    setValue("joinMessageChannel", guildData.joinMessageChannel);
    setValue("joinMessage", guildData.joinMessage);
    setValue("leaveMessageChannel", guildData.leaveMessageChannel);
    setValue("leaveMessage", guildData.leaveMessage);
  }, [guildData]);

  const onSubmit = async (e) => {
    update({
      botId,
      guildId,
      data: {
        ...guildData,
        ...e,
      },
    }).then(({ data, error }) => {
      Logger.info("SubmitEvent", { module: "SubmitForm" }, data, error);
      apiMessages.showMessage({
        type: "GreetingSystem",
        title: data ? "updated" : error?.data?.error || error,
      });
    });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        height: fullHeight ? "100%" : "auto",
      }}
    >
      <Card sx={{ height: "100%" }}>
        <MDBox pt={2} px={2}>
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            Counter System
          </MDTypography>
        </MDBox>
        <MDBox p={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={5}>
              <MDBox pt={2} px={2}>
                <FormCategoryAutocomplete
                  name="joinMessageChannel"
                  label="Willkommensnachricht Channel"
                  categories={categoriesData}
                  options={[...textChannelData].sort((x, y) => x.category - y.category)}
                  groupByFunction="default"
                  labelIcon={(option) => <AutoCompleteChannelIcon option={option} />}
                  additionalParameters={["type"]}
                  formHandler={formHandler}
                  refetch={() => {
                    textChannelsRefetch();
                    categoriesRefetch();
                  }}
                  refetching={textChannelsIsFetching || categoriesIsFetching}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} lg={5}>
              <MDBox pt={2} px={2}>
                <FormInput
                  formHandler={formHandler}
                  name="joinMessage"
                  label="Willkommensnachricht"
                  rules={{
                    required: "Du musst einen Namen angeben",
                  }}
                  variables={[
                    {
                      label: "%guildName% einfügen",
                      description: "Fügt den Namen des Servers ein.",
                      replacer: "%guildName%",
                    },
                  ]}
                />
                {guildData.joinMessage && (
                  <MDTypography fontWeight="regular" variant="button">
                    {guildData.joinMessage
                      ?.replaceAll("%totalSize%", Math.floor(Math.random() * 31) + 50)
                      .replaceAll("%guildName%", guildData.name)}
                  </MDTypography>
                )}
              </MDBox>
            </Grid>
            <Grid item xs={12} md={2}>
              <MDButton
                variant="gradient"
                color="info"
                sx={{
                  mt: 2,
                }}
                fullWidth
                onClick={() => {
                  setModals((prev) => ({
                    ...prev,
                    welcome: true,
                  }));
                }}
              >
                Bild ändern
              </MDButton>
            </Grid>
            <Grid item xs={12} lg={5}>
              <MDBox pt={2} px={2}>
                <FormCategoryAutocomplete
                  name="leaveMessageChannel"
                  label="Abschiedsnachricht Channel"
                  categories={categoriesData}
                  options={[...textChannelData].sort((x, y) => x.category - y.category)}
                  groupByFunction="default"
                  labelIcon={(option) => <AutoCompleteChannelIcon option={option} />}
                  additionalParameters={["type"]}
                  formHandler={formHandler}
                  refetch={() => {
                    textChannelsRefetch();
                    categoriesRefetch();
                  }}
                  refetching={textChannelsIsFetching || categoriesIsFetching}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} lg={5}>
              <MDBox pt={2} px={2}>
                <FormInput
                  formHandler={formHandler}
                  name="leaveMessage"
                  label="Abschiedsnachricht"
                  rules={{
                    required: "Du musst einen Namen angeben",
                  }}
                  variables={[
                    {
                      label: "%count% einfügen",
                      description: "Fügt die Anzahl der Mitglieder ein.",
                      replacer: "%count%",
                    },
                  ]}
                />
                {guildData.leaveMessage && (
                  <MDTypography fontWeight="regular" variant="button">
                    {guildData.leaveMessage
                      ?.replaceAll("%totalSize%", Math.floor(Math.random() * 31) + 50)
                      .replaceAll("%guildName%", guildData.name)}
                  </MDTypography>
                )}
              </MDBox>
            </Grid>
            <Grid item xs={12} md={2}>
              <MDButton
                variant="gradient"
                color="info"
                sx={{
                  mt: 2,
                }}
                fullWidth
                onClick={() => {
                  setModals((prev) => ({
                    ...prev,
                    leave: true,
                  }));
                }}
              >
                Bild ändern
              </MDButton>
            </Grid>
            <Grid item xs={12}>
              <MDBox pt={2} px={2}>
                <MDButton
                  type="submit"
                  variant="contained"
                  sx={{ width: "100%" }}
                  disabled={isLoading}
                  color="primary"
                >
                  Speichern
                  {isLoading && (
                    <FontAwesomeIcon icon={faSpinnerThird} size="lg" spin style={{ marginLeft: "5px" }} />
                  )}
                </MDButton>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    </form>
  );
}
