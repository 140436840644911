import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";
import { LogsTable } from "./LogsTable";
import { DefaultChannelForm } from "./DefaultChannelForm";

const logDesc = {
  TICKET_CLOSE: "Ticket wird geschlossen",
};

function LogsSettingsTabs({ botId, serverId }) {
  return (
    <>
      <Grid item xs={12}>
        <Card sx={{ height: "100%" }}>
          <MDBox pt={2} px={2}>
            <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
              Einstellungen
            </MDTypography>
          </MDBox>
          <MDBox p={2}>
            <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
              <LogsTable guildId={serverId} botId={botId} />
            </MDBox>
          </MDBox>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <DefaultChannelForm guildId={serverId} botId={botId} />
      </Grid>
    </>
  );
}

export default LogsSettingsTabs;
