/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";

// Material Dashboard 2 PRO React imageExample components
import DashboardLayout from "../../../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../../../examples/Navbars/DashboardNavbar";
import FooterSimple from "../../../../../components/Footer/FooterSimple";

// WelcomeLeave page components
// Images
import Card from "@mui/material/Card";
import {faToiletPaper} from "@fortawesome/pro-duotone-svg-icons";
import {useParams} from "react-router-dom";
import ModuleHeader from "../components/ModuleHeader";
import Error404Modal from "../../../../../components/Dialog/Error404Modal";
import {HowItWorks} from "../components/HowItWorks";
import {AutoRoleTable} from "./AutoRoleTable";
import {AutoRoleAddForm} from "./AutoRoleAddForm";

const HelpContent = [
  {
    title: "Was ist das?",
    type: "text",

    description: `Diese Funktion kann von Serveradministratoren oder Bots mit entsprechenden Berechtigungen implementiert werden. Sie bietet eine bequeme Möglichkeit, Benutzern beim Betreten eines Servers automatisch die erforderlichen Rollen zuzuweisen, ohne dass Administratoren dies manuell tun müssen.`,
  },
  {
    title: "Wie funktioniert das?",
    type: "text",
    description: `Die AutoRole-Funktion ist besonders nützlich in größeren Servern oder Communitys, in denen viele Benutzer beitreten und bestimmte Rollen benötigen, um auf bestimmte Kanäle oder Funktionen zugreifen zu können. Indem die Rollen automatisch zugewiesen werden, wird der Verwaltungsaufwand minimiert und sicherstellt, dass neue Mitglieder schnell die erforderlichen Berechtigungen erhalten.`,
  },
  {
    title: "Was muss angegeben werden?",
    type: "table",
    params: [
      {
        name: "Rolle",
        description: `Welche Rolle soll automatisch beim beitreten zugewiesen werden?`,
      },
    ],
  },
];

function AutoRole() {
  const { botId, serverId } = useParams();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Error404Modal />
      <MDBox mb={2} />
      <ModuleHeader
        name="AutoRole"
        type="AUTO_ROLE"
        description="Füge Rollen bei Serverbeitritt automatisch hinzu."
        botId={botId}
        serverId={serverId}
        icon={faToiletPaper}
      />
      <MDBox mt={5} mb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <HowItWorks items={HelpContent} />
          </Grid>
          <Grid item xs={12} md={6} lg={7} xl={8}>
            <Card sx={{ height: "100%" }}>
              <MDBox pt={2} px={2}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  Aktuelle AutoRoles
                </MDTypography>
              </MDBox>
              <MDBox p={2}>
                <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                  <AutoRoleTable guildId={serverId} botId={botId} />
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={5} xl={4}>
            <AutoRoleAddForm guildId={serverId} botId={botId} fullHeight />
          </Grid>
        </Grid>
      </MDBox>
      <FooterSimple />
    </DashboardLayout>
  );
}

export default AutoRole;
