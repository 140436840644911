import Dialog from "@mui/material/Dialog";
import { Collapse, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import MDTypography from "../../MDTypography";
import MDBox from "../../MDBox";
import MDButton from "../../MDButton";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FormInput } from "../../Form/FormInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCode,
  faFileExport,
  faFileImport,
  faFloppyDisk,
  faPaste,
  faTrashCan,
} from "@fortawesome/pro-solid-svg-icons";
import MDInput from "../../MDInput";
import { enqueueSnackbar } from "notistack";
import Logger from "../../../Logger";

export function EmbedStorageDialog({ open, setOpen, get: getEmbedJson, set: setEmbedJson }) {
  const [storage, setStorage] = useState({});
  const [showCode, setShowCode] = useState({});

  const close = () => {
    setOpen(false);
  };

  const saveCurrent = (name, json) => {
    const oldStorage = localStorage.getItem("embedStorage");
    const storageObject = oldStorage ? JSON.parse(oldStorage) : {} || {};
    storageObject[name] = json;
    localStorage.setItem("embedStorage", JSON.stringify(storageObject));
    setStorage(storageObject);
    enqueueSnackbar("Vorlage gespeichert", { variant: "success" });
  };

  const loadCurrent = (name, json) => {
    setEmbedJson(json);
    enqueueSnackbar("Vorlage geladen", { variant: "success" });
  };

  const deleteCurrent = (name) => {
    const oldStorage = localStorage.getItem("embedStorage");
    const storageObject = oldStorage ? JSON.parse(oldStorage) : {} || {};
    delete storageObject[name];
    localStorage.setItem("embedStorage", JSON.stringify(storageObject));
    setStorage(storageObject);
    enqueueSnackbar("Vorlage gelöscht", { variant: "success" });
  };

  const loadAll = () => {
    const oldStorage = localStorage.getItem("embedStorage");
    const storageObject = oldStorage ? JSON.parse(oldStorage) : {} || {};
    setStorage(storageObject);
  };

  const download = (filename, json) => {
    const blob = new Blob([JSON.stringify(json)], { type: "text/json" });
    const link = document.createElement("a");

    link.download = filename;
    link.href = window.URL.createObjectURL(blob);
    link.dataset.downloadurl = ["text/json", link.download, link.href].join(":");

    const evt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });

    link.dispatchEvent(evt);
    link.remove();
    enqueueSnackbar(`Vorlage ${filename} heruntergeladen`, { variant: "success" });
  };

  const handleFileSelect = useCallback((e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      if (!e.target.result) return window.alert("Fehler beim Lesen der Datei");
      const result = JSON.parse(e.target.result);
      if (!result) return window.alert("Fehler beim Lesen der Datei");
      if (!(result.embeds || result.content)) return window.alert("Fehler beim Lesen der Datei");
      setEmbedJson(result);
      enqueueSnackbar("Vorlage importiert", { variant: "success" });
    };
    reader.onabort = () => enqueueSnackbar("Import abgebrochen", { variant: "warning" });
    reader.onerror = () => enqueueSnackbar("Fehler beim Import", { variant: "error" });
    if (!file) return enqueueSnackbar("Es wurde keine Datei ausgewählt", { variant: "error" });
    reader.readAsText(file);

    // Entfernen des EventListeners
    e.target.removeEventListener("change", handleFileSelect);
  }, []);

  const upload = useCallback(() => {
    const fileInput = document.getElementById("fileInput");
    fileInput.addEventListener("change", handleFileSelect);
    fileInput.click();
  }, [handleFileSelect]);

  const onSubmit = async (e) => {
    Logger.log("Submit", {}, e);
    saveCurrent(e.name, getEmbedJson());
  };

  const formHandler = useForm({
    defaultValues: {
      name: "",
    },
  });
  const { control, handleSubmit } = formHandler;

  useEffect(() => {
    loadAll();
  }, []);

  return (
    <>
      <Dialog open={open} maxWidth="xl">
        <DialogTitle>Embed Vorlagen</DialogTitle>
        <DialogContent>
          <MDBox pb={2}>
            <MDTypography variant="h6">
              Alle Embed Saves werden nur lokal auf dem Gerät gespeichert. Sie sind aber Bot- und
              Serverübergreifend verfügbar.
            </MDTypography>
          </MDBox>

          <MDBox display="flex" gap={1.5} flexDirection="column" width="100%">
            {Object.entries(storage).map(([name, json]) => {
              return (
                <div key={name}>
                  <MDBox display="flex" gap={1.5} alignItems="center">
                    <MDInput readOnly disabled fullWidth value={name} />
                    <MDButton
                      title={"Überschreiben/Aktualisieren"}
                      variant="outlined"
                      iconOnly
                      color="secondary"
                      onClick={() => {
                        const confirm = window.confirm(`Möchtest du '${name}' wirklich überschreiben?`);
                        if (!confirm) return;
                        saveCurrent(name, getEmbedJson());
                      }}
                    >
                      <FontAwesomeIcon icon={faFloppyDisk} size="xl" />
                    </MDButton>

                    <MDButton
                      variant="outlined"
                      title="Laden"
                      iconOnly
                      color={JSON.stringify(json) == JSON.stringify(getEmbedJson()) ? "success" : "secondary"}
                      onClick={() => loadCurrent(name, json)}
                    >
                      <FontAwesomeIcon icon={faPaste} size="xl" />
                    </MDButton>
                    <MDButton
                      variant="outlined"
                      title="Code anzeigen"
                      iconOnly
                      color={showCode[name] ? "success" : "secondary"}
                      onClick={() =>
                        setShowCode({
                          ...showCode,
                          [name]: !showCode[name] ?? true,
                        })
                      }
                    >
                      <FontAwesomeIcon icon={faCode} size="xl" />
                    </MDButton>
                    <MDButton
                      variant="outlined"
                      iconOnly
                      title="Exportieren"
                      color={showCode[name] ? "success" : "secondary"}
                      onClick={() => download(name + ".json", json)}
                    >
                      <FontAwesomeIcon icon={faFileExport} size="xl" />
                    </MDButton>
                    <MDButton
                      variant="outlined"
                      iconOnly
                      title="Löschen"
                      color="error"
                      onClick={() => {
                        const confirm = window.confirm(`Möchtest du '${name}' wirklich löschen?`);
                        if (!confirm) return;
                        deleteCurrent(name);
                      }}
                    >
                      <FontAwesomeIcon icon={faTrashCan} size="xl" />
                    </MDButton>
                  </MDBox>
                  <Collapse in={showCode[name] ?? false}>
                    <MDTypography variant="caption">
                      <code>{JSON.stringify(json, null, 0)}</code>
                    </MDTypography>
                  </Collapse>
                </div>
              );
            })}

            <form onSubmit={handleSubmit(onSubmit)}>
              <MDBox display="flex" gap={1}>
                <FormInput
                  name="name"
                  type="text"
                  label="Neue Vorlage erstellen"
                  rules={{
                    required: "Name ist erforderlich",
                  }}
                  formHandler={formHandler}
                />
                <MDButton
                  variant="outlined"
                  sx={{ mt: 0.5 }}
                  type="submit"
                  iconOnly
                  color="secondary"
                  title={"Speichern"}
                >
                  <FontAwesomeIcon icon={faFloppyDisk} size="xl" />
                </MDButton>
                <input
                  type="file"
                  id="fileInput"
                  accept=".json"
                  style={{
                    display: "none",
                  }}
                />
                <MDButton
                  title="Importieren"
                  variant="outlined"
                  sx={{ mt: 0.5 }}
                  type="button"
                  iconOnly
                  onClick={upload}
                  color="secondary"
                >
                  <FontAwesomeIcon icon={faFileImport} size="xl" />
                </MDButton>
              </MDBox>
            </form>
          </MDBox>
        </DialogContent>
        <DialogActions className="grid grid-cols-1 gap-2 md:grid-cols-2 xl:grid-cols-3">
          <MDButton variant="gradient" color="secondary" id="allButton" onClick={close}>
            <span>Schließen</span>
          </MDButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
