import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getServerUrl } from "../config";
import JSONBig from "json-bigint";
import Logger from "../Logger";
const JSONBigString = JSONBig({ storeAsString: true });

const commonErrorProperties = ["name", "message", "stack", "code"];

export const serializeFetchError = (value) => {
  if (typeof value === "object" && value !== null) {
    const simpleError = {};
    for (const property of commonErrorProperties) {
      if (typeof value[property] === "string") {
        simpleError[property] = value[property];
      }
    }

    return simpleError;
  }

  return { message: String(value) };
};
export const defaultHeaders = {
  credentials: "include",
  mode: "cors",
  redirect: "manual",
};

export const dynamicBaseQuery = async (args, api, extraOptions) => {
  const urlEnd = typeof args === "string" ? args : args.url;
  const adjustedUrl = `${getServerUrl()}/${urlEnd}`;

  const adjustedArgs =
    typeof args === "string"
      ? adjustedUrl
      : {
          ...args,
          url: adjustedUrl,
          credentials: "include",
          mode: "cors",
          redirect: "manual",
          responseHandler: (response) => response.text(),
        };

  return fetchBaseQuery({
    transformResponse: async (response, meta) => {
      const text = await response.text();
      try {
        return JSONBigString.parse(text, {});
      } catch (e) {
        return text;
      }
    },
  })(adjustedArgs, api, extraOptions);
};

export const defaultTransformResponse = async (baseQueryReturnValue, meta, arg) => {
  const text = baseQueryReturnValue;
  try {
    return JSONBigString.parse(text);
  } catch (e) {
    Logger.error("Failed to parse JSON", { module: "ReduxQuery" }, e, text);
    throw text;
  }
};

export const defaultTransformErrorResponse = async (baseQueryReturnValue, meta, arg) => {
  const text = baseQueryReturnValue;
  try {
    return {
      ...text,
      data: JSONBigString.parse(text.data),
    };
  } catch (e) {
    Logger.error("Failed to parse JSON", { module: "ReduxQuery" }, e, text);
    return text;
  }
};

export const devUser = {
  flags: 4325504,
  banner: null,
  avatar: "a9ea2eb23e2d3e647d6e6ca6304d90b9",
  locale: "de",
  discriminator: "0",
  premium_type: 3,
  banner_color: "#1d52bb",
  accent_color: 1921723,
  global_name: "Pedda",
  avatar_decoration_data: null,
  mfa_enabled: true,
  id: "327402871668867072",
  public_flags: 4325504,
  username: "ultimatumgamer",
};

export const devRoles = [
  "SV_TRIAL_TEAM",
  "SV_DEVELOPER",
  "SV_ADMIN",
  "SV_DEVELOPER_MANAGEMENT",
  "SV_TEAM",
  "SV_SUPPORTER_MANAGEMENT",
  "SV_SUPPORT",
  "SV_TRANSLATOR_MANAGEMENT",
  "SV_TRANSLATOR",
  "SV_CONTENT",
  "PREMIUM",
  "USER",
];
