import Card from "@mui/material/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/pro-solid-svg-icons";
import { Collapse } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import ApiKeyInput from "../../../components/ApiKeyInput";
import MDButton from "../../../components/MDButton";
import MDTypography from "../../../components/MDTypography";
import MDBox from "../../../components/MDBox";
import apiMessages from "../../../apiMessages";
import { useCreateSupportPinMutation } from "../../../store/global/global-api";

export default function CreateSupportPinCard() {
  const { botId, serverId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [supportPin, setSupportPin] = useState("");
  const [opened, setOpened] = useState(false);

  const [createSupportPin, {}] = useCreateSupportPinMutation();

  const onSubmit = (e) => {
    e.preventDefault();

    createSupportPin({ botId }).then(({ data, error }) => {
      apiMessages.showMessage({
        type: "BotOverview",
        title: data ? "pinCreated" : error?.data?.error || error,
      });
      data && setSupportPin(data.pin);
    });
  };

  return (
    <Card>
      <MDBox
        p={2}
        width="100%"
        display="flex"
        justifyContent="space-between"
        onClick={() => setOpened(!opened)}
      >
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          Support Pin
        </MDTypography>
        <MDButton color="primary" iconOnly size="small" sx={{ marginLeft: "auto" }}>
          <FontAwesomeIcon
            icon={faAngleUp}
            style={{
              transform: opened ? "rotate(0)" : "rotate(-180deg)",
              transition: "transform .5s",
            }}
          />
        </MDButton>
      </MDBox>
      <Collapse in={opened} unmountOnExit>
        <MDBox component="form" role="form" pt={1} pb={2} px={2} onSubmit={onSubmit}>
          <MDBox pb={3}>
            <MDBox>
              <MDTypography
                variant="h6"
                fontWeight="light"
                horizontalAlign="justify"
                gutterBottom
                pl={2}
                pb={2}
              >
                <ul>
                  <li>Bitte erstelle eine Support-Pin, wenn du Hilfe benötigst.</li>
                  <li>
                    Unser Support Pin ist ein spezielles Feature, das unseren Supportern hilft, Probleme
                    schnell und einfach zu lösen in dem sie das gleiche sehen wie ein normaler User.
                  </li>
                  <li>
                    Es ermöglicht es ihnen, auf den gesamten Bot zuzugreifen und mögliche Probleme zu beheben.
                  </li>
                  <li>
                    Es ist jedoch wichtig, dass der Pin sorgfältig verwaltet wird, um sicherzustellen, dass er
                    nicht an unbefugte Personen weitergegeben wird.
                  </li>
                  <li>
                    Der Pin ist nur für einen Tag gültig, um sicherzustellen, dass er nicht missbraucht wird.
                  </li>
                </ul>
              </MDTypography>
              <ApiKeyInput value={supportPin} label="Support Pin" />
              <MDButton variant="contained" color="primary" fullWidth sx={{ mt: 2 }} type="submit">
                Support Pin generieren
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Collapse>
    </Card>
  );
}
