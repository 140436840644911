import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { faArrowUpRightFromSquare, faHourglass, faList } from "@fortawesome/pro-solid-svg-icons";
import { useCallback } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import DashboardLayout from "../../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import MDBox from "../../../../components/MDBox";
import FooterSimple from "../../../../components/Footer/FooterSimple";
import ModuleHeader from "./components/ModuleHeader";
import LoadingItem from "../../components/LoadingItem";
import moduleMessages from "../../../../moduleMessages";
import Error404Modal from "../../../../components/Dialog/Error404Modal";
import MDButton from "../../../../components/MDButton";
import { useGetModulesQuery, useUpdateModuleMutation } from "../../../../store/modules/module-api";

function ModuleOverview({ botId, serverId }) {
  const [update, { isLoading: updateIsLoading }] = useUpdateModuleMutation({});

  const { data: modulesData = [], isLoading: modulesIsLoading } = useGetModulesQuery({
    botId,
    guildId: serverId,
  });

  const textAndLink = moduleMessages;

  const changeModuleStatus = useCallback(
    (type, status) => {
      update({
        botId,
        guildId: serverId,
        module: type,
        data: { disabled: !status },
      });
    },
    [botId, serverId]
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Error404Modal />
      <MDBox mb={2} />
      <ModuleHeader
        name="Module Overview"
        type=""
        description="Modulübersicht über alle Module des Bots."
        serverId={serverId}
        disableSwitch
        icon={faList}
        botId={botId}
      />
      <MDBox mt={5} mb={3}>
        <Grid container spacing={2}>
          {[...modulesData]
            .sort((a, b) => a.moduleType - b.moduleType)
            .map((module, key) => (
              <Grid item xs={12} md={6} lg={4} key={key}>
                <Card>
                  <MDBox p={2}>
                    <LoadingItem
                      icon={
                        textAndLink[module.moduleType]
                          ? textAndLink[module.moduleType].icon || faArrowUpRightFromSquare
                          : faHourglass
                      }
                      color={textAndLink[module.moduleType] ? "info" : "warning"}
                      new={textAndLink[module.moduleType]?.new}
                      beta={textAndLink[module.moduleType]?.beta}
                      title={textAndLink[module.moduleType]?.text || module.moduleType}
                      description={
                        !textAndLink[module.moduleType] ? (
                          <MDButton
                            size="small"
                            color="warning"
                            variant="contained"
                            sx={{ p: 1, py: 0, pointerEvents: "none" }}
                          >
                            Bald verfügbar
                          </MDButton>
                        ) : (
                          <MDButton
                            size="small"
                            color={!module.disabled ? "success" : "error"}
                            disabled={modulesIsLoading || updateIsLoading}
                            sx={{ p: 1, py: 0 }}
                            onClick={() => changeModuleStatus(module?.moduleType, module.disabled)}
                          >
                            {!module.disabled ? "Aktiviert" : "Deaktiviert"}
                            {updateIsLoading && (
                              <FontAwesomeIcon
                                icon={faSpinnerThird}
                                size="lg"
                                spin
                                fade
                                style={{
                                  marginLeft: "5px",
                                }}
                              />
                            )}
                          </MDButton>
                        )
                      }
                      loading={modulesIsLoading}
                      enabled={!!textAndLink[module.moduleType]}
                      isLink
                      href={`./${textAndLink[module.moduleType]?.link}` || "./"}
                    />
                  </MDBox>
                </Card>
              </Grid>
            ))}
        </Grid>
      </MDBox>
      <FooterSimple />
    </DashboardLayout>
  );
}

ModuleOverview.propTypes = {
  serverId: PropTypes.string.isRequired,
  botId: PropTypes.string.isRequired,
};

export default ModuleOverview;
