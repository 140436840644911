import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import MDBox from "../../MDBox";
import MDTypography from "../../MDTypography";
import MDButton from "../../MDButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faClone, faTimes } from "@fortawesome/pro-solid-svg-icons";
import React from "react";
import { FormCheckbox, FormInput } from "../../Form/FormInput";

/**
 * Renders a field component with input fields and checkboxes.
 * @param {Object} props - The props object.
 * @param {Object} props.field - The field object.
 * @param {number} props.fieldIndex - The index of the field.
 * @param {boolean} props.viewOnly - Flag indicating if the field is view-only.
 * @param {UseFieldArrayReturn} props.fieldArray - Flag indicating if the field is part of a field array.
 * @param {function} props.formHandler - The form handler function.
 * @param {number} props.embedIndex - The index of the embed.
 * @returns {JSX.Element} - The rendered field component.
 */
export function Field({ field, fieldIndex, viewOnly, fieldArray, formHandler, embedIndex }) {
  return (
    <Grid item xs={12} md={6} key={"field" + fieldIndex}>
      <Card
        sx={(theme) => ({
          backgroundColor: theme.palette?.card?.dark,
          p: 1.5,
        })}
      >
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {fieldIndex + 1}. Field
          <MDBox sx={{ float: "right" }} display="flex" flexDirection="row" gap={0.5}>
            <MDButton
              color="secondary"
              size="small"
              disabled={viewOnly || fieldIndex === 0}
              iconOnly
              onClick={() => fieldArray.swap(fieldIndex, fieldIndex - 1)}
            >
              <FontAwesomeIcon icon={faChevronUp} />
            </MDButton>
            <MDButton
              color="secondary"
              size="small"
              disabled={viewOnly || fieldIndex === fieldArray.fields.length - 1}
              iconOnly
              onClick={() => fieldArray.swap(fieldIndex, fieldIndex + 1)}
            >
              <FontAwesomeIcon icon={faChevronDown} />
            </MDButton>
            <MDButton
              color="secondary"
              size="small"
              disabled={viewOnly || fieldArray.fields.length >= 25}
              iconOnly
              onClick={() => fieldArray.insert(fieldIndex, field)}
            >
              <FontAwesomeIcon icon={faClone} />
            </MDButton>
            <MDButton
              color="error"
              size="small"
              disabled={viewOnly}
              iconOnly
              onClick={() => fieldArray.remove(fieldIndex)}
            >
              <FontAwesomeIcon icon={faTimes} />
            </MDButton>
          </MDBox>
        </MDTypography>
        <MDBox display="flex" flexDirection="column" gap={1} mt={2}>
          <FormInput
            formHandler={formHandler}
            name={`embeds[${embedIndex}].fields[${fieldIndex}].name`}
            label="Name"
            disabled={viewOnly}
            size="small"
            rules={{
              maxLength: {
                value: 256,
                message: "Der Name darf maximal 256 Zeichen lang sein.",
              },
              required: "Du musst einen Namen angeben.",
            }}
          />

          <FormInput
            formHandler={formHandler}
            name={`embeds[${embedIndex}].fields[${fieldIndex}].value`}
            label="Value"
            size="small"
            minRows={4}
            multiline
            rules={{
              maxLength: {
                value: 1024,
                message: "Der Wert darf maximal 1024 Zeichen lang sein.",
              },
            }}
          />
          <FormCheckbox
            formHandler={formHandler}
            name={`embeds[${embedIndex}].fields[${fieldIndex}].inline`}
            label="Inline"
          />
        </MDBox>
      </Card>
    </Grid>
  );
}
