import { useEffect, useState } from "react";
import { useGetBotsQuery } from "../../store/bot/bot-api";
import { useGetAccountQuery } from "../../store/auth/auth-api";
import SidenavRoot from "../../examples/Sidenav/SidenavRoot";
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";
import Icon from "@mui/material/Icon";
import { NavLink, useLocation } from "react-router-dom";
import sidenavLogoLabel from "../../examples/Sidenav/styles/sidenav";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import { useDispatch, useSelector } from "react-redux";
import { uiActions } from "../../store/ui/ui-slice";
import { useSessionParameter } from "../../examples/ParameterUtils";
import { UserRoute } from "./UserRoute";
import { BotsRoute } from "./BotsRoute";
import MDInput from "../MDInput";
import { FallbackComponent } from "../ErrorBoundary/FallbackComponent";
import * as Sentry from "@sentry/react";

export function SidenavWrapper({ transparentSidenav, brand = "", brandName = "" }) {
  const { data: botsData } = useGetBotsQuery();
  const { data: userData } = useGetAccountQuery();

  const { miniSidenav, whiteSidenav, darkMode } = useSelector((state) => state.ui);
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const [openCollapseArray, setOpenCollapseArray] = useSessionParameter("navbar", false, []);

  //TODO simplify this
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];
  const items = pathname.split("/").slice(1);
  const itemParentName = items[1];
  let textColor = "white";

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      dispatch(uiActions.setTempMiniSidenav(false));
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      dispatch(uiActions.setTempMiniSidenav(true));
      setOnMouseEnter(false);
    }
  };

  const closeSidenav = () => dispatch(uiActions.setMiniSidenav(true));

  useEffect(() => {
    setOpenCollapseArray(pathname.split("/"));
    // setOpenNestedCollapse(itemParentName);
  }, []);

  useEffect(() => {
    function handleMiniSidenav() {
      dispatch(uiActions.setMiniSidenav(window.innerWidth < 1200));
    }

    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  const [search, setSearch] = useState("");

  return (
    <Sentry.ErrorBoundary
      fallback={({ eventId, error, componentStack, resetError }) => (
        <FallbackComponent
          eventId={eventId}
          error={error}
          componentStack={componentStack}
          resetError={resetError}
          display="flex"
          flexDirection="column"
        />
      )}
    >
      <SidenavRoot
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
        variant="permanent"
        transitionDuration={0}
        ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
      >
        <MDBox pt={3} pb={1} px={4} textAlign="center" sx={{ zIndex: 10 }}>
          <MDBox
            display={{ xs: "block", xl: "none" }}
            position="absolute"
            top={0}
            right={0}
            p={1.625}
            onClick={closeSidenav}
            sx={{ cursor: "pointer" }}
          >
            <MDTypography variant="h6" color="secondary">
              <Icon sx={{ fontWeight: "bold" }}>close</Icon>
            </MDTypography>
          </MDBox>
          <MDBox component={NavLink} to="/" display="flex" alignItems="center">
            {brand && <MDBox component="img" src={brand} alt="Brand" width="2rem" />}
            <MDBox width={!brandName && "100%"} sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}>
              <MDTypography component="h6" variant="button" fontWeight="medium" color={textColor}>
                {brandName}
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
        <Divider />
        <List>
          <UserRoute search={search} />
          <BotsRoute search={search} />
        </List>
        <Divider />

        <MDBox
          p={1}
          bottom={0}
          position="sticky"
          backgroundColor="currentColor"
          sx={({ palette: { gradients }, functions: { linearGradient } }) => ({
            //background: gradients.dark.state,
            //backgroundOpacity: 0.5,
            backdropFilter: "blur(15px)",
            marginTop: "auto",
            zIndex: 110,
          })}
        >
          <MDInput
            label="Suchen"
            type="search"
            fullWidth
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              style: {
                color: "white",
              },
            }}
          />
        </MDBox>
      </SidenavRoot>
    </Sentry.ErrorBoundary>
  );
}
