/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "../../../../../components/MDBox";

// Material Dashboard 2 PRO React imageExample components
import DashboardLayout from "../../../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../../../examples/Navbars/DashboardNavbar";
import FooterSimple from "../../../../../components/Footer/FooterSimple";

// WelcomeLeave page components
// Images
import {faFloppyDisk} from "@fortawesome/pro-duotone-svg-icons";
import {useParams} from "react-router-dom";
import ModuleHeader from "../components/ModuleHeader";
import Error404Modal from "../../../../../components/Dialog/Error404Modal";
import {RoleSaverUpdateForm} from "./RoleSaverUpdateForm";

function RoleSaver() {
  const { botId, serverId } = useParams();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Error404Modal />
      <MDBox mb={2} />
      <ModuleHeader
        name="Role Saver"
        type="ROLE_SAVER"
        description="Leute behalten ihre Rollen auch bei Neueintritt."
        botId={botId}
        serverId={serverId}
        icon={faFloppyDisk}
      />
      <MDBox mt={5} mb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <RoleSaverUpdateForm guildId={serverId} botId={botId} />
          </Grid>
        </Grid>
      </MDBox>
      <FooterSimple />
    </DashboardLayout>
  );
}

export default RoleSaver;
