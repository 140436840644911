import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { authApi } from "./auth/auth-api";
import authSlice, { authActions } from "./auth/auth-slice";
import { botApi } from "./bot/bot-api";
import uiSlice from "./ui/ui-slice";
import { guildApi } from "./guild/guild-api";
import { moduleApi } from "./modules/module-api";
import { globalApi } from "./global/global-api";
import * as Sentry from "@sentry/react";
import cookieSlice from "./cookie/cookie-slice";
import flagsSlice from "./flags/flags-slice";
import adminSlice from "./admin/admin-slice";
import { adminApi } from "./admin/admin-api";

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

const store = configureStore({
  devTools: {
    actionCreators: {
      ...cookieSlice.actions,
      ...authSlice.actions,
      ...uiSlice.actions,
    },
  },
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    auth: authSlice.reducer,
    [globalApi.reducerPath]: globalApi.reducer,
    [botApi.reducerPath]: botApi.reducer,
    [guildApi.reducerPath]: guildApi.reducer,
    [moduleApi.reducerPath]: moduleApi.reducer,
    ui: uiSlice.reducer,
    cookie: cookieSlice.reducer,
    flags: flagsSlice.reducer,
    [adminApi.reducerPath]: adminApi.reducer,
    admin: adminSlice.reducer,
  },
  enhancers: (getDefaultEnhancers) => {
    return getDefaultEnhancers().concat(sentryReduxEnhancer);
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, //TODO: Mal schauen warum das jetzt immer kommt.
    })
      .concat(authApi.middleware)
      .concat(globalApi.middleware)
      .concat(botApi.middleware)
      .concat(guildApi.middleware)
      .concat(moduleApi.middleware)
      .concat(adminApi.middleware),
});

setupListeners(store.dispatch);

export default store;
