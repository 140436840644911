import MDInput from "../MDInput";
import { Controller } from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";
import FormField from "../../layouts/old/pages/account/components/FormField";
import MDTypography from "../MDTypography";
import { useSelector } from "react-redux";
import MDButton from "../MDButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { faCircleBolt, faPlus, faRotateForward } from "@fortawesome/pro-solid-svg-icons";
import Box from "@mui/material/Box";
import { Checkbox, Collapse, FormControlLabel, FormGroup, InputAdornment, Switch } from "@mui/material";
import MDBox from "../MDBox";
import { useState } from "react";
import IconButton from "@mui/material/IconButton";

function getNestedError(obj, path) {
  const keys = path.match(/[^.[\]]+/g) || [];
  return keys.reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined), obj);
}

/**
 * @function FormInput
 * @param formHandler {UseFormReturn<FieldValue, any, undefined>}
 * @param rules {Object}
 * @param name {string}
 * @param label {string}
 * @param variables {Array<{label: string, replacer: string}>}
 * @param props {any}
 * @return {JSX.Element}
 * @constructor
 */
export function FormInput({ formHandler = {}, rules = {}, name = "", label = "", variables = [], ...props }) {
  const [showReplacer, setShowReplacer] = useState(false);
  const error = getNestedError(formHandler.formState.errors, name)?.message || "";
  return (
    <Controller
      control={formHandler.control}
      name={name}
      rules={rules}
      render={({ field: fieldProps }) => (
        <MDBox
          display="flex"
          flexDirection="column"
          gap={1}
          pb={error ? 0 : 1}
          sx={{
            width: "100%",
          }}
        >
          <MDInput
            {...props}
            variant="outlined"
            label={label}
            {...formHandler.register(fieldProps.name, rules)}
            value={String(fieldProps.value)}
            error={Boolean(formHandler.formState.errors[name])}
            fullWidth
            InputProps={{
              endAdornment: variables.length > 0 && (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    color={showReplacer ? "primary" : "secondary"}
                    onClick={() => setShowReplacer(!showReplacer)}
                  >
                    <FontAwesomeIcon icon={faCircleBolt} />
                  </IconButton>
                </InputAdornment>
              ),
              ...props.InputProps,
            }}
            pb={0}
          />
          {error && (
            <MDTypography variant="caption" color="error" p={0} m={0}>
              {error}
            </MDTypography>
          )}
          {variables.length > 0 && (
            <Collapse in={showReplacer} unmountOnExit>
              <MDBox p={0} m={0} display="flex" flexDirection="column" flexWrap="wrap" gap={1}>
                {variables.map(({ label, replacer, description }, index) => (
                  <MDBox key={index + label}>
                    <MDButton
                      variant="gradient"
                      color="primary"
                      size="small"
                      iconOnly
                      sx={{ mr: 1 }}
                      onClick={() => formHandler.setValue(name, formHandler.getValues(name) + replacer)}
                    >
                      <FontAwesomeIcon icon={faPlus} size="lg" />
                    </MDButton>
                    <MDTypography variant="caption" p={0} m={0}>
                      {label} - {description}
                    </MDTypography>
                  </MDBox>
                ))}
              </MDBox>
            </Collapse>
          )}
        </MDBox>
      )}
    />
  );
}

export function FormCheckbox({ formHandler = {}, rules = {}, name = "", label = "", ...props }) {
  const error = getNestedError(formHandler.formState.errors, name)?.message || "";

  return (
    <Controller
      control={formHandler.control}
      name={name}
      rules={rules}
      render={({ field: fieldProps }) => (
        <MDBox
          display="flex"
          flexDirection="column"
          gap={1}
          pb={error ? 0 : 0}
          sx={{
            width: "100%",
          }}
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  {...formHandler.register(fieldProps.name, rules)}
                  checked={fieldProps.value}
                  //error={Boolean(formHandler.formState.errors[name]) || false}
                  onChange={(e) => {
                    fieldProps.onChange(e.target.checked);
                  }}
                />
              }
              label={label}
            />
          </FormGroup>
          {error && (
            <MDTypography variant="caption" color="error" p={0} m={0}>
              {error}
            </MDTypography>
          )}
        </MDBox>
      )}
    />
  );
}

export function FormSwitch({ formHandler = {}, rules = {}, name = "", label = "", ...props }) {
  const error = getNestedError(formHandler.formState.errors, name)?.message || "";

  return (
    <Controller
      control={formHandler.control}
      name={name}
      rules={rules}
      render={({ field: fieldProps }) => (
        <MDBox
          display="flex"
          flexDirection="column"
          gap={1}
          pb={error ? 0 : 1}
          sx={{
            width: "100%",
          }}
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  {...formHandler.register(fieldProps.name, rules)}
                  checked={fieldProps.value}
                  onChange={(e) => {
                    fieldProps.onChange(e.target.checked);
                  }}
                />
              }
              label={label}
            />
          </FormGroup>
          {error && (
            <MDTypography variant="caption" color="error" p={0} m={0}>
              {error}
            </MDTypography>
          )}
        </MDBox>
      )}
    />
  );
}

export function FormAutocomplete({
  formHandler = {},
  rules = {},
  name = "",
  label = "",
  options = [],
  autoCompleteProps = {},
  ...props
}) {
  const error = getNestedError(formHandler.formState.errors, name)?.message || "";

  return (
    <Controller
      control={formHandler.control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <MDBox
          display="flex"
          flexDirection="column"
          gap={1}
          pb={error ? 0 : 1}
          sx={{
            width: "100%",
          }}
        >
          <Autocomplete
            options={options}
            value={options.find((option) => option?.value === value) || null}
            onChange={(e, newValue) => {
              onChange(newValue?.value);
            }}
            isOptionEqualToValue={(option, val) => option?.value === val?.value}
            getOptionLabel={(option) => option?.label}
            renderInput={(params) => (
              <FormField
                {...params}
                label={label}
                variant="outlined"
                name={name}
                error={Boolean(formHandler.formState.errors[name])}
                InputLabelProps={{ shrink: true }}
                {...props}
              />
            )}
            {...autoCompleteProps}
          />
          {error && (
            <MDTypography variant="caption" color="error" p={0} m={0}>
              {error}
            </MDTypography>
          )}
        </MDBox>
      )}
    />
  );
}

export function FormCategoryAutocomplete({
  formHandler = {},
  rules = {},
  name = "",
  label = "",
  options = [],
  labelParameter = "name",
  labelIcon = (option) => {},
  uniqueParameter = "id",
  groupByParameter = "category",
  groupByFunction = null,
  categories = [],
  refetch = () => {},
  refetching = false,
  autoCompleteProps = {},
  additionalParameters = [],

  ...props
}) {
  const expertMode = useSelector((state) => state.ui.expertMode);

  if (groupByFunction === "default") {
    groupByFunction = (channel) =>
      expertMode && channel.category
        ? `${
            categories.find((x) => x.id?.toString() === channel?.category?.toString())?.name
          } - ${channel?.category?.toString()}`
        : categories.find((x) => x.id?.toString() === channel?.category?.toString())?.name;
  }

  const error = getNestedError(formHandler.formState.errors, name)?.message || "";

  return (
    <Controller
      control={formHandler.control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <MDBox
          display="flex"
          flexDirection="column"
          gap={1}
          pb={error ? 0 : 1}
          sx={{
            width: "100%",
          }}
        >
          <Autocomplete
            isOptionEqualToValue={(option, value) =>
              option[uniqueParameter]?.toString() === value[uniqueParameter]?.toString()
            }
            options={options?.map((channel, key) => ({
              key,
              [groupByParameter]: groupByFunction ? groupByFunction(channel, key) : channel[groupByParameter],
              [labelParameter]: channel[labelParameter],
              [uniqueParameter]: channel[uniqueParameter],
              ...additionalParameters.reduce((acc, cur) => {
                acc[cur] = channel[cur];
                return acc;
              }, {}),
            }))}
            value={options.find((option) => option[uniqueParameter]?.toString() === value) || null}
            onChange={(e, newValue) => {
              onChange((newValue && newValue[uniqueParameter]?.toString()) || null);
            }}
            groupBy={(option) => option[groupByParameter]}
            getOptionLabel={(option) =>
              `${option[labelParameter]}${expertMode ? ` - ${option[uniqueParameter]}` : ""}`
            }
            renderOption={(optionProps, option) => (
              <li {...optionProps} key={option[uniqueParameter]?.toString()}>
                {labelIcon(option)}
                {`${option[labelParameter]}${expertMode ? ` - ${option[uniqueParameter]}` : ""}`}
              </li>
            )}
            renderGroup={(params) => (
              <>
                {params.group && (
                  <MDTypography
                    fontWeight="medium"
                    variant="button"
                    sx={{ px: 1 }}
                    key={params.key + params.group}
                  >
                    {params.group}
                  </MDTypography>
                )}
                {params.children}
              </>
            )}
            noOptionsText={
              <MDBox display="flex" flexDirection="column" alignItems="center" gap={1}>
                <MDTypography variant="button" fontWeight="medium">
                  Es wurden keine Optionen gefunden.
                </MDTypography>
                <MDButton
                  variant="outlined"
                  color="info"
                  fullWidth
                  size="small"
                  disabled={refetching}
                  onClick={refetch}
                >
                  {refetching ? (
                    <FontAwesomeIcon icon={faSpinnerThird} size="lg" spin fade />
                  ) : (
                    <>
                      <FontAwesomeIcon size="1x" style={{ marginRight: "10px" }} icon={faRotateForward} />
                      Klicke hier zum erneuten Laden
                    </>
                  )}
                </MDButton>
              </MDBox>
            }
            renderInput={(params) => (
              <FormField
                {...params}
                label={label}
                hideStatusIcon
                variant="outlined"
                name={name}
                error={Boolean(formHandler.formState.errors[name])}
                InputLabelProps={{ shrink: true }}
                {...props}
              />
            )}
            {...autoCompleteProps}
          />
          {error && (
            <MDTypography variant="caption" color="error" p={0} m={0}>
              {error}
            </MDTypography>
          )}
        </MDBox>
      )}
    />
  );
}

export function FormSimpleAutocomplete({
  formHandler = {},
  rules = {},
  name = "",
  label = "",
  options = [],
  labelParameter = "name",
  labelIcon = (option) => {},
  uniqueParameter = "id",
  disabledOptions = (options) => options,
  categories = [],
  refetch = () => {},
  refetching = false,
  renderOption = "default",
  multiple = false,
  autoCompleteProps = {},
  additionalParameters = [],

  ...props
}) {
  const expertMode = useSelector((state) => state.ui.expertMode);

  if (renderOption === "default") {
    renderOption = (optionProps, option) => (
      <li {...optionProps} key={option[uniqueParameter]?.toString()}>
        {labelIcon(option)}
        {`${option[labelParameter]}${expertMode ? ` - ${option[uniqueParameter]}` : ""}`}
      </li>
    );
  } else if (renderOption === "emoji") {
    renderOption = (optionProps, option) => (
      <Box component="li" {...optionProps} key={option[uniqueParameter]?.toString()}>
        {labelIcon(option)}
        <img
          alt="Emoji"
          loading="lazy"
          width="auto"
          height="20"
          style={{
            marginRight: "5px",
          }}
          src={`https://cdn.discordapp.com/emojis/${option.id}.webp?size=96&quality=lossless`}
        />
        {(option.name && option.id && `${option.name}${expertMode ? ` - ${option.id}` : ""}`) || ""}
      </Box>
    );
  }

  const error = getNestedError(formHandler.formState.errors, name)?.message || "";

  return (
    <Controller
      control={formHandler.control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <MDBox
          display="flex"
          flexDirection="column"
          gap={1}
          pb={error ? 0 : 1}
          sx={{
            width: "100%",
          }}
        >
          <Autocomplete
            isOptionEqualToValue={(option, value) =>
              option[uniqueParameter]?.toString() === value[uniqueParameter]?.toString()
            }
            options={
              options?.map((channel, key) => ({
                key,
                [labelParameter]: channel[labelParameter],
                [uniqueParameter]: channel[uniqueParameter],
                ...additionalParameters.reduce((acc, cur) => {
                  acc[cur] = channel[cur];
                  return acc;
                }, {}),
              })) || []
            }
            getOptionDisabled={disabledOptions}
            value={
              (multiple
                ? options.filter((option) =>
                    value?.some((r) => r.toString() === option[uniqueParameter]?.toString())
                  ) || null
                : options.find((option) => option[uniqueParameter]?.toString() === value)) || null
            }
            onChange={(e, newValue) => {
              multiple
                ? onChange(newValue?.map((r) => r[uniqueParameter]))
                : onChange(newValue?.[uniqueParameter]);
            }}
            getOptionLabel={(option) =>
              `${option[labelParameter]}${expertMode ? ` - ${option[uniqueParameter]}` : ""}`
            }
            multiple={multiple}
            renderOption={renderOption}
            noOptionsText={
              <MDBox display="flex" flexDirection="column" alignItems="center" gap={1}>
                <MDTypography variant="button" fontWeight="medium">
                  Es wurden keine Optionen gefunden.
                </MDTypography>
                <MDButton
                  variant="outlined"
                  color="info"
                  fullWidth
                  size="small"
                  disabled={refetching}
                  onClick={refetch}
                >
                  {refetching ? (
                    <FontAwesomeIcon icon={faSpinnerThird} size="lg" spin fade />
                  ) : (
                    <>
                      <FontAwesomeIcon size="1x" style={{ marginRight: "10px" }} icon={faRotateForward} />
                      Klicke hier zum erneuten Laden
                    </>
                  )}
                </MDButton>
              </MDBox>
            }
            renderInput={(params) => (
              <FormField
                {...params}
                label={label}
                hideStatusIcon
                variant="outlined"
                name={name}
                error={Boolean(formHandler.formState.errors[name])}
                InputLabelProps={{ shrink: true }}
                {...props}
              />
            )}
            {...autoCompleteProps}
          />
          {error && (
            <MDTypography variant="caption" color="error" p={0} m={0}>
              {error}
            </MDTypography>
          )}
        </MDBox>
      )}
    />
  );
}
