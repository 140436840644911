import MDBox from "../../../../../../../components/MDBox";
import {
  FormCategoryAutocomplete,
  FormInput,
  FormSimpleAutocomplete,
  FormSwitch,
} from "../../../../../../../components/Form/FormInput";
import { Grid, InputAdornment } from "@mui/material";
import MDButton from "../../../../../../../components/MDButton";
import {
  useGetCategoriesQuery,
  useGetEmojisQuery,
  useGetRolesQuery,
  useGetTextChannelsQuery,
} from "../../../../../../../store/guild/guild-api";
import { useGetModuleDataQuery } from "../../../../../../../store/modules/module-api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/pro-solid-svg-icons";
import { useMemo, useState } from "react";
import EmojiPicker, { Emoji, EmojiStyle, SkinTonePickerLocation, Theme } from "emoji-picker-react";
import { getUnifiedEmoji, isUnifiedEmoji } from "../../../../../../../Utils";
import { useWatch } from "react-hook-form";
import { useUnleashFlag } from "../../../../../../../store/flags/flags-actions";
import {
  AutoCompleteChannelIcon,
  AutoCompleteRoleIcon,
} from "../../../../../../../components/Form/AutoCompleteIcon";
import { useSelector } from "react-redux";

export function CategoryForm({ formHandler, botId, guildId, setBuilderOpened }) {
  const { getValues, setValue } = formHandler;

  const customEmojiInTicketSystem = useUnleashFlag("customEmojiInTicketSystem");

  const [openEmojiPicker, setOpenEmojiPicker] = useState(false);
  const darkMode = useSelector((state) => state.ui.darkMode);
  const {
    data: rolesData = [],
    isLoading: rolesIsLoading,
    refetch: rolesRefetch,
    isRefetching: rolesIsRefetching,
  } = useGetRolesQuery({
    botId,
    guildId,
  });

  const {
    data: emojiData = [],
    refetch: emojiRefetch,
    isFetching: emojiIsFetching,
  } = useGetEmojisQuery({
    botId,
    guildId,
  });

  const {
    data: textChannelsData = [],
    isLoading: textChannelsIsLoading,
    refetch: textChannelsRefetch,
    isRefetching: textChannelsIsRefetching,
  } = useGetTextChannelsQuery({
    botId,
    guildId,
  });

  const {
    data: categoriesData = [],
    isLoading: categoriesIsLoading,
    refetch: categoriesRefetch,
    isRefetching: categoriesIsRefetching,
  } = useGetCategoriesQuery({
    botId,
    guildId,
  });

  const {
    data: ticketPanelData = [],
    isLoading: ticketPanelIsLoading,
    refetch: ticketPanelRefetch,
    isRefetching: ticketPanelIsRefetching,
  } = useGetModuleDataQuery({
    botId,
    guildId,
    module: "ticket/panel",
  });

  const customEmojiPicker = useMemo(
    () => (
      <EmojiPicker
        onEmojiClick={(emojiData) => {
          setValue("emoji", emojiData.emoji);
        }}
        autoFocusSearch={false}
        theme={darkMode ? Theme.DARK : Theme.LIGHT}
        skinTonePickerLocation={SkinTonePickerLocation.PREVIEW}
        width="100%"
        lazyLoadEmojis
        previewConfig={{
          defaultCaption: "Wähle ein Emoji",
          defaultEmoji: "1f41f",
        }}
        searchPlaceHolder="Suchen"
        emojiStyle={EmojiStyle.TWITTER}
        customEmojis={
          customEmojiInTicketSystem
            ? [...emojiData]?.map((emoji) => ({
                id: emoji.id,
                names: [emoji.name],
                imgUrl: emoji.imageUrl,
              }))
            : []
        }
      />
    ),

    [emojiData, setValue, customEmojiInTicketSystem]
  );

  const emote = useWatch({
    control: formHandler.control,
    name: "emoji",
  });

  return (
    <>
      <MDBox pt={1} pb={2} px={2}>
        <FormInput
          formHandler={formHandler}
          name="name"
          label="Ticket Kategorie Name"
          rules={{
            required: "Bitte gib einen Namen für die Kategorie an.",
          }}
        />
      </MDBox>
      <MDBox pt={1} pb={2} px={2}>
        <FormInput
          formHandler={formHandler}
          name="emoji"
          label="Emoji"
          rules={{
            required: "Bitte gib ein Emoji an.",
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{ paddingLeft: 0, marginRight: 0 }}>
                {isUnifiedEmoji(getUnifiedEmoji(emote)) ? (
                  <Emoji unified={getUnifiedEmoji(emote)} emojiStyle={EmojiStyle.TWITTER} size={22} />
                ) : (
                  <img
                    alt=""
                    src={emojiData?.find((r) => r.id === emote)?.imageUrl}
                    style={{ height: "22px" }}
                  />
                )}
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end" sx={{ paddingLeft: 0, paddingRight: 0, marginRight: 0 }}>
                <MDButton
                  variant="gradient"
                  sx={{ mr: 1 }}
                  onClick={emojiRefetch}
                  color="primary"
                  iconOnly
                  size="small"
                >
                  <FontAwesomeIcon icon={faSync} spin={emojiIsFetching} />
                </MDButton>
                <MDButton
                  variant="contained"
                  onClick={() => setOpenEmojiPicker(!openEmojiPicker)}
                  color="primary"
                  size="small"
                >
                  {openEmojiPicker ? "Schließen" : "Öffnen"}
                </MDButton>
              </InputAdornment>
            ),
          }}
        />
        {openEmojiPicker && (
          <>
            <MDBox pt={2} px={2} />
            {customEmojiPicker}
          </>
        )}
      </MDBox>
      <MDBox pt={1} pb={2} px={2}>
        <FormInput formHandler={formHandler} name="description" label="Ticket Beschreibung" />
      </MDBox>
      <MDBox pt={1} pb={2} px={2}>
        <FormSimpleAutocomplete
          name="supportRoles"
          label="Support Rollen"
          options={[...rolesData].sort((x, y) => x.category - y.category)}
          formHandler={formHandler}
          additionalParameters={["colorHex"]}
          labelIcon={(option) => <AutoCompleteRoleIcon option={option} />}
          refetch={() => {
            rolesRefetch();
          }}
          multiple
          refetching={rolesIsRefetching}
        />
      </MDBox>
      <MDBox pt={1} pb={2} px={2}>
        <FormSimpleAutocomplete
          name="category"
          label="Category"
          options={[...categoriesData]}
          formHandler={formHandler}
          refetch={() => {
            categoriesRefetch();
          }}
          refetching={categoriesIsRefetching}
          rules={{
            required: "Du musst eine Kategorie angeben.",
          }}
        />
      </MDBox>
      <MDBox pt={1} pb={2} px={2}>
        <FormInput
          formHandler={formHandler}
          name="ticketName"
          label="Ticket Name"
          rules={{
            required: "Bitte gib einen Namen für das Ticket an.",
          }}
          variables={[
            {
              label: "%user_id% einfügen",
              description: "Fügt die User ID ein",
              replacer: "%user_id%",
            },
            {
              label: "%user_name% einfügen",
              description: "Fügt den User Namen ein",
              replacer: "%user_name%",
            },
          ]}
        />
      </MDBox>
      <MDBox pt={1} pb={2} px={2}>
        <FormSimpleAutocomplete
          name="archive"
          label="Archive"
          options={[...categoriesData]}
          formHandler={formHandler}
          refetch={() => {
            categoriesRefetch();
          }}
          rules={{
            validate: (value) => {
              const values = getValues();
              if (values.instantDelete && values.archive) {
                return "Instant Delete und Archive können nicht gleichzeitig aktiviert sein.";
              }
              return true;
            },
          }}
          refetching={categoriesIsRefetching}
        />
      </MDBox>
      <MDBox pt={1} pb={2} px={2}>
        <FormCategoryAutocomplete
          name="customLogsChannel"
          label="Custom Logs Channel"
          categories={categoriesData}
          options={[...textChannelsData].sort((x, y) => x.category - y.category)}
          groupByFunction="default"
          labelIcon={(option) => <AutoCompleteChannelIcon option={option} />}
          additionalParameters={["type"]}
          formHandler={formHandler}
          refetch={() => {
            textChannelsRefetch();
            categoriesRefetch();
          }}
          refetching={textChannelsIsRefetching || categoriesIsRefetching}
        />
      </MDBox>
      <MDBox pt={1} pb={2} px={2}>
        <MDButton
          variant="outlined"
          color="info"
          fullWidth
          size="large"
          onClick={() => setBuilderOpened(true)}
        >
          Erstellnachricht bearbeiten
        </MDButton>
      </MDBox>
      <MDBox pt={1} pb={2} px={2}>
        <Grid container>
          <Grid item xs={12} md={6} xl={4}>
            <FormSwitch
              formHandler={formHandler}
              name="instantDelete"
              label="Instant Delete"
              rules={{
                validate: (value) => {
                  const values = getValues();
                  if (values.instantDelete && values.archive) {
                    return "Instant Delete und Archive können nicht gleichzeitig aktiviert sein.";
                  }
                  return true;
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} xl={4}>
            <FormSwitch formHandler={formHandler} name="autoTranscript" label="Auto Transcript" />
          </Grid>

          <Grid item xs={12} md={6} xl={4}>
            <FormSwitch formHandler={formHandler} name="sendDM" label="Send DM" />
          </Grid>
          <Grid item xs={12} md={6} xl={4}>
            <FormSwitch formHandler={formHandler} name="pinBotMessage" label="Pin Bot Message" />
          </Grid>
          <Grid item xs={12} md={6} xl={4}>
            <FormSwitch
              formHandler={formHandler}
              name="mentionUserOnCreation"
              label="Mention User On Creation"
            />
          </Grid>
          <Grid item xs={12} md={6} xl={4}>
            <FormSwitch formHandler={formHandler} name="allowReopening" label="Allow Reopening" />
          </Grid>
          <Grid item xs={12} md={6} xl={4}>
            <FormSwitch formHandler={formHandler} name="mentionSupportRoles" label="Mention Support Roles" />
          </Grid>
          <Grid item xs={12} md={6} xl={4}>
            <FormSwitch formHandler={formHandler} name="publicTicket" label="Public Ticket" />
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}
