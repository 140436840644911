/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Custom styles for the SidenavItem
import { item, itemArrow, itemContent } from "examples/Sidenav/styles/sidenavItem"; // Material Dashboard 2 PRO React contexts
import { useEffect } from "react";
import ListItemIcon from "@mui/material/ListItemIcon";
import { useSessionParameter } from "../ParameterUtils";
import { collapseIcon, collapseIconBox } from "./styles/sidenavCollapse";
import { useSelector } from "react-redux";

function SidenavItem({
  color = "info",
  name,
  navKey,
  active = false,
  href2 = "",
  nested = false,
  children = false,
  open = false,
  addMargin,
  icon = "",
  sticky = false,
  ...rest
}) {
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = useSelector((state) => state.ui);

  const [opened, setOpened] = useSessionParameter(navKey, false, false);

  useEffect(() => {
    const { pathname } = window.location;
    if (pathname.startsWith(href2)) {
      setOpened(true);
    }
  }, []);
  /*
              if (undefined !== key) {
                console.log("sidenavItem", key, false);
              } else {
                console.warn("sideNavItem", key, true);
              }
              */

  return (
    <>
      <ListItem
        {...rest}
        onClick={() => setOpened(!opened)}
        component="li"
        sx={(theme) => ({
          background: sticky ? theme.palette.background.sidenav : "inherit",
          ...item(theme, { active, color, transparentSidenav, whiteSidenav, darkMode, icon }),
          position: sticky ? "sticky" : "relative",
          top: sticky ? "65px" : "auto",
          zIndex: sticky ? 50 : "inherit",
          backdropFilter: sticky ? "blur(10px)" : "none",
          outline: sticky ? "0.5px dashed rgba(87, 94, 110, 1)" : "none",
          marginY: addMargin ? addMargin : 0,
        })}
      >
        <MDBox
          sx={(theme) =>
            itemContent(theme, {
              active,
              miniSidenav,
              name,
              opened,
              nested,
              transparentSidenav,
              whiteSidenav,
              darkMode,
              icon,
            })
          }
        >
          {icon ? (
            <ListItemIcon
              sx={(theme) => collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode })}
            >
              {typeof icon === "string" ? (
                <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon>
              ) : (
                icon
              )}
            </ListItemIcon>
          ) : null}
          <ListItemText primary={name} sx={icon ? { marginLeft: "10px" } : null} />
          {children && (
            <Icon
              component="i"
              sx={(theme) =>
                itemArrow(theme, {
                  opened,
                  miniSidenav,
                  transparentSidenav,
                  whiteSidenav,
                  darkMode,
                  icon,
                })
              }
            >
              expand_less
            </Icon>
          )}
        </MDBox>
      </ListItem>
      {children && (
        <Collapse in={opened} timeout="auto" unmountOnExit {...rest}>
          {children}
        </Collapse>
      )}
    </>
  );
}

// Typechecking props for the SidenavItem
SidenavItem.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  href2: PropTypes.string,
  active: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  navKey: PropTypes.string.isRequired,
  nested: PropTypes.bool,
  children: PropTypes.node,
  open: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default SidenavItem;
