import { useForm } from "react-hook-form";
import { useUpdateModuleDataMutation } from "../../../../../store/modules/module-api";
import { useGetCategoriesQuery, useGetTextChannelsQuery } from "../../../../../store/guild/guild-api";
import Logger from "../../../../../Logger";
import apiMessages from "../../../../../apiMessages";
import MDBox from "../../../../../components/MDBox";
import { FormCategoryAutocomplete } from "../../../../../components/Form/FormInput";
import MDButton from "../../../../../components/MDButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { faBroomWide, faFloppyDisk } from "@fortawesome/pro-solid-svg-icons";
import { AutoCompleteChannelIcon } from "../../../../../components/Form/AutoCompleteIcon";

export function CustomLogChannelForm({ botId, guildId, selectedChannel, logType, enabled }) {
  const [update, { isLoading }] = useUpdateModuleDataMutation();

  const formHandler = useForm({
    defaultValues: {
      channelId: selectedChannel,
      enabled: enabled,
    },
  });
  const { control, handleSubmit, reset } = formHandler;

  const {
    data: textChannelData = [],
    isLoading: textChannelsIsLoading,
    isFetching: textChannelIsFetching,
    refetch: textChannelRefetch,
  } = useGetTextChannelsQuery({
    botId,
    guildId,
  });

  const {
    data: categoriesData = [],
    isFetching: categoriesIsFetching,
    refetch: categoriesRefetch,
  } = useGetCategoriesQuery({
    botId,
    guildId,
  });

  const onSubmit = async (e) => {
    update({
      botId,
      guildId,
      module: "log",
      id: logType,
      data: {
        ...e,
      },
    }).then(({ data, error }) => {
      Logger.info("SubmitEvent", { module: "SubmitForm" }, data, error);
      apiMessages.showMessage({
        type: "LogSystem",
        title: data ? "customUpdated" : error?.data?.error || error,
      });
    });
  };

  const onReset = () => {
    reset({
      channelId: selectedChannel,
    });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      onReset={onReset}
      style={{
        width: "100%",
      }}
    >
      <MDBox display="flex" flexDirection="column" gap={1}>
        <MDBox
          sx={{
            minWidth: "300px",
          }}
        >
          <FormCategoryAutocomplete
            name="channelId"
            label="Log Kanal"
            categories={categoriesData}
            options={[...textChannelData].sort((x, y) => x.category - y.category)}
            groupByFunction="default"
            labelIcon={(option) => <AutoCompleteChannelIcon option={option} />}
            additionalParameters={["type"]}
            formHandler={formHandler}
            refetch={() => {
              textChannelRefetch();
              categoriesRefetch();
            }}
            refetching={textChannelIsFetching || categoriesIsFetching}
          />
        </MDBox>
        <MDBox display="flex" flexDirection="row" gap={1}>
          <MDButton
            type="submit"
            variant="contained"
            sx={{ width: "100%" }}
            disabled={isLoading}
            color="primary"
          >
            {isLoading ? (
              <FontAwesomeIcon icon={faSpinnerThird} size="lg" spin />
            ) : (
              <FontAwesomeIcon icon={faFloppyDisk} size="lg" />
            )}
          </MDButton>
          <MDButton
            type="reset"
            variant="outlined"
            sx={{ width: "100%" }}
            disabled={isLoading}
            color="primary"
          >
            {isLoading ? (
              <FontAwesomeIcon icon={faSpinnerThird} size="lg" spin />
            ) : (
              <FontAwesomeIcon icon={faBroomWide} size="lg" />
            )}
          </MDButton>
        </MDBox>
      </MDBox>
    </form>
  );
}
