import MDAvatar from "../MDAvatar";
import SidenavCollapse from "../../examples/Sidenav/SidenavCollapse";
import { useGetAccountQuery } from "../../store/auth/auth-api";
import MDBox from "../MDBox";
import { NavLink, useLocation } from "react-router-dom";
import SidenavItem from "../../examples/Sidenav/SidenavItem";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  faExclamationCircle,
  faGrid,
  faList,
  faPuzzlePiece,
  faUserHeadset,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGetBotQuery } from "../../store/bot/bot-api";
import { Skeleton } from "@mui/material";
import SidenavList from "../../examples/Sidenav/SidenavList";
import * as Utils from "../../Utils";
import {
  faAbacus,
  faFaceSwear,
  faFileLines,
  faFlagSwallowtail,
  faFloppyDisk,
  faHandWave,
  faListCheck,
  faMedal,
  faMemo,
  faMessageSmile,
  faNoteSticky,
  faPaperPlaneTop,
  faPartyHorn,
  faRulerCombined,
  faSquarePollVertical,
  faTicket,
  faToiletPaper,
  faVacuum,
} from "@fortawesome/pro-duotone-svg-icons";
import { FallbackComponent } from "../ErrorBoundary/FallbackComponent";
import * as Sentry from "@sentry/react";
import { useGetPluginsQuery } from "../../store/global/global-api";
import { useUnleashFlag } from "../../store/flags/flags-actions";

export function BotRoute({ botId, search, supportBot }) {
  const location = useLocation();
  const { data: bot = {}, isLoading: botIsLoading, isError: botIsError } = useGetBotQuery({ botId });
  const { data: plugins = [], isLoading: pluginsIsLoading } = useGetPluginsQuery({ botId });
  const { data: userData } = useGetAccountQuery();
  const stickyNavbar = useUnleashFlag("stickyNavbarItems");

  const showModuleIcons = useSelector((state) => state.ui?.showModuleIcons);

  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];
  const items = pathname.split("/").slice(1);
  const itemParentName = items[1];
  const [openCollapseArray, setOpenCollapseArray] = useState([]);

  const toggleCollapse = (key) => {
    if (openCollapseArray.includes(key)) {
      const array = [...openCollapseArray];
      array.splice(openCollapseArray.indexOf(key), 1);
      setOpenCollapseArray(array);
    } else {
      setOpenCollapseArray([...openCollapseArray, key]);
    }
  };

  const collapseIsOpen = (key) => openCollapseArray.includes(key);

  const collapseIsActive = (key) => key === collapseName;

  if (botIsLoading)
    return (
      <MDBox px={2}>
        <Skeleton variant="text" animation="wave" width="100%" height={75} />
      </MDBox>
    );

  const searchIncludes = (key) => key.toLowerCase().includes(search.toLowerCase());

  return (
    <Sentry.ErrorBoundary
      fallback={({ eventId, error, componentStack, resetError }) => (
        <FallbackComponent
          eventId={eventId}
          error={error}
          componentStack={componentStack}
          resetError={resetError}
          display="flex"
          flexDirection="column"
        />
      )}
    >
      <SidenavCollapse
        name={bot?.name}
        sticky={stickyNavbar && true}
        navKey={`bot-${botId} `}
        addMargin={1}
        icon={
          botIsError ? (
            <FontAwesomeIcon icon={faExclamationCircle} fade color="red" />
          ) : (
            <MDAvatar alt={bot?.name} src={bot?.avatarUrl} size="sm" />
          )
        }
        active={collapseIsActive(`bot${bot.botId}`)}
        open={collapseIsOpen(`bot${bot.botId}`)}
        onClick={() => toggleCollapse(`bot${bot.botId}`)}
      >
        {botIsError && (
          <SidenavList>
            {[
              {
                name: `Bot Fehler`,
                key: `bot${botId}error`,
                route: `/bots/${botId}/error`,
                icon: <FontAwesomeIcon icon={faFileLines} />,
              },
            ].map(({ route, name, key, icon }) => (
              <MDBox mx={1} key={key}>
                <NavLink to={route} sx={{ textDecoration: "none" }}>
                  <SidenavItem
                    navKey={key}
                    name={name}
                    active={route === pathname}
                    nested
                    icon={showModuleIcons && icon}
                  />
                </NavLink>
              </MDBox>
            ))}
          </SidenavList>
        )}
        {!botIsError && (
          <SidenavList>
            {[
              {
                name: `Bot Übersicht`,
                key: `bot${bot.botId}overview`,
                route: `/bots/${bot.botId}`,
                icon: <FontAwesomeIcon icon={faGrid} />,
              },
              {
                name: `Whitelist`,
                key: `bot${bot.botId}whitelist`,
                route: `/bots/${bot.botId}/whitelist`,
                icon: <FontAwesomeIcon icon={faFlagSwallowtail} />,
                hidden: bot?.owner?.id?.toString() !== userData?.id?.toString(),
              },
            ]
              .filter((x) => !x.hidden && searchIncludes(x.name))
              .map(({ route, name, key, icon }) => (
                <MDBox mx={1} key={key}>
                  <NavLink to={route} sx={{ textDecoration: "none" }}>
                    <SidenavItem
                      navKey={key}
                      name={name}
                      active={route === pathname}
                      nested
                      icon={showModuleIcons && icon}
                    />
                  </NavLink>
                </MDBox>
              ))}
            <SidenavList>
              <SidenavItem
                navKey={`bot${bot.botId}servers`}
                name={`Guilds`}
                active={`bot${bot.botId}servers` === itemParentName ? "isParent" : false}
                open={collapseIsOpen(`bot${bot.botId}servers`)}
                onClick={() => toggleCollapse(`bot${bot.botId}servers`)}
              >
                {searchIncludes("Guild Liste") && (
                  <NavLink to={`/bots/${bot.botId}/servers`} style={{ textDecoration: "none" }}>
                    <SidenavItem
                      name="Guild Liste"
                      navKey={`bot${bot.botId}servers`}
                      active={`/bots/${bot.botId}/servers` === pathname}
                      icon={showModuleIcons && <FontAwesomeIcon icon={faList} />}
                      nested
                    />
                  </NavLink>
                )}
                {bot?.guilds?.map((server, key) => (
                  <SidenavItem
                    key={key}
                    sticky={stickyNavbar && true}
                    navKey={`bot${bot.botId}servers`}
                    name={
                      <abbr title={server.name} style={{ textDecoration: "none" }}>
                        {Utils.shortString(server.name, 13)}
                      </abbr>
                    }
                    active={`bot${bot.botId}servers` === itemParentName ? "isParent" : false}
                    open={collapseIsOpen(`bot${bot.botId}server${server.id}`)}
                    icon={<MDAvatar alt={bot?.name} src={server?.iconUrl} size="sm" />}
                    addMargin={0.5}
                    nested
                    onClick={() => toggleCollapse(`bot${bot.botId}server${server.id}`)}
                  >
                    {searchIncludes("Server Übersicht") && (
                      <NavLink to={`/bots/${bot.botId}/servers/${server.id}`} sx={{ textDecoration: "none" }}>
                        <SidenavItem
                          name="Server Übersicht"
                          navKey={`bot${bot.botId}server${server.id}overview`}
                          active={`/bots/${bot.botId}/servers/${server.id}` === pathname}
                          nested
                          icon={showModuleIcons && <FontAwesomeIcon icon={faGrid} />}
                        />
                      </NavLink>
                    )}
                    {[
                      {
                        name: `ZählerSystem`,
                        key: `bot${bot.botId}server${server.id}counter`,
                        route: `/bots/${bot.botId}/servers/${server.id}/modules/counter-system`,
                        icon: <FontAwesomeIcon icon={faAbacus} />,
                      },
                      {
                        name: `RoleReaction`,
                        key: `bot${bot.botId}server${server.id}role-reaction`,
                        route: `/bots/${bot.botId}/servers/${server.id}/modules/role-reaction`,
                        icon: <FontAwesomeIcon icon={faRulerCombined} />,
                      },
                      {
                        name: `StickyMessages`,
                        key: `bot${bot.botId}server${server.id}sticky-messages`,
                        route: `/bots/${bot.botId}/servers/${server.id}/modules/sticky-messages`,
                        icon: <FontAwesomeIcon icon={faNoteSticky} />,
                      },
                      {
                        name: `Logs`,
                        key: `bot${bot.botId}server${server.id}logs`,
                        route: `/bots/${bot.botId}/servers/${server.id}/modules/logs`,
                        icon: <FontAwesomeIcon icon={faFileLines} />,
                      },
                      {
                        name: `AutoRole`,
                        key: `bot${bot.botId}server${server.id}auto-role`,
                        route: `/bots/${bot.botId}/servers/${server.id}/modules/auto-role`,
                        icon: <FontAwesomeIcon icon={faToiletPaper} />,
                      },
                      {
                        name: `AutoClear`,
                        key: `bot${bot.botId}server${server.id}auto-clear`,
                        route: `/bots/${bot.botId}/servers/${server.id}/modules/auto-clear`,
                        icon: <FontAwesomeIcon icon={faVacuum} />,
                      },
                      {
                        name: `AutoReaction`,
                        key: `bot${bot.botId}server${server.id}auto-reaction`,
                        route: `/bots/${bot.botId}/servers/${server.id}/modules/auto-reaction`,
                        icon: <FontAwesomeIcon icon={faMessageSmile} />,
                      },
                      {
                        name: `Giveaway`,
                        key: `bot${bot.botId}server${server.id}giveaway`,
                        route: `/bots/${bot.botId}/servers/${server.id}/modules/giveaway`,
                        icon: <FontAwesomeIcon icon={faPartyHorn} />,
                      },
                      {
                        name: `GreetingSystem`,
                        key: `bot${bot.botId}server${server.id}welcome-leave`,
                        route: `/bots/${bot.botId}/servers/${server.id}/modules/greeting-system`,
                        icon: <FontAwesomeIcon icon={faHandWave} />,
                      },
                      {
                        name: `ChannelLog`,
                        key: `bot${bot.botId}server${server.id}channel-log`,
                        route: `/bots/${bot.botId}/servers/${server.id}/modules/channel-log`,
                        icon: <FontAwesomeIcon icon={faListCheck} />,
                      },
                      {
                        name: `Suggestion`,
                        key: `bot${bot.botId}server${server.id}suggestion`,
                        route: `/bots/${bot.botId}/servers/${server.id}/modules/suggestion`,
                        icon: <FontAwesomeIcon icon={faMemo} />,
                      },
                      {
                        name: `RoleSaver`,
                        key: `bot${bot.botId}server${server.id}role-saver`,
                        route: `/bots/${bot.botId}/servers/${server.id}/modules/role-saver`,
                        icon: <FontAwesomeIcon icon={faFloppyDisk} />,
                      },
                      {
                        name: `TicketSystem`,
                        key: `bot${bot.botId}server${server.id}ticket-system`,
                        route: `/bots/${bot.botId}/servers/${server.id}/modules/ticket-system`,
                        icon: <FontAwesomeIcon icon={faTicket} />,
                      },
                      {
                        name: `WordBlacklist`,
                        key: `bot${bot.botId}server${server.id}word-blacklist`,
                        route: `/bots/${bot.botId}/servers/${server.id}/modules/word-blacklist`,
                        icon: <FontAwesomeIcon icon={faFaceSwear} />,
                      },
                      {
                        name: `SendEmbed`,
                        key: `bot${bot.botId}server${server.id}send-embed`,
                        route: `/bots/${bot.botId}/servers/${server.id}/send-embed`,
                        icon: <FontAwesomeIcon icon={faPaperPlaneTop} />,
                      },
                      {
                        name: `PrivateTalk`,
                        key: `bot${bot.botId}server${server.id}private-talk`,
                        route: `/bots/${bot.botId}/servers/${server.id}/modules/private-talk`,
                        icon: <FontAwesomeIcon icon={faUserHeadset} />,
                      },
                      {
                        name: `Vote`,
                        key: `bot${bot.botId}server${server.id}vote`,
                        route: `/bots/${bot.botId}/servers/${server.id}/modules/vote`,
                        icon: <FontAwesomeIcon icon={faSquarePollVertical} />,
                      },
                      {
                        name: `XPSystem`,
                        key: `bot${bot.botId}server${server.id}xp-system`,
                        route: `/bots/${bot.botId}/servers/${server.id}/modules/xp-system`,
                        icon: <FontAwesomeIcon icon={faMedal} />,
                      },
                      plugins.find(({ name }) => name === "game-channel") && {
                        name: "GameRole",
                        key: `bot${bot.botId}server${server.id}game-role`,
                        route: `/bots/${bot.botId}/servers/${server.id}/plugins/game-role`,
                        icon: <FontAwesomeIcon icon={faPuzzlePiece} />,
                        isPlugin: true,
                      },
                    ]
                      .sort((a, b) =>
                        a.isPlugin === b.isPlugin ? a.name.localeCompare(b.name) : a.isPlugin ? 1 : -1
                      )
                      .filter((x) => x && !x.hidden && searchIncludes(x.name))
                      .map(({ route, name, key, icon }) => (
                        <NavLink to={route} sx={{ textDecoration: "none" }} key={key}>
                          <SidenavItem
                            navKey={key}
                            name={name}
                            active={route === pathname}
                            nested
                            icon={showModuleIcons && icon}
                          />
                        </NavLink>
                      ))}
                  </SidenavItem>
                ))}
              </SidenavItem>
            </SidenavList>
          </SidenavList>
        )}
      </SidenavCollapse>
    </Sentry.ErrorBoundary>
  );
}
