import { createApi } from "@reduxjs/toolkit/query/react";
import { defaultTransformErrorResponse, defaultTransformResponse, dynamicBaseQuery } from "../redux-utils.js";

export const guildApi = createApi({
  reducerPath: "guildApi",
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    getGuild: builder.query({
      query: ({ botId, guildId }) => ({
        url: `api/${botId}/guild/${guildId}`,
      }),
      providesTags: (result, error, { botId, guildId }) => [{ type: "Guild", id: `${botId}-${guildId}` }],
      transformResponse: defaultTransformResponse,
      transformErrorResponse: defaultTransformErrorResponse,
    }),
    updateGuild: builder.mutation({
      query: ({ botId, guildId, data }) => ({
        url: `api/${botId}/guild/${guildId}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, { botId, guildId }) => [{ type: "Guild", id: `${botId}-${guildId}` }],
      transformResponse: defaultTransformResponse,
      transformErrorResponse: defaultTransformErrorResponse,
    }),
    getTextChannels: builder.query({
      query: ({ botId, guildId }) => ({
        url: `api/${botId}/guild/${guildId}/textChannels`,
      }),
      providesTags: (result, error, { botId, guildId }) => [
        { type: "TextChannels", id: `${botId}-${guildId}` },
      ],
      transformResponse: defaultTransformResponse,
      transformErrorResponse: defaultTransformErrorResponse,
    }),
    getVoiceChannels: builder.query({
      query: ({ botId, guildId }) => ({
        url: `api/${botId}/guild/${guildId}/voiceChannels`,
      }),
      providesTags: (result, error, { botId, guildId }) => [
        { type: "VoiceChannels", id: `${botId}-${guildId}` },
      ],
      transformResponse: defaultTransformResponse,
      transformErrorResponse: defaultTransformErrorResponse,
    }),

    getCategories: builder.query({
      query: ({ botId, guildId }) => ({
        url: `api/${botId}/guild/${guildId}/categories`,
      }),
      providesTags: (result, error, { botId, guildId }) => [
        { type: "Categories", id: `${botId}-${guildId}` },
      ],
      transformResponse: defaultTransformResponse,
      transformErrorResponse: defaultTransformErrorResponse,
    }),
    getEmojis: builder.query({
      query: ({ botId, guildId }) => ({
        url: `api/${botId}/guild/${guildId}/emotes`,
      }),
      providesTags: (result, error, { botId, guildId }) => [{ type: "Emojis", id: `${botId}-${guildId}` }],
      transformResponse: defaultTransformResponse,
      transformErrorResponse: defaultTransformErrorResponse,
    }),
    getRoles: builder.query({
      query: ({ botId, guildId }) => ({
        url: `api/${botId}/guild/${guildId}/roles`,
      }),
      providesTags: (result, error, { botId, guildId }) => [{ type: "Roles", id: `${botId}-${guildId}` }],
      transformResponse: defaultTransformResponse,
      transformErrorResponse: defaultTransformErrorResponse,
    }),
    getUser: builder.query({
      query: ({ botId, guildId, userId }) => ({
        url: `api/${botId}/guild/${guildId}/user/${userId}`,
      }),
      providesTags: (result, error, { botId, guildId, userId }) => [
        { type: "User", id: `${botId}-${guildId}-${userId}` },
      ],
      transformResponse: defaultTransformResponse,
      transformErrorResponse: defaultTransformErrorResponse,
    }),
    getStatistics: builder.query({
      query: ({ botId, guildId, size = 10, page = 0, search = "" }) => ({
        url: `api/${botId}/guild/${guildId}/statistics?size=${size}&page=${page}&search=${search}`,
        params: { size, page, search },
      }),
      providesTags: (result, error, { botId, guildId }) => [
        { type: "Statistics", id: `${botId}-${guildId}` },
      ],
      transformResponse: defaultTransformResponse,
      transformErrorResponse: defaultTransformErrorResponse,
    }),
  }),
});
export const {
  useGetGuildQuery,
  useUpdateGuildMutation,
  useGetTextChannelsQuery,
  useGetCategoriesQuery,
  useGetEmojisQuery,
  useGetRolesQuery,
  useGetVoiceChannelsQuery,
  useGetUserQuery,
  useGetStatisticsQuery,
} = guildApi;
