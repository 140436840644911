import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHashtag, faMegaphone, faQuestionCircle, faVolume } from "@fortawesome/pro-solid-svg-icons";
import MDBox from "../MDBox";

const iconMapping = {
  TEXT: faHashtag,
  VOICE: faVolume,
  NEWS: faMegaphone,
  DEFAULT: faQuestionCircle,
};

const iconStyle = {
  TEXT: { marginRight: "8px" },
  VOICE: { marginRight: "5px" },
  NEWS: { marginRight: "5px" },
  DEFAULT: { marginRight: "5px" },
};

export const AutoCompleteChannelIcon = React.memo(({ option }) => {
  if (!option) return null;

  const icon = iconMapping[option.type] || iconMapping.DEFAULT;
  return <FontAwesomeIcon icon={icon} style={iconStyle[option.type] || iconStyle.DEFAULT} />;
});

export const AutoCompleteRoleIcon = React.memo(({ option }) => {
  console.log(option);
  return (
    <MDBox
      component="span"
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="10px"
      height="10px"
      borderRadius="33%"
      mr={1}
      sx={{
        backgroundColor: option.colorHex,
      }}
    />
  );
});
