/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Custom styles for the SidenavCollapse
import {
  collapseArrow,
  collapseIcon,
  collapseIconBox,
  collapseItem,
  collapseText,
} from "examples/Sidenav/styles/sidenavCollapse"; // Material Dashboard 2 PRO React context
import { useSessionParameter } from "../ParameterUtils";
import { useSelector } from "react-redux";

function SidenavCollapse({
  icon,
  name = "?",
  children = false,
  active = false,
  navKey,
  noCollapse = false,
  sticky,
  open = false,
  addMargin,
  ...rest
}) {
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = useSelector((state) => state.ui);

  const [opened, setOpend] = useSessionParameter(navKey, false, false);

  const toggleCollapse = () => {
    setOpend(!opened);
  };

  return (
    <>
      <ListItem
        component="li"
        onClick={toggleCollapse}
        sx={(theme) => ({
          position: sticky ? "sticky" : "relative",
          top: sticky ? "2px" : "auto",
          zIndex: sticky ? 100 : "inherit",
          marginY: addMargin ? addMargin : 0,
        })}
      >
        <MDBox
          {...rest}
          sx={(theme) => ({
            ...collapseItem(theme, { active, transparentSidenav, whiteSidenav, darkMode }),
            outline: sticky ? "3px double rgba(87, 94, 110, 1)" : "none",
            backdropFilter: sticky ? "blur(10px)" : "none",
            background: sticky ? theme.palette.background.sidenav : "none",
          })}
        >
          <ListItemIcon
            sx={(theme) => collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode })}
          >
            {typeof icon === "string" ? (
              <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon>
            ) : (
              icon
            )}
          </ListItemIcon>

          <ListItemText
            primary={name}
            sx={(theme) =>
              collapseText(theme, {
                miniSidenav,
                transparentSidenav,
                whiteSidenav,
                active,
              })
            }
          />

          <Icon
            sx={(theme) =>
              collapseArrow(theme, {
                noCollapse,
                transparentSidenav,
                whiteSidenav,
                miniSidenav,
                opened,
                active,
                darkMode,
              })
            }
          >
            expand_less
          </Icon>
        </MDBox>
      </ListItem>
      {children && (
        <Collapse in={opened} unmountOnExit>
          {children}
        </Collapse>
      )}
    </>
  );
}

// Typechecking props for the SidenavCollapse
SidenavCollapse.propTypes = {
  icon: PropTypes.node.isRequired,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  navKey: PropTypes.string.isRequired,
  children: PropTypes.node,
  active: PropTypes.bool,
  noCollapse: PropTypes.bool,
  open: PropTypes.bool,
};

export default SidenavCollapse;
