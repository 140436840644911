import Card from "@mui/material/Card";
import { useEffect, useMemo, useState } from "react";
import { Grid } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Collapse from "@mui/material/Collapse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faRectangleXmark } from "@fortawesome/pro-solid-svg-icons";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import MDBox from "../../../../../../../components/MDBox";
import MDTypography from "../../../../../../../components/MDTypography";
import { hexToDecimal } from "../../../../Tools";
import MDButton from "../../../../../../../components/MDButton";
import apiMessages from "../../../../../../../apiMessages";
import Builder from "../../../../../../../components/EmbedBuilder/Builder";
import Preview from "../../../../../../../components/EmbedBuilder/Preview";
import { useForm } from "react-hook-form";
import Logger from "../../../../../../../Logger";
import {
  useGetCategoriesQuery,
  useGetRolesQuery,
  useGetTextChannelsQuery,
} from "../../../../../../../store/guild/guild-api";
import {
  useCreateModuleDataMutation,
  useGetModuleDataQuery,
} from "../../../../../../../store/modules/module-api";
import { CategoryForm } from "./CategoryForm";

export function TicketSystemNewCategoryForm({ botId, serverId }) {
  const {
    data: rolesData = [],
    isLoading: rolesIsLoading,
    refetch: rolesRefetch,
    isRefetching: rolesIsRefetching,
  } = useGetRolesQuery({
    botId,
    guildId: serverId,
  });

  const {
    data: textChannelsData = [],
    isLoading: textChannelsIsLoading,
    refetch: textChannelsRefetch,
    isRefetching: textChannelsIsRefetching,
  } = useGetTextChannelsQuery({
    botId,
    guildId: serverId,
  });

  const {
    data: categoriesData = [],
    isLoading: categoriesIsLoading,
    refetch: categoriesRefetch,
    isRefetching: categoriesIsRefetching,
  } = useGetCategoriesQuery({
    botId,
    guildId: serverId,
  });

  const {
    data: ticketPanelData = [],
    isLoading: ticketPanelIsLoading,
    refetch: ticketPanelRefetch,
    isRefetching: ticketPanelIsRefetching,
  } = useGetModuleDataQuery({
    botId,
    guildId: serverId,
    module: "ticket/panel",
  });

  const [update, { isLoading }] = useCreateModuleDataMutation();

  const [newInput, setNewInput] = useState({});

  const [loaded, setLoaded] = useState(false);
  const [opened, setOpened] = useState(false);
  const [builderOpened, setBuilderOpened] = useState(false);

  const [json, setJson] = useState(
    sessionStorage.getItem("ticketEmbedBuilder")
      ? JSON.parse(sessionStorage.getItem("ticketEmbedBuilder"))
      : {
          content: "",
          embeds: [],
        }
  );

  const formHandler = useForm({
    defaultValues: {
      name: "",
      supportRoles: [],
      category: "",
      ticketName: "",
      archive: "",
      customLogsChannel: "",
      instantDelete: false,
      autoTranscript: false,
      sendDM: false,
      pinBotMessage: false,
      mentionUserOnCreation: false,
      allowReopening: false,
      mentionSupportRoles: false,
      publicTicket: false,
      emoji: "",
      description: "",
    },
  });
  const { control, handleSubmit, setValue, getValues } = formHandler;

  const onSubmit = async (e) => {
    Logger.info("SubmitEvent", { module: "SubmitForm" }, e);
    const sendJson = JSON.parse(JSON.stringify(json));

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < json.embeds.length; i++) {
      console.log("Color", sendJson.embeds[i].color);
      // eslint-disable-next-line no-restricted-globals
      if (sendJson.embeds[i].color && isNaN(sendJson.embeds[i].color)) {
        console.log("Color Before", sendJson.embeds[i].color);
        sendJson.embeds[i].color = hexToDecimal(sendJson.embeds[i]?.color?.replace("#", ""));
        console.log("Color After", sendJson.embeds[i].color);
      }
      if (sendJson.embeds[i].timestamp) {
        const isoString = new Date(sendJson.embeds[i].timestamp).toISOString();
        console.log("Before", sendJson.embeds[i].timestamp, isoString);
        sendJson.embeds[i].timestamp = isoString; /* .substring(0, isoString.length - 1); */
        console.log("After", sendJson.embeds[i].timestamp, isoString);
      } else {
        sendJson.embeds[i].timestamp = null;
      }
    }

    update({
      botId,
      guildId: serverId,
      module: "ticket/panel",
      data: {
        ...e,
        welcomeJson: {
          content: sendJson.content || "",
          embeds: sendJson.embeds || [],
          attachments: [],
        },
        emoji: e.emoji || null,
        description: e.description || "",
      },
    }).then(({ data, error }) => {
      Logger.info("SubmitEvent", { module: "SubmitForm" }, data, error);
      apiMessages.showMessage({
        type: "TicketPanel",
        title: data ? "added" : error?.data?.error || error,
      });
    });
  };

  useEffect(() => {
    // setJson({});
    const saveInterval = setTimeout(() => {
      sessionStorage.setItem("ticketEmbedBuilder", JSON.stringify(json));
    }, 1000 * 5);
    return () => {
      clearTimeout(saveInterval);
    };
  }, [json]);

  return (
    <>
      <Dialog fullScreen open={builderOpened} onClose={() => setBuilderOpened(false)}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton edge="start" onClick={() => setBuilderOpened(false)} aria-label="close">
              <MDTypography>
                <FontAwesomeIcon icon={faRectangleXmark} />
              </MDTypography>
            </IconButton>
            <MDTypography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Eröffnungsnachricht (wird automatisch gespeichert)
            </MDTypography>
          </Toolbar>
        </AppBar>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={6}>
            {useMemo(
              () => (
                <Builder setJson={setJson} json={json} />
              ),
              [setJson]
            )}
          </Grid>
          <Grid item xs={12} lg={6}>
            {useMemo(
              () => (
                <Preview json={json} bot={botId} />
              ),
              [json, botId]
            )}
          </Grid>
        </Grid>
      </Dialog>
      <Card>
        <MDBox p={2}>
          <MDBox
            width="100%"
            display="flex"
            justifyContent="space-between"
            onClick={() => setOpened(!opened)}
          >
            <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
              Neue Kategorie
            </MDTypography>
            {ticketPanelData.length > 0 ? (
              <MDButton color="primary" iconOnly size="small" sx={{ marginLeft: "auto" }}>
                <FontAwesomeIcon
                  icon={faAngleUp}
                  style={{
                    transform: opened ? "rotate(0)" : "rotate(-180deg)",
                    transition: "transform .5s",
                  }}
                />
              </MDButton>
            ) : null}
          </MDBox>
        </MDBox>

        <Collapse in={ticketPanelData.length < 1 || opened} unmountOnExit>
          <form onSubmit={handleSubmit(onSubmit)}>
            <CategoryForm
              formHandler={formHandler}
              guildId={serverId}
              botId={botId}
              setBuilderOpened={setBuilderOpened}
            />
            <MDBox pt={1} pb={2} px={2}>
              <MDButton variant="contained" type="submit" color="primary" fullWidth disabled={isLoading}>
                Neue Kategorie erstellen
                {isLoading && (
                  <FontAwesomeIcon
                    icon={faSpinnerThird}
                    size="lg"
                    spin
                    style={{
                      marginLeft: "5px",
                    }}
                  />
                )}
              </MDButton>
            </MDBox>
          </form>
        </Collapse>
      </Card>
    </>
  );
}
