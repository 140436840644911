import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import Error404Modal from "../../../components/Dialog/Error404Modal";
import MDBox from "../../../components/MDBox";
import backgroundImage from "../../../assets/images/bg-profile.jpeg";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import Card from "@mui/material/Card";
import { useGetEntitiesQuery, useListBotsQuery } from "../../../store/admin/admin-api";
import { useEffect, useState } from "react";
import { FormAutocomplete } from "../../../components/Form/FormInput";
import { useForm } from "react-hook-form";
import MDButton from "../../../components/MDButton";
import MDTypography from "../../../components/MDTypography";
import Grid from "@mui/material/Grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRobot } from "@fortawesome/pro-solid-svg-icons";
import { AdminEntitiesTable } from "./AdminEntitiesTable";
import { useSearchParams } from "react-router-dom";
import MDAlert from "../../../components/MDAlert";
import apiMessages from "../../../apiMessages";

export function AdminEntities() {
  const {
    data: botsData = {},
    isLoading: botsIsLoading,
    isError: botsIsError,
    error: botsError,
  } = useListBotsQuery({
    page: 0,
    size: 1000,
  });

  const [currentBot, setCurrentBot] = useState(null);
  const [currentEntity, setCurrentEntity] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    data: entitiesData = [],
    isLoading: entitiesIsLoading,
    isError: entitiesIsError,
    error: entitiesError,
  } = useGetEntitiesQuery(
    {
      botId: currentBot,
    },
    {
      skip: !currentBot,
    }
  );

  const formHandler = useForm({
    defaultValues: {
      botId: "",
    },
  });
  const { control, handleSubmit, setValue } = formHandler;

  const onSubmit = async (e) => {
    setCurrentBot(e.botId);
  };

  useEffect(() => {
    const botId = searchParams.get("botId");
    if (botId) {
      setCurrentBot(botId);
      setValue("botId", botId);
    }
    const entity = searchParams.get("entity");
    if (entity) setCurrentEntity(entity);
  }, []);

  useEffect(() => {
    currentBot && searchParams.set("botId", currentBot);
    currentEntity && searchParams.set("entity", currentEntity);
    setSearchParams(searchParams);
  }, [currentBot, currentEntity]);

  console.log({
    entitiesError,
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Error404Modal />
      <MDBox mb={2} />
      <MDBox position="relative" mb={5}>
        <MDBox
          display="flex"
          alignItems="center"
          position="relative"
          minHeight="15rem"
          borderRadius="xl"
          sx={{
            backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.info.main, 0.6),
                rgba(gradients.info.state, 0.6)
              )}, url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            overflow: "hidden",
          }}
        />
        <Card
          sx={{
            position: "relative",
            mt: -5,
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          {" "}
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <MDTypography variant="h5" fontWeight="medium">
                <FontAwesomeIcon icon={faRobot} size="2x" />
              </MDTypography>
            </Grid>
            <Grid item>
              <MDBox height="100%" mt={0.5} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium" sx={{ width: "100%" }}>
                  Admin Bot Entities
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        </Card>

        <Card sx={{ mt: 1 }}>
          <MDBox p={2}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <MDBox
                display="flex"
                flexDirection={{
                  xs: "column",
                  lg: "row",
                }}
                gap={2}
              >
                <MDBox
                  sx={{
                    width: "100%",
                  }}
                >
                  <FormAutocomplete
                    formHandler={formHandler}
                    fullWidth
                    rules={{
                      required: "Dieses Feld ist erforderlich",
                    }}
                    name={"botId"}
                    options={botsData?.content?.map((bot) => ({
                      label: bot.botId,
                      value: bot.botId,
                    }))}
                  />
                </MDBox>
                <MDButton
                  color="primary"
                  type="submit"
                  sx={{
                    whiteSpace: "nowrap",
                    minWidth: "max-content",
                  }}
                >
                  Entities laden
                </MDButton>
              </MDBox>
            </form>
            {!currentBot && (
              <MDAlert color="warning" sx={{ mt: 2 }}>
                <MDTypography variant="h6" fontWeight="medium">
                  Bitte wähle einen Bot aus, um die Entities zu laden
                </MDTypography>
              </MDAlert>
            )}
          </MDBox>

          {currentBot && !entitiesIsLoading && (
            <MDBox gap={2} p={2}>
              {entitiesData
                ?.map((entity) => ({
                  label: entity
                    .split("/")
                    .pop()
                    .split("_")
                    .map((e) => e[0].toUpperCase() + e.slice(1))
                    .join(" "),
                  value: entity.split("/").pop(),
                }))
                .sort((a, b) => a.label.localeCompare(b.label))
                .map(({ label, value }) => {
                  return (
                    <MDButton
                      color="info"
                      variant={currentEntity === value ? "contained" : "outlined"}
                      onClick={() => setCurrentEntity(value)}
                      sx={{
                        m: 0.5,
                      }}
                    >
                      {label}
                    </MDButton>
                    // entity is a string /api/715718893641138286/admin/entity/channel_logs
                  );
                })}
            </MDBox>
          )}
        </Card>
        {entitiesIsError && (
          <MDAlert color="error" sx={{ my: 2 }}>
            <MDTypography variant="h6">
              {apiMessages.getErrorFromResponse("AdminEntities", entitiesError) || "Unbekannter Fehler"}
            </MDTypography>
          </MDAlert>
        )}
        {currentEntity && (
          <Card sx={{ mt: 2 }}>
            <AdminEntitiesTable botId={currentBot} entityName={currentEntity} />
          </Card>
        )}
      </MDBox>
    </DashboardLayout>
  );
}
