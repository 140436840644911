import apiMessages from "../../../../apiMessages";
import MDButton from "../../../../components/MDButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { useCreateNotUsedBotMutation, useGetNotUsedBotsQuery } from "../../../../store/admin/admin-api";
import { useForm } from "react-hook-form";
import Logger from "../../../../Logger";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import { FormInput } from "../../../../components/Form/FormInput";
import Card from "@mui/material/Card";

export function AdminBotTokenForm({ fullHeight = false }) {
  const { data: tokenData, tokenIsLoading } = useGetNotUsedBotsQuery();
  const [createToken, { isLoading: createTokenIsLoading }] = useCreateNotUsedBotMutation();

  const formHandler = useForm({
    defaultValues: {
      token: "",
    },
  });
  const { control, handleSubmit } = formHandler;

  const onSubmit = async (e) => {
    createToken({
      data: {
        ...e,
      },
    }).then(({ data, error }) => {
      Logger.info("SubmitEvent", { module: "SubmitForm" }, data, error);
      apiMessages.showMessage({
        type: "AdminToken",
        title: data ? "added" : error?.data?.error || error,
      });
    });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        height: fullHeight ? "100%" : "auto",
      }}
    >
      <Card sx={{ height: "100%" }}>
        <MDBox pt={2} px={2}>
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            Neuen Token hinzufügen
          </MDTypography>
        </MDBox>
        <MDBox p={2} display="flex" flexDirection="column" gap={1}>
          <FormInput
            formHandler={formHandler}
            name="token"
            label="Token"
            autoComplete="off"
            rules={{
              required: "Es muss ein Token angegeben werden.",
            }}
          />
          <MDButton
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            disabled={createTokenIsLoading}
            endIcon={createTokenIsLoading && <FontAwesomeIcon icon={faSpinnerThird} spin />}
          >
            Hinzufügen
          </MDButton>
        </MDBox>
      </Card>
    </form>
  );
}
