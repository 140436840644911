/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

/**
 All of the routes for the Material Dashboard 2 PRO React are added here,
 You can add a new route, customize the routes and delete the routes here.

 Once you add a new route on this file it will be visible automatically on
 the Sidenav.

 For adding a new route you can follow the existing routes in the routes array.
 1. The `type` key with the `collapse` value is used for a route.
 2. The `type` key with the `title` value is used for a title inside the Sidenav.
 3. The `type` key with the `divider` value is used for a divider between Sidenav items.
 4. The `name` key is used for the name of the route on the Sidenav.
 5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
 6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
 7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
 inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
 8. The `route` key is used to store the route location which is used for the react router.
 9. The `href` key is used to store the external links location.
 10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
 10. The `component` key is used to store the component of its route.
 */
import Overview from "layouts/account/Overview";
import Settings from "layouts/account/Settings";
import { Navigate, Route } from "react-router-dom";
import BotOverview from "./layouts/bot/BotOverview/BotOverview";
import Whitelist from "./layouts/bot/Whitelist/Whitelist";
import ServerOverview from "./layouts/bot/server/ServerOverview/ServerOverview";
import ServerList from "./layouts/bot/server/Serverlist/ServerList";
import BotList from "./layouts/bot/BotList/BotList";
import SendEmbed from "./layouts/bot/server/SendEmbed/SendEmbed";
import Logout from "./layouts/account/Logout";
import ModuleManager from "./layouts/bot/server/modules/ModuleManager";
import Login from "./layouts/account/Login";
import AdminBotPanel from "./layouts/admin/AdminBotPanel/AdminBotPanel";
import AdminBotCreate from "./layouts/admin/AdminBotPanel/AdminBotCreate/AdminBotCreate";
import AdminPinPanel from "./layouts/admin/AdminPinPanel";
import SupportPinJoin from "./layouts/support/SupportPinJoin";
import ServiceOffline from "./layouts/account/ServiceOffline";
import AdminBotUpdate from "./layouts/admin/AdminBotPanel/AdminBotUpdate/AdminBotUpdate";
import ImageEditorModal, { imageExample } from "./components/Modal/ImageEditorModal";
import { WelcomeRedirect } from "./layouts/home/WelcomeRedirect";
import { AdminEntities } from "./layouts/admin/AdminEntities/AdminEntities";
import { AdminBotToken } from "./layouts/admin/AdminBotPanel/AdminBotToken/AdminBotToken";
import { BotError } from "./layouts/bot/BotError";
import { PublicXP } from "./layouts/home/PublicXP";
import { PublicGiveaway } from "./layouts/home/PublicGiveaway";
import PluginManager from "./layouts/bot/server/plugins/PluginManager";
import { PublicVote } from "./layouts/home/PublicVote";

export function rawRoutes() {
  return [
    <Route key="route-welcome" path="/" element={<WelcomeRedirect />} />,
    <Route key="route-bots" exact path="/bots" element={<BotList />} />,
    <Route key="route-bot-overview" path="/bots/:botId" element={<BotOverview />} />,
    <Route key="route-bot-error" path="/bots/:botId/error" element={<BotError />} />,
    <Route key="route-bot-whitelist" path="/bots/:botId/whitelist" element={<Whitelist />} />,
    <Route key="route-server-list" path="/bots/:botId/servers" element={<ServerList />} />,
    <Route key="route-server-overview" path="/bots/:botId/servers/:serverId" element={<ServerOverview />} />,
    <Route key="route-send-embed" path="/bots/:botId/servers/:serverId/send-embed" element={<SendEmbed />} />,
    <Route
      key="route-module-manager"
      path="/bots/:botId/servers/:serverId/modules"
      element={<ModuleManager />}
    />,
    <Route
      key="route-module-detail"
      path="/bots/:botId/servers/:serverId/modules/:module"
      element={<ModuleManager />}
    />,

    //  <Route key "route-plugin-manager" path="/bots/:botId/servers/:serverId/plugins" element={<PluginManager />} />,
    <Route
      key="route-plugin-detail"
      path="/bots/:botId/servers/:serverId/plugins/:plugin"
      element={<PluginManager />}
    />,

    <Route key="route-login" exact path="/account/login" element={<Login />} />,
    <Route key="route-account-overview" exact path="/account" element={<Overview />} />,
    <Route key="route-settings" exact path="/account/settings" element={<Settings />} />,
    <Route key="route-logout" exact path="/account/logout" element={<Logout />} />,
    // TODO Fix Redirect
    <Route key="route-navigate-login" exact path="/login" element={<Navigate to="/account/login" />} />,
    <Route key="route-navigate-logout" exact path="/logout" element={<Navigate to="/account/logout" />} />,

    <Route key="route-support-join" exact path="/support/join" element={<SupportPinJoin />} />,
    <Route key="route-navigate-support" exact path="/support" element={<Navigate to="/support/join" />} />,

    <Route key="route-admin-bot" exact path="/admin/bot" element={<AdminBotPanel />} />,
    <Route key="route-admin-bot-create" exact path="/admin/bot/create" element={<AdminBotCreate />} />,
    <Route key="route-admin-bot-update" exact path="/admin/bot/:botId" element={<AdminBotUpdate />} />,
    <Route key="route-admin-bot-token" exact path="/admin/bot/token" element={<AdminBotToken />} />,

    <Route key="route-admin-pin" exact path="/admin/pin" element={<AdminPinPanel />} />,
    <Route key="route-admin-entity" exact path="/admin/entity" element={<AdminEntities />} />,
    <Route key="route-navigate-admin" exact path="/admin" element={<Navigate to="/admin/bot/list" />} />,

    <Route
      key="route-public-xp"
      exact
      path="/public/bot/:botId/guild/:guildId/module/xp"
      element={<PublicXP />}
    />,
    <Route
      key="route-public-giveaway"
      exact
      path="/public/bot/:botId/guild/:guildId/module/giveaway/:id"
      element={<PublicGiveaway />}
    />,
    <Route
      key="route-public-vote"
      exact
      path="/public/bot/:botId/guild/:guildId/module/vote/:id"
      element={<PublicVote />}
    />,
    <Route key="route-service-offline" exact path="/service/unreachable" element={<ServiceOffline />} />,

    <Route
      key="route-image-editor"
      exact
      path="/editor"
      element={<ImageEditorModal open canvasData={imageExample} />}
    />,
    <Route key="route-default" path="*" element={<Navigate to="/bots" replace />} />,
  ];
}
