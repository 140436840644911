import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import React, { useEffect, useState } from "react";
import MDBox from "../MDBox";
import MDButton from "../MDButton";
import { EmbedStorageDialog } from "./components/EmbedStorageDialog";
import { useFieldArray, useForm } from "react-hook-form";
import { FormInput } from "../Form/FormInput";
import { Embed } from "./components/Embed";
import * as Sentry from "@sentry/react";
import { FallbackComponent } from "../ErrorBoundary/FallbackComponent";

function Builder({ json, setJson, viewOnly = false }) {
  const [showStorageDialog, setShowStorageDialog] = useState(false);

  const formHandler = useForm({
    defaultValues: json || {
      content: "",
      embeds: [],
    },
    mode: "onTouched",
  });
  const { control, getValues, setValue, handleSubmit, reset } = formHandler;

  useEffect(() => {
    const timeout = setInterval(() => {
      const values = getValues();
      if (JSON.stringify(values) !== JSON.stringify(json)) setJson(values);
    }, 1000);
    return () => {
      clearInterval(timeout);
    };
  }, [getValues, json]);

  useEffect(() => {
    reset(json);
  }, [json]);

  const embedArray = useFieldArray({
    control,
    shouldUnregister: false,
    name: "embeds",
  });
  const { fields: embeds, append: addEmbed, remove: removeEmbed } = embedArray;

  return (
    <Sentry.ErrorBoundary
      fallback={({ eventId, error, componentStack, resetError }) => (
        <FallbackComponent
          error={error}
          eventId={eventId}
          componentStack={componentStack}
          resetError={resetError}
          display="flex"
          flexDirection="column"
        />
      )}
    >
      <form onSubmit={handleSubmit(() => {})}>
        {showStorageDialog && (
          <EmbedStorageDialog
            open={showStorageDialog}
            setOpen={setShowStorageDialog}
            get={getValues}
            set={reset}
          />
        )}
        <MDBox sx={{ borderRadius: "5px" }}>
          <MDBox>
            <Card>
              <MDBox p={2}>
                <FormInput
                  variant="outlined"
                  label="Content"
                  multiline
                  minRows={3}
                  sx={{ width: "100%" }}
                  disabled={viewOnly}
                  formHandler={formHandler}
                  name="content"
                  rules={{
                    maxLength: {
                      value: 2048,
                      message: "Der Content darf maximal 2048 Zeichen lang sein.",
                    },
                  }}
                />
              </MDBox>
            </Card>
          </MDBox>
          {/* eslint-disable-next-line no-unused-vars */}
          <MDBox pt={1} pb={1} display="flex" flexDirection="column" gap={1}>
            {embeds?.map((embed, embedIndex) => (
              <>
                <Embed
                  embed={embed}
                  embedIndex={embedIndex}
                  embedArray={embedArray}
                  formHandler={formHandler}
                  viewOnly={viewOnly}
                />
              </>
            ))}
          </MDBox>
          <MDBox pt={1} pb={1} display="flex" flexDirection="row" gap={1}>
            <MDButton
              sx={{ width: "100%" }}
              variant="gradient"
              color="info"
              disabled={embeds?.length >= 10 || viewOnly}
              onClick={() =>
                addEmbed({
                  type: "rich",
                  title: "",
                  description: "",
                  url: "",
                  color: "#5865F2",
                  author: {
                    name: "",
                    url: "",
                    icon_url: "",
                  },
                  footer: {
                    text: "",
                    icon_url: "",
                  },
                  timestamp: "",
                  image: {
                    url: "",
                  },
                  thumbnail: {
                    url: "",
                  },
                  fields: [],
                })
              }
            >
              Embed hinzufügen
            </MDButton>
            <MDButton
              variant="gradient"
              color="secondary"
              sx={{ width: "100%" }}
              disabled={viewOnly}
              onClick={() => setValue("embeds", [])}
            >
              Embed leeren
            </MDButton>
          </MDBox>
        </MDBox>

        <MDBox pt={1} pb={1}>
          <MDButton color="dark" fullWidth onClick={() => setShowStorageDialog(true)}>
            Speicher verwalten
          </MDButton>
        </MDBox>
      </form>
    </Sentry.ErrorBoundary>
  );
}

export default Builder;

Builder.propTypes = {
  json: PropTypes.objectOf(PropTypes.any).isRequired,
  setJson: PropTypes.func.isRequired,
  viewOnly: PropTypes.bool,
};
