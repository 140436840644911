/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React from "react";
import { BrowserRouter, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import App from "App";
import "./assets/css/cookie.css";
import "./assets/css/custom.css";
import "material-icons/iconfont/material-icons.css";
import { initText } from "./consoleMessages";
import store from "./store";
import config, { devModeEnabled } from "./config";
import FlagProvider, { UnleashClient } from "@unleash/proxy-client-react";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";

initText();

export const client = new UnleashClient(devModeEnabled() ? config.local.unleash : config.unleash);
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const container = document.getElementById("app");
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <FlagProvider startClient={false} unleashClient={client}>
        <App />
      </FlagProvider>
    </Provider>
  </BrowserRouter>
);
