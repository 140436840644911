/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components

// Material Dashboard 2 PRO React components

// Material Dashboard 2 PRO React imageExample components

// WelcomeLeave page components
// Images
import { useMemo, useState } from "react";
import { Menu, Skeleton } from "@mui/material";
import {
  faCircleCheck,
  faCircleMinus,
  faCircleXmark,
  faSpinnerThird,
} from "@fortawesome/pro-duotone-svg-icons";

import {
  faCircleCheck as faCircleCheckSolid,
  faCircleMinus as faCircleMinusSolid,
  faCircleXmark as faCircleXmarkSolid,
  faTrash,
} from "@fortawesome/pro-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MDButton from "../../../../../components/MDButton";
import apiErrorMessages from "../../../../../apiMessages";
import ReloadTable from "../../../../../components/Table/ReloadTable";
import { useSelector } from "react-redux";
import {
  useDeleteModuleDataMutation,
  useUpdateModuleDataMutation,
} from "../../../../../store/modules/module-api";
import NotificationItem from "../../../../../examples/Items/NotificationItem";
import SuggestionPreview from "./SuggestionPreview";
import MDBox from "../../../../../components/MDBox";

const module = "suggestion";

const statusTypes = {
  ACCEPTED: {
    label: "Akzeptiert",
    color: "success",
    icon: faCircleCheckSolid,
  },
  DENIED: {
    label: "Abgelehnt",
    color: "error",
    icon: faCircleXmarkSolid,
  },
  NONE: {
    label: "Neutral",
    color: "secondary",
    icon: faCircleMinusSolid,
  },
};

export function SuggestionTable({ botId, guildId }) {
  const [deleteModuleData, { isLoading: deleteModuleDataIsLoading }] = useDeleteModuleDataMutation({});

  const [updateModuleData, { isLoading: updateModuleDataIsLoading }] = useUpdateModuleDataMutation({});

  const expertMode = useSelector((state) => state.ui.expertMode);

  const [openMenu, setOpenMenu] = useState(false);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  const deleteDataEvent = (id) => {
    deleteModuleData({ botId, guildId, module, id }).then(({ data, error }) => {
      apiErrorMessages.showMessage({
        type: "Suggestion",
        title: data ? "deleted" : error?.data?.error || error,
      });
    });
  };

  const tableColumns = useMemo(
    () =>
      [
        {
          Header: "Vorschlag",
          accessor: "data",
          width: "700px",
        },
        expertMode
          ? {
              Header: "Einreicher",
              accessor: "userId",
            }
          : null,
        {
          Header: "Actions",
          accessor: "actions",
          disableSort: true,
          width: "0%",
        },
      ].filter((x) => x),
    [expertMode]
  );

  const tableRows = useMemo(
    () => (row) => ({
      message: row.message,
      data: (
        <SuggestionPreview
          botId={botId}
          guildId={guildId}
          createdAt={row.createdAt}
          message={row.message}
          userId={row.userId}
        />
      ),

      actions: (
        <MDBox display="flex" gap={2} flexDirection="column">
          <MDButton
            color={statusTypes[row.status]?.color || statusTypes.NONE.color}
            variant="gradient"
            disabled={updateModuleDataIsLoading}
            onClick={handleOpenMenu}
            id={row.id}
            iconOnly
          >
            <FontAwesomeIcon icon={statusTypes[row.status]?.icon || statusTypes.NONE.icon} />
            {updateModuleDataIsLoading && <FontAwesomeIcon icon={faSpinnerThird} spin />}
          </MDButton>
          <MDButton
            variant="contained"
            iconOnly
            disabled={deleteModuleDataIsLoading}
            color="primary"
            onClick={() => deleteDataEvent(row.id)}
          >
            {deleteModuleDataIsLoading ? (
              <FontAwesomeIcon icon={faSpinnerThird} size="lg" spin />
            ) : (
              <FontAwesomeIcon icon={faTrash} size="lg" />
            )}
          </MDButton>
        </MDBox>
      ),
    }),
    [deleteModuleDataIsLoading]
  );

  console.log(openMenu);

  return (
    <>
      <Menu
        anchorEl={openMenu}
        anchorReference={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={Boolean(openMenu)}
        onClose={handleCloseMenu}
        sx={{ mt: 1 }}
      >
        <NotificationItem
          icon={<FontAwesomeIcon icon={faCircleCheck} color="info" />}
          title="Akzeptieren"
          color="success"
          onClick={() => {
            updateModuleData({ botId, guildId, module, id: openMenu.id, data: { status: "ACCEPTED" } }).then(
              ({ data, error }) => {
                apiErrorMessages.showMessage({
                  type: "Suggestion",
                  title: data ? "accepted" : error?.data?.error || error,
                });
                handleCloseMenu();
              }
            );
          }}
        />
        <NotificationItem
          icon={<FontAwesomeIcon icon={faCircleMinus} />}
          title="Neutralisieren"
          onClick={() => {
            updateModuleData({ botId, guildId, module, id: openMenu.id, data: { status: "NONE" } }).then(
              ({ data, error }) => {
                apiErrorMessages.showMessage({
                  type: "Suggestion",
                  title: data ? "neutralized" : error?.data?.error || error,
                });
                handleCloseMenu();
              }
            );
          }}
        />
        <NotificationItem
          icon={<FontAwesomeIcon icon={faCircleXmark} />}
          title="Ablehnen"
          color="error"
          onClick={() => {
            updateModuleData({ botId, guildId, module, id: openMenu.id, data: { status: "DENIED" } }).then(
              ({ data, error }) => {
                apiErrorMessages.showMessage({
                  type: "Suggestion",
                  title: data ? "denied" : error?.data?.error || error,
                });
                handleCloseMenu();
              }
            );
          }}
        />
      </Menu>
      <ReloadTable
        path={{
          botId,
          guildId,
          module,
        }}
        initialState={{
          sortBy: [
            {
              id: "createdAt",
              desc: true,
            },
          ],
        }}
        loaded
        rowsLoad={() => ({
          roleId: <Skeleton width={100} animation="wave" />,
          roleName: <Skeleton width={100} animation="wave" />,
          actions: <Skeleton width={100} animation="wave" />,
        })}
        columns={tableColumns}
        rows={tableRows}
        canSearch
        showTotalEntries
      />{" "}
    </>
  );
}
