import { fabric } from "fabric";
import Logger from "../../../Logger";

function randomInteger(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
 * Check if hex color is valid
 * @param hex
 * @return {boolean}
 */
export function isHexColor(hex) {
  return /^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/.test(hex);
}

/**
 * Converts a hex color to rgba
 * @param hex
 * @param opacity
 * @return {string}
 */
export function hexToRgba(hex, opacity) {
  hex = hex.replace("#", "");
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

const uniqueId = () => Math.floor(Math.random() * (99 - 10 + 1) + 10);

/**
 * Converts a rgba color to hex
 * @param {string} rgba
 * @return {{color: string, opacity: number}}
 */
export function rgbaToHex(rgba) {
  const sep = rgba.indexOf(",") > -1 ? "," : " ";
  rgba = rgba.substr(5).split(")")[0].split(sep);

  // Strip the slash if using space-separated syntax
  if (rgba.indexOf("/") > -1) rgba.splice(3, 1);

  // eslint-disable-next-line no-restricted-syntax,guard-for-in
  for (const R in rgba) {
    const r = rgba[R];
    if (r.indexOf("%") > -1) {
      const p = r.substr(0, r.length - 1) / 100;

      if (R < 3) {
        rgba[R] = Math.round(p * 255);
      } else {
        rgba[R] = p;
      }
    }
  }

  let r = (+rgba[0]).toString(16);
  let g = (+rgba[1]).toString(16);
  let b = (+rgba[2]).toString(16);
  let a = Math.round(+rgba[3] * 255);

  if (r.length === 1) r = `0${r}`;
  if (g.length === 1) g = `0${g}`;
  if (b.length === 1) b = `0${b}`;
  if (a.length === 1) a = `0${a}`;

  return {
    color: `#${r}${g}${b}`,
    opacity: (a / 255).toFixed(2),
  };
}

/**
 * Decode a backend compatible rectangle object to a fabric rectangle object
 * @param element
 * @return {fabric.Rect}
 */
function decodeRectangle(element = {}) {
  const { x, y, width, height, borderRadius, color, transparency } = element;
  const object = new fabric.Rect({
    top: y,
    left: x,
    width,
    height,
    fill: hexToRgba(color, transparency),
    rx: borderRadius,
    ry: borderRadius,
    sv_type: "RECTANGLE",
  });
  object.setControlsVisibility({
    mtr: false,
  });
  object.set("uniqueId", uniqueId());

  return object;
}

/**
 * Encode a fabric rectangle object to a backend compatible object
 * @param  {fabric.Rect} element
 * @return {{color: string, borderRadius: *, transparency: number, x, width, y, type: string, height}}
 */
function encodeRectangle(element = {}) {
  const { top, left, width, height, fill, rx, ry } = element;
  const { color, opacity } = rgbaToHex(fill);

  return {
    x: left,
    y: top,
    width,
    height,
    color,
    transparency: opacity,
    borderRadius: rx || ry,
    type: "RECTANGLE",
  };
}

/**
 *
 * @param {Object} element
 * @return {fabric.Text}
 */
function decodeText(element = {}) {
  const { x, y, text, color, font, fontSize } = element;
  const object = new fabric.Text(text || "", {
    top: y,
    left: x,
    fill: color,
    fontFamily: font,
    lockUniScaling: true,
    fontSize: fontSize / 1.5,
    sv_type: "TEXT",
  });
  object.setControlsVisibility({
    mt: false,
    mb: false,
    ml: false,
    mr: false,
    mtr: false,
  });
  object.set("uniqueId", uniqueId());

  return object;
}

/**
 * Encode a fabric text object to a backend compatible object
 * @param {fabric.Text} element
 * @return {{color, x, y, fontSize: number, text, type: string, font}}
 */
function encodeText(element = {}) {
  const { top, left, text, fill, fontFamily, fontSize } = element;
  return {
    x: left,
    y: top,
    text,
    color: fill,
    font: fontFamily,
    fontSize: fontSize * 1.5,
    type: "TEXT",
  };
}

/**
 *
 * @param func {function}
 * @param defaultReturn {*}
 * @return {*}
 */
export function inlineTryCatch(func = () => {}, defaultReturn = null) {
  try {
    return func();
  } catch (e) {
    return defaultReturn;
  }
}

/**
 * Decode a backend compatible image object to a fabric image object
 * @param {Object} element
 * @return {Promise<fabric.Image>}
 */
function decodeImage(element = {}) {
  const { x, y, width, height, borderRadius } = element;
  let { url } = element;
  if (url === "%user_avatar%") {
    url = `https://cdn.discordapp.com/embed/avatars/${randomInteger(0, 5)}.png`;
  }

  // eslint-disable-next-line new-cap
  return new Promise((resolve, reject) => {
    // TODO: warum clipTo das nicht?
    function roundedCorners(ctx) {
      return new fabric.Rect({
        left: -ctx.width / 2,
        top: -ctx.height / 2,
        rx: borderRadius,
        ry: borderRadius,
        width: ctx.width,
        height: ctx.height,
        fill: "#000000",
      });
    }

    fabric.Image.fromURL(
      url,
      (img) => {
        if (width) img.scaleToWidth(width);
        if (height) img.scaleToHeight(height);
        img.set({
          top: y,
          left: x,
          sv_type: "IMAGE",
          sv_url: url,
          clipPath: roundedCorners(img),
        });
        img.setControlsVisibility({
          mt: true,
          mb: true,
          ml: true,
          mr: true,
          mtr: false,
        });
        img.set("uniqueId", uniqueId());

        resolve(img);
      },
      {
        crossOrigin: "Anonymous",
      }
    );
  });
}

/**
 * Encode a fabric image object to a backend compatible object
 * @param {fabric.Image} element
 * @return {{borderRadius: *, x, width: number, y, type: string, url: *, height: number}}
 */
function encodeImage(element = {}) {
  const { top, left, width, height, sv_url, src, clipPath, scaleX, scaleY } = element;
  const { rx, ry } = clipPath;
  return {
    x: left,
    y: top,
    width: width * scaleX,
    height: height * scaleY,
    borderRadius: rx || ry,
    type: "IMAGE",
    // eslint-disable-next-line camelcase
    url: sv_url || src,
  };
}

/**
 * Decode a backend compatible loader object to a fabric loader object
 * @param {Object} element
 * @return {fabric.Group}
 */
function decodeLoader(element = {}) {
  const {
    x,
    y,
    width,
    height,
    progress,
    barBorderRadius,
    barColor,
    barOffsetX,
    barOffsetY,
    backgroundBorderRadius,
    backgroundColor,
    borderColor,
  } = element;
  const object = new fabric.Group(
    [
      new fabric.Rect({
        top: 0,
        left: 0,
        width,
        height,
        fill: backgroundColor,
        rx: backgroundBorderRadius,
        ry: backgroundBorderRadius,
      }),
      new fabric.Rect({
        top: 0 + barOffsetY,
        left: 0 + barOffsetX,
        width: ((width - barOffsetX * 2) * progress) / 100,
        height: height - barOffsetY * 2,
        fill: barColor,
        rx: barBorderRadius,
        ry: barBorderRadius,
      }),
    ],
    {
      top: y,
      left: x,
      borderColor,
      sv_type: "LOADER",
      sv_barOffsetX: barOffsetX,
      sv_barOffsetY: barOffsetY,
      sv_progress: progress,
    }
  );

  object.setControlsVisibility({
    mtr: false,
  });
  object.set("uniqueId", uniqueId());

  return object;
}

/**
 * Encode a fabric loader object to a backend compatible object
 * @param {fabric.Group} element
 * @return {{backgroundColor, barBorderRadius, x, width, y, progress, barColor, barOffsetY, type: string, barOffsetX, backgroundBorderRadius, height}}
 */
function encodeLoader(element = {}) {
  const { top, left, width, height, sv_barOffsetX, sv_barOffsetY, sv_progress } = element;
  console.log(element);
  const children = element.objects;
  const background = children[0];
  const bar = children[1];
  return {
    x: left,
    y: top,
    width,
    height,
    barColor: bar.fill,
    barBorderRadius: bar.rx,
    backgroundColor: background.fill,
    backgroundBorderRadius: background.rx,
    barOffsetX: sv_barOffsetX,
    barOffsetY: sv_barOffsetY,
    progress: sv_progress,
    type: "LOADER",
  };
}

/**
 * Decode a backend compatible element object to a fabric object
 * @param {Object} element
 * @return {fabric.Group|Promise<fabric.Image>|fabric.Rect|null|fabric.Text}
 */
export function decodeBackendElements(element = {}) {
  switch (element.type) {
    case "RECTANGLE":
      return decodeRectangle(element);
    case "TEXT":
      return decodeText(element);
    case "IMAGE":
      return decodeImage(element);
    case "LOADER":
      return decodeLoader(element);
    default:
      Logger.warning("Unknown element type", {}, element.type, element);
      return null;
  }
}

/**
 *
 * @param {fabric.Group|Promise<fabric.Image>|fabric.Rect|null|fabric.Text} element
 * @return {Object|Object[]|null}
 */
export function encodeBackendElements(element = {}) {
  switch (element.sv_type) {
    case "RECTANGLE":
      return encodeRectangle(element);
    case "TEXT":
      return encodeText(element);
    case "IMAGE":
      return encodeImage(element);
    case "LOADER":
      return encodeLoader(element);
    default:
      Logger.warning("Unknown element sv_type", {}, element.sv_type, element);
      return null;
  }
}

/**
 *
 * @param {Object} decodedJson
 * @param {React.Ref} fabricRef
 * @return {Promise<{backgroundColor: string, elements: *[], width: number, height: number}>}
 */
export async function decodedJsonToEncodedJson(decodedJson = {}, fabricRef = {}) {
  const encodedJson = {
    width: fabricRef.width || 800,
    height: fabricRef.height || 800,
    backgroundColor: fabricRef.backgroundColor || "#000000",
    elements: [],
  };
  encodedJson.backgroundColor = decodedJson.background;
  // eslint-disable-next-line no-restricted-syntax
  for (const element of decodedJson.objects) {
    let encodedElements = encodeBackendElements(element);
    // eslint-disable-next-line no-continue
    if (!encodedElements) continue;
    if (!(encodedElements instanceof Array)) encodedElements = [encodedElements];

    // eslint-disable-next-line no-restricted-syntax
    for (let encodedElement of encodedElements) {
      if (encodedElement instanceof Promise) {
        encodedElement = await encodedElement;
      }
      encodedJson.elements.push(encodedElement);
    }
  }
  return encodedJson;
}

export { decodeRectangle, decodeText, decodeLoader, decodeImage };

/**
 * Decode a backend compatible json to a fabric array
 * @param encodedJson
 * @return {Promise<*[]>}
 */
export async function encodedJsonToFabricArray(encodedJson = {}) {
  const fabricArray = [];
  if (!encodedJson.data) return fabricArray;
  for (const element of encodedJson.data.elements) {
    let parsedElements = decodeBackendElements(element);
    if (!parsedElements) continue;
    if (!(parsedElements instanceof Array)) parsedElements = [parsedElements];
    for (let parsedElement of parsedElements) {
      if (parsedElement instanceof Promise) parsedElement = await parsedElement;
      parsedElement.uniqueId = Math.floor(Math.random() * (99 - 10 + 1) + 10);
      fabricArray.push(parsedElement);
    }
  }

  return fabricArray;
}

/**
 * Decode a backend compatible json to a fabric canvas
 * @type {{top: updateObject.top, left: updateObject.left, width: updateObject.width, getActiveObject: ((function(*): (Object|null))|*), height: updateObject.height}}
 */
export const updateObject = {
  getActiveObject: (canvas) => {
    // eslint-disable-next-line react/destructuring-assignment
    const activeObject = canvas.getActiveObjects();
    if (activeObject.length >= 1) {
      return activeObject[0];
    }
    return null;
  },
  top: (canvas, value, updateMeta) => {
    const object = updateObject.getActiveObject(canvas);
    console.log(object, "object");
    object.set({ top: Number(value) });
    object.setCoords();
    canvas.renderAll();
    updateMeta({ ...object, top: Number(value) });
  },
  left: (canvas, value) => {
    const object = updateObject.getActiveObject(canvas);
    console.log(object, "object");
    object.set({ left: Number(value) });
    object.setCoords();
    canvas.renderAll();
  },
  width: (canvas, value) => {
    const object = updateObject.getActiveObject(canvas);
    object.set({ width: Number(value) });
    object.setCoords();
    canvas.renderAll();
  },
  height: (canvas, value) => {
    const object = updateObject.getActiveObject(canvas);
    object.set({ height: Number(value) });
    object.setCoords();
    canvas.renderAll();
  },
};

/**
 *
 * @param {Object} object
 * @param {Object[]} replacer
 * @return {any}
 */
export const replace = (object, replacer) => {
  if (!object || !object.data) return;
  const newObject = JSON.parse(JSON.stringify(object));
  const {
    data: { elements },
  } = newObject;

  elements.map((element) => {
    if (element.text) {
      replacer.forEach((replace) => {
        element.text = element.text.replace(`%${replace.name}%`, replace.value);
      });
    }
    return element;
  });
  return newObject;
};
