// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";

// Material Dashboard 2 PRO React imageExample components
import DashboardLayout from "../../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import FooterSimple from "../../../../components/Footer/FooterSimple";

import Card from "@mui/material/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRobot } from "@fortawesome/pro-solid-svg-icons";
import { useParams } from "react-router-dom";
import backgroundImage from "../../../../assets/images/bg-profile.jpeg";
import Error404Modal from "../../../../components/Dialog/Error404Modal";
import { UpdatePropertyForm } from "./UpdatePropertyForm";
import { UpdateStatusForm } from "./UpdateStatusForm";
import { UpdateEnvironment } from "./UpdateEnvironment";
import { UpdateBotForm } from "./UpdateBotForm";

function AdminBotUpdate() {
  const { botId } = useParams();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Error404Modal />
      <MDBox mb={2} />
      <MDBox position="relative" mb={5}>
        <MDBox
          display="flex"
          alignItems="center"
          position="relative"
          minHeight="15rem"
          borderRadius="xl"
          sx={{
            backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.info.main, 0.6),
                rgba(gradients.info.state, 0.6)
              )}, url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            overflow: "hidden",
          }}
        />

        <Card
          sx={{
            position: "relative",
            mt: -5,
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <MDTypography variant="h5" fontWeight="medium">
                <FontAwesomeIcon icon={faRobot} size="2x" />
              </MDTypography>
            </Grid>
            <Grid item>
              <MDBox height="100%" mt={0.5} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium" sx={{ width: "100%" }}>
                  Admin Bot Update
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        </Card>
      </MDBox>

      <MDBox mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <UpdatePropertyForm botId={botId} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <UpdateBotForm botId={botId} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <UpdateStatusForm botId={botId} />
          </Grid>
          <Grid item xs={12} md={6} lg={12}>
            <UpdateEnvironment botId={botId} />
          </Grid>
        </Grid>
      </MDBox>
      <FooterSimple />
    </DashboardLayout>
  );
}

export default AdminBotUpdate;
