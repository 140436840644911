import { Skeleton } from "@mui/material";
import { useMemo, useState } from "react";
import Card from "@mui/material/Card";
import { faCheck, faEdit, faTimes, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import MDBox from "../../../../../../../components/MDBox";
import DataTable from "../../../../../../../examples/Tables/DataTable";
import MDButton from "../../../../../../../components/MDButton";
import MDTypography from "../../../../../../../components/MDTypography";
import MDBadge from "../../../../../../../components/MDBadge";
import { useDeleteModuleDataMutation, useGetModuleDataQuery } from "store/modules/module-api";
import {
  useGetCategoriesQuery,
  useGetEmojisQuery,
  useGetRolesQuery,
  useGetTextChannelsQuery,
} from "store/guild/guild-api";
import { useSelector } from "react-redux";
import { TicketSystemModal } from "./TicketSystemModal";
import { getUnifiedEmoji, isUnifiedEmoji } from "../../../../../../../Utils";
import { Emoji, EmojiStyle } from "emoji-picker-react";

export function TicketSystemTable({ botId, serverId }) {
  const {
    data: textChannelData = [],
    isLoading: textChannelIsLoading,
    isError: textChannelIsError,
    isFetching: textChannelIsFetching,
  } = useGetTextChannelsQuery({ botId: botId, guildId: serverId });

  const {
    data: categoriesData = [],
    isLoading: categoriesIsLoading,
    isError: categoriesIsError,
    isFetching: categoriesIsFetching,
  } = useGetCategoriesQuery({ botId: botId, guildId: serverId });

  const {
    data: roleData = [],
    isLoading: roleIsLoading,
    isError: roleIsError,
    isFetching: roleIsFetching,
  } = useGetRolesQuery({ botId: botId, guildId: serverId });

  const {
    data: ticketPanelData = [],
    isLoading: ticketPanelIsLoading,
    isError: ticketPanelIsError,
    isFetching: ticketPanelIsFetching,
  } = useGetModuleDataQuery({ botId: botId, guildId: serverId, module: "ticket/panel" });

  const {
    data: emoteData = [],
    isLoading: emoteIsLoading,
    isError: emoteIsError,
    isFetching: emoteIsFetching,
  } = useGetEmojisQuery({ botId: botId, guildId: serverId });

  const expertMode = useSelector((state) => state.ui.expertMode);

  const [editTicketCategory, setEditTicketCategory] = useState(null);

  const [deleteModuleData, { isLoading: deleteModuleDataIsLoading }] = useDeleteModuleDataMutation();

  const deleteEvent = (id) => {
    deleteModuleData({
      botId: botId,
      guildId: serverId,
      module: "ticket/panel",
      id: id,
    });
  };

  const ticketCategoriesTableData = useMemo(
    () =>
      ticketPanelData?.map((row) => ({
        name: row.name,
        id: row.id,
        description: row.description,
        emoji:
          row.emoji &&
          (isUnifiedEmoji(getUnifiedEmoji(row.emoji)) ? (
            <Emoji unified={getUnifiedEmoji(row.emoji)} emojiStyle={EmojiStyle.TWITTER} size={22} />
          ) : (
            <img
              alt={emoteData?.find((r) => r.id === row.emoji)?.name || <i>Name nicht gefunden</i>}
              loading="lazy"
              width="auto"
              height="20"
              style={{
                marginRight: "5px",
              }}
              src={`https://cdn.discordapp.com/emojis/${row.emoji}.webp?size=96&quality=lossless`}
            />
          )),

        ticketName: row.ticketName,
        supportRoles: row.supportRoles.map((role) => (
          <MDBadge
            size="xs"
            color="primary"
            variant="gradient"
            badgeContent={
              roleData.find((r) => r.id?.toString() === role?.toString())?.name || (
                <>
                  <abbr title={role.toString()}>
                    <i>Name nicht gefunden</i>
                  </abbr>
                </>
              )
            }
          />
        )),
        categoryId: (
          <abbr title={row.category?.toString()}>
            {categoriesData.find((r) => r.id?.toString() === row.category?.toString())?.name || (
              <i>Name nicht gefunden</i>
            )}
          </abbr>
        ),
        archiveId: (
          <abbr title={row.archive?.toString()}>
            {categoriesData.filter((r) => r.id?.toString() === row.archive?.toString())[0]?.name || (
              <i>Name nicht gefunden</i>
            )}
          </abbr>
        ),
        customLogsChannel: (
          <abbr title={row.customLogsChannel?.toString()}>
            {textChannelData.filter((c) => c.id?.toString() === row.customLogsChannel?.toString())[0]
              ?.name || <i>Name nicht gefunden</i>}
          </abbr>
        ),
        allowReopening: row.allowReopening ? (
          <FontAwesomeIcon icon={faCheck} />
        ) : (
          <FontAwesomeIcon icon={faTimes} />
        ),
        autoTranscript: row.autoTranscript ? (
          <FontAwesomeIcon icon={faCheck} />
        ) : (
          <FontAwesomeIcon icon={faTimes} />
        ),
        instantDelete: row.instantDelete ? (
          <FontAwesomeIcon icon={faCheck} />
        ) : (
          <FontAwesomeIcon icon={faTimes} />
        ),
        mentionSupportRoles: row.mentionSupportRoles ? (
          <FontAwesomeIcon icon={faCheck} />
        ) : (
          <FontAwesomeIcon icon={faTimes} />
        ),
        mentionUserOnCreation: row.mentionUserOnCreation ? (
          <FontAwesomeIcon icon={faCheck} />
        ) : (
          <FontAwesomeIcon icon={faTimes} />
        ),
        pinBotMessage: row.pinBotMessage ? (
          <FontAwesomeIcon icon={faCheck} />
        ) : (
          <FontAwesomeIcon icon={faTimes} />
        ),
        publicTicket: row.publicTicket ? (
          <FontAwesomeIcon icon={faCheck} />
        ) : (
          <FontAwesomeIcon icon={faTimes} />
        ),
        sendDM: row.sendDM ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} />,
        actions: (
          <>
            <MDBox display="flex" justifyContent="flex-end">
              <MDButton
                variant="contained"
                iconOnly
                color="warning"
                sx={{ mr: 1 }}
                onClick={() => setEditTicketCategory(row.id)}
              >
                <FontAwesomeIcon icon={faEdit} />
              </MDButton>
              <MDButton
                variant="contained"
                disabled={deleteModuleDataIsLoading}
                iconOnly
                color="primary"
                onClick={() => {
                  deleteEvent(row.id);
                }}
              >
                {deleteModuleDataIsLoading ? (
                  <FontAwesomeIcon icon={faSpinnerThird} size="lg" spin fade />
                ) : (
                  <FontAwesomeIcon icon={faTrash} size="lg" />
                )}
              </MDButton>
            </MDBox>
          </>
        ),
      })),
    [ticketPanelData, categoriesData, textChannelData, roleData, emoteData, deleteModuleDataIsLoading]
  );

  const ticketCategoriesTableColumns = useMemo(
    () =>
      [
        expertMode
          ? {
              Header: "ID",
              accessor: "id",
            }
          : null,

        {
          Header: "Name",
          accessor: "name",
        },
        { Header: "Beschreibung", accessor: "description" },
        {
          Header: "Emoji",
          accessor: "emoji",
        },
        {
          Header: "TicketName",
          accessor: "ticketName",
        },
        expertMode
          ? {
              Header: "SupportRoles",
              accessor: "supportRoles",
            }
          : null,
        expertMode
          ? {
              Header: "categoryId",
              accessor: "categoryId",
            }
          : null,
        expertMode
          ? {
              Header: "archiveId",
              accessor: "archiveId",
            }
          : null,
        expertMode
          ? {
              Header: "customLogsChannel",
              accessor: "customLogsChannel",
            }
          : null,
        expertMode
          ? {
              Header: "allowReopening",
              accessor: "allowReopening",
            }
          : null,
        expertMode
          ? {
              Header: "autoTranscript",
              accessor: "autoTranscript",
            }
          : null,
        expertMode
          ? {
              Header: "instantDelete",
              accessor: "instantDelete",
            }
          : null,
        expertMode
          ? {
              Header: "mentionSupportRoles",
              accessor: "mentionSupportRoles",
            }
          : null,
        expertMode
          ? {
              Header: "mentionUserOnCreation",
              accessor: "mentionUserOnCreation",
            }
          : null,
        expertMode
          ? {
              Header: "pinBotMessage",
              accessor: "pinBotMessage",
            }
          : null,
        expertMode
          ? {
              Header: "publicTicket",
              accessor: "publicTicket",
            }
          : null,
        expertMode
          ? {
              Header: "sendDM",
              accessor: "sendDM",
            }
          : null,
        {
          Header: "Actions",
          accessor: "actions",
          width: "0%",
          disableSort: true,
        },
      ].filter((x) => x),
    [expertMode]
  );

  const allLoaded =
    !textChannelIsLoading &&
    !roleIsFetching &&
    !categoriesIsFetching &&
    !ticketPanelIsFetching &&
    !emoteIsFetching;

  return (
    <>
      <TicketSystemModal
        serverId={serverId}
        botId={botId}
        editTicketCategory={editTicketCategory}
        onClose={() => {
          setEditTicketCategory(null);
        }}
      />
      <Card sx={{ height: "100%" }}>
        <MDBox pt={2} px={2}>
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            Ticketkategorien
          </MDTypography>
        </MDBox>
        <MDBox p={2}>
          <DataTable
            table={{
              columns: ticketCategoriesTableColumns,
              rows: allLoaded
                ? ticketCategoriesTableData
                : [1, 2, 3, 4, 5].map(() => ({
                    ticketName: <Skeleton width={100} animation="wave" />,
                    supportRoles: <Skeleton width={100} animation="wave" />,
                    archiveId: <Skeleton width={100} animation="wave" />,
                    customLogsChannel: <Skeleton width={100} animation="wave" />,
                    categoryId: <Skeleton width={100} animation="wave" />,
                    panelId: <Skeleton width={100} animation="wave" />,
                    allowReopening: <Skeleton width={100} animation="wave" />,
                    autoTranscript: <Skeleton width={100} animation="wave" />,
                    instantDelete: <Skeleton width={100} animation="wave" />,
                    mentionSupportRoles: <Skeleton width={100} animation="wave" />,
                    mentionUserOnCreation: <Skeleton width={100} animation="wave" />,
                    pinBotMessage: <Skeleton width={100} animation="wave" />,
                    publicTicket: <Skeleton width={100} animation="wave" />,
                    sendDM: <Skeleton width={100} animation="wave" />,
                    actions: (
                      <MDButton
                        variant="contained"
                        size="small"
                        sx={{ width: "100%" }}
                        disabled
                        color="primary"
                      >
                        Löschen
                      </MDButton>
                    ),
                  })),
            }}
            canSearch
          />
        </MDBox>
      </Card>
    </>
  );
}
