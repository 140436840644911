/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components

// Material Dashboard 2 PRO React components

// Material Dashboard 2 PRO React imageExample components

// WelcomeLeave page components
// Images
import {useMemo} from "react";
import {Skeleton} from "@mui/material";
import apiErrorMessages from "../../../../../apiMessages";
import {useGetTextChannelsQuery} from "../../../../../store/guild/guild-api";
import {useSelector} from "react-redux";
import {useGetModuleDataQuery, useUpdateModuleDataMutation} from "../../../../../store/modules/module-api";
import MDTypography from "../../../../../components/MDTypography";
import Switch from "@mui/material/Switch";
import {CustomLogChannelForm} from "./CustomLogChannelForm";
import DataTable from "../../../../../examples/Tables/DataTable";

const module = "log";

const logDesc = {
  TICKET_CLOSE: "Ticket wird geschlossen",
};

export function LogsTable({ botId, guildId }) {
  const { data: textChannelData = [], isLoading: textChannelsIsLoading } = useGetTextChannelsQuery({
    botId,
    guildId,
  });

  const {
    data: logsData = [],
    isLoading: logsDataIsLoading,
    isFetching: logsDataIsFetching,
    refetch: logsDataRefetch,
  } = useGetModuleDataQuery({
    botId,
    guildId,
    module: "log",
  });

  const [updateModuleData, { isLoading: updateModuleDataIsLoading }] = useUpdateModuleDataMutation({});

  const expertMode = useSelector((state) => state.ui.expertMode);

  const updateDataEvent = (id, enabled) => {
    updateModuleData({
      botId,
      guildId,
      module,
      id,
      data: {
        enabled,
      },
    }).then(({ data, error }) => {
      apiErrorMessages.showMessage({
        type: "LogSystem",
        title: data ? "typeStatusUpdated" : error?.data?.error || error,
      });
    });
  };

  const tableColumns = useMemo(
    () =>
      [
        {
          Header: "LogType",
          accessor: "type",
        },
        {
          Header: "Kategorie",
          accessor: "category",
        },
        {
          Header: "Beschreibung",
          accessor: "description",
        },
        {
          Header: "Action",
          accessor: "active",
        },
        {
          Header: "Actions",
          accessor: "actions",
          disableSort: true,
          width: "7%",
        },
      ].filter((x) => x),
    []
  );

  const tableRows = useMemo(
    () => (row) => ({
      type: row.logType,
      category: row.category,
      description: logDesc[row.logType] || "?",
      active: (
        <>
          <Switch
            disabled={updateModuleDataIsLoading}
            checked={row.enabled}
            onChange={(e) => updateDataEvent(row.logType, e.target.checked)}
          />
          <MDTypography variant="button">{row.enabled ? "Aktiviert" : "Deaktiviert"}</MDTypography>
        </>
      ),

      actions: (
        <CustomLogChannelForm
          guildId={guildId}
          enabled={row.enabled}
          botId={botId}
          selectedChannel={row.channelId}
          logType={row.logType}
        />
      ),
    }),
    [textChannelData, updateModuleDataIsLoading]
  );

  return (
    <DataTable
      entriesPerPage={{ defaultValue: 50 }}
      table={{
        columns: tableColumns,
        rows: !logsDataIsLoading
          ? logsData.map(tableRows) || []
          : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(() => ({
              type: <Skeleton width={100} animation="wave" />,
              description: <Skeleton width={100} animation="wave" />,
              actions: <Skeleton width={100} animation="wave" />,
            })),
      }}
      canSearch
    />
  );
}
