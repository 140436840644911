import { useGetCategoriesQuery, useGetTextChannelsQuery } from "../../../../../store/guild/guild-api";
import Grid from "@mui/material/Grid";
import MDBox from "../../../../../components/MDBox";
import { FormCategoryAutocomplete, FormInput } from "../../../../../components/Form/FormInput";
import { AutoCompleteChannelIcon } from "../../../../../components/Form/AutoCompleteIcon";

// TODO - not finished
export function GiveawayForm({ formHandler, botId, guildId }) {
  const {
    data: categoriesData = [],

    isFetching: categoriesIsFetching,
    refetch: categoriesRefetch,
  } = useGetCategoriesQuery({
    botId,
    guildId,
  });
  const {
    data: textChannelData = [],
    refetch: textChannelsRefetch,
    isFetching: textChannelsIsFetching,
  } = useGetTextChannelsQuery({
    botId,
    guildId,
  });

  const isBefore = (date) => {
    if (!date) {
      return true;
    }
    const newDate = new Date(date);
    const today = new Date();
    return newDate > today;
  };

  return (
    <Grid item xs={12}>
      <MDBox pt={2} px={2}>
        <FormCategoryAutocomplete
          name="channelId"
          label="Channel"
          categories={categoriesData}
          options={[...textChannelData].sort((x, y) => x.category - y.category)}
          groupByFunction="default"
          labelIcon={(option) => <AutoCompleteChannelIcon option={option} />}
          additionalParameters={["type"]}
          formHandler={formHandler}
          refetch={() => {
            textChannelsRefetch();
            categoriesRefetch();
          }}
          refetching={textChannelsIsFetching || categoriesIsFetching}
          rules={{
            required: "Du musst einen Channel angeben.",
          }}
        />
      </MDBox>
      <MDBox pt={2} px={2}>
        <FormInput
          formHandler={formHandler}
          name="winnerNumber"
          label="Wie viele Gewinner soll es geben?"
          rules={{
            required: "Du musst eine Zahl angeben.",
            min: {
              value: 1,
              message: "Es muss mindestens ein Gewinner geben.",
            },
          }}
        />
      </MDBox>
      <MDBox pt={2} px={2}>
        <FormInput
          formHandler={formHandler}
          name="prize"
          label="Was gibt es zu gewinnen?"
          rules={{
            required: "Du musst einen Preis angeben.",
          }}
        />
      </MDBox>
      <MDBox pt={2} px={2}>
        <FormInput
          name="endTime"
          label="Wann soll das Gewinnspiel enden?"
          type="datetime-local"
          hideStatusIcon
          formHandler={formHandler}
          InputLabelProps={{ shrink: true }}
          rules={{
            required: "Du musst ein Datum angeben.",
            validate: (value) => isBefore(value) || "Das Datum muss in der Zukunft liegen.",
          }}
        />
      </MDBox>
    </Grid>
  );
}
