import MDBox from "../../../../../components/MDBox";
import { FormCategoryAutocomplete, FormCheckbox, FormInput } from "../../../../../components/Form/FormInput";
import { AutoCompleteChannelIcon } from "../../../../../components/Form/AutoCompleteIcon";
import { Controller, useFieldArray } from "react-hook-form";
import MDInput from "../../../../../components/MDInput";
import { InputAdornment } from "@mui/material";
import MDButton from "../../../../../components/MDButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import MDTypography from "../../../../../components/MDTypography";
import Grid from "@mui/material/Grid";
import { useGetCategoriesQuery, useGetTextChannelsQuery } from "../../../../../store/guild/guild-api";

export default function VoteSystemForm({ formHandler, botId, guildId }) {
  const {
    data: textChannelData = [],
    isFetching: textChannelIsFetching,

    refetch: textChannelRefetch,
  } = useGetTextChannelsQuery({
    botId,
    guildId,
  });
  const {
    data: categoriesData = [],

    isFetching: categoriesIsFetching,
    refetch: categoriesRefetch,
  } = useGetCategoriesQuery({
    botId,
    guildId,
  });

  const { control, handleSubmit } = formHandler;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "answers",
  });

  const isBefore = (date) => {
    if (!date) {
      return true;
    }
    const newDate = new Date(date);
    const today = new Date();
    return newDate > today;
  };

  return (
    <Grid item xs={12}>
      <MDBox pt={2} px={2}>
        <FormCategoryAutocomplete
          name="channelId"
          label="Channel"
          categories={categoriesData}
          options={[...textChannelData].sort((x, y) => x.category - y.category)}
          groupByFunction="default"
          labelIcon={(option) => <AutoCompleteChannelIcon option={option} />}
          additionalParameters={["type"]}
          formHandler={formHandler}
          refetch={() => {
            textChannelRefetch();
            categoriesRefetch();
          }}
          refetching={textChannelIsFetching || categoriesIsFetching}
          rules={{
            required: "Du musst einen Channel angeben.",
          }}
        />
      </MDBox>
      <MDBox pt={2} px={2}>
        <FormInput
          name="question"
          label="Frage"
          formHandler={formHandler}
          rules={{
            required: "Du musst eine Frage angeben.",
            maxLength: {
              value: 2048,
              message: "Die Frage darf maximal 2048 Zeichen lang sein.",
            },
          }}
        />
      </MDBox>
      {fields.map((field, index) => (
        <MDBox pt={2} px={2} key={field.id}>
          <Controller
            control={control}
            name={`answers[${index}].text`}
            defaultValue={field.text || ""}
            render={({ field }) => (
              <>
                <MDInput
                  variant="outlined"
                  label={`Antwort ${index + 1}`}
                  {...formHandler.register(field.name, {
                    required: "Du musst diese Antwort angeben, oder sie entfernen.",
                  })}
                  value={String(field?.value)}
                  error={Boolean(
                    (formHandler?.formState?.errors?.answers &&
                      formHandler?.formState?.errors?.answers[index]) ||
                      false
                  )}
                  hideStatusIcon
                  fullWidth
                  pb={0}
                  InputProps={{
                    endAdornment: fields?.length > 1 && (
                      <InputAdornment position="end">
                        <MDButton
                          sx={{ mr: 0 }}
                          variant="outlined"
                          iconOnly
                          circular
                          size="small"
                          onClick={() => remove(index)}
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </MDButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <MDTypography variant="caption" color="error" p={0} m={0}>
                  {(formHandler.formState.errors?.answers &&
                    formHandler.formState.errors?.answers[index]?.text?.message) ||
                    ""}
                </MDTypography>
              </>
            )}
          />
        </MDBox>
      ))}
      <MDBox pt={2} px={2}>
        <MDButton
          color="primary"
          disabled={fields.length >= 4}
          fullWidth
          variant="outlined"
          onClick={() => append({ text: "" })}
        >
          Antwort Hinzufügen
        </MDButton>
      </MDBox>
      <MDBox pt={2} px={2}>
        <FormInput
          name="expiresAt"
          label="Ablaufdatum"
          type="datetime-local"
          hideStatusIcon
          formHandler={formHandler}
          InputLabelProps={{ shrink: true }}
          rules={{
            required: "Du musst ein Ablaufdatum angeben.",
            validate: (value) => isBefore(value) || "Das Datum muss in der Zukunft liegen.",
          }}
        />
      </MDBox>
      <MDBox
        px={2}
        display="grid"
        gridTemplateColumns={{
          xs: "1fr",
          xl: "1fr 2fr",
        }}
        gap={1}
      >
        <FormCheckbox name="isAnonymous" label="Geheimes Voting" formHandler={formHandler} />
        <MDTypography variant="caption" p={0} m={0} sx={{ flexGrow: 1, alignContent: "center" }}>
          Wenn diese Option aktiviert ist, wird die Abstimmung anonym durchgeführt, d.h. die Namen der
          Teilnehmer werden nicht angezeigt.
        </MDTypography>

        <FormCheckbox name="isMultiVote" label="Multi Voting" formHandler={formHandler} />
        <MDTypography variant="caption" p={0} m={0} sx={{ flexGrow: 1, alignContent: "center" }}>
          Wenn diese Option aktiviert ist, können mehrere Antworten ausgewählt werden
        </MDTypography>
      </MDBox>
    </Grid>
  );
}
