import { createApi } from "@reduxjs/toolkit/query/react";
import { defaultTransformErrorResponse, defaultTransformResponse, dynamicBaseQuery } from "../redux-utils.js";

export const moduleApi = createApi({
  reducerPath: "moduleApi",
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    getModules: builder.query({
      query: ({ botId, guildId }) => ({
        url: `api/${botId}/guild/${guildId}/module`,
      }),
      transformResponse: defaultTransformResponse,
      transformErrorResponse: defaultTransformErrorResponse,
      providesTags: (result, error, { botId, guildId }) => [{ type: "Modules", id: `${botId}-${guildId}` }],
    }),
    updateModule: builder.mutation({
      query: ({ botId, guildId, data, module }) => ({
        url: `api/${botId}/guild/${guildId}/module/${module}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, { botId, guildId }) => [
        { type: "Modules", id: `${botId}-${guildId}` },
      ],
      transformResponse: defaultTransformResponse,
      transformErrorResponse: defaultTransformErrorResponse,
    }),

    getModuleData: builder.query({
      query: ({ botId, guildId, module = "", isModule = true, isPlugin = false, plugin = "", id }) => ({
        url: `api/${botId}/guild/${guildId}${isModule ? "/module" : ""}${isPlugin ? "/plugins" : ""}/${module}${plugin}${id ? `/${id}` : ""}`,
      }),
      transformResponse: defaultTransformResponse,
      transformErrorResponse: defaultTransformErrorResponse,
      providesTags: (result, error, { botId, guildId, module }) => [
        { type: "Module", id: `${botId}-${guildId}-${module}` },
      ],
    }),
    getModuleTableData: builder.query({
      query: ({
        botId,
        guildId,
        module = "",
        size,
        page,
        search,
        isModule = true,
        isPlugin = false,
        plugin = "",
      }) => ({
        url: `api/${botId}/guild/${guildId}${isModule ? "/module" : ""}${
          isPlugin ? "/plugins" : ""
        }/${module}${plugin}?size=${size}&page=${page}&search=${search}`,
        params: { size, page, search },
      }),
      transformResponse: defaultTransformResponse,
      transformErrorResponse: defaultTransformErrorResponse,
      providesTags: (result, error, { botId, guildId, module, size, page, search }) => [
        { type: "Module", id: `${botId}-${guildId}-${module}` },
        { type: "ModuleTable", id: `${botId}-${guildId}-${module}-${size}-${page}-${search}` },
      ],
    }),
    createModuleData: builder.mutation({
      query: ({ botId, guildId, module = "", data, isModule = true, isPlugin = false, plugin = "" }) => ({
        url: `api/${botId}/guild/${guildId}${isModule ? "/module" : ""}${
          isPlugin ? "/plugins" : ""
        }/${module}${plugin}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result, error, { botId, guildId, module }) => [
        { type: "Module", id: `${botId}-${guildId}-${module}` },
      ],
      transformResponse: defaultTransformResponse,
      transformErrorResponse: defaultTransformErrorResponse,
    }),
    updateModuleData: builder.mutation({
      query: ({ botId, guildId, module = "", data, id, isModule = true, isPlugin = false, plugin = "" }) => ({
        url: `api/${botId}/guild/${guildId}${isModule ? "/module" : ""}${
          isPlugin ? "/plugins" : ""
        }/${module}${plugin}${id ? `/${id}` : ""}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, { botId, guildId, module, id }) => [
        { type: "Module", id: `${botId}-${guildId}-${module}` },
      ],
      transformResponse: defaultTransformResponse,
      transformErrorResponse: defaultTransformErrorResponse,
    }),
    deleteModuleData: builder.mutation({
      query: ({ botId, guildId, module = "", id, isModule = true, isPlugin = false, plugin = "" }) => ({
        url: `api/${botId}/guild/${guildId}${isModule ? "/module" : ""}${
          isPlugin ? "/plugins" : ""
        }/${module}${plugin}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, { botId, guildId, module }) => [
        { type: "Module", id: `${botId}-${guildId}-${module}` },
      ],
      transformResponse: defaultTransformResponse,
      transformErrorResponse: defaultTransformErrorResponse,
    }),
  }),
});
export const {
  useGetModulesQuery,
  useUpdateModuleMutation,
  useGetModuleDataQuery,
  useGetModuleTableDataQuery,
  useUpdateModuleDataMutation,
  useDeleteModuleDataMutation,
  useCreateModuleDataMutation,
} = moduleApi;
