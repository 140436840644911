/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";

// Material Dashboard 2 PRO React imageExample components
import DashboardLayout from "../../../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../../../examples/Navbars/DashboardNavbar";
import FooterSimple from "../../../../../components/Footer/FooterSimple";

// WelcomeLeave page components
// Images
import {useMemo, useState} from "react";
import Card from "@mui/material/Card";
import {faPartyHorn} from "@fortawesome/pro-duotone-svg-icons";
import {useParams} from "react-router-dom";
import MDButton from "../../../../../components/MDButton";
import ModuleHeader from "../components/ModuleHeader";
import Error404Modal from "../../../../../components/Dialog/Error404Modal";
import {GiveawayTable} from "./GiveawayTable";
import {GiveawayAddForm} from "./GiveawayAddForm";

function Giveaway() {
  const { botId, serverId } = useParams();

  const [loaded, setLoaded] = useState(false);
  const [textChannels, setTextChannels] = useState([]);

  const [update, setUpdate] = useState(false);

  const tableColumns = useMemo(
    () =>
      [
        {
          Header: "ChannelId",
          accessor: "channelId",
        },
        {
          Header: "ChannelName",
          accessor: "channelName",
        },
        {
          Header: "Message",
          accessor: "messageId",
        },
        {
          Header: "Beendet",
          accessor: "ended",
        },
        {
          Header: "Host",
          accessor: "host",
        },
        {
          Header: "Gewinnerzahl",
          accessor: "winnerNumber",
        },
        {
          Header: "Preis",
          accessor: "prize",
        },
        {
          Header: "Endzeit",
          accessor: "endTime",
        },
        {
          Header: "Actions",
          accessor: "actions",
        },
      ].filter((x) => x),
    []
  );

  const tableRows = useMemo(
    () => (role) => ({
      channelId: role.channelId?.toString(),
      channelName: textChannels.filter((r) => r.id?.toString() === role.channelId?.toString())[0]?.name || (
        <i>Name nicht gefunden</i>
      ),
      messageId: role.messageId?.toString(),
      ended: role.ended,
      host: role.host,
      winnerNumber: role.winnerNumber?.toString(),
      prize: role.prize,
      endTime: role.endTime,
      actions: (
        <MDButton variant="contained" size="small" sx={{ width: "100%" }} disabled={!loaded} color="primary">
          Löschen
        </MDButton>
      ),
    }),
    [loaded, botId, serverId]
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Error404Modal />
      <MDBox mb={2} />
      <ModuleHeader
        name="Gewinnspiel"
        type="GIVEAWAY_SYSTEM"
        description="Erstelle und verwalte Gewinnspiele"
        botId={botId}
        serverId={serverId}
        icon={faPartyHorn}
      />
      <MDBox mt={5} mb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <Card sx={{ height: "100%" }}>
              <MDBox pt={2} px={2}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  Aktuelle Giveaways
                </MDTypography>
              </MDBox>
              <MDBox p={2}>
                <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                  <GiveawayTable botId={botId} guildId={serverId} />
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "none",
            }}
          >
            <GiveawayAddForm botId={botId} guildId={serverId} />
          </Grid>
        </Grid>
      </MDBox>
      <FooterSimple />
    </DashboardLayout>
  );
}

export default Giveaway;
