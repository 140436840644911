import { useEffect, useRef, useState } from "react";
import Logger from "../../../Logger";
import { fabric } from "fabric";
import { encodedJsonToFabricArray, replace } from "./FabricUtils";
import { Skeleton } from "@mui/material";

export function PreviewCanvas({ canvasData = null, title = "unknown", replacer = [] }) {
  const fabricRef = useRef(null);
  const canvasRef = useRef(null);
  const imageRef = useRef(null);
  const canvasParent = useRef(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [waitForFabric, setWaitForFabric] = useState(false);
  const disposeFabric = () => {
    if (fabricRef.current) fabricRef.current.dispose();
    Logger.info("Disposed fabric", {
      module: "PreviewCanvas",
    });
    if (canvasParent.current) canvasParent.current.remove();
  };

  useEffect(() => {
    if (!canvasRef.current) return;
    const initFabric = () => {
      Logger.log("Initializing fabric", { module: "PreviewCanvas" });
      fabricRef.current = new fabric.Canvas(canvasRef.current, {
        height: canvasData?.data?.height || 800,
        width: canvasData?.data?.width || 800,
        backgroundColor: canvasData?.data?.backgroundColor || "transparent",
        selection: false,
        renderOnAddRemove: true,
        preserveObjectStacking: true,
      });
      Logger.success("Initialized fabric", { module: "PreviewCanvas" });
    };
    if (!fabricRef.current) initFabric();
  }, [canvasRef, canvasData, waitForFabric]);

  useEffect(() => {
    const wait = setInterval(() => {
      if (canvasRef.current) {
        setWaitForFabric(true);
        clearInterval(wait);
      } else {
        Logger.log("Waiting for canvas ref", {
          module: "PreviewCanvas",
        });
      }
    }, 100);
  }, [canvasRef, canvasData]);

  // Load elements
  useEffect(() => {
    if (!fabricRef.current || !imageRef.current) return;
    if (!canvasData) return;
    if (!canvasData.data) return;

    const loadElements = async () => {
      const fabricArray = await encodedJsonToFabricArray(replace({ ...canvasData }, replacer));
      for (const argument of fabricArray) {
        fabricRef.current.add(argument);
      }

      imageRef.current.src = fabricRef.current.toDataURL({
        format: "png",
        quality: 1,
        multiplier: 10,
      });
      setImageLoaded(true);

      try {
        disposeFabric();
      } catch (e) {
        Logger.error(
          `Failed to dispose ${title} fabric after loading canvas data`,
          {
            module: "PreviewCanvas",
          },
          e
        );
      }
    };
    loadElements();
  }, [fabricRef, imageRef, waitForFabric, canvasData]);

  return (
    <>
      <div ref={canvasParent}>
        <canvas
          ref={canvasRef}
          style={{
            border: "1px solid black",
            display: "none",
            height: 0,
            width: 0,
          }}
        />
      </div>
      <img
        ref={imageRef}
        alt="Current Image"
        style={{
          maxHeight: "100%",
          maxWidth: "100%",
          display: imageLoaded ? "block" : "none",
          zIndex: 1,
          position: "relative",
        }}
      />
      {!canvasData && <Skeleton width={600} height={300} animation="pulse" sx={{ position: "absolute" }} />}
    </>
  );
}
