import JSONBig from "json-bigint";
import config from "../../config";

// eslint-disable-next-line import/prefer-default-export
export function getAdmin({ type = "" }) {
  return new Promise((resolve, reject) => {
    const path = `${type}`;

    fetch(`${config.local.enabled ? config.local.backend.url : config.backend.url}/api/${path}`, {
      method: "GET",
      redirect: "manual",
      credentials: "include",
    })
      .then(async (response) => response.ok && JSONBig.parse(await response.text()))
      .then((result) => {
        if (typeof result === "undefined") {
          console.log(
            `%c[AdminTools] Error while fetching renewed data for ${path}`,
            "background: #8f0719; color: #ffffff",
            result
          );
          return reject(result);
        }
        console.log(
          `%c[AdminTools] Pulling data for ${path}`,
          "background: #1b5200; color: #ffffff",
          result
        );
        return resolve(result);
      })
      .catch((error) => {
        console.log(
          `%c[AdminTools] Error while fetching renewed data for ${path}`,
          "background: #8f0719; color: #ffffff",
          error
        );
        return reject(error);
      });
  });
}

export function postAdmin({ type = "", data = {}, ignoreResponse = false }) {
  return new Promise((resolve, reject) => {
    const path = `admin/bot/${type}`;

    try {
      fetch(`${config.local.enabled ? config.local.backend.url : config.backend.url}/api/${path}`, {
        method: "POST",
        redirect: "manual",
        credentials: "include",
        headers: new Headers({ "Content-Type": "application/json" }),
        body: JSON.stringify(data),
      })
        .then(async (response) => {
          if (response.ok) {
            return ignoreResponse ? [] : JSONBig.parse(await response.text());
          }
          throw new Error(await response.text());
        })
        .then((result) => {
          if (typeof result === "undefined") {
            throw new Error(result);
          }
          console.log(
            `%c[AdminTools] Pulling data for ${path}`,
            "background: #1b5200; color: #ffffff",
            result
          );
          return resolve(result);
        })
        .catch((error) => {
          console.log(
            `%c[AdminTools] Error while fetching renewed data for ${path}`,
            "background: #8f0719; color: #ffffff",
            error?.message
          );
          reject(error);
        });
    } catch (error) {
      reject(error);
    }
  });
}

export function deleteAdmin({ type = "", data = {}, ignoreResponse = false }) {
  return new Promise((resolve, reject) => {
    const path = `admin/bot/${type}`;

    try {
      fetch(`${config.local.enabled ? config.local.backend.url : config.backend.url}/api/${path}`, {
        method: "DELETE",
        redirect: "manual",
        credentials: "include",
        headers: new Headers({ "Content-Type": "application/json" }),
        body: JSON.stringify(data),
      })
        .then(async (response) => {
          if (response.ok) {
            return ignoreResponse ? [] : JSONBig.parse(await response.text());
          }
          throw new Error(await response.text());
        })
        .then((result) => {
          if (typeof result === "undefined") {
            throw new Error(result);
          }
          console.log(
            `%c[AdminTools] Pulling data for ${path}`,
            "background: #1b5200; color: #ffffff",
            result
          );
          return resolve(result);
        })
        .catch((error) => {
          console.log(
            `%c[AdminTools] Error while fetching renewed data for ${path}`,
            "background: #8f0719; color: #ffffff",
            error?.message
          );
          reject(error);
        });
    } catch (error) {
      reject(error);
    }
  });
}
