const config = {
  version: process.env.REACT_APP_VERSION || "development",
  local: {
    enabled: !process.env.NODE_ENV || process.env.NODE_ENV === "development",
    backend: {
      url: "http://localhost:8080",
      timeout: 5000,
    },
    bot: "715718893641138286",
    unleash: {
      url: "https://unleash.sv-studios.net/api/frontend",
      clientKey: "*:development.15c73744ef01b0a9d8bb519e4dc5312136063472c61f19c0bf7f6e65",
      refreshInterval: 15,
      appName: "Bot-Frontend",
      environment: "development",
      disableMetrics: true,
      disableRefresh: true,
      flagPrefix: "bot.frontend",
    },
  },
  backend: {
    url: "https://panel.sv-studios.net",
    timeout: 5000,
  },
  cacheTimes: {
    global: 1000 * 60,
    bot: 1000 * 30,
    server: {
      global: 1000 * 60 * 5,
      modules: 1000 * 10,
    },
  },
  unleash: {
    url: "https://unleash.sv-studios.net/api/frontend",
    clientKey: "*:production.f090826c23d1f1440975f43831439c6fc6019ec8566128c2de604b24",
    refreshInterval: 60,
    appName: "Bot-Frontend",
    environment: "production",
    flagPrefix: "bot.frontend",
  },
  sentry: {
    dsn: process.env.SENTRY_DSN || "https://2f0cc56581304dfa9b9e38c6a5120fd1@sentry.psh.services/9",
  },
};

export default config;

export const getServerUrl = () => {
  if (config.local.enabled) {
    return config.local.backend.url;
  }

  return config.backend.url;
};

export const devModeEnabled = () => {
  return config.local.enabled;
};
