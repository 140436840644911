import Card from "@mui/material/Card";
import MDBox from "../../../../../../../components/MDBox";
import MDTypography from "../../../../../../../components/MDTypography";
import MDButton from "../../../../../../../components/MDButton";
import Grid from "@mui/material/Grid";
import { useForm, useWatch } from "react-hook-form";
import {
  FormCategoryAutocomplete,
  FormInput,
  FormSimpleAutocomplete,
} from "../../../../../../../components/Form/FormInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import apiMessages from "../../../../../../../apiMessages";
import {
  useCreateModuleDataMutation,
  useGetModuleDataQuery,
} from "../../../../../../../store/modules/module-api";
import { useGetCategoriesQuery, useGetTextChannelsQuery } from "../../../../../../../store/guild/guild-api";
import { Emoji, EmojiStyle } from "emoji-picker-react";
import { Collapse, InputAdornment } from "@mui/material";
import React, { useState } from "react";
import { faAngleUp } from "@fortawesome/pro-solid-svg-icons";
import LastMessageModal from "../../../../../../../components/Modal/LastMessageModal";
import Logger from "../../../../../../../Logger";
import { getUnifiedEmoji, isUnifiedEmoji } from "../../../../../../../Utils";
import { AutoCompleteChannelIcon } from "../../../../../../../components/Form/AutoCompleteIcon";
import { useSelector } from "react-redux";
import MDAlert from "../../../../../../../components/MDAlert";

export function TicketSystemNewActionForm({ botId, guildId, fullHeight }) {
  const darkMode = useSelector((state) => state.ui.darkMode);
  const [update, { isLoading }] = useCreateModuleDataMutation();

  const {
    data: categoriesData = [],

    isFetching: categoriesIsFetching,
    refetch: categoriesRefetch,
  } = useGetCategoriesQuery({
    botId,
    guildId,
  });
  const {
    data: textChannelData = [],
    refetch: textChannelsRefetch,
    isFetching: textChannelsIsFetching,
  } = useGetTextChannelsQuery({
    botId,
    guildId,
  });

  const {
    data: ticketPanelData = [],
    isLoading: ticketPanelIsLoading,
    isFetching: ticketPanelIsFetching,
    refetch: ticketPanelRefetch,
  } = useGetModuleDataQuery({
    botId,
    guildId: guildId,
    module: "ticket/panel",
  });
  const { data: ticketActionData = [] } = useGetModuleDataQuery({
    botId,
    guildId: guildId,
    module: "ticket/action",
  });

  const formHandler = useForm({
    defaultValues: {
      channelId: "",
      messageId: "",
      panelIds: [],
    },
  });
  const { control, handleSubmit, setValue } = formHandler;

  const emote = useWatch({
    control,
    name: "emoji",
  });

  const onSubmit = async (e) => {
    update({
      botId,
      guildId,
      module: "ticket/action",
      data: {
        ...e,
      },
    }).then(({ data, error }) => {
      Logger.info("SubmitEvent", { module: "SubmitForm" }, data, error);
      apiMessages.showMessage({
        type: "TicketAction",
        title: data ? "added" : error?.data?.error || error,
      });
    });
  };

  const [showLastMessageModal, setShowLastMessageModal] = useState(false);
  const [open, setOpen] = useState(false);

  const channel = useWatch({
    control,
    name: "channelId",
  });

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        height: fullHeight ? "100%" : "auto",
      }}
    >
      <LastMessageModal
        serverId={guildId}
        botId={botId}
        open={showLastMessageModal}
        defaultChannel={channel}
        allowedMessages={["selfBot"]}
        infoText={
          <MDAlert color="info">
            <MDTypography variant="body2">
              Die ausgewählte Nachricht muss von aktuellen Bot gesendet worden sein.
            </MDTypography>
          </MDAlert>
        }
        onClose={(state, data) => {
          setShowLastMessageModal(state);
          if (data) {
            setValue("messageId", data?.message || "");
            setValue("channelId", data?.channel || "");
          }
        }}
      />
      <Card sx={{ height: "100%" }}>
        <MDBox
          p={2}
          width="100%"
          display="flex"
          justifyContent="space-between"
          onClick={() => setOpen(!open)}
        >
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            Neue Ticket Action
          </MDTypography>
          {ticketActionData.content?.length > 0 ? (
            <MDButton color="primary" iconOnly size="small" sx={{ marginLeft: "auto" }}>
              <FontAwesomeIcon
                icon={faAngleUp}
                style={{
                  transform: open ? "rotate(0)" : "rotate(-180deg)",
                  transition: "transform .5s",
                }}
              />
            </MDButton>
          ) : null}
        </MDBox>
        <Collapse in={ticketActionData.content?.length === 0 || open}>
          <MDBox p={2}>
            <Grid item xs={12}>
              <MDBox pt={2} px={2}>
                <FormCategoryAutocomplete
                  name="channelId"
                  label="Channel"
                  categories={categoriesData}
                  options={[...textChannelData].sort((x, y) => x.category - y.category)}
                  groupByFunction="default"
                  labelIcon={(option) => <AutoCompleteChannelIcon option={option} />}
                  additionalParameters={["type"]}
                  formHandler={formHandler}
                  refetch={() => {
                    textChannelsRefetch();
                    categoriesRefetch();
                  }}
                  refetching={textChannelsIsFetching || categoriesIsFetching}
                  rules={{
                    required: "Du musst einen Channel angeben.",
                  }}
                />
              </MDBox>
              <MDBox pt={2} px={2}>
                <FormInput
                  formHandler={formHandler}
                  name="messageId"
                  label="Message"
                  rules={{
                    required: "Du musst eine Message angeben.",
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" sx={{ paddingRight: 0, marginLeft: 0 }}>
                        <MDButton
                          aria-label="Add"
                          edge="end"
                          color="primary"
                          variant="contained"
                          size="small"
                          onClick={() => {
                            setShowLastMessageModal(true);
                          }}
                        >
                          Auswählen
                        </MDButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </MDBox>
              <MDBox pt={2} px={2}>
                <FormSimpleAutocomplete
                  name="panelIds"
                  multiple
                  label="Ticket Panel"
                  options={[...ticketPanelData]}
                  additionalParameters={["emoji"]}
                  labelIcon={(option) => (
                    <MDBox mr={1} display="flex" alignItems="center">
                      {isUnifiedEmoji(getUnifiedEmoji(option.emoji)) ? (
                        <Emoji
                          unified={getUnifiedEmoji(option.emoji)}
                          emojiStyle={EmojiStyle.TWITTER}
                          size={17.5}
                        />
                      ) : (
                        <img
                          alt={"Panel Icon " + option.emoji}
                          loading="lazy"
                          width="auto"
                          height="17.5px"
                          src={`https://cdn.discordapp.com/emojis/${option.emoji}.webp?size=96&quality=lossless`}
                        />
                      )}
                    </MDBox>
                  )}
                  formHandler={formHandler}
                  refetch={() => {
                    ticketPanelRefetch();
                  }}
                  refetching={ticketPanelIsFetching}
                  rules={{
                    required: "Du musst ein Ticket Panel angeben.",
                  }}
                />
              </MDBox>
            </Grid>

            <Grid item xs={12}>
              <MDBox pt={2} px={2}>
                <MDButton
                  type="submit"
                  variant="contained"
                  sx={{ width: "100%" }}
                  disabled={isLoading}
                  color="primary"
                >
                  Hinzufügen
                  {isLoading && (
                    <FontAwesomeIcon icon={faSpinnerThird} size="lg" spin style={{ marginLeft: "5px" }} />
                  )}
                </MDButton>
              </MDBox>
            </Grid>
          </MDBox>
        </Collapse>
      </Card>
    </form>
  );
}
